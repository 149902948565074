<div>
  <div>
    <goa-callout
      type="important"
      heading="{{ 'expenditureCashflowHeading' | res: pageId }}"
      *ngIf="hasPendingCashflows"
    >
      {{ expendituresWithCashflowLabel }} <br />
      {{ 'expenditureCashflowsDescription' | res: pageId }}</goa-callout
    >
  </div>

  <div id="errors-callout" class="mb-56px" style="margin-top: 0.75rem">
    <goa-callout
      type="emergency"
      heading="{{ 'errorCalloutHeading' | res: pageId }}"
      *ngIf="showErrorFieldsCallout || invalidProjectIds.length > 0"
    >
      <a
        *ngIf="checkProjectDetailsErrorCallout && !checkMediaCommunicationErrorCallout"
        (click)="jumpToField('hasMediaCommunication')"
        class="validation-message-link"
        >Project details<br
      /></a>

      <a
        *ngIf="checkMediaCommunicationErrorCallout"
        (click)="jumpToField('hasMediaCommunication')"
        class="validation-message-link"
        >{{ 'mediaCommunicationFormError' | res: pageId }}<br
      /></a>

      <span *ngFor="let error of invalidProjectIds.slice(0, NoOfInvalidProjectsToShow)">
        <a (click)="scrollIntoView(error.id)" class="validation-message-link">Project {{ error.idTxt }}</a>
        - enter project details. <br />
      </span>
      <div *ngIf="showViewAdditionalItemsLink">
        <a class="icon-link-container" (click)="onClickViewAddtionalItems(true)"
          ><goa-icon type="add-circle" size="medium" class="icon"></goa-icon>View additional items ({{
            invalidProjectIds.length - minInvalidProjectsToShow
          }})</a
        >
      </div>
      <div *ngIf="!showViewAdditionalItemsLink && invalidProjectIds.length > minInvalidProjectsToShow">
        <a class="icon-link-container" (click)="onClickViewAddtionalItems(false)"
          ><goa-icon type="remove-circle"></goa-icon>Hide additional items</a
        >
      </div>
    </goa-callout>
  </div>

  <h3 id="gen-info-heading-1">{{ 'statementExpenseSectionHeading1' | res: pageId }}</h3>

  <goa-container type="non-interactive" accent="thick" maxWidth="400px" class="total-container">
    <div slot="title">Your totals</div>

    <div class="container-example-1">
      <goa-table width="100%">
        <tbody>
          <tr>
            <td class="align-right">{{ 'totalLgffFundingAvailableIncludingCreditItems' | res: pageId }}:</td>
            <td class="align-right">{{ totalLgffFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</td>
          </tr>
          <tr>
            <td class="align-right">{{ 'totalFundingApplied' | res: pageId }}:</td>
            <td class="align-right">{{ totalLgffFundingApplied | currency: '$' : 'symbol' : '1.0-0' }}</td>
          </tr>
          <tr>
            <td class="align-right">{{ 'totalFutureFundingAvailable2' | res: pageId }}:</td>
            <td class="align-right">{{ totalLgffFutureFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</td>
          </tr>
        </tbody>
      </goa-table>
    </div>
  </goa-container>

  <app-sepo-expenditure-table
    [sepoExpendituresData]="sepoExpendituresData"
    [errorExpenditureIds]="errorExpenditureIds"
    (expenditureIdToEdit)="onEditProject($event)"
  ></app-sepo-expenditure-table>

  <div class="future-funding-container">
    <div class="funding-text">
      <span>Total LGFF funding available for future years: </span>
    </div>
    <div class="funding-value">
      <span>{{ currentSepo.totalFutureFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</span>
    </div>
  </div>

  <goa-divider mt="xl" mb="xl"></goa-divider>

  <h3 id="gen-info-heading-1">{{ 'statementExpenseSectionHeading2' | res: pageId }}</h3>

  <div class="media-communication-form-container">
    <form [formGroup]="mediaCommunicationForm">
      <h4 class="media-communication-label">{{ 'mediaComunicationQuestionLabel' | res: pageId }}</h4>
      <goa-form-item id="hasMediaCommunication">
        <goa-radio-group
          name="hasMediaCommunication"
          formControlName="hasMediaCommunication"
          id="hasMediaCommunication"
          goaValue
          (click)="hasMediaCommunicationPlanClicked(true)"
        >
          <goa-radio-item
            name="hasMediaCommunication"
            label="Yes"
            [value]="true"
            [error]="errors['hasMediaCommunication']"
          ></goa-radio-item>
        </goa-radio-group>
        <div class="nestedStep" *ngIf="getMediaCommunicationPlanYes">
          <goa-form-item
            label="Please specify"
            helpText="Provide additional details about the application, event, or communication activities."
          >
            <goa-textarea
              type="text"
              formControlName="projectDetails"
              goaValue
              width="600px"
              countby="character"
              maxcount="150"
              name="projectDetails"
              value=""
              [error]="errors['projectDetails']"
              (focusout)="onFocusOut('projectDetails')"
              (focusin)="onFocusIn('projectDetails')"
            ></goa-textarea>
            <div class="field-validation-error-msg" *ngIf="errors['projectDetails']">
              <goa-icon size="small" type="warning" theme="filled"></goa-icon>
              <span>{{ errors['projectDetails'] }}</span>
            </div>
          </goa-form-item>
        </div>
      </goa-form-item>
      <goa-form-item>
        <goa-radio-group
          name="hasMediaCommunicationNo"
          id="hasMediaCommunicationNo"
          formControlName="hasMediaCommunicationNo"
          goaValue
          (click)="hasMediaCommunicationPlanClicked(false)"
        >
          <goa-radio-item
            name="hasMediaCommunicationNo"
            label="No"
            [value]="true"
            [error]="errors['hasMediaCommunicationNo']"
          ></goa-radio-item>
        </goa-radio-group>
        <div class="nestedStep disclaimer-container" *ngIf="getMediaCommunicationPlanNo">
          <p class="disclaimer-text">
            <span class="disclaimer-heading">Disclaimer:</span>
            {{ 'noDisclaimer' | res: pageId }}
            <span>
              <a href="mailto:{{ 'noDisclaimerMail' | res: pageId }}"> {{ 'noDisclaimerMail' | res: pageId }}.</a>
            </span>
          </p>
        </div>
        <div class="field-validation-error-msg" *ngIf="errors['hasMediaCommunicationNo']">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ errors['hasMediaCommunicationNo'] }}</span>
        </div>
      </goa-form-item>
    </form>
  </div>
</div>

<ng-container *ngIf="openSepoProjectUpdateModal">
  <app-lgff-goa-modal
    [id]="'app-edit-sepo-project-expenditures-modal'"
    [pageId]="pageId"
    [headingId]="'Edit project expenditures'"
    [open]="openSepoProjectUpdateModal"
    [actionBtnNumber]="2"
    [maxwidth]="'1024px'"
    [okBtnLabel]="'Save'"
    (okBtnClickedEvent)="saveSepoProjectEditModal()"
    (cancelBtnClickedEvent)="cancelSepoProjectEditModal()"
  >
    <app-edit-sepo-project-expenditures-modal
      [sepoProjectToUpdate]="projectToUpdate"
      [projectExpenditureId]="projectToUpdate.id"
      [canEditProjectStatus]="true"
      [projectStatus]="projectStatuses$ | async"
    ></app-edit-sepo-project-expenditures-modal>
  </app-lgff-goa-modal>
</ng-container>
