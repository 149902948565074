/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateProgramBudgetDto } from '../models/create-program-budget-dto';
import { ProgramBudgetDto } from '../models/program-budget-dto';
import { UpdateProgramBudgetDto } from '../models/update-program-budget-dto';

@Injectable({ providedIn: 'root' })
export class ProgramBudgetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAll_7()` */
  static readonly FindAll_7Path = '/api/program-budgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProgramBudgetDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.FindAll_7Path, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramBudgetDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ProgramBudgetDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramBudgetDto>>): Array<ProgramBudgetDto> => r.body)
    );
  }

  /** Path part for operation `create_3()` */
  static readonly Create_3Path = '/api/program-budgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateProgramBudgetDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProgramBudgetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.Create_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProgramBudgetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateProgramBudgetDto
    },
    context?: HttpContext
  ): Observable<ProgramBudgetDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgramBudgetDto>): ProgramBudgetDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableProgramBudgetActions()` */
  static readonly GetUserAvailableProgramBudgetActionsPath = '/api/program-budgets/available-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableProgramBudgetActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableProgramBudgetActions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.GetUserAvailableProgramBudgetActionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableProgramBudgetActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableProgramBudgetActions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableProgramBudgetActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `findOne_7()` */
  static readonly FindOne_7Path = '/api/program-budgets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProgramBudgetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.FindOne_7Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProgramBudgetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ProgramBudgetDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgramBudgetDto>): ProgramBudgetDto => r.body)
    );
  }

  /** Path part for operation `remove_2()` */
  static readonly Remove_2Path = '/api/program-budgets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProgramBudgetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.Remove_2Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProgramBudgetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ProgramBudgetDto> {
    return this.remove$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgramBudgetDto>): ProgramBudgetDto => r.body)
    );
  }

  /** Path part for operation `update_3()` */
  static readonly Update_3Path = '/api/program-budgets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(
    params: {
      id: string;
      body: UpdateProgramBudgetDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProgramBudgetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProgramBudgetService.Update_3Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProgramBudgetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(
    params: {
      id: string;
      body: UpdateProgramBudgetDto
    },
    context?: HttpContext
  ): Observable<ProgramBudgetDto> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgramBudgetDto>): ProgramBudgetDto => r.body)
    );
  }

}
