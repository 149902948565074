import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { ResourcePipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { Select, Store } from '@ngxs/store';
import { isEqual } from 'date-fns';
import _ from 'lodash';
import { Observable, Subscription, combineLatest, skipUntil, startWith, takeWhile, timer } from 'rxjs';
import { ProjectStatusType, SepoExtUpdateDto, SepoProjectExpenditureDto } from '@app-com/api/models';
import { LookupStringValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { formatNumberWithCommas } from '@app-com/directives';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { LoadProjectStatus } from '@app-pot/store/actions/lookup-value.action';
import { SepoExpenditures } from '../sepo-expenditure-types';

@Component({
  selector: 'app-edit-project-expenditures-modal',
  templateUrl: './edit-project-expenditures-modal.component.html',
  styleUrl: './edit-project-expenditures-modal.component.scss',
})
export class EditProjectExpendituresModalComponent {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo.projectUpdates;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'SEPO';

  projectEditForm: FormGroup;
  UiDefApplicationId: UiFieldCtrDef;
  UiDefApplicationName: UiFieldCtrDef;
  UiDefApplicationStatus: UiFieldCtrDef;
  UiDefProjectId: UiFieldCtrDef;
  UiDefProjectName: UiFieldCtrDef;
  UiDefProjectStatus: UiFieldCtrDef;
  UiDefEstimatedTotalCost: UiFieldCtrDef;
  UiDefLgffFundingRemaining: UiFieldCtrDef;
  UiDefActualReportingYearCost: UiFieldCtrDef;
  UiDefFundingFromOtherGrantPrograms: UiFieldCtrDef;
  UiDefLgffFundingApplied: UiFieldCtrDef;
  UiDefFundingFromMunicipalSources: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  submitClicked = false;
  sub = new Subscription();
  combinedFundingVsReportingYearCostValidationMessage =
    '"Portion funded by other grant programs", "LGFF funding applied", and "Portion funded from municipal sources" must be positive numbers and their sum must equal the "Actual reporting year cost".';
  lgffFundingAmountAppliedVsReportingYearCostValidationMessage =
    'Amount must be less than or equal to the "Actual reporting year cost".';
  fundingFromOtherGrantProgramsVsReportingYearCostValidationMessage =
    'Amount must be less than or equal to the "Actual reporting year cost".';
  lgffFundingAmountAppliedVsFundingRemainingValidationMessage =
    '"LGFF funding applied" is greater than the "LGFF funding remaining" and the project is not reported as completed/fully-funded. If this value is correct, please submit a cash flow update to reflect this change.';

  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  @Input() areThereOtherSourcesOfFunding: boolean;

  @Input() storedProjectIdToUpdate?: SepoExpenditures;
  storedProjectToUpdate: SepoProjectExpenditureDto = {
    actualReportingYearCost: 0,
    amountFundedOtherGrantPrograms: 0,
    applicationId: 0,
    applicationIdTxt: '',
    applicationName: '',
    createdAt: '',
    createdBy: '',
    createdByName: '',
    estimatedTotalLGFFFunding: 0,
    fundingAppliedFromLGFF: 0,
    fundingAppliedFromMunicipalSources: 0,
    id: 0,
    projectId: 0,
    projectIdTxt: '',
    projectName: '',
    projectStatusTitle: '',
    sepoId: 0,
    status: ProjectStatusType.NotStarted,
    totalRemaining: 0,
    updatedAt: '',
    updatedBy: '',
    updatedByName: '',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() isProjectValid: EventEmitter<any> = new EventEmitter();
  @Output() saveProject: EventEmitter<SepoExtUpdateDto> = new EventEmitter<SepoExtUpdateDto>();
  @Select(LookupValueState.getProjectStatus) projectStatuses$: Observable<LookupStringValue[]>;
  @Select(EditSepoState.getEditSepoButtonAction) getEditSepoButtonAction$: Observable<
    'cancel' | 'confirm' | 'save' | 'previous'
  >;
  calloutRef: ElementRef;
  @ViewChild('callout', { read: ElementRef, static: false }) set content(content: ElementRef) {
    if (content) {
      this.calloutRef = content;
    }
  }
  isAlive = true;
  projectDescription: string | undefined;
  isAutosaveNeeded = false;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadProjectStatus());
    this.sub.add(
      this.getEditSepoButtonAction$.subscribe((sepoAction) => {
        if (sepoAction && sepoAction.indexOf('save') >= 0) {
          this.projectEditForm.markAllAsTouched();

          this.pageUiDefs.forEach((uiDef) => {
            CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
          });
          console.log('Validate');
          this.isProjectValid.emit(this.projectEditForm?.valid);
          if (this.projectEditForm?.valid) {
            console.log('Updated project', this.projectEditForm?.value);
            // this.store.dispatch(new SetSEPOExpenditureProjects(this._getProjectUpdate()));
          }
        } else {
          setTimeout(() => {
            this.jumpToCallout(this.calloutRef);
          }, 100);
        }
      }),
    );

    this.createProjectEditForm();
    this.initializeProjectToUpdate();
    this._registerChangeObserverForAutosaveFlag();
  }

  //This is to prevent autosave from being triggered when a user simply tabs through input fields.
  private _registerChangeObserverForAutosaveFlag() {
    this.projectEditForm.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        skipUntil(timer(500)), //When the form is open in edit mode and values are being populated, the change event may trigger multiple times. This is to skip those initial changes.
      )
      .subscribe(() => (this.isAutosaveNeeded = true));
  }

  autosave() {
    if (!this.isAutosaveNeeded) return;
    this.isAutosaveNeeded = false;
    if (!this.projectEditForm.valid) return;
    const project = this._getProjectUpdate(); // form project data

    if (_.isEqual(project, this.storedProjectToUpdate)) {
      return;
    }

    console.log('saved project', project);
    //hj this.store.dispatch(new SetSEPOExpenditureProjects(project));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeCommaFormat(val: any) {
    if (typeof val == 'string') {
      const str = val.replace(/, /g, '');
      return parseFloat(str);
    }
    return val;
  }

  // convert page UI-data into model, before sending to back-end
  private _getProjectUpdate(): Partial<SepoProjectExpenditureDto> {
    const project: Partial<SepoProjectExpenditureDto> = {
      projectId: this.storedProjectToUpdate?.projectId,
      //  estimatedTotalLGFFFunding: this.projectEditForm?.value[this.UiDefEstimatedTotalCost.nameCtr],
      actualReportingYearCost: this.projectEditForm?.value[this.UiDefActualReportingYearCost.nameCtr],
      fundingAppliedFromLGFF: this.projectEditForm?.value[this.UiDefLgffFundingApplied.nameCtr],
      // totalRemaining: this.projectEditForm?.value[this.UiDefLgffFundingRemaining.nameCtr],
      // fundingAppliedFromMunicipalSources: this.projectEditForm?.value[this.UiDefFundingFromMunicipalSources.nameCtr],
      amountFundedOtherGrantPrograms: this.projectEditForm?.value[this.UiDefFundingFromOtherGrantPrograms.nameCtr],

      status: ProjectStatusType.NotStarted,
    };

    const projectValWithoutFormatting = {
      ...project,

      //  estimatedTotalLGFFFunding: this.removeCommaFormat(project.estimatedTotalLGFFFunding),
      actualReportingYearCost: this.removeCommaFormat(project.actualReportingYearCost),
      fundingAppliedFromLGFF: this.removeCommaFormat(project.fundingAppliedFromLGFF),
      //  totalRemaining: this.removeCommaFormat(project.totalRemaining),
      //  fundingAppliedFromMunicipalSources: this.removeCommaFormat(project.fundingAppliedFromMunicipalSources),
      amountFundedOtherGrantPrograms: this.removeCommaFormat(project.amountFundedOtherGrantPrograms),
    };
    return projectValWithoutFormatting;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  createProjectEditForm() {
    this.projectEditForm = this.formBuilder.group({});

    this.UiDefProjectStatus = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.projectStatus);
    this.projectEditForm.addControl(this.UiDefProjectStatus.nameCtr, this.UiDefProjectStatus.formCtr);

    this.UiDefEstimatedTotalCost = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.estimatedTotalCost);
    this.projectEditForm.addControl(this.UiDefEstimatedTotalCost.nameCtr, this.UiDefEstimatedTotalCost.formCtr);

    this.UiDefLgffFundingRemaining = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.fundingRemaining);
    this.projectEditForm.addControl(this.UiDefLgffFundingRemaining.nameCtr, this.UiDefLgffFundingRemaining.formCtr);

    this.UiDefActualReportingYearCost = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.actualReportingYearCost);
    this.projectEditForm.addControl(
      this.UiDefActualReportingYearCost.nameCtr,
      this.UiDefActualReportingYearCost.formCtr,
    );

    this.UiDefFundingFromOtherGrantPrograms = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromOtherGrantPrograms,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromOtherGrantPrograms.nameCtr,
      this.UiDefFundingFromOtherGrantPrograms.formCtr,
    );

    this.UiDefLgffFundingApplied = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.fundingApplied);
    this.projectEditForm.addControl(this.UiDefLgffFundingApplied.nameCtr, this.UiDefLgffFundingApplied.formCtr);

    this.UiDefFundingFromMunicipalSources = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromMunicipalSources,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromMunicipalSources.nameCtr,
      this.UiDefFundingFromMunicipalSources.formCtr,
    );

    this.projectEditForm.addValidators([
      this._lgffFundingAmountAppliedVsReportingYearCostValidator,
      this._fundingFromOtherGrantProgramsVsReportingYearCostValidator,
      this._lgffFundingAmountAppliedVsFundingRemainingValidator,
      this._combinedFundingVsReportingYearCostValidator,
    ]);

    this.pageUiDefs = [
      this.UiDefProjectStatus,
      // this.UiDefEstimatedTotalCost,
      // this.UiDefApplicationId,
      this.UiDefFundingFromOtherGrantPrograms,
      this.UiDefFundingFromMunicipalSources,
      this.UiDefActualReportingYearCost,
      //this.UiDefApplicationName,
      // this.UiDefApplicationStatus,
      this.UiDefLgffFundingApplied,
      //  this.UiDefLgffFundingRemaining,
      // this.UiDefProjectId,
      // this.UiDefProjectName,
    ];
  }

  initializeProjectToUpdate() {
    this.projectEditForm?.setValue({
      [this.UiDefProjectStatus.nameCtr]: this.storedProjectToUpdate?.projectStatusTitle,
      [this.UiDefEstimatedTotalCost.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.estimatedTotalLGFFFunding?.toString(),
      ),
      [this.UiDefLgffFundingRemaining.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.totalRemaining?.toString(),
      ),
      [this.UiDefActualReportingYearCost.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.actualReportingYearCost?.toString(),
      ),
      [this.UiDefLgffFundingApplied.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.fundingAppliedFromLGFF?.toString(),
      ),
      [this.UiDefFundingFromOtherGrantPrograms.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.amountFundedOtherGrantPrograms?.toString(),
      ),
      [this.UiDefFundingFromMunicipalSources.nameCtr]: formatNumberWithCommas(
        this.storedProjectToUpdate?.fundingAppliedFromMunicipalSources?.toString(),
      ),
    });
    this._watchForAmountChange(
      this.projectEditForm?.get('actualReportingYearCost')?.value,
      this.projectEditForm?.get('fundingApplied')?.value,
      this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.value,
    );
    console.log('Project edit form values', this.projectEditForm.value);
  }

  areDatesEqual(dt1: string, dt2: string) {
    const firstDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt1!,
    );
    const secondDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt2!,
    );
    const eq = isEqual(firstDt as string, secondDt as string);
    return eq;
  }

  get shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingAmountAppliedGreaterThanReportingYearCost')
    );
  }
  get shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingAmountAppliedGreaterThanFundingRemaining')
    );
  }

  get shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.projectEditForm?.hasError('fundingFromOtherGrantProgramsGreaterThanReportingYearCost')
    );
  }

  get shouldShowcombinedFundingVsReportingYearCostValidationMessage(): boolean {
    return this.projectEditForm?.hasError('combinedFundingGreaterThanReportingYearCost');
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return (
      baseHasError ||
      this.shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
      this.shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
      this.shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
      this.shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage
    );
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  jumpToCallout(fieldTemplate: ElementRef) {
    if (fieldTemplate) {
      const fieldElement = fieldTemplate.nativeElement;
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        console.error('Cannot find linked field: ' + fieldTemplate.nativeElement);
      }
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedInNonBlankOrErrorField = true;
    }

    if (isBlurAway) {
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');

        // if (UiDef.nameCtr === this.CurrentPageDef.anticipatedEndDate.nameCtr) {
        //   if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedStartDate)) {
        //     CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedStartDate, true, 'crossFocusOut');
        //   }
        // } else if (UiDef.nameCtr === this.CurrentPageDef.anticipatedStartDate.nameCtr) {
        //   if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedEndDate)) {
        //     CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedEndDate, true, 'crossFocusOut');
        //   }
        // }
      }, 400);
    }
  }

  public hasProjectFormAnyError(submitted: boolean): boolean {
    this.submitClicked = this.submitClicked || submitted;

    if (this.areThereOtherSourcesOfFunding) {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.enable();
    } else {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.disable();
    }

    return !this.projectEditForm.valid;
  }

  private _lgffFundingAmountAppliedVsReportingYearCostValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const lgffFundingAmountApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    if (lgffFundingAmountApplied > estimatedTotalCost) {
      return { lgffFundingAmountAppliedGreaterThanReportingYearCost: true };
    }

    return null;
  }
  private _lgffFundingAmountAppliedVsFundingRemainingValidator(control: AbstractControl): ValidationErrors | null {
    const projectStatus = control.get('projectStatus')?.value;
    const lgffFundingAmountApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    //  console.log(lgffFundingAmountApplied, this.storedProjectToUpdate?.totalRemaining, projectStatus);
    if (
      lgffFundingAmountApplied > 0 && // this.storedProjectToUpdate?.totalRemaining &&
      projectStatus != ProjectStatusType.Completed
    ) {
      return { lgffFundingAmountAppliedGreaterThanFundingRemaining: true };
    }

    return null;
  }

  private _fundingFromOtherGrantProgramsVsReportingYearCostValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (fundingFromOtherGrantPrograms > actualReportingYearCost) {
      return { fundingFromOtherGrantProgramsGreaterThanReportingYearCost: true };
    }

    return null;
  }

  private _combinedFundingVsReportingYearCostValidator(control: AbstractControl): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const fundingApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (fundingApplied + fundingFromOtherGrantPrograms > actualReportingYearCost) {
      return { combinedFundingGreaterThanReportingYearCost: true };
    }

    return null;
  }

  private _watchForAmountChange(
    actualReportingYearCost: number | undefined,
    fundingApplied: number | undefined,
    fundingFromOtherGrantPrograms: number | undefined,
  ) {
    const actualReportingYearCostCtrl = this.projectEditForm.get('actualReportingYearCost');
    const fundingAppliedCtrl = this.projectEditForm.get('fundingApplied');
    const fundingFromOtherGrantProgramsCtrl = this.projectEditForm.get('fundingFromOtherGrantPrograms');
    const fundingFromMunicipalSourcesCtrl = this.projectEditForm.get('fundingFromMunicipalSources');
    if (
      !actualReportingYearCostCtrl ||
      !fundingAppliedCtrl ||
      !fundingFromOtherGrantProgramsCtrl ||
      !fundingFromMunicipalSourcesCtrl
    )
      return;
    combineLatest([
      actualReportingYearCostCtrl.valueChanges.pipe(startWith(actualReportingYearCost?.toString())),
      fundingAppliedCtrl.valueChanges.pipe(startWith(fundingApplied?.toString())),
      fundingFromOtherGrantProgramsCtrl.valueChanges.pipe(startWith(fundingFromOtherGrantPrograms?.toString())),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([actualReportingYearCostStr, fundingAppliedStr, fundingFromOtherGrantProgramsStr]) => {
        const actualReportingYearCost =
          typeof actualReportingYearCostStr == 'string'
            ? CommUtilsService.parseInputInt(actualReportingYearCostStr)
            : actualReportingYearCostStr;
        const fundingApplied =
          typeof fundingAppliedStr == 'string' ? CommUtilsService.parseInputInt(fundingAppliedStr) : fundingAppliedStr;
        const fundingFromOtherGrantPrograms =
          typeof fundingFromOtherGrantProgramsStr == 'string'
            ? CommUtilsService.parseInputInt(fundingFromOtherGrantProgramsStr)
            : fundingFromOtherGrantProgramsStr;
        const fundingFromMunicipalSources = this._calculateFundingFromMunicipalSources(
          actualReportingYearCost,
          fundingApplied,
          fundingFromOtherGrantPrograms,
        );
        // fundingFromMunicipalSourcesCtrl.setValue(formatNumberWithCommas(fundingFromMunicipalSources));
        fundingFromMunicipalSourcesCtrl.setValue(fundingFromMunicipalSources);
      });
  }

  private _calculateFundingFromMunicipalSources(
    actualReportingYearCost: number,
    fundingApplied: number,
    fundingFromOtherGrantPrograms: number,
  ): string | null {
    const fundingFromMunicipalSources = formatNumberWithCommas(
      actualReportingYearCost - (fundingApplied + fundingFromOtherGrantPrograms),
    );
    return fundingFromMunicipalSources;
  }
}
