<app-lgff-goa-nested-rows-table
  [headers]="headers"
  [showAdditionalHeader]="showAdditionalHeader"
  [additionalHeaders]="additionalHeaders"
  [tableData]="tableData"
  childKey="projects"
  tableWidth="136.1875rem"
  [parentColumnIndent]="parentColumnIndent"
  [cellTemplates]="{
    id: editTemplate
  }"
></app-lgff-goa-nested-rows-table>
<ng-template #editTemplate let-row>
  <goa-icon-button
    [disabled]="editBtnDisabled"
    id="{{ row }}"
    size="small"
    class="action-icon"
    variant="color"
    icon="pencil"
    (click)="exeEditProject(row)"
    [ngClass]="{ 'errored-border': errorProjectOutcomeIds.includes(row), 'disabled-action-icon': editBtnDisabled }"
  ></goa-icon-button>
</ng-template>
