import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { LandingComponent } from './features/landing/landing.component';
import { LogoutComponent } from './features/logout/logout.component';
import { AccessForbiddenComponent } from './features/error-pages/access-forbidden/access-forbidden.component';
import { NotFoundComponent } from './features/error-pages/notfound/notfound.component';
import { GrantApplicationComponent } from './features/grant-application/grant-application.component';
import { ViewApplicationsComponent } from './features/view-applications/view-applications.component';
import { AuthGuard } from './guard/auth.guard';
import { ApplicationSummaryComponent } from './features/view-applications/application-summary/application-summary.component';
import { ResourcesComponent } from './features/resources/resources.component';
import { GeneralErrorComponent } from './features/error-pages/general-error/general-error.component';
import { DraftApplicationsComponent } from './features/view-applications/draft-applications/draft-applications.component';
import { SubmittedApplicationsComponent } from './features/view-applications/submitted-applications/submitted-applications.component';
import { ReturnedApplicationsComponent } from './features/view-applications/returned-applications/returned-applications.component';
import { AcceptedApplicationsComponent } from './features/view-applications/accepted-applications/accepted-applications.component';
import { WithdrawnApplicationsComponent } from './features/view-applications/withdrawn-applications/withdrawn-applications.component';
import { AllocationsAndPaymentsComponent } from './features/allocations-and-payments/allocations-and-payments.component';
import { UnauthorizedComponent } from './features/error-pages/unauthorized/unauthorized.component';
import { CashFlowUpdatesComponent } from './features/cash-flow-updates/cash-flow-updates.component';
import { CashFlowSubmitComponent } from './features/cash-flow-updates/cash-flow-submit/cash-flow-submit.component';
import { CashFlowContactsComponent } from './features/cash-flow-updates/cash-flow-contacts/cash-flow-contacts.component';
import { ApplicationFundingComponent } from './features/cash-flow-updates/application-funding/application-funding.component';
import { ProjectUpdatesComponent } from './features/cash-flow-updates/project-updates/project-updates.component';
import { CashFlowUpdateReviewComponent } from './features/cash-flow-updates/cash-flow-update-review/cash-flow-update-review.component';
import { CashFlowUpdateApplicationsComponent } from './features/view-applications/cash-flow-update-applications/cash-flow-update-applications.component';
import { EditSepoComponent } from './features/sepo/edit-sepo/edit-sepo.component';
import { EditSepoContactInfoComponent } from './features/sepo/edit-sepo/edit-sepo-contact-info/edit-sepo-contact-info.component';
import { EditSepoAssetFundComponent } from './features/sepo/edit-sepo/edit-sepo-asset-fund/edit-sepo-asset-fund.component';
import { EditSepoStatementExpendituresComponent } from './features/sepo/edit-sepo/edit-sepo-statement-expenditures/edit-sepo-statement-expenditures.component';
import { EditSepoImmediateOutcomesComponent } from './features/sepo/edit-sepo/edit-sepo-immediate-outcomes/edit-sepo-immediate-outcomes.component';
import { EditSepoCertifySubmitComponent } from './features/sepo/edit-sepo/edit-sepo-certify-submit/edit-sepo-certify-submit.component';
import { FeatureFlagGuard } from '@app-com/services/feature-flag-guard.service';
import { ViewSepoComponent } from './features/sepo/view-sepo/view-sepo.component';

const routes: Routes = [
  { path: 'grant-application', component: GrantApplicationComponent, canActivate: [AuthGuard] },
  {
    path: 'cash-flow-updates',
    component: CashFlowUpdatesComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CashFlowContactsComponent,
      },
      {
        path: 'submit',
        component: CashFlowSubmitComponent,
      },
      {
        path: 'application-funding',
        pathMatch: 'full',
        component: ApplicationFundingComponent,
      },
      {
        path: 'project-updates',
        pathMatch: 'full',
        component: ProjectUpdatesComponent,
      },
      {
        path: 'review',
        pathMatch: 'full',
        component: CashFlowUpdateReviewComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-sepo',
    component: EditSepoComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: EditSepoContactInfoComponent,
      },
      {
        path: 'contact-info',
        pathMatch: 'full',
        component: EditSepoContactInfoComponent,
      },
      {
        path: 'asset-management-and-funds',
        pathMatch: 'full',
        component: EditSepoAssetFundComponent,
      },
      {
        path: 'statement-of-expenditures',
        pathMatch: 'full',
        component: EditSepoStatementExpendituresComponent,
      },
      {
        path: 'immediate-outcomes',
        pathMatch: 'full',
        component: EditSepoImmediateOutcomesComponent,
      },
      {
        path: 'certify-and-submit',
        pathMatch: 'full',
        component: EditSepoCertifySubmitComponent,
      },
    ],
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: { featureFlag: 'SEPO' },
  },
  {
    path: 'view-sepo',
    component: ViewSepoComponent,
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: { featureFlag: 'SEPO' },
  },
  {
    path: 'view-applications',
    component: ViewApplicationsComponent,
    children: [
      {
        path: 'drafts',
        component: DraftApplicationsComponent,
      },
      {
        path: 'submitted',
        component: SubmittedApplicationsComponent,
      },
      {
        path: 'returned',
        component: ReturnedApplicationsComponent,
      },
      {
        path: 'accepted',
        component: AcceptedApplicationsComponent,
      },
      {
        path: 'cash-flow-updates',
        component: CashFlowUpdateApplicationsComponent,
      },
      {
        path: 'withdrawn',
        component: WithdrawnApplicationsComponent,
      },
      {
        path: '',
        component: DraftApplicationsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: 'allocations-payments', component: AllocationsAndPaymentsComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard] },
  {
    path: 'application-summary/:status/:organizationId/:id',
    component: ApplicationSummaryComponent,
    canActivate: [AuthGuard],
  },
  { path: 'landing', component: LandingComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'access-forbidden', component: AccessForbiddenComponent, canActivate: [AuthGuard] },
  { path: 'general-error', component: GeneralErrorComponent },
  { path: 'page-not-found', component: NotFoundComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
