<div id="errors-callout" class="mb-56px" style="margin-top: 0.75rem">
  <goa-callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: pageId }}"
    *ngIf="invalidProjectIds && invalidProjectIds!.length > 0"
  >
    <span *ngFor="let error of invalidProjectIds.slice(0, NoOfInvalidProjectsToShow)">
      <a (click)="scrollIntoView(error.id)" class="validation-message-link">Project {{ error.idTxt }}</a>
      - enter project details. <br />
    </span>
    <div *ngIf="showViewAdditionalItemsLink">
      <a class="icon-link-container" (click)="onClickViewAddtionalItems(true)"
        ><goa-icon type="add-circle" size="medium" class="icon"></goa-icon>View additional items ({{
          invalidProjectIds.length - minInvalidProjectsToShow
        }})</a
      >
    </div>
    <div *ngIf="!showViewAdditionalItemsLink && invalidProjectIds.length > minInvalidProjectsToShow">
      <a class="icon-link-container" (click)="onClickViewAddtionalItems(false)"
        ><goa-icon type="remove-circle"></goa-icon>Hide additional items</a
      >
    </div>
  </goa-callout>
</div>
<h3 id="immediate-outcomes-heading1">{{ 'immediateOutcomesHeading1' | res: pageId }}</h3>
<h3 id="immediate-outcomes-subtitle">
  Please edit the information in the table below to reflect our completed projects.
</h3>
<div id="immediate-outcomes-heading2">{{ 'immediateOutcomesHeading2' | res: pageId }}</div>
<div>
  <app-sepo-outcomes-table
    [headers]="headers"
    [showAdditionalHeader]="true"
    [additionalHeaders]="additionalHeaders"
    [tableData]="applications"
    [parentColumnIndent]="parentColumnIndent"
    [editProject]="onEditProject"
    [errorProjectOutcomeIds]="errorProjectOutcomeIds"
  ></app-sepo-outcomes-table>
</div>
<ng-container *ngIf="openSepoProjectUpdateModal">
  <app-lgff-goa-modal
    [id]="'app-edit-sepo-project-outcomes-modal'"
    [pageId]="pageId"
    [headingId]="'Edit project outcomes'"
    [open]="openSepoProjectUpdateModal"
    [actionBtnNumber]="2"
    [maxwidth]="'1024px'"
    [okBtnLabel]="'Save'"
    (okBtnClickedEvent)="saveSepoProjectEditModal()"
    (cancelBtnClickedEvent)="cancelSepoProjectEditModal()"
  >
    <app-edit-sepo-project-outcomes-modal
      (isProjectValid)="isProjectValid($event)"
      [sepoProjectOutcomeToUpdate]="projectOutcomeToUpdate"
      [projectOutcomeId]="projectOutcomeToUpdate.id"
      [allCapitalAssetTypes]="allCapitalAssetTypes"
      [functionalCategoryTypes]="functionalCategoryTypes"
      [assetConditionRankings]="assetConditionRankings"
      [primaryOutcomes]="primaryOutcomes"
      [capitalAssetMeasurementUnit]="capitalAssetMeasurementUnit"
    ></app-edit-sepo-project-outcomes-modal>
  </app-lgff-goa-modal>
</ng-container>
<ng-container *ngIf="openSepoOutcomesReporting">
  <app-lgff-goa-modal
    [id]="'app-edit-project-outcomes-modal'"
    [pageId]="pageId"
    [headingId]="'Additional outcomes reporting required'"
    [open]="openSepoOutcomesReporting"
    [actionBtnNumber]="2"
    [maxwidth]="'1024px'"
    [okBtnLabel]="'Close and continue'"
    [cancelBtnLabel]="'Copy text to clipboard'"
    (okBtnClickedEvent)="closePrimaryOutcomeReportingModal()"
    (cancelBtnClickedEvent)="copyClipboardPrimaryOutcomeReportingModal()"
  >
    <app-edit-outcomes-reporting
      [sepoProjectOutcomeForReporting]="primaryOutcomesReporting"
      [primaryOutcomes]="primaryOutcomes"
    ></app-edit-outcomes-reporting>
  </app-lgff-goa-modal>
</ng-container>
