<div id="information-callout" class="mb-56px" style="margin-top: 0.75rem">
  <goa-callout
    type="information"
    heading="You can still continue to complete and submit your SEPO before providing any additional reporting."
  >
  </goa-callout>
</div>
<goa-block direction="column">
  <div class="heading" *ngIf="sepoProjectOutcomeForReporting.length == 1">
    {{ 'SEPOOutcomeReportingLabel' | res: pageId }}
  </div>
  <div class="heading" *ngIf="sepoProjectOutcomeForReporting.length > 1">
    {{ 'SEPOOutcomeReportingLabel1' | res: pageId }}
  </div>
  <goa-block
    class="sepoProjectOutcome"
    gap="none"
    direction="column"
    *ngFor="let sepoProjectOutcome of sepoProjectOutcomeForReporting; let i = index"
  >
    <div>
      <span class="projectLabel">Project: </span>{{ sepoProjectOutcome.projectName }}
      {{ sepoProjectOutcome.projectIdTxt }}
    </div>
    <div><span class="outcomeLabel">Outcome: </span>{{ sepoProjectOutcomeTitle[i] }}</div>
  </goa-block>
  <goa-block direction="column" gap="none">
    <div class="heading">{{ 'SEPOOutcomeReportingLabel2' | res: pageId }}</div>
  </goa-block>
  <goa-block direction="column" gap="none">
    <div class="heading">{{ 'SEPOOutcomeReportingLabel3' | res: pageId }}</div>
    <div class="heading">{{ 'SEPOOutcomeReportingLabel4' | res: pageId }}</div>
  </goa-block>
</goa-block>
