import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { CategoryWithTitle } from '../models';
import {
  Observable,
  Subscription,
  filter,
  takeWhile,
  debounceTime,
  distinctUntilChanged,
  Subject,
  combineLatest,
  tap,
} from 'rxjs';
import { ApplicationDraftRecordDto, FunctionalCategoryDraftDto, ProjectDraftDto } from '@app-com/api/models';
import { Select, Store } from '@ngxs/store';
import { ApplicationGrantState } from '@app-pot/store/state/application-draft.state';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { BaseStepperComponent } from '../../../shared/components/base-stepper/base-stepper.component';
import {
  AutosaveFunctionalCategories,
  DeleteProjects,
  SetFormStepperStatus,
  SetFunctionalCategories,
} from '@app-pot/store/actions/application-draft.action';
import { LoadFunctionalCategoryTypes } from '@app-pot/store/actions/lookup-value.action';
import { LookupValue, LookupValueState } from '@app-com/state/lookup-value.state';
import { FormSequence, FormStatusCodes } from '../models/enums';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';

@Component({
  selector: 'app-functional-categories',
  templateUrl: './functional-categories.component.html',
  styleUrls: ['./functional-categories.component.scss', '../common.scss'],
})
export class FunctionalCategoriesComponent extends BaseStepperComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(ApplicationGrantState.fetchApplicationDto) fetchApplicationDto$: Observable<ApplicationDraftRecordDto>;
  @Select(ApplicationGrantState.existingApplicationDraft) existingApplicationDraft$: Observable<boolean>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupValue[]>;

  pageId = 'FUNCTIONAL_CATEGORIES';
  isAlive = true;
  nextClickValidation = false;

  functionalCategories: FormGroup;
  functionalCategoryEditForm: FormGroup;
  functionalCategoriesSources: FormGroup;
  categoryLabel: string = this.res.transform('primaryCategory', this.pageId);
  helpTextCategory: string = this.res.transform('primaryCategoryHelpText', this.pageId);
  mCategoryLabel: string;
  mHelpTextCategory: string;
  categories: { id: number; name: string; disabled: boolean }[];
  categoriesAll: { id: number; name: string; disabled: boolean }[];

  categoryList: CategoryWithTitle[] = [{ categoryId: 0, categoryTitle: '', isPrimary: false, cost: 0 }];

  costValue?: string = '';
  totalCost: number;
  displayForm = true;
  isDropdownStyleAdjusted = false;
  isOpen = false;
  isEditOpen = false;
  currentCategoryId: number;
  currentCategoryTitle: string;
  currentCost: number;
  currentPrimary: boolean;
  selectedIndex: number;
  categoryAlreadyExisting = false;
  categoryProjectExisting = false;
  errors: { [key: string]: string } = {};
  errorFieldLinkStyle = { 'text-decoration': 'underline', color: '#004A8F' };
  projectsPage: ProjectDraftDto[];
  functionalCategoriesPage: FunctionalCategoryDraftDto[] = [];
  categoryNotValid = false;
  functionalCategoryFirstTimeInput = false;
  sub = new Subscription();
  existingDraft: boolean;
  private triggerAutosaveSubject = new Subject<void>();

  areThereOtherSourcesOfFundingRadioState = ''; // 'true' for Yes, 'false' for No, '' for undefined

  safeCtrlNames: string[] = [
    'isFundingFromAMWWP',
    'isFundingFromCCBF',
    'isFundingFromSTIP',
    'isFundingFromWaterForLife',
    'isFundingFromMSI',
    'isFundingFromOther',
    'isOwnedByLocalGovernment',
    'isNonProfitOwnership',
    'isRegionalServiceCommissionOwnership',
    'isLocalGovernmentOwnership',
    'areThereOtherSourcesOfFunding', // may need add more fileds like specify-text, dropdown, percents
  ];
  touchedName: string[] = [];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
    private snackBarService: SnackBarService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  private resetTouchedValues() {
    this.safeCtrlNames.forEach((name) => {
      const ctrl = this.functionalCategoriesSources?.controls[name];
      if (!!ctrl && ctrl.touched) {
        ctrl.markAsUntouched();
        // console.error(name + " touched value is reset");
      }
    });
  }

  ngAfterViewInit(): void {
    this.resetTouchedValues();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  _prepareForm() {
    this.functionalCategories = this.formBuilder.group({
      category: ['', [Validators.required]],
      isPrimary: [''],
      cost: ['', [Validators.required]],
    });

    this.functionalCategoryEditForm = this.formBuilder.group({
      mCategory: ['', [Validators.required]],
      mPrimary: [''],
      mCost: ['', [Validators.required]],
    });

    this.functionalCategoriesSources = this.formBuilder.group(
      {
        areThereOtherSourcesOfFunding: [undefined],
        isFundingFromAMWWP: [false],
        isFundingFromCCBF: [false],
        isFundingFromSTIP: [false],
        isFundingFromMSI: [false],
        isFundingFromWaterForLife: [false],
        isFundingFromOther: [false],
        fundingFromOther: [''],
        noOtherSourceOfFunding: [undefined],

        isOwnedByLocalGovernment: [false],
        isNonProfitOwnership: [false],
        nonProfitOwnership: [''],
        isRegionalServiceCommissionOwnership: [false],
        regionalServiceCommissionOwnership: [''],
        isLocalGovernmentOwnership: [false],
        localGovernmentOwnership: [''],
      },
      {
        validators: [
          this._areThereOtherSourcesOfFundingValidator,
          this._whoWillOwnTheValidator,
          this._otherSourcesOfFundingYesValidator,
          this._otherSourcesOfFundingYesOtherSpecifyValidator,
          this._ownershipNonProfitOrganizationSpecifyValidator,
          this._ownershipRegionalServiceCommissionSpecifyValidator,
          this._ownershipAnotherLocalGovtSpecifyValidator,
        ],
      },
    );
    this._SubscribeToFormValuesForStructureChange();

    this.sub.add(
      this.functionalCategoryTypes$.subscribe((functionalCategoryTypes) => {
        this.categories = functionalCategoryTypes
          .map((c) => {
            return {
              id: c.id,
              name: c.title,
              disabled: false,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      }),
    );
    this.sub.add(
      this.functionalCategoryTypes$.subscribe((functionalCategoryTypes) => {
        this.categoriesAll = functionalCategoryTypes
          .map((c) => {
            return {
              id: c.id,
              name: c.title,
              disabled: false,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      }),
    );

    this.sub.add(
      this.existingApplicationDraft$.subscribe((existingDraft) => {
        this.existingDraft = existingDraft;
      }),
    );
    this.sub.add(
      combineLatest([
        this.fetchApplicationDto$.pipe(filter((fetchedApplication) => !!fetchedApplication)),
        this.functionalCategoryTypes$,
      ])
        .pipe(
          tap(([fetchedApplication, functionalCategoryTypes]) => {
            this.functionalCategoriesPage = fetchedApplication.functionalCategories ?? [];
            if (this.functionalCategoriesPage.length == 0 && fetchedApplication.name != '') {
              this.functionalCategoryFirstTimeInput = true;
            }

            if (fetchedApplication.functionalCategories?.length) {
              this.categoryList = fetchedApplication.functionalCategories.map((category) => ({
                categoryId: category.functionalCategoryTypeId ?? 0,
                isPrimary: category.isPrimary,
                cost: +(category.percentageCost ?? 0),
                categoryTitle: functionalCategoryTypes.find((value) => value.id === category.functionalCategoryTypeId)
                  ?.title,
              }));
              this.resetForm();
            }
            if (fetchedApplication.projects) {
              this.projectsPage = fetchedApplication.projects;
            }
            if (this.existingDraft) {
              const existRadiState = fetchedApplication.areThereOtherSourcesOfFunding ?? undefined;
              if (existRadiState == undefined) {
                this.areThereOtherSourcesOfFundingRadioState = '';
              } else if (existRadiState) {
                this.areThereOtherSourcesOfFundingRadioState = 'true';
              } else {
                this.areThereOtherSourcesOfFundingRadioState = 'false';
              }
              this.setAreThereOtherSourcesOfFundingRadioStates();
              this.functionalCategoriesSources.patchValue({
                areThereOtherSourcesOfFunding: this.areThereOtherSourcesOfFundingRadioState === 'true',
                isFundingFromAMWWP: fetchedApplication.isFundingFromAMWWP,
                isFundingFromCCBF: fetchedApplication.isFundingFromCCBF,
                isFundingFromSTIP: fetchedApplication.isFundingFromSTIP,
                isFundingFromMSI: fetchedApplication.isFundingFromMSI,
                isFundingFromWaterForLife: fetchedApplication.isFundingFromWaterForLife,
                isFundingFromOther: fetchedApplication.isFundingFromOther,
                fundingFromOther: fetchedApplication.fundingFromOther,
                noOtherSourceOfFunding: this.areThereOtherSourcesOfFundingRadioState === 'false',

                isOwnedByLocalGovernment: fetchedApplication.isOwnedByLocalGovernment ?? false,
                isNonProfitOwnership: fetchedApplication.isNonProfitOwnership,
                nonProfitOwnership: fetchedApplication.nonProfitOwnership,
                isRegionalServiceCommissionOwnership: fetchedApplication.isRegionalServiceCommissionOwnership,
                regionalServiceCommissionOwnership: fetchedApplication.regionalServiceCommissionOwnership,
                isLocalGovernmentOwnership: fetchedApplication.isLocalGovernmentOwnership,
                localGovernmentOwnership: fetchedApplication.localGovernmentOwnership,
              });
              this.resetTouchedValues();
            }
          }),
        )
        .subscribe(),
    );
  }

  private _SubscribeToFormValuesForStructureChange() {
    const checkboxAndSpecifyTxts = [
      { checkbox: 'isFundingFromOther', textbox: 'fundingFromOther' },
      { checkbox: 'isNonProfitOwnership', textbox: 'nonProfitOwnership' },
      { checkbox: 'isRegionalServiceCommissionOwnership', textbox: 'regionalServiceCommissionOwnership' },
      { checkbox: 'isLocalGovernmentOwnership', textbox: 'localGovernmentOwnership' },
    ];

    for (const controls of checkboxAndSpecifyTxts) {
      this.sub.add(
        this.functionalCategoriesSources
          .get(controls.checkbox)
          ?.valueChanges.pipe(takeWhile(() => this.isAlive))
          .subscribe((checked: boolean) => this._configureSpecifyTxt(checked, controls.textbox)),
      );
    }
  }

  private _configureSpecifyTxt(checked: boolean, specifyTxtName: string): void {
    if (!checked) this.functionalCategoriesSources.controls[specifyTxtName].reset();
  }

  get isFundingFromOther(): boolean {
    return this.functionalCategoriesSources.get('isFundingFromOther')?.value === true;
  }

  get isNonProfitOwnership(): boolean {
    return this.functionalCategoriesSources.get('isNonProfitOwnership')?.value === true;
  }

  get isRegionalServiceCommissionOwnership(): boolean {
    return this.functionalCategoriesSources.get('isRegionalServiceCommissionOwnership')?.value === true;
  }

  get isLocalGovernmentOwnership(): boolean {
    return this.functionalCategoriesSources.get('isLocalGovernmentOwnership')?.value === true;
  }

  private _areThereOtherSourcesOfFundingValidator(control: AbstractControl): ValidationErrors | null {
    const areThereOtherSourcesOfFunding = control.get('areThereOtherSourcesOfFunding');

    if (areThereOtherSourcesOfFunding?.value !== true && areThereOtherSourcesOfFunding?.value !== false) {
      return { areThereOtherSourcesOfFundingQuestionNotAnswered: true };
    }

    return null;
  }

  private _whoWillOwnTheValidator(control: AbstractControl): ValidationErrors | null {
    const isOwnedByLocalGovernment = control.get('isOwnedByLocalGovernment');
    const isNonProfitOwnership = control.get('isNonProfitOwnership');
    const isRegionalServiceCommissionOwnership = control.get('isRegionalServiceCommissionOwnership');
    const isLocalGovernmentOwnership = control.get('isLocalGovernmentOwnership');

    if (
      isOwnedByLocalGovernment?.value !== true &&
      isNonProfitOwnership?.value !== true &&
      isRegionalServiceCommissionOwnership?.value !== true &&
      isLocalGovernmentOwnership?.value !== true
    ) {
      return { whoWillOwnTheQuestionNotAnswered: true };
    }

    return null;
  }

  private _otherSourcesOfFundingYesValidator(control: AbstractControl): ValidationErrors | null {
    const areThereOtherSourcesOfFunding = control.get('areThereOtherSourcesOfFunding');
    if (areThereOtherSourcesOfFunding?.value !== true) return null;
    const isFundingFromAMWWP = control.get('isFundingFromAMWWP');
    const isFundingFromCCBF = control.get('isFundingFromCCBF');
    const isFundingFromSTIP = control.get('isFundingFromSTIP');
    const isFundingFromMSI = control.get('isFundingFromMSI');
    const isFundingFromWaterForLife = control.get('isFundingFromWaterForLife');
    const isFundingFromOther = control.get('isFundingFromOther');

    if (
      isFundingFromAMWWP?.value !== true &&
      isFundingFromCCBF?.value !== true &&
      isFundingFromSTIP?.value !== true &&
      isFundingFromMSI?.value !== true &&
      isFundingFromWaterForLife?.value !== true &&
      isFundingFromOther?.value !== true
    ) {
      return { otherSourcesOfFundingYesButNoSourcesSelected: true };
    }
    return null;
  }

  private _otherSourcesOfFundingYesOtherSpecifyValidator(control: AbstractControl): ValidationErrors | null {
    const isFundingFromOther = control.get('isFundingFromOther');
    const fundingFromOther = control.get('fundingFromOther');
    return FunctionalCategoriesComponent._specifyValidator(
      isFundingFromOther,
      fundingFromOther,
      'otherSourcesOfFundingYesAndOtherSpecifyButEmpty',
    );
  }

  private _ownershipNonProfitOrganizationSpecifyValidator(control: AbstractControl): ValidationErrors | null {
    const isNonProfitOwnership = control.get('isNonProfitOwnership');
    const nonProfitOwnership = control.get('nonProfitOwnership');
    return FunctionalCategoriesComponent._specifyValidator(
      isNonProfitOwnership,
      nonProfitOwnership,
      'ownershipNonProfitOrganizationSpecifyEmpty',
    );
  }

  private _ownershipRegionalServiceCommissionSpecifyValidator(control: AbstractControl): ValidationErrors | null {
    const isRegionalServiceCommissionOwnership = control.get('isRegionalServiceCommissionOwnership');
    const regionalServiceCommissionOwnership = control.get('regionalServiceCommissionOwnership');
    return FunctionalCategoriesComponent._specifyValidator(
      isRegionalServiceCommissionOwnership,
      regionalServiceCommissionOwnership,
      'ownershipRegionalServiceCommissionSpecifyEmpty',
    );
  }

  private _ownershipAnotherLocalGovtSpecifyValidator(control: AbstractControl): ValidationErrors | null {
    const isLocalGovernmentOwnership = control.get('isLocalGovernmentOwnership');
    const localGovernmentOwnership = control.get('localGovernmentOwnership');
    return FunctionalCategoriesComponent._specifyValidator(
      isLocalGovernmentOwnership,
      localGovernmentOwnership,
      'ownershipAnotherLocalGovtSpecifyEmpty',
    );
  }

  private static _specifyValidator(
    checkbox: AbstractControl | null,
    textbox: AbstractControl | null,
    errorCode: string,
  ): ValidationErrors | null {
    if (checkbox?.value !== true) return null;
    if (textbox?.value == null || textbox.value.trim() == '') return { [errorCode]: true };
    return null;
  }

  get shouldShowOtherFundingSourceValidationMessage(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('areThereOtherSourcesOfFunding')) &&
      this._hasErrorFcs('areThereOtherSourcesOfFundingQuestionNotAnswered')
    );
  }

  get shouldShowOwnershipValidationMessage(): boolean {
    return (
      (this.nextClickValidation ||
        this._isTouchedFcs('isOwnedByLocalGovernment') ||
        this._isTouchedFcs('isNonProfitOwnership') ||
        this._isTouchedFcs('isRegionalServiceCommissionOwnership') ||
        this._isTouchedFcs('isLocalGovernmentOwnership')) &&
      this._hasErrorFcs('whoWillOwnTheQuestionNotAnswered')
    );
  }

  get shouldShowOtherSourcesOfFundingYesButNoSourcesSelected(): boolean {
    return (
      (this.nextClickValidation ||
        this._isTouchedFcs('isFundingFromAMWWP') ||
        this._isTouchedFcs('isFundingFromCCBF') ||
        this._isTouchedFcs('isFundingFromSTIP') ||
        this._isTouchedFcs('isFundingFromMSI') ||
        this._isTouchedFcs('isFundingFromWaterForLife') ||
        this._isTouchedFcs('isFundingFromOther')) &&
      this._hasErrorFcs('otherSourcesOfFundingYesButNoSourcesSelected')
    );
  }

  get shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('fundingFromOther')) &&
      this._hasErrorFcs('otherSourcesOfFundingYesAndOtherSpecifyButEmpty')
    );
  }

  get shouldShowOwnershipNonProfitOrganizationSpecifyEmpty(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('nonProfitOwnership')) &&
      this._hasErrorFcs('ownershipNonProfitOrganizationSpecifyEmpty')
    );
  }

  get shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('regionalServiceCommissionOwnership')) &&
      this._hasErrorFcs('ownershipRegionalServiceCommissionSpecifyEmpty')
    );
  }

  get shouldShowOwnershipAnotherLocalGovtSpecifyEmpty(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('localGovernmentOwnership')) &&
      this._hasErrorFcs('ownershipAnotherLocalGovtSpecifyEmpty')
    );
  }

  onFcsTxtBoxBlur(controlName: string) {
    this.markAsTouchedFcs(controlName);
    this.triggerAutosave();
  }

  markAsTouchedFcs(controlName: string) {
    this.timeoutIds.push(
      setTimeout(() => {
        this.functionalCategoriesSources.get(controlName)?.markAsTouched();
      }, 150),
    );
  }

  private _isTouchedFcs(controlName: string): boolean {
    const isTouched = this.functionalCategoriesSources?.get(controlName)?.touched ?? false;
    if (isTouched && this.touchedName.indexOf(controlName) < 0) {
      this.touchedName.push(controlName);
      // console.warn(controlName + ' isTouched= ' + isTouched);
    }
    return isTouched;
  }

  private _hasErrorFcs(errorCode: string): boolean {
    return this.functionalCategoriesSources.hasError(errorCode);
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadFunctionalCategoryTypes());
    this._prepareForm();

    this.resetForm();

    this.categoriesAll = this.categoriesAll
      .map((c) => {
        return {
          id: c.id,
          name: c.name,
          disabled: false,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.sub.add(
      this.functionalCategories
        .get('category')
        ?.valueChanges.pipe(takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.timeoutIds.push(
            setTimeout(() => {
              this.onCategoryPercentageValueChanges('category');
            }),
          );
        }),
    );

    this.sub.add(
      this.functionalCategories
        .get('cost')
        ?.valueChanges.pipe(takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.timeoutIds.push(
            setTimeout(() => {
              this.onCategoryPercentageValueChanges('cost');
            }),
          );
        }),
    );
    this.sub.add(
      this.functionalCategoryEditForm
        .get('mCategory')
        ?.valueChanges.pipe(takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.timeoutIds.push(
            setTimeout(() => {
              this.onCategoryPercentageModalFieldValueChange('mCategory');
            }),
          );
        }),
    );

    this.sub.add(
      this.functionalCategoryEditForm
        .get('mCost')
        ?.valueChanges.pipe(takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.timeoutIds.push(
            setTimeout(() => {
              this.onCategoryPercentageModalFieldValueChange('mCost');
            }),
          );
        }),
    );
    this.setupAutosaveTrigger();
  }

  private setupAutosaveTrigger() {
    //setup autosave trigger on all input fields' value change except text boxes.
    Object.keys(this.functionalCategoriesSources.controls).forEach((key) => {
      const control = this.functionalCategoriesSources.get(key) as FormControl;

      const txtBoxes = [
        'fundingFromOther',
        'nonProfitOwnership',
        'regionalServiceCommissionOwnership',
        'localGovernmentOwnership',
      ];
      if (txtBoxes.includes(key)) return; //text boxes are using (blur) to autosave.

      this.sub.add(
        control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
          this.triggerAutosaveSubject.next();
        }),
      );
    });

    //autosave calls funnel
    this.sub.add(
      this.triggerAutosaveSubject
        .pipe(debounceTime(200)) //A single input change may trigger mutiple value changes. So skipping until it's settled.
        .subscribe(() => {
          //autosave
          const functionalCategoryPage = this.GetFunctionalCategories();
          this.store.dispatch(new AutosaveFunctionalCategories(functionalCategoryPage));
        }),
    );
  }

  triggerAutosave() {
    this.triggerAutosaveSubject.next();
  }

  ngAfterViewChecked(): void {
    if (!this.isDropdownStyleAdjusted) {
      this.adjustShadowDomCss();
      this.isDropdownStyleAdjusted = true;
    }
  }

  resetForm() {
    this.displayForm = false;
    this.functionalCategories.controls['category'].reset();
    this.functionalCategories.controls['isPrimary'].reset();
    this.functionalCategories.controls['cost'].reset();
    this.timeoutIds.push(
      setTimeout(() => {
        this.displayForm = true;
        this.isDropdownStyleAdjusted = false;
      }, 0),
    );

    if (this.categoryList.filter((item) => item.isPrimary === true).length > 0) {
      this.categoryLabel = this.res.transform('additionalCategory', this.pageId);
      this.helpTextCategory = this.res.transform('additionalCategoryHelpText', this.pageId);
    } else {
      this.categoryLabel = this.res.transform('primaryCategory', this.pageId);
      this.helpTextCategory = this.res.transform('primaryCategoryHelpText', this.pageId);
    }
    //reset category list
    this.categories = this.categoriesAll
      .map((c) => {
        return {
          id: c.id,
          name: c.name,
          disabled: false,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    //set category list based on selection - disable already selected category
    if (this.categoryList.filter((x) => x.isPrimary == true).length > 0) {
      for (const c of this.categoryList) {
        for (const ct of this.categories) {
          if (ct.id === c.categoryId)
            this.categories[this.categories.findIndex((x) => x.name == ct.name)].disabled = true;
        }
      }
    }
    this.categoryList.sort((a, b) => (a.isPrimary! > b.isPrimary! ? -1 : 1));
    this.calculateTotalCost();
  }

  openDeleteModal(i: number) {
    this.isOpen = true;
    this.selectedIndex = i;
    this.currentCategoryTitle = this.categoryList[i].categoryTitle!;
    this.currentCost = this.categoryList[i].cost!;
  }

  closeDeleteModal() {
    this.isOpen = false;
  }

  closeEditModal() {
    this.isEditOpen = false;

    this.timeoutIds.push(
      setTimeout(() => {
        Object.keys(this.functionalCategoryEditForm.controls).forEach((control) => {
          if (this.errors[control]) delete this.errors[control];
        });
        this.mCategoryNotValid = false;
        this.categoryAlreadyExisting = false;
        this.categoryProjectExisting = false;
      }),
    );
    this.categoriesAll = this.categoriesAll
      .map((c) => {
        return {
          id: c.id,
          name: c.name,
          disabled: false,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    const body = Array.from(document.getElementsByClassName('mat-typography') as HTMLCollectionOf<HTMLElement>);

    body.forEach((b) => {
      b.style.overflow = 'auto';
    });
  }

  onOptionsSelected(event: Event) {
    this.timeoutIds.push(
      setTimeout(() => {
        const ddlModalElement = event.target as HTMLSelectElement;
        if (ddlModalElement) {
          const value: number | undefined = parseInt(ddlModalElement.value);
          if (
            this.isEditOpen == true &&
            value != this.currentCategoryId &&
            this.categoryList.filter((item) => item.categoryId === value).length > 0
          ) {
            this.categoryAlreadyExisting = true;
          } else {
            this.categoryAlreadyExisting = false;
          }

          if (
            this.isEditOpen == true &&
            value != this.currentCategoryId &&
            this.currentPrimary == true &&
            this.projectsPage?.length > 0
          ) {
            this.categoryProjectExisting = true;
          } else {
            this.categoryProjectExisting = false;
          }
        }
      }, 100),
    );
  }

  onEditClick(i: number) {
    this.mCategoryNotValid = false;
    this.categoryAlreadyExisting = false;
    this.categoryProjectExisting = false;
    this.selectedIndex = i;
    this.currentCategoryTitle = this.categoryList[i].categoryTitle!;
    this.currentCategoryId = this.categoryList[i].categoryId!;
    this.functionalCategoryEditForm.controls['mCategory'].patchValue(this.categoryList[i].categoryId);
    this.currentPrimary = this.categoryList[i].isPrimary!;
    this.functionalCategoryEditForm.controls['mPrimary'].patchValue(this.categoryList[i].isPrimary);
    this.currentCost = this.categoryList[i].cost!;
    this.functionalCategoryEditForm.controls['mCost'].patchValue(this.categoryList[i].cost);

    if (this.currentPrimary == false) {
      this.mCategoryLabel = this.res.transform('additionalCategory', this.pageId);
      this.mHelpTextCategory = this.res.transform('additionalCategoryHelpText', this.pageId);
    } else {
      this.mCategoryLabel = this.res.transform('primaryCategory', this.pageId);
      this.mHelpTextCategory = this.res.transform('primaryCategoryHelpText', this.pageId);
    }

    if (!this.currentPrimary) {
      this.categoriesAll = this.categoriesAll
        .map((c) => {
          return {
            id: c.id,
            name: c.name,
            disabled:
              c.id == this.currentCategoryId || c.id != this.categoryList.find((y) => y.categoryId == c.id)?.categoryId
                ? false
                : true,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    this.adjustShadowDomCssModal();

    this.isEditOpen = true;
    // enable scrollbar on main component on modal close
    const body = Array.from(document.getElementsByClassName('mat-typography') as HTMLCollectionOf<HTMLElement>);
    body.forEach((b) => {
      b.style.overflow = 'auto';
    });
  }

  mCategoryNotValid = false;
  updateCategory() {
    console.log('Category updated');
    this.validateModalFieldsOnSave();
    if (this.functionalCategoryEditForm.valid) {
      this.mCategoryNotValid = false;
      const i = this.selectedIndex;
      if (this.functionalCategoryEditForm.controls['mCategory'].value != this.currentCategoryId) {
        this.categories[this.categories.findIndex((x) => x.id == this.categoryList[i].categoryId)].disabled = false;
      }
      this.categoryList.splice(i, 1);
      if (this.categoryAlreadyExisting) {
        this.categoryList.splice(
          this.categoryList.findIndex(
            (x) => x.categoryId === this.functionalCategoryEditForm.controls['mCategory'].value,
          ),
          1,
        );
      }
      this.functionalCategoryEditForm.patchValue({ primary: this.currentPrimary });
      const updatedCategory: CategoryWithTitle = {
        categoryId: this.functionalCategoryEditForm.controls['mCategory'].value,
        categoryTitle:
          this.categories[
            this.categories.findIndex((x) => x.id === this.functionalCategoryEditForm.controls['mCategory'].value)
          ].name,
        isPrimary: this.functionalCategoryEditForm.controls['mPrimary'].value,
        cost: +this.functionalCategoryEditForm.controls['mCost'].value,
      };
      this.categoryList.push(updatedCategory);
      console.log('Category updated dispatch invoked');
      this.store.dispatch(new SetFunctionalCategories(this.GetFunctionalCategories()));
      if (this.categoryProjectExisting) {
        this.store.dispatch(new DeleteProjects());
      }
      this.calculateTotalCost();
      this.snackBarCategoryEdited(this.currentCategoryTitle);
      this.resetForm();
      this.closeEditModal();
      this.triggerAutosave();
    }
  }

  get selectedModalCategory(): string {
    return this.categories[
      this.categories.findIndex((x) => x.id === this.functionalCategoryEditForm.controls['mCategory'].value)
    ].name;
  }

  msg = '';
  snackBarCategoryEdited(res: string) {
    this.msg = '';
    this.msg = `"${res}" category successfully edited.`;
    this.snackBarService.showSuccessMessage(this.msg);
  }

  removeCategory() {
    this.isOpen = false;
    const i = this.selectedIndex;
    this.categories[this.categories.findIndex((x) => x.id == this.categoryList[i].categoryId)].disabled = false;
    this.categoryList.splice(i, 1);
    this.store.dispatch(new SetFunctionalCategories(this.GetFunctionalCategories()));
    this.calculateTotalCost();

    this.snackBarService.showSuccessMessage(`"${this.currentCategoryTitle}" category successfully deleted.`);
    this.triggerAutosave();
  }

  get areThereOtherSourcesOfFunding(): boolean | undefined {
    return this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].value;
  }

  private setAreThereOtherSourcesOfFundingRadioStates() {
    this.setRadioCheckedState('questionNo', this.areThereOtherSourcesOfFundingRadioState === 'false');
    this.setRadioCheckedState('areThereOtherSourcesOfFunding', this.areThereOtherSourcesOfFundingRadioState === 'true');
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
        if (checkedValue !== undefined) {
          elem.setAttribute('checked', checked ? 'true' : 'false');
        }
      }
    });
  }

  // set areThereOtherSourcesOfFunding(answer: boolean | undefined)
  public OtherSourcesOfFundingClicked(answer: boolean) {
    if (answer === true) {
      this.functionalCategoriesSources.controls['noOtherSourceOfFunding'].setValue(false);
      this.setRadioCheckedState('questionNo', false);
      this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].setValue(true);
      this.setRadioCheckedState('areThereOtherSourcesOfFunding', true);
    } else {
      this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].setValue(false);
      this.setRadioCheckedState('areThereOtherSourcesOfFunding', false);
      this.functionalCategoriesSources.controls['noOtherSourceOfFunding'].setValue(true);
      this.setRadioCheckedState('questionNo', true);
    }
    if (answer !== true) {
      this.functionalCategoriesSources.controls['fundingFromOther'].reset();
      this.functionalCategoriesSources.patchValue({
        areThereOtherSourcesOfFunding: false,
        fundingFromOther: '',
        isFundingFromOther: false,
        isFundingFromAMWWP: false,
        isFundingFromCCBF: false,
        isFundingFromSTIP: false,
        isFundingFromMSI: false,
        isFundingFromWaterForLife: false,
        noOtherSourceOfFunding: true,
      });

      //TODO: refactor the code to define proper form groups,
      //so that we can reset the group instead of resetting individual controls
      this.functionalCategoriesSources.controls['isFundingFromAMWWP'].reset();
      this.functionalCategoriesSources.controls['isFundingFromCCBF'].reset();
      this.functionalCategoriesSources.controls['isFundingFromSTIP'].reset();
      this.functionalCategoriesSources.controls['isFundingFromMSI'].reset();
      this.functionalCategoriesSources.controls['isFundingFromWaterForLife'].reset();
      this.functionalCategoriesSources.controls['isFundingFromOther'].reset();

      //Even after reset(), sometimes these controls are in touched state. So manually calling markAsUntouched()
      this.functionalCategoriesSources.controls['isFundingFromAMWWP'].markAsUntouched();
      this.functionalCategoriesSources.controls['isFundingFromCCBF'].markAsUntouched();
      this.functionalCategoriesSources.controls['isFundingFromSTIP'].markAsUntouched();
      this.functionalCategoriesSources.controls['isFundingFromMSI'].markAsUntouched();
      this.functionalCategoriesSources.controls['isFundingFromWaterForLife'].markAsUntouched();
      this.functionalCategoriesSources.controls['isFundingFromOther'].markAsUntouched();
    }
    this.cdRef.detectChanges();
  }

  adjustShadowDomCss() {
    const min_width = window.matchMedia('(min-width: 42rem)');
    if (min_width.matches) {
      const fiPrimaryCategory = document.getElementById('fiPrimaryCategory');
      if (fiPrimaryCategory) fiPrimaryCategory.style.width = '42rem';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const goa_popover = <any>(
        document.querySelector('goa-dropdown#ddlPrimaryCategory')?.shadowRoot?.querySelector('goa-popover')
      );
      if (goa_popover) {
        goa_popover.maxwidth = '42rem';

        const popover_target_container = goa_popover.shadowRoot?.querySelector('div');
        if (popover_target_container) {
          popover_target_container.style.width = '100%';

          const popover_target = popover_target_container.querySelector('div.popover-target');
          if (popover_target) popover_target.style.width = '100%';
        }
      }
    }
  }

  adjustShadowDomCssModal() {
    const min_width = window.matchMedia('(min-width: 42rem)');
    if (min_width.matches) {
      const fiPrimaryCategory = document.getElementById('mfiPrimaryCategory');
      if (fiPrimaryCategory) {
        fiPrimaryCategory.style.width = '42rem';
        fiPrimaryCategory.style.display = 'block';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const goa_popover = <any>(
        document.querySelector('goa-dropdown#mddlPrimaryCategory')?.shadowRoot?.querySelector('goa-popover')
      );
      if (goa_popover) {
        goa_popover.maxwidth = '42rem';

        const popover_target_container = goa_popover.shadowRoot?.querySelector('div');
        if (popover_target_container) {
          popover_target_container.style.width = '100%';

          const popover_target = popover_target_container.querySelector('div.popover-target');
          if (popover_target) popover_target.style.width = '100%';
        }
      }
    }
  }

  addCategory() {
    this.validateFieldsOnAdd();
    if (this.functionalCategories.valid) {
      this.categoryNotValid = false;
      if (this.categoryList.filter((item) => item.categoryId === 0).length > 0) {
        this.categoryList.pop();
      }
      if (this.categoryList.length == 0) {
        this.functionalCategories.patchValue({ isPrimary: true });
      } else {
        this.functionalCategories.patchValue({ isPrimary: false });
      }

      const newCategory: CategoryWithTitle = {
        categoryId: this.functionalCategories.controls['category'].value,
        categoryTitle:
          this.categories[
            this.categories.findIndex((x) => x.id == this.functionalCategories.controls['category'].value)
          ].name,
        isPrimary: this.functionalCategories.controls['isPrimary'].value,
        cost: +this.functionalCategories.controls['cost'].value,
      };
      this.categoryList.push(newCategory);
      this.store.dispatch(new SetFunctionalCategories(this.GetFunctionalCategories()));
      this.calculateTotalCost();
      const category = this.categoryList[this.categoryList.length - 1].categoryTitle!;
      this.snackBarService.showSuccessMessageWithScrollToTopLink(
        `"${category}" category successfully added.`,
        'func-cat-heading-1',
        'to add another category.',
      );
      this.resetForm();
      this.triggerAutosave();
    }
  }

  isPrimaryCategoryNotMajorityPercentage = false;
  isTotalPercentageInvalid = false;
  calculateTotalCost() {
    this.totalCost = this.categoryList.reduce((sum: number, current) => sum + +current.cost! ?? 0, 0);
    const majorityPercentage = Math.max(...this.categoryList.map((o) => o.cost!));
    const primaryCat = this.categoryList.find((n) => n.isPrimary == true);
    const primaryPercentage = primaryCat?.cost || 0;
    if (this.totalCost == 0 && this.nextClickValidation) {
      this.errors['category'] = this.getErrorMessage('category');
      this.errors['cost'] = this.getErrorMessage('cost');
      this.categoryNotValid = true;
    } else if (
      ((this.functionalCategoriesPage.length ?? 0) > 0 && !this.functionalCategoryFirstTimeInput) ||
      this.nextClickValidation
    ) {
      this.isPrimaryCategoryNotMajorityPercentage = majorityPercentage > primaryPercentage;
      this.isTotalPercentageInvalid = this.totalCost != 100;
    }
    //Add return value to help check if the step is valid when clicking the Previous btn.
    //The return value is only used in validateOnPrevious().
    //If return true, it means that the form is not valid, we need to show "Partially Complete" in the stepper for this step.
    //If return false,it means that we need to set the stepper status to Complete.
    //At the same time, the form is not valid, we should not trigger the error callout on top of the screen (which should only be triggered when clicking the Next btn)
    //Also this is not used to block the user from going previous.
    return majorityPercentage > primaryPercentage || this.totalCost != 100;
  }

  GetFunctionalCategories(): Partial<ApplicationDraftRecordDto> {
    let categories: FunctionalCategoryDraftDto[] = [];

    if (this.categoryList && this.categoryList.filter((x) => x.isPrimary == true).length > 0) {
      categories = this.categoryList?.map((c) => {
        return {
          isPrimary: c.isPrimary,
          percentageCost: +(c.cost ?? 0),
          functionalCategoryTypeId: c.categoryId,
        };
      });
    }
    return {
      areThereOtherSourcesOfFunding: this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].value,
      isFundingFromAMWWP: this.functionalCategoriesSources.controls['isFundingFromAMWWP'].value ? true : false,
      isFundingFromCCBF: this.functionalCategoriesSources.controls['isFundingFromCCBF'].value ? true : false,
      isFundingFromSTIP: this.functionalCategoriesSources.controls['isFundingFromSTIP'].value ? true : false,
      isFundingFromMSI: this.functionalCategoriesSources.controls['isFundingFromMSI'].value ? true : false,
      isFundingFromWaterForLife: this.functionalCategoriesSources.controls['isFundingFromWaterForLife'].value
        ? true
        : false,
      isFundingFromOther: this.functionalCategoriesSources.controls['isFundingFromOther'].value ? true : false,
      fundingFromOther: this.functionalCategoriesSources.controls['isFundingFromOther'].value
        ? this.functionalCategoriesSources.controls['fundingFromOther'].value
        : undefined,

      isOwnedByLocalGovernment: this.functionalCategoriesSources.controls['isOwnedByLocalGovernment'].value
        ? true
        : false,
      isNonProfitOwnership: this.functionalCategoriesSources.controls['isNonProfitOwnership'].value ? true : false,
      nonProfitOwnership: this.functionalCategoriesSources.controls['isNonProfitOwnership'].value
        ? this.functionalCategoriesSources.controls['nonProfitOwnership'].value
        : undefined,
      isRegionalServiceCommissionOwnership: this.functionalCategoriesSources.controls[
        'isRegionalServiceCommissionOwnership'
      ].value
        ? true
        : false,
      regionalServiceCommissionOwnership: this.functionalCategoriesSources.controls[
        'isRegionalServiceCommissionOwnership'
      ].value
        ? this.functionalCategoriesSources.controls['regionalServiceCommissionOwnership'].value
        : undefined,
      isLocalGovernmentOwnership: this.functionalCategoriesSources.controls['isLocalGovernmentOwnership'].value
        ? true
        : false,
      localGovernmentOwnership: this.functionalCategoriesSources.controls['isLocalGovernmentOwnership'].value
        ? this.functionalCategoriesSources.controls['localGovernmentOwnership'].value
        : undefined,

      functionalCategories: categories,
    };
  }

  onCategoryPercentageValueChanges(controlName: string) {
    //fields mandatory on focus out for if there is no primary category yet or error raised by Add button for additional category
    if (
      this.categoryList.filter((x) => x.isPrimary == true).length == 0 ||
      (this.calloutAdditionalCategoryErrorOnAdd && this.categoryNotValid)
    ) {
      if (!this.getErrorMessage(controlName)) {
        delete this.errors[controlName];
      }
    }
    if (!this.errors['category'] && !this.errors['cost']) this.categoryNotValid = false;
  }

  onFocusOut(controlName: string) {
    //fields mandatory on focus out for if there is no primary category yet or error raised by Add button for additional category
    if (
      this.categoryList.filter((x) => x.isPrimary == true).length == 0 ||
      (this.calloutAdditionalCategoryErrorOnAdd && this.categoryNotValid)
    ) {
      if (this.getErrorMessage(controlName)) this.errors[controlName] = this.getErrorMessage(controlName);
      else delete this.errors[controlName];
    }

    if (!this.errors['category'] && !this.errors['cost']) this.categoryNotValid = false;
  }

  getErrorMessage(name: string): string {
    switch (name) {
      case 'category':
        if (this.functionalCategories.get('category')?.hasError('required')) {
          return this.res.transform('errorMsgCategoryRequired', this.pageId);
        }
        break;
      case 'mCategory':
        if (this.functionalCategoryEditForm.get('mCategory')?.hasError('required')) {
          return this.res.transform('errorMsgCategoryRequired', this.pageId);
        }
        break;
      case 'cost':
        if (this.functionalCategories.get('cost')?.hasError('required')) {
          return this.res.transform('errorMsgPercentageCostRequired', this.pageId);
        }
        break;
      case 'mCost':
        if (this.functionalCategoryEditForm.get('mCost')?.hasError('required')) {
          return this.res.transform('errorMsgPercentageCostRequired', this.pageId);
        }
        break;
      default:
        return '';
    }
    return '';
  }

  validateFieldsOnAdd() {
    if (this.getErrorMessage('category')) {
      this.errors['category'] = this.getErrorMessage('category');
    } else {
      delete this.errors['category'];
    }
    if (this.getErrorMessage('cost')) {
      this.errors['cost'] = this.getErrorMessage('cost');
    } else {
      delete this.errors['cost'];
    }

    if (this.errors['category'] || this.errors['cost']) {
      this.categoryNotValid = true;
    } else {
      this.categoryNotValid = false;
    }
  }

  clearAdditionalCategory() {
    Object.keys(this.functionalCategories.controls).forEach((control) => {
      if (this.errors[control]) {
        delete this.errors[control];
        this.categoryNotValid = false;
      }
    });
    this.resetForm();
  }

  get calloutAdditionalCategoryErrorOnAdd(): boolean {
    return this.categoryList.filter((x) => x.isPrimary == true).length > 0 ? true : false;
  }

  validateModalFieldsOnSave() {
    if (this.errors['mCategory'] || this.errors['mCost']) this.mCategoryNotValid = true;
    else this.mCategoryNotValid = false;
  }

  onModalFieldValueChange(controlName: string) {
    if (this.getErrorMessage(controlName)) this.errors[controlName] = this.getErrorMessage(controlName);
    else delete this.errors[controlName];
    //clear callout flag
    if (!this.errors['mCategory'] && !this.errors['mCost']) {
      this.mCategoryNotValid = false;
    } else {
      this.mCategoryNotValid = true;
    }
  }
  onCategoryPercentageModalFieldValueChange(controlName: string) {
    if (!this.getErrorMessage(controlName)) {
      delete this.errors[controlName];
    }
    if (!this.errors['mCategory'] && !this.errors['mCost']) this.mCategoryNotValid = false;
  }

  _isStepFullyEmpty(): boolean {
    //if the table is empty, and none of the fields are touched, we consider the form is fully empty.
    const res =
      this.categoryList[0].categoryId == 0 &&
      this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].value == undefined &&
      !this.functionalCategoriesSources.controls['isOwnedByLocalGovernment'].touched &&
      !this.functionalCategoriesSources.controls['isNonProfitOwnership'].touched &&
      !this.functionalCategoriesSources.controls['isRegionalServiceCommissionOwnership'].touched &&
      !this.functionalCategoriesSources.controls['isLocalGovernmentOwnership'].touched;
    return res;
  }

  _isStepStatusIncomplete(): boolean {
    const res =
      this.showErrorFieldsCallout ||
      this.categoryList[0].categoryId == 0 ||
      this.calculateTotalCost() ||
      !this.functionalCategoriesSources.valid;
    return res;
  }

  override validateOnPrevious(): boolean {
    if (this._isStepFullyEmpty()) {
      this.store.dispatch(
        new SetFormStepperStatus({
          [FormSequence.FunctionalCat]: FormStatusCodes.NotStarted,
        }),
      );
    } else if (this._isStepStatusIncomplete()) {
      this.store.dispatch(
        new SetFormStepperStatus({
          [FormSequence.FunctionalCat]: FormStatusCodes.InComplete,
        }),
      );
    }
    ////We don't dispatch an action for Complete status inside the Previous btn.
    //return !this.hasIncompleteCategoryModal();
    return true; //TODO: Now there is no validation on Previous button click. As this function is also used to set the stepper status,
    //this function cannot be deleted now. So returning true to not change it's signature. This need to be refactored.
  }

  override validateOnNext() {
    // console.error('Functional validateOnNext called !!!!!');
    this._markVisibleControlsAsTouched();
    this.nextClickValidation = true;
    this.calculateTotalCost();
    if (this.showErrorFieldsCallout || this.totalCost === 0) {
      this.jumpToField('func-cat-heading-3');
      return false;
    } else {
      this.nextClickValidation = false;
    }
    //we only dispatch an action for Complete status in the validateOnNext, or when load a new draft in the subscription.
    //Here we only consider the step is complete if the user has clicked the next btn.
    //Because otherwise, we have to validate everything inside a onChange on the whole form.
    this.store.dispatch(
      new SetFormStepperStatus({
        [FormSequence.FunctionalCat]: FormStatusCodes.Complete,
      }),
    );
    return true;
  }

  //Marking visible controls as touched so that the corresponding validation messages will be shown
  private _markVisibleControlsAsTouched() {
    // console.warn('Functional _markVisibleControlsAsTouched called !!!!!!');
    this.functionalCategoriesSources.controls['areThereOtherSourcesOfFunding'].markAsTouched();
    if (this.areThereOtherSourcesOfFunding) {
      this.functionalCategoriesSources.controls['isFundingFromAMWWP'].markAsTouched();
      this.functionalCategoriesSources.controls['isFundingFromCCBF'].markAsTouched();
      this.functionalCategoriesSources.controls['isFundingFromSTIP'].markAsTouched();
      this.functionalCategoriesSources.controls['isFundingFromMSI'].markAsTouched();
      this.functionalCategoriesSources.controls['isFundingFromWaterForLife'].markAsTouched();
      this.functionalCategoriesSources.controls['isFundingFromOther'].markAsTouched();
      if (this.isFundingFromOther) {
        this.functionalCategoriesSources.controls['fundingFromOther'].markAsTouched();
      }
    }
    this.functionalCategoriesSources.controls['isOwnedByLocalGovernment'].markAsTouched();
    this.functionalCategoriesSources.controls['isNonProfitOwnership'].markAsTouched();
    if (this.isNonProfitOwnership) {
      this.functionalCategoriesSources.controls['nonProfitOwnership'].markAsTouched();
    }
    this.functionalCategoriesSources.controls['isRegionalServiceCommissionOwnership'].markAsTouched();
    if (this.isRegionalServiceCommissionOwnership) {
      this.functionalCategoriesSources.controls['regionalServiceCommissionOwnership'].markAsTouched();
    }
    this.functionalCategoriesSources.controls['isLocalGovernmentOwnership'].markAsTouched();
    if (this.isLocalGovernmentOwnership) {
      this.functionalCategoriesSources.controls['localGovernmentOwnership'].markAsTouched();
    }
  }

  shouldShowIncompleteCategoryModalOnNext() {
    return (
      !this.showErrorFieldsCallout &&
      ((this.functionalCategories.controls['category'].value !== null &&
        this.functionalCategories.controls['category'].value !== undefined &&
        this.functionalCategories.controls['category'].value !== '') ||
        !CommUtilsService.isStringEmpty(this.functionalCategories.controls['cost'].value))
    );
  }

  shouldShowOtherSourceFundingChangedToNoModalOnNext() {
    const projectsWithOtherSourceFunding = this.projectsPage.filter((project) => {
      return project.amountFromOtherGrantPrograms ?? 0 > 0;
    });
    return (
      !this.showErrorFieldsCallout &&
      this.functionalCategoriesSources.controls['noOtherSourceOfFunding'].value &&
      projectsWithOtherSourceFunding.length > 0
    );
  }

  shouldShowIncompleteCategoryModalOnSaveAndClose() {
    return (
      (this.functionalCategories.controls['category'].value !== null &&
        this.functionalCategories.controls['category'].value !== undefined &&
        this.functionalCategories.controls['category'].value !== '') ||
      !CommUtilsService.isStringEmpty(this.functionalCategories.controls['cost'].value)
    );
  }

  shouldShowOtherSourceFundingChangedToNoModalOnSaveAndClose() {
    const projectsWithOtherSourceFunding = this.projectsPage.filter((project) => {
      return project.amountFromOtherGrantPrograms ?? 0 > 0;
    });
    return (
      this.functionalCategoriesSources.controls['noOtherSourceOfFunding'].value &&
      projectsWithOtherSourceFunding.length > 0
    );
  }

  get showErrorFieldsCallout(): boolean {
    return (
      this.isPrimaryCategoryNotMajorityPercentage ||
      this.isTotalPercentageInvalid ||
      this.shouldShowOtherFundingSourceValidationMessage ||
      this.shouldShowOtherSourcesOfFundingYesButNoSourcesSelected ||
      this.shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty ||
      this.shouldShowOwnershipValidationMessage ||
      this.shouldShowOwnershipNonProfitOrganizationSpecifyEmpty ||
      this.shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty ||
      this.shouldShowOwnershipAnotherLocalGovtSpecifyEmpty
    );
  }
}
