import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { ResourcePipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { Subscription, combineLatest, startWith, takeWhile } from 'rxjs';
import { ProjectStatusType, SepoProjectExpenditureDto, SepoProjectExpenditureUpdateDto } from '@app-com/api/models';
import { formatNumberWithCommas } from '@app-com/directives';

@Component({
  selector: 'app-edit-sepo-project-expenditures-modal',
  templateUrl: './edit-sepo-project-expenditures-modal.component.html',
  styleUrl: './edit-sepo-project-expenditures-modal.component.scss',
})
export class EditSepoProjectExpendituresModalComponent {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo.projectUpdates;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'SEPO';

  projectEditForm: FormGroup;
  UiDefApplicationId: UiFieldCtrDef;
  UiDefApplicationName: UiFieldCtrDef;
  UiDefApplicationStatus: UiFieldCtrDef;
  UiDefProjectId: UiFieldCtrDef;
  UiDefProjectName: UiFieldCtrDef;
  UiDefProjectStatus: UiFieldCtrDef;
  UiDefEstimatedTotalCost: UiFieldCtrDef;
  UiDefLgffFundingRemaining: UiFieldCtrDef;
  UiDefActualReportingYearCost: UiFieldCtrDef;
  UiDefFundingFromOtherGrantPrograms: UiFieldCtrDef;
  UiDefLgffFundingApplied: UiFieldCtrDef;
  UiDefFundingFromMunicipalSources: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  submitClicked = false;
  sub = new Subscription();
  lgffFundingFromOtherGrantProgramsRequiredMessage = 'Enter the portion funded by other grant programs.';
  lgffFundingAmountAppliedRequiredMessage = 'Enter the LGFF funding applied.';

  combinedFundingVsReportingYearCostValidationMessage =
    '"Portion funded by other grant programs", "LGFF funding applied", and "Portion funded from municipal sources" must be positive numbers and their sum must equal the "Actual reporting year cost".';
  lgffFundingAmountAppliedVsFundingRemainingValidationMessage =
    '"LGFF funding applied" is greater than the "LGFF funding remaining" and the project is not reported as completed/fully-funded. If this value is correct, please submit a cash flow update to reflect this change.';

  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  @Input() areThereOtherSourcesOfFunding: boolean;
  @Input() organizationId: number;
  @Input() projectExpenditureId: number;
  @Input() sepoProjectToUpdate?: SepoProjectExpenditureDto;
  @Input() canEditProjectStatus?: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() projectStatus?: any;
  @Input() estimatedFundingLabel?: string = 'Total approved LGFF funding';
  @Input() reportingCostLabel?: string = 'Actual reporting year cost';
  @Input() lgffFundingReportingYearCostRequiredMessage?: string = 'Enter the actual reporting year cost.';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() isProjectValid: EventEmitter<any> = new EventEmitter();
  @Output() saveProject: EventEmitter<SepoProjectExpenditureDto> = new EventEmitter<SepoProjectExpenditureDto>();

  lgffFundingAmountAppliedVsReportingYearCostValidationMessage = `Amount must be less than or equal to the "${this.reportingCostLabel}".`;
  fundingFromOtherGrantProgramsVsReportingYearCostValidationMessage = `Amount must be less than or equal to the "${this.reportingCostLabel}".`;
  calloutRef: ElementRef;
  totalRemaining: number;
  projectExpenditureStatusCalloutHeading: string;
  projectStatusAmountError: string;
  saveButtonClickValidation = false;
  @ViewChild('callout', { read: ElementRef, static: false }) set content(content: ElementRef) {
    if (content) {
      this.calloutRef = content;
    }
  }
  @ViewChild('calloutImp', { read: ElementRef, static: false }) set contentCall(content: ElementRef) {
    if (content) {
      this.calloutRef = content;
    }
  }
  isAlive = true;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
  ) {}

  ngOnInit(): void {
    this.createProjectEditForm();
    this.initializeProjectToUpdate();
  }
  onChangeProjectStatus() {
    setTimeout(() => {
      if (this.projectStatusValidationCallout) {
        this.jumpToCallout(this.calloutRef);
      }
    }, 100);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeCommaFormat(val: any) {
    if (typeof val == 'string') {
      const str = val.replace(/,/g, '');
      return parseFloat(str);
    }
    return val;
  }

  validateValues(): boolean {
    this.projectEditForm.markAllAsTouched();
    this.pageUiDefs.forEach((uiDef) => {
      CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
    });
    this.saveButtonClickValidation = true;
    if (this.projectEditForm?.valid) {
      return true;
    } else {
      setTimeout(() => {
        this.jumpToCallout(this.calloutRef);
      }, 100);
      return false;
    }
  }

  // convert page UI-data into model, before sending to back-end
  _getProjectUpdate(): SepoProjectExpenditureUpdateDto {
    const project: SepoProjectExpenditureUpdateDto = {
      status: this.projectEditForm?.value[this.UiDefProjectStatus.nameCtr],
      actualReportingYearCost: this.projectEditForm?.value[this.UiDefActualReportingYearCost.nameCtr],
      fundingAppliedFromLGFF: this.projectEditForm?.value[this.UiDefLgffFundingApplied.nameCtr],
      fundingAppliedFromMunicipalSources: this.projectEditForm?.value[this.UiDefFundingFromMunicipalSources.nameCtr],
      amountFundedOtherGrantPrograms: this.projectEditForm?.value[this.UiDefFundingFromOtherGrantPrograms.nameCtr],
    };

    const projectValWithoutFormatting = {
      ...project,

      actualReportingYearCost: this.removeCommaFormat(project.actualReportingYearCost),
      fundingAppliedFromLGFF: this.removeCommaFormat(project.fundingAppliedFromLGFF),
      fundingAppliedFromMunicipalSources: this.removeCommaFormat(project.fundingAppliedFromMunicipalSources),
      amountFundedOtherGrantPrograms: this.removeCommaFormat(project.amountFundedOtherGrantPrograms),
    };
    return projectValWithoutFormatting;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }
  createProjectEditForm() {
    this.projectEditForm = this.formBuilder.group({});

    this.UiDefProjectStatus = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.projectStatus);
    this.projectEditForm.addControl(this.UiDefProjectStatus.nameCtr, this.UiDefProjectStatus.formCtr);

    this.UiDefEstimatedTotalCost = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.estimatedTotalCost);
    this.projectEditForm.addControl(this.UiDefEstimatedTotalCost.nameCtr, this.UiDefEstimatedTotalCost.formCtr);

    this.UiDefLgffFundingRemaining = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.fundingRemaining);
    this.projectEditForm.addControl(this.UiDefLgffFundingRemaining.nameCtr, this.UiDefLgffFundingRemaining.formCtr);

    this.UiDefActualReportingYearCost = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.actualReportingYearCost);
    this.projectEditForm.addControl(
      this.UiDefActualReportingYearCost.nameCtr,
      this.UiDefActualReportingYearCost.formCtr,
    );

    this.UiDefFundingFromOtherGrantPrograms = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromOtherGrantPrograms,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromOtherGrantPrograms.nameCtr,
      this.UiDefFundingFromOtherGrantPrograms.formCtr,
    );

    this.UiDefLgffFundingApplied = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.fundingApplied);
    this.projectEditForm.addControl(this.UiDefLgffFundingApplied.nameCtr, this.UiDefLgffFundingApplied.formCtr);

    this.UiDefFundingFromMunicipalSources = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromMunicipalSources,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromMunicipalSources.nameCtr,
      this.UiDefFundingFromMunicipalSources.formCtr,
    );
    this.totalRemaining = this.sepoProjectToUpdate?.totalRemaining ?? 0;
    this.projectEditForm.addValidators([
      this._lgffFundingReportingYearCostValidator,
      this._lgffFundingFromOtherGrantProgramsValidator,
      this._lgffFundingAmountAppliedValidator,
      this._lgffFundingAmountAppliedVsReportingYearCostValidator,
      this._fundingFromOtherGrantProgramsVsReportingYearCostValidator,
      this._combinedFundingVsReportingYearCostValidator,
    ]);
    this.pageUiDefs = [
      this.UiDefProjectStatus,
      this.UiDefActualReportingYearCost,
      this.UiDefFundingFromOtherGrantPrograms,
      this.UiDefFundingFromMunicipalSources,
      this.UiDefLgffFundingApplied,
    ];
  }

  initializeProjectToUpdate() {
    if (this.sepoProjectToUpdate) {
      this.projectEditForm?.setValue({
        [this.UiDefProjectStatus.nameCtr]: this.sepoProjectToUpdate?.status,
        [this.UiDefEstimatedTotalCost.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.estimatedTotalLGFFFunding?.toString(),
        ),
        [this.UiDefLgffFundingRemaining.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.totalRemaining?.toString(),
        ),
        [this.UiDefActualReportingYearCost.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.actualReportingYearCost?.toString(),
        ),
        [this.UiDefLgffFundingApplied.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.fundingAppliedFromLGFF?.toString(),
        ),
        [this.UiDefFundingFromOtherGrantPrograms.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.amountFundedOtherGrantPrograms?.toString(),
        ),
        [this.UiDefFundingFromMunicipalSources.nameCtr]: formatNumberWithCommas(
          this.sepoProjectToUpdate?.fundingAppliedFromMunicipalSources?.toString(),
        ),
      });
      this._watchForAmountChange(
        this.projectEditForm?.get('actualReportingYearCost')?.value,
        this.projectEditForm?.get('fundingApplied')?.value,
        this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.value,
      );
      console.log('Project edit form values', this.projectEditForm.value);
    }
  }
  projectStatusControlValue(controlName: string) {
    if (this.projectEditForm?.get(controlName)?.value) {
      if (this.projectEditForm?.get('projectStatus')?.value === ProjectStatusType.NotStarted) {
        this.projectStatusAmountError = "This amount must be cleared when the project status is 'Not started'.";
        return true;
      }
      if (this.projectEditForm?.get('projectStatus')?.value === ProjectStatusType.Withdrawn) {
        this.projectStatusAmountError = "This amount must be cleared when the project status is 'Withdrawn'.";
        return true;
      }
    }
    return false;
  }

  projectStatusControlValueValidationMessage(controlName: string): boolean {
    const projectStatusCheck = this.projectStatusControlValue(controlName);
    return projectStatusCheck;
  }
  get projectStatusValidationCallout(): boolean {
    if (
      this.saveButtonClickValidation == false &&
      (this.projectEditForm?.get('projectStatus')?.value === ProjectStatusType.NotStarted ||
        this.projectEditForm?.get('projectStatus')?.value === ProjectStatusType.Withdrawn) &&
      (this.projectEditForm?.get('actualReportingYearCost')?.value ||
        this.projectEditForm?.get('fundingApplied')?.value ||
        this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.value)
    ) {
      this.projectExpenditureStatusCalloutHeading = this.res
        .transform('projectExpenditureStatusError', this.pageId)
        .replace(
          '%%',
          this.projectEditForm?.get('projectStatus')?.value === ProjectStatusType.NotStarted
            ? 'Not started'
            : 'Withdrawn',
        );

      return true;
    }
    return false;
  }
  get shouldShowAmountErrorOnProjectStatus(): boolean {
    return (
      this.projectEditForm?.get('projectStatus')?.value &&
      this.projectEditForm?.get('projectStatus')?.value != ProjectStatusType.NotStarted &&
      this.projectEditForm?.get('projectStatus')?.value != ProjectStatusType.Withdrawn
    );
  }
  get shouldShowlgffFundingReportingYearCostRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('actualReportingYearCost')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingReportingYearCost')
    );
  }
  get allowZero() {
    if (
      this.sepoProjectToUpdate &&
      !this.sepoProjectToUpdate?.status &&
      this.sepoProjectToUpdate?.amountExpendedFromLGFFToDate > 0
    ) {
      return true;
    }
    return (
      (this.sepoProjectToUpdate?.status == ProjectStatusType.InProgress ||
        this.sepoProjectToUpdate?.status == ProjectStatusType.Completed) &&
      this.sepoProjectToUpdate?.amountExpendedFromLGFFToDate > 0
    );
  }
  get shouldShowlgffFundingReportingYearCostNotRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('actualReportingYearCost')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingReportingYearCostNotRequired')
    );
  }
  get shouldShowlgffFundingFromOtherGrantProgramsRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingFromOtherGrantPrograms')
    );
  }
  get shouldShowlgffFundingFromOtherGrantProgramsNotRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingFromOtherGrantProgramsNotRequired')
    );
  }
  get shouldShowlgffFundingAmountAppliedRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingAmountApplied')
    );
  }
  get shouldShowlgffFundingAmountAppliedNotRequiredMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingAmountAppliedNotRequired')
    );
  }
  get shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.shouldShowAmountErrorOnProjectStatus &&
      this.projectEditForm?.hasError('lgffFundingAmountAppliedGreaterThanReportingYearCost')
    );
  }
  get shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingApplied')?.touched ?? false) &&
      this.shouldShowAmountErrorOnProjectStatus &&
      this._lgffFundingAmountAppliedVsFundingRemainingValidator()
    );
  }

  get shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.shouldShowAmountErrorOnProjectStatus &&
      this.projectEditForm?.hasError('fundingFromOtherGrantProgramsGreaterThanReportingYearCost')
    );
  }

  get shouldShowcombinedFundingVsReportingYearCostValidationMessage(): boolean {
    return (
      this.shouldShowAmountErrorOnProjectStatus &&
      this.projectEditForm?.hasError('combinedFundingGreaterThanReportingYearCost')
    );
  }

  get shouldShowPortionMunicipalSourceNegativeValidationMessage(): boolean {
    const rawValue = this.projectEditForm?.get(this.UiDefFundingFromMunicipalSources.nameCtr)?.value ?? '0';
    return rawValue.startsWith('-');
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return (
      this.saveButtonClickValidation &&
      (baseHasError ||
        this.projectStatusControlValueValidationMessage('actualReportingYearCost') ||
        this.projectStatusControlValueValidationMessage('fundingFromOtherGrantPrograms') ||
        this.projectStatusControlValueValidationMessage('fundingApplied') ||
        this.shouldShowlgffFundingReportingYearCostRequiredMessage ||
        this.shouldShowlgffFundingFromOtherGrantProgramsRequiredMessage ||
        this.shouldShowlgffFundingAmountAppliedRequiredMessage ||
        this.shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
        this.shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
        this.shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
        this.shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage)
    );
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  jumpToCallout(fieldTemplate: ElementRef) {
    if (fieldTemplate) {
      const fieldElement = fieldTemplate.nativeElement;
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        console.error('Cannot find linked field: ' + fieldTemplate.nativeElement);
      }
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedInNonBlankOrErrorField = true;
      UiDef.formCtr.markAsTouched();
    }

    if (isBlurAway) {
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');
      }, 400);
    }
  }

  public hasProjectFormAnyError(submitted: boolean): boolean {
    this.submitClicked = this.submitClicked || submitted;

    if (this.areThereOtherSourcesOfFunding) {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.enable();
    } else {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.disable();
    }

    return !this.projectEditForm.valid;
  }

  private _lgffFundingAmountAppliedVsReportingYearCostValidator(control: AbstractControl): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const lgffFundingAmountApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    if (lgffFundingAmountApplied > actualReportingYearCost) {
      return { lgffFundingAmountAppliedGreaterThanReportingYearCost: true };
    }

    return null;
  }
  private _lgffFundingReportingYearCostValidator(control: AbstractControl): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const showAmountErrorOnProjectStatus =
      control.get('projectStatus')?.value != ProjectStatusType.NotStarted &&
      control.get('projectStatus')?.value != ProjectStatusType.Withdrawn;
    if (
      showAmountErrorOnProjectStatus &&
      (actualReportingYearCost == null || control.get('actualReportingYearCost')?.value == '')
    ) {
      return { lgffFundingReportingYearCost: true };
    }
    if (
      !showAmountErrorOnProjectStatus &&
      actualReportingYearCost != null &&
      control.get('actualReportingYearCost')?.value != ''
    ) {
      return { lgffFundingReportingYearCostNotRequired: true };
    }
    return null;
  }
  private _lgffFundingFromOtherGrantProgramsValidator(control: AbstractControl): ValidationErrors | null {
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    const showAmountErrorOnProjectStatus =
      control.get('projectStatus')?.value != ProjectStatusType.NotStarted &&
      control.get('projectStatus')?.value != ProjectStatusType.Withdrawn;
    if (
      showAmountErrorOnProjectStatus &&
      (fundingFromOtherGrantPrograms == null || control.get('fundingFromOtherGrantPrograms')?.value == '')
    ) {
      return { lgffFundingFromOtherGrantPrograms: true };
    }
    if (
      !showAmountErrorOnProjectStatus &&
      fundingFromOtherGrantPrograms != null &&
      control.get('fundingFromOtherGrantPrograms')?.value != ''
    ) {
      return { lgffFundingFromOtherGrantProgramsNotRequired: true };
    }
    return null;
  }
  private _lgffFundingAmountAppliedValidator(control: AbstractControl): ValidationErrors | null {
    const lgffFundingAmountApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    const showAmountErrorOnProjectStatus =
      control.get('projectStatus')?.value != ProjectStatusType.NotStarted &&
      control.get('projectStatus')?.value != ProjectStatusType.Withdrawn;
    if (
      showAmountErrorOnProjectStatus &&
      (lgffFundingAmountApplied == null || control.get('fundingApplied')?.value == '')
    ) {
      return { lgffFundingAmountApplied: true };
    }
    if (
      !showAmountErrorOnProjectStatus &&
      lgffFundingAmountApplied != null &&
      control.get('fundingApplied')?.value != ''
    ) {
      return { lgffFundingAmountAppliedNotRequired: true };
    }
    return null;
  }
  private _lgffFundingAmountAppliedVsFundingRemainingValidator(): boolean {
    const projectStatus = this.projectEditForm.get('projectStatus')?.value;

    const totalRemaining = this.sepoProjectToUpdate?.totalRemaining ?? 0;
    const lgffFundingAmountApplied =
      typeof this.projectEditForm.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(this.projectEditForm.get('fundingApplied')?.value)
        : this.projectEditForm.get('fundingApplied')?.value;

    if (lgffFundingAmountApplied > totalRemaining && projectStatus != ProjectStatusType.Completed) {
      return true;
    }

    return false;
  }

  private _fundingFromOtherGrantProgramsVsReportingYearCostValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (fundingFromOtherGrantPrograms > actualReportingYearCost) {
      return { fundingFromOtherGrantProgramsGreaterThanReportingYearCost: true };
    }

    return null;
  }

  private _combinedFundingVsReportingYearCostValidator(control: AbstractControl): ValidationErrors | null {
    const actualReportingYearCost =
      typeof control.get('actualReportingYearCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('actualReportingYearCost')?.value)
        : control.get('actualReportingYearCost')?.value;
    const fundingApplied =
      typeof control.get('fundingApplied')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingApplied')?.value)
        : control.get('fundingApplied')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (fundingApplied + fundingFromOtherGrantPrograms > actualReportingYearCost) {
      return { combinedFundingGreaterThanReportingYearCost: true };
    }

    return null;
  }

  private _watchForAmountChange(
    actualReportingYearCost: number | undefined,
    fundingApplied: number | undefined,
    fundingFromOtherGrantPrograms: number | undefined,
  ) {
    const actualReportingYearCostCtrl = this.projectEditForm.get('actualReportingYearCost');
    const fundingAppliedCtrl = this.projectEditForm.get('fundingApplied');
    const fundingFromOtherGrantProgramsCtrl = this.projectEditForm.get('fundingFromOtherGrantPrograms');
    const fundingFromMunicipalSourcesCtrl = this.projectEditForm.get('fundingFromMunicipalSources');
    if (
      !actualReportingYearCostCtrl ||
      !fundingAppliedCtrl ||
      !fundingFromOtherGrantProgramsCtrl ||
      !fundingFromMunicipalSourcesCtrl
    )
      return;
    combineLatest([
      actualReportingYearCostCtrl.valueChanges.pipe(startWith(actualReportingYearCost?.toString())),
      fundingAppliedCtrl.valueChanges.pipe(startWith(fundingApplied?.toString())),
      fundingFromOtherGrantProgramsCtrl.valueChanges.pipe(startWith(fundingFromOtherGrantPrograms?.toString())),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([actualReportingYearCostStr, fundingAppliedStr, fundingFromOtherGrantProgramsStr]) => {
        const actualReportingYearCost =
          typeof actualReportingYearCostStr == 'string'
            ? CommUtilsService.parseInputInt(actualReportingYearCostStr)
            : actualReportingYearCostStr;
        const fundingApplied =
          typeof fundingAppliedStr == 'string' ? CommUtilsService.parseInputInt(fundingAppliedStr) : fundingAppliedStr;
        const fundingFromOtherGrantPrograms =
          typeof fundingFromOtherGrantProgramsStr == 'string'
            ? CommUtilsService.parseInputInt(fundingFromOtherGrantProgramsStr)
            : fundingFromOtherGrantProgramsStr;
        const fundingFromMunicipalSources = this._calculateFundingFromMunicipalSources(
          actualReportingYearCost,
          fundingApplied,
          fundingFromOtherGrantPrograms,
        );
        // fundingFromMunicipalSourcesCtrl.setValue(formatNumberWithCommas(fundingFromMunicipalSources));
        fundingFromMunicipalSourcesCtrl.setValue(fundingFromMunicipalSources);
      });
  }

  private _calculateFundingFromMunicipalSources(
    actualReportingYearCost: number,
    fundingApplied: number,
    fundingFromOtherGrantPrograms: number,
  ): string | null {
    const fundingFromMunicipalSources = formatNumberWithCommas(
      actualReportingYearCost - (fundingApplied + fundingFromOtherGrantPrograms),
    );
    return fundingFromMunicipalSources;
  }
}
