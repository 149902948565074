import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularComponentsModule } from '@abgov/angular-components';
import { PercentageDirective } from '@app-com/directives';
import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import '@abgov/web-components';
import { CdkTableModule } from '@angular/cdk/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { GoogleMapsModule } from '@angular/google-maps';
import { HeaderComponent } from './features/header/header.component';
import { HomeComponent } from './features/home/home.component';
import { LandingComponent } from './features/landing/landing.component';
import { LogoutComponent } from './features/logout/logout.component';
import { AllocationsAndPaymentsComponent } from './features/allocations-and-payments/allocations-and-payments.component';
import { GrantApplicationComponent } from './features/grant-application/grant-application.component';
import { GrantApplicationBannerComponent } from './features/grant-application/grant-application-banner/grant-application-banner.component';
import { GrantApplicationGeneralInfoComponent } from './features/grant-application/grant-application-general-info/grant-application-general-info.component';
import { InputFormatDirective } from './shared/directives/input-format.directive';
import { AccessForbiddenComponent } from './features/error-pages/access-forbidden/access-forbidden.component';
import { NotFoundComponent } from './features/error-pages/notfound/notfound.component';
import { FunctionalCategoriesComponent } from './features/grant-application/functional-categories/functional-categories.component';
import { ProjectsComponent } from './features/grant-application/projects/projects.component';
import { ColumnTotalPipe, SafeDollarPipe, PositiveDecimalPipe } from '@app-com/pipes';
import { CommaFormatNumberDirective, PositiveDecimalDirective, FeatureFlagDirective } from '@app-com/directives';
import { ProjectDeleteComponent } from './features/grant-application/projects/project-delete/project-delete.component';
import { ProjectFormComponent } from './features/grant-application/projects/project-form/project-form.component';
import { ProjectEditComponent } from './features/grant-application/projects/project-edit/project-edit.component';
import { CertificationComponent } from './features/grant-application/certification/certification.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ApplicationGrantState } from './store/state/application-draft.state';
import { ViewApplicationsComponent } from './features/view-applications/view-applications.component';
import { ApplicationsState } from './store/state/applications.state';
import { DraftApplicationsComponent } from './features/view-applications/draft-applications/draft-applications.component';
import { LookupValueState } from '../../../common/state/lookup-value.state';
import { ConfirmCancelDeleteDraftAppComponent } from './features/grant-application/confirm-cancel-delete-draft-app/confirm-cancel-delete-draft-app.component';
import { SuccessMessageComponent } from './shared/components/success-message/success-message.component';
import { SubmittedApplicationsComponent } from './features/view-applications/submitted-applications/submitted-applications.component';
import { IncompleteModalComponent } from './features/grant-application/incomplete-modal/incomplete-modal.component';
import { FooterComponent } from './features/footer/footer.component';
import { AuthInterceptor } from '@app-com/interceptors/auth.interceptor';
import { GooglemapComponent } from './features/maps/googlemap/googlemap.component';
import { NgxsStoragePluginModule, SESSION_STORAGE_ENGINE, StorageOption } from '@ngxs/storage-plugin';
import { CurrentContextState } from './store/state/current-context.state';
import { SvgAlbertaLogoComponent } from './features/svg-alberta-logo/svg-alberta-logo.component';
import { DialogComponent } from './features/maps/dialog/dialog.component';
import { ApplicationSummaryComponent } from './features/view-applications/application-summary/application-summary.component';
import { ResourcesComponent } from './features/resources/resources.component';
import { ErrorPageLayoutComponent } from './layouts/error-page-layout/error-page-layout.component';
import { PopupLayoutComponent } from './layouts/popup-layout/popup-layout.component';
import { GeneralErrorComponent } from './features/error-pages/general-error/general-error.component';
import { HttpErrorInterceptor } from '@app-com/interceptors/http-error.interceptor';
import { DownloadPdfLinkComponent } from './shared/components/download-pdf-link/download-pdf-link.component';
import { DownloadSubmittedApplicationsListPdfComponent } from './features/view-applications/submitted-applications/download-submitted-applications-list-pdf/download-submitted-applications-list-pdf.component';
import { DownloadSubmittedApplicationPdfComponent } from './features/view-applications/submitted-applications/download-submitted-application-pdf/download-submitted-application-pdf.component';
import { GrantAppGuideComponent } from './features/grant-application/grant-app-guide/grant-app-guide.component';
import { DownloadDraftApplicationsListPdfComponent } from './features/view-applications/draft-applications/download-draft-applications-list-pdf/download-draft-applications-list-pdf.component';
import { DownloadDraftApplicationPdfComponent } from './features/view-applications/draft-applications/download-draft-application-pdf/download-draft-application-pdf.component';
import { SvgAlbertaLogoHeaderComponent } from './features/svg-alberta-logo-header/svg-alberta-logo-header.component';
import { ReturnedApplicationsComponent } from './features/view-applications/returned-applications/returned-applications.component';
import { DownloadReturnedApplicationPdfComponent } from './features/view-applications/returned-applications/download-returned-application-pdf/download-returned-application-pdf.component';
import { AcceptedApplicationsComponent } from './features/view-applications/accepted-applications/accepted-applications.component';
import { DownloadAcceptedApplicationPdfComponent } from './features/view-applications/accepted-applications/download-accepted-application-pdf/download-accepted-application-pdf.component';
import { DownloadAcceptedApplicationsListPdfComponent } from './features/view-applications/accepted-applications/download-accepted-applications-list-pdf/download-accepted-applications-list-pdf.component';
import { WithdrawnApplicationsComponent } from './features/view-applications/withdrawn-applications/withdrawn-applications.component';
import { CashFlowUpdateApplicationsComponent } from './features/view-applications/cash-flow-update-applications/cash-flow-update-applications.component';

import { PreventModalFlickerDirective } from '@app-com/directives/prevent-modal-flicker-directive';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { ProjectCommonModule } from '@app-com/project.common.module';
import { CookieDisabledComponent } from './features/error-pages/cookie-disabled/cookie-disabled.component';
import { CashFlowGuideComponent } from './features/cash-flow-updates/cash-flow-guide/cash-flow-guide.component';
import { CashFlowUpdatesComponent } from './features/cash-flow-updates/cash-flow-updates.component';
import { CashFlowContactsComponent } from './features/cash-flow-updates/cash-flow-contacts/cash-flow-contacts.component';
import { CashFlowUpdateState } from './store/state/cash-flow-update.state';
import { CashFlowSubmitComponent } from './features/cash-flow-updates/cash-flow-submit/cash-flow-submit.component';
import { ApplicationFundingComponent } from './features/cash-flow-updates/application-funding/application-funding.component';
import { ProjectUpdatesComponent } from './features/cash-flow-updates/project-updates/project-updates.component';
import { NoGrantFundingDialogComponent } from './features/cash-flow-updates/application-funding/no-grant-funding-dialog/no-grant-funding-dialog.component';
import { CashFlowUpdateReviewComponent } from './features/cash-flow-updates/cash-flow-update-review/cash-flow-update-review.component';
import { CashFlowProjectUpdatesEditFormComponent } from './features/cash-flow-updates/cash-flow-project-updates-edit-form/cash-flow-project-updates-edit-form.component';
import { CashFlowCancelDialogComponent } from './features/cash-flow-updates/cash-flow-cancel-dialog/cash-flow-cancel-dialog.component';
import { EditSepoComponent } from './features/sepo/edit-sepo/edit-sepo.component';
import { EditSepoContactInfoComponent } from './features/sepo/edit-sepo/edit-sepo-contact-info/edit-sepo-contact-info.component';
import { EditSepoAssetFundComponent } from './features/sepo/edit-sepo/edit-sepo-asset-fund/edit-sepo-asset-fund.component';
import { EditSepoStatementExpendituresComponent } from './features/sepo/edit-sepo/edit-sepo-statement-expenditures/edit-sepo-statement-expenditures.component';
import { EditSepoImmediateOutcomesComponent } from './features/sepo/edit-sepo/edit-sepo-immediate-outcomes/edit-sepo-immediate-outcomes.component';
import { EditSepoCertifySubmitComponent } from './features/sepo/edit-sepo/edit-sepo-certify-submit/edit-sepo-certify-submit.component';
import { EditSepoState } from './store/state/edit-sepo.state';
import { ViewSepoComponent } from './features/sepo/view-sepo/view-sepo.component';
import { NgIdleModule } from '@ng-idle/core';
import { DeleteDraftSepoDataDialogComponent } from './features/sepo/edit-sepo/delete-draft-sepo-data-dialog/delete-draft-sepo-data-dialog.component';
import { EditOutcomesReportingComponent } from './features/sepo/edit-sepo/edit-sepo-immediate-outcomes/edit-outcomes-reporting/edit-outcomes-reporting.component';
import { DownloadSepoPdfComponent } from './features/sepo/view-sepo/download-sepo-pdf/download-sepo-pdf.component';

export const MAT_LIBS = [
  CdkTableModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
];

const routes: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    NgxsModule.forRoot(
      [
        ApplicationGrantState,
        ApplicationsState,
        CurrentContextState,
        LookupValueState,
        CashFlowUpdateState,
        EditSepoState,
      ],
      {
        developmentMode: true,
      },
    ),
    GoogleMapsModule,
    NgIdleModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: [
        {
          key: CurrentContextState,
          engine: SESSION_STORAGE_ENGINE,
        },
      ],
    }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularComponentsModule,
    NgbModule,
    ProjectCommonModule,
    ...MAT_LIBS,
    ColumnTotalPipe,
    SafeDollarPipe,
    PositiveDecimalPipe,
    CommaFormatNumberDirective,
    PositiveDecimalDirective,
    SvgAlbertaLogoComponent,
    PreventModalFlickerDirective,
    FeatureFlagDirective,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookieDisabledComponent,
    HomeComponent,
    LandingComponent,
    LogoutComponent,
    AllocationsAndPaymentsComponent,
    AccessForbiddenComponent,
    NotFoundComponent,
    GrantApplicationComponent,
    GrantApplicationBannerComponent,
    GrantApplicationGeneralInfoComponent,
    FunctionalCategoriesComponent,
    InputFormatDirective,
    PercentageDirective,
    ProjectsComponent,
    ProjectDeleteComponent,
    ProjectFormComponent,
    ProjectEditComponent,
    CertificationComponent,
    ViewApplicationsComponent,
    DraftApplicationsComponent,
    ConfirmCancelDeleteDraftAppComponent,
    SuccessMessageComponent,
    SubmittedApplicationsComponent,
    AcceptedApplicationsComponent,
    WithdrawnApplicationsComponent,
    CashFlowUpdateApplicationsComponent,
    IncompleteModalComponent,
    GooglemapComponent,
    DialogComponent,
    ApplicationSummaryComponent,
    ResourcesComponent,
    ErrorPageLayoutComponent,
    PopupLayoutComponent,
    GeneralErrorComponent,
    DownloadPdfLinkComponent,
    DownloadSubmittedApplicationsListPdfComponent,
    DownloadSubmittedApplicationPdfComponent,
    DownloadAcceptedApplicationPdfComponent,
    DownloadAcceptedApplicationsListPdfComponent,
    GrantAppGuideComponent,
    DownloadDraftApplicationsListPdfComponent,
    DownloadDraftApplicationPdfComponent,
    SvgAlbertaLogoHeaderComponent,
    ReturnedApplicationsComponent,
    DownloadReturnedApplicationPdfComponent,
    CashFlowUpdatesComponent,
    CashFlowGuideComponent,
    CashFlowContactsComponent,
    CashFlowSubmitComponent,
    ApplicationFundingComponent,
    CashFlowCancelDialogComponent,
    ProjectUpdatesComponent,
    NoGrantFundingDialogComponent,
    CashFlowUpdateReviewComponent,
    CashFlowProjectUpdatesEditFormComponent,
    EditSepoComponent,
    EditSepoContactInfoComponent,
    EditSepoAssetFundComponent,
    EditSepoStatementExpendituresComponent,
    EditSepoImmediateOutcomesComponent,
    EditSepoCertifySubmitComponent,
    ViewSepoComponent,
    DeleteDraftSepoDataDialogComponent,
    EditOutcomesReportingComponent,
    DownloadSepoPdfComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ResourcePipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],

  exports: [AppRoutingModule, ...MAT_LIBS],
})
export class AppModule {}
