<div>
  <div id="immediate-outcomes-heading1">{{ 'immediateOutcomesHeading1' | res: pageId }}:</div>
  <div id="immediate-outcomes-heading2">{{ 'immediateOutcomesHeading2' | res: pageId }}:</div>
  <app-lgff-goa-nested-rows-table
    [headers]="headers"
    [showAdditionalHeader]="true"
    [additionalHeaders]="additionalHeaders"
    [tableData]="applications"
    childKey="projects"
    tableWidth="136.1875rem"
    [parentColumnIndent]="parentColumnIndent"
    [cellTemplates]="{
      edit: editTemplate
    }"
  ></app-lgff-goa-nested-rows-table>

  <ng-template #editTemplate let-name>
    <goa-icon size="small" class="action-icon" type="pencil"></goa-icon>
  </ng-template>
  <div></div>
</div>
