/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CustomMunicipalityDto } from '../models/custom-municipality-dto';
import { MunicipalAppSummaryDto } from '../models/municipal-app-summary-dto';
import { MunicipalInternalAuditDto } from '../models/municipal-internal-audit-dto';
import { MunicipalPaymentSummaryDto } from '../models/municipal-payment-summary-dto';
import { MunicipalSepoSummaryDto } from '../models/municipal-sepo-summary-dto';
import { MunicipalTabCountDto } from '../models/municipal-tab-count-dto';
import { WorknoteDto } from '../models/worknote-dto';

@Injectable({ providedIn: 'root' })
export class MunicipalityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAll_11()` */
  static readonly FindAll_11Path = '/api/municipality/total';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomMunicipalityDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.FindAll_11Path, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomMunicipalityDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<CustomMunicipalityDto> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomMunicipalityDto>): CustomMunicipalityDto => r.body)
    );
  }

  /** Path part for operation `getMuncipalTabCount()` */
  static readonly GetMuncipalTabCountPath = '/api/municipality/details/tab-counts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMuncipalTabCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMuncipalTabCount$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MunicipalTabCountDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMuncipalTabCountPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MunicipalTabCountDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMuncipalTabCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMuncipalTabCount(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<MunicipalTabCountDto> {
    return this.getMuncipalTabCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<MunicipalTabCountDto>): MunicipalTabCountDto => r.body)
    );
  }

  /** Path part for operation `getMunicipalAppSummary()` */
  static readonly GetMunicipalAppSummaryPath = '/api/municipality/details/app-summary/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMunicipalAppSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalAppSummary$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MunicipalAppSummaryDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMunicipalAppSummaryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MunicipalAppSummaryDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMunicipalAppSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalAppSummary(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<MunicipalAppSummaryDto> {
    return this.getMunicipalAppSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<MunicipalAppSummaryDto>): MunicipalAppSummaryDto => r.body)
    );
  }

  /** Path part for operation `getMunicipalPaymentSummary()` */
  static readonly GetMunicipalPaymentSummaryPath = '/api/municipality/details/payments-summary/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMunicipalPaymentSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalPaymentSummary$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MunicipalPaymentSummaryDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMunicipalPaymentSummaryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MunicipalPaymentSummaryDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMunicipalPaymentSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalPaymentSummary(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<MunicipalPaymentSummaryDto> {
    return this.getMunicipalPaymentSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<MunicipalPaymentSummaryDto>): MunicipalPaymentSummaryDto => r.body)
    );
  }

  /** Path part for operation `getMunicipalSepoSummary()` */
  static readonly GetMunicipalSepoSummaryPath = '/api/municipality/details/sepo-summary/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMunicipalSepoSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalSepoSummary$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MunicipalSepoSummaryDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMunicipalSepoSummaryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MunicipalSepoSummaryDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMunicipalSepoSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalSepoSummary(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<MunicipalSepoSummaryDto> {
    return this.getMunicipalSepoSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<MunicipalSepoSummaryDto>): MunicipalSepoSummaryDto => r.body)
    );
  }

  /** Path part for operation `getMunicipalWorkNotes()` */
  static readonly GetMunicipalWorkNotesPath = '/api/municipality/details/work-notes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMunicipalWorkNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalWorkNotes$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMunicipalWorkNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMunicipalWorkNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalWorkNotes(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.getMunicipalWorkNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `getMunicipalAudits()` */
  static readonly GetMunicipalAuditsPath = '/api/municipality/details/audit/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMunicipalAudits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalAudits$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MunicipalInternalAuditDto>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetMunicipalAuditsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MunicipalInternalAuditDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMunicipalAudits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMunicipalAudits(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<MunicipalInternalAuditDto> {
    return this.getMunicipalAudits$Response(params, context).pipe(
      map((r: StrictHttpResponse<MunicipalInternalAuditDto>): MunicipalInternalAuditDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableMunicipalTotalDetailsActions()` */
  static readonly GetUserAvailableMunicipalTotalDetailsActionsPath = '/api/municipality/available-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableMunicipalTotalDetailsActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableMunicipalTotalDetailsActions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, MunicipalityService.GetUserAvailableMunicipalTotalDetailsActionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableMunicipalTotalDetailsActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableMunicipalTotalDetailsActions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableMunicipalTotalDetailsActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

}
