/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplicationBatchStageTypeDto } from '../models/application-batch-stage-type-dto';
import { ApplicationRecordLookupSetDto } from '../models/application-record-lookup-set-dto';
import { ApplicationStageTypeDto } from '../models/application-stage-type-dto';
import { ApplicationStatusTypeDto } from '../models/application-status-type-dto';
import { AssetConditionRankingDto } from '../models/asset-condition-ranking-dto';
import { CapitalAssetTypeDto } from '../models/capital-asset-type-dto';
import { CashFlowUpdateStageTypeDto } from '../models/cash-flow-update-stage-type-dto';
import { CashFlowUpdateStatusTypeDto } from '../models/cash-flow-update-status-type-dto';
import { FunctionalCategoryTypeDto } from '../models/functional-category-type-dto';
import { OrganizationFilterDto } from '../models/organization-filter-dto';
import { PaymentStatusTypeDto } from '../models/payment-status-type-dto';
import { PrimaryOutcomeDto } from '../models/primary-outcome-dto';
import { ProjectStatusTypeDto } from '../models/project-status-type-dto';
import { SepoAssetManagentPlanBarrierDto } from '../models/sepo-asset-managent-plan-barrier-dto';
import { SepoBatchStageTypeDto } from '../models/sepo-batch-stage-type-dto';
import { SepoStageTypeDto } from '../models/sepo-stage-type-dto';
import { SepoStatusTypeDto } from '../models/sepo-status-type-dto';

@Injectable({ providedIn: 'root' })
export class LookupValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAllApplicationRecordLookupSet()` */
  static readonly FindAllApplicationRecordLookupSetPath = '/api/lookup-values/application-record-lookup-set';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllApplicationRecordLookupSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllApplicationRecordLookupSet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationRecordLookupSetDto>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllApplicationRecordLookupSetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRecordLookupSetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllApplicationRecordLookupSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllApplicationRecordLookupSet(
    params?: {
    },
    context?: HttpContext
  ): Observable<ApplicationRecordLookupSetDto> {
    return this.findAllApplicationRecordLookupSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationRecordLookupSetDto>): ApplicationRecordLookupSetDto => r.body)
    );
  }

  /** Path part for operation `findAllAssetConditionRankings()` */
  static readonly FindAllAssetConditionRankingsPath = '/api/lookup-values/asset-condition-rankings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAssetConditionRankings()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAssetConditionRankings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AssetConditionRankingDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllAssetConditionRankingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssetConditionRankingDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllAssetConditionRankings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAssetConditionRankings(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AssetConditionRankingDto>> {
    return this.findAllAssetConditionRankings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AssetConditionRankingDto>>): Array<AssetConditionRankingDto> => r.body)
    );
  }

  /** Path part for operation `findAllCapitalAssetTypes()` */
  static readonly FindAllCapitalAssetTypesPath = '/api/lookup-values/capital-asset-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCapitalAssetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCapitalAssetTypes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CapitalAssetTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllCapitalAssetTypesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CapitalAssetTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCapitalAssetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCapitalAssetTypes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CapitalAssetTypeDto>> {
    return this.findAllCapitalAssetTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CapitalAssetTypeDto>>): Array<CapitalAssetTypeDto> => r.body)
    );
  }

  /** Path part for operation `findAllFunctionalCategoryTypes()` */
  static readonly FindAllFunctionalCategoryTypesPath = '/api/lookup-values/functional-category-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllFunctionalCategoryTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllFunctionalCategoryTypes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FunctionalCategoryTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllFunctionalCategoryTypesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FunctionalCategoryTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllFunctionalCategoryTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllFunctionalCategoryTypes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FunctionalCategoryTypeDto>> {
    return this.findAllFunctionalCategoryTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalCategoryTypeDto>>): Array<FunctionalCategoryTypeDto> => r.body)
    );
  }

  /** Path part for operation `findAllPrimaryOutcomes()` */
  static readonly FindAllPrimaryOutcomesPath = '/api/lookup-values/primary-outcomes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllPrimaryOutcomes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPrimaryOutcomes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrimaryOutcomeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllPrimaryOutcomesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrimaryOutcomeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllPrimaryOutcomes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllPrimaryOutcomes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<PrimaryOutcomeDto>> {
    return this.findAllPrimaryOutcomes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrimaryOutcomeDto>>): Array<PrimaryOutcomeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationStatuses()` */
  static readonly GetApplicationStatusesPath = '/api/lookup-values/application-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationStatusTypeDto>> {
    return this.getApplicationStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationStatusTypeDto>>): Array<ApplicationStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationStages()` */
  static readonly GetApplicationStagesPath = '/api/lookup-values/application-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationStageTypeDto>> {
    return this.getApplicationStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationStageTypeDto>>): Array<ApplicationStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `getApplicationBatchStages()` */
  static readonly GetApplicationBatchStagesPath = '/api/lookup-values/application-batch-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationBatchStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationBatchStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetApplicationBatchStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationBatchStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationBatchStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ApplicationBatchStageTypeDto>> {
    return this.getApplicationBatchStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationBatchStageTypeDto>>): Array<ApplicationBatchStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `getCashFlowUpdateStatuses()` */
  static readonly GetCashFlowUpdateStatusesPath = '/api/lookup-values/cash-flow-update-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCashFlowUpdateStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashFlowUpdateStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CashFlowUpdateStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetCashFlowUpdateStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CashFlowUpdateStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCashFlowUpdateStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashFlowUpdateStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CashFlowUpdateStatusTypeDto>> {
    return this.getCashFlowUpdateStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CashFlowUpdateStatusTypeDto>>): Array<CashFlowUpdateStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `getCashFlowUpdateStages()` */
  static readonly GetCashFlowUpdateStagesPath = '/api/lookup-values/cash-flow-update-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCashFlowUpdateStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashFlowUpdateStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CashFlowUpdateStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetCashFlowUpdateStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CashFlowUpdateStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCashFlowUpdateStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCashFlowUpdateStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CashFlowUpdateStageTypeDto>> {
    return this.getCashFlowUpdateStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CashFlowUpdateStageTypeDto>>): Array<CashFlowUpdateStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `getProjectStatuses()` */
  static readonly GetProjectStatusesPath = '/api/lookup-values/project-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProjectStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetProjectStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ProjectStatusTypeDto>> {
    return this.getProjectStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProjectStatusTypeDto>>): Array<ProjectStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `getPaymentStatuses()` */
  static readonly GetPaymentStatusesPath = '/api/lookup-values/payment-filter-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetPaymentStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<PaymentStatusTypeDto>> {
    return this.getPaymentStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentStatusTypeDto>>): Array<PaymentStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `findFilterOrganizationsWithPayment()` */
  static readonly FindFilterOrganizationsWithPaymentPath = '/api/lookup-values/payment-filter-organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterOrganizationsWithPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithPayment$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationFilterDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterOrganizationsWithPaymentPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationFilterDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterOrganizationsWithPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithPayment(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<OrganizationFilterDto>> {
    return this.findFilterOrganizationsWithPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationFilterDto>>): Array<OrganizationFilterDto> => r.body)
    );
  }

  /** Path part for operation `findFilterProgramYearsWithPayment()` */
  static readonly FindFilterProgramYearsWithPaymentPath = '/api/lookup-values/payment-filter-program-years';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterProgramYearsWithPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithPayment$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterProgramYearsWithPaymentPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterProgramYearsWithPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithPayment(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.findFilterProgramYearsWithPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `findFilterOrganizationsWithSepo()` */
  static readonly FindFilterOrganizationsWithSepoPath = '/api/lookup-values/sepo-filter-organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterOrganizationsWithSepo()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithSepo$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationFilterDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterOrganizationsWithSepoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationFilterDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterOrganizationsWithSepo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithSepo(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<OrganizationFilterDto>> {
    return this.findFilterOrganizationsWithSepo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationFilterDto>>): Array<OrganizationFilterDto> => r.body)
    );
  }

  /** Path part for operation `findFilterProgramYearsWithSepo()` */
  static readonly FindFilterProgramYearsWithSepoPath = '/api/lookup-values/sepo-filter-program-years';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterProgramYearsWithSepo()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithSepo$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterProgramYearsWithSepoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterProgramYearsWithSepo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithSepo(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.findFilterProgramYearsWithSepo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `findFilterOrganizationsWithApplication()` */
  static readonly FindFilterOrganizationsWithApplicationPath = '/api/lookup-values/application-filter-organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterOrganizationsWithApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithApplication$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationFilterDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterOrganizationsWithApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationFilterDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterOrganizationsWithApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterOrganizationsWithApplication(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<OrganizationFilterDto>> {
    return this.findFilterOrganizationsWithApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationFilterDto>>): Array<OrganizationFilterDto> => r.body)
    );
  }

  /** Path part for operation `findFilterProgramYearsWithApplication()` */
  static readonly FindFilterProgramYearsWithApplicationPath = '/api/lookup-values/application-filter-program-years';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findFilterProgramYearsWithApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithApplication$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindFilterProgramYearsWithApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findFilterProgramYearsWithApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findFilterProgramYearsWithApplication(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.findFilterProgramYearsWithApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `findOrganizationsWithCashflowUpdate()` */
  static readonly FindOrganizationsWithCashflowUpdatePath = '/api/lookup-values/cfu-filter-organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOrganizationsWithCashflowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOrganizationsWithCashflowUpdate$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationFilterDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindOrganizationsWithCashflowUpdatePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationFilterDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOrganizationsWithCashflowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOrganizationsWithCashflowUpdate(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<OrganizationFilterDto>> {
    return this.findOrganizationsWithCashflowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationFilterDto>>): Array<OrganizationFilterDto> => r.body)
    );
  }

  /** Path part for operation `findProgramYearsWithCashFlowUpdate()` */
  static readonly FindProgramYearsWithCashFlowUpdatePath = '/api/lookup-values/cfu-filter-program-years';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findProgramYearsWithCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProgramYearsWithCashFlowUpdate$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindProgramYearsWithCashFlowUpdatePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findProgramYearsWithCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProgramYearsWithCashFlowUpdate(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.findProgramYearsWithCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getSepoStatuses()` */
  static readonly GetSepoStatusesPath = '/api/lookup-values/sepo-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSepoStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoStatuses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoStatusTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetSepoStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoStatusTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSepoStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoStatuses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SepoStatusTypeDto>> {
    return this.getSepoStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoStatusTypeDto>>): Array<SepoStatusTypeDto> => r.body)
    );
  }

  /** Path part for operation `getSepoStages()` */
  static readonly GetSepoStagesPath = '/api/lookup-values/sepo-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSepoStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetSepoStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSepoStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SepoStageTypeDto>> {
    return this.getSepoStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoStageTypeDto>>): Array<SepoStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `getSepoBatchStages()` */
  static readonly GetSepoBatchStagesPath = '/api/lookup-values/sepo-batch-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSepoBatchStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoBatchStages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoBatchStageTypeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.GetSepoBatchStagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoBatchStageTypeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSepoBatchStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSepoBatchStages(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SepoBatchStageTypeDto>> {
    return this.getSepoBatchStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoBatchStageTypeDto>>): Array<SepoBatchStageTypeDto> => r.body)
    );
  }

  /** Path part for operation `findAllSepoAssetManagentPlanBarriers()` */
  static readonly FindAllSepoAssetManagentPlanBarriersPath = '/api/lookup-values/sepo-asset-managent-plan-barriers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllSepoAssetManagentPlanBarriers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllSepoAssetManagentPlanBarriers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoAssetManagentPlanBarrierDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupValueService.FindAllSepoAssetManagentPlanBarriersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoAssetManagentPlanBarrierDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllSepoAssetManagentPlanBarriers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllSepoAssetManagentPlanBarriers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SepoAssetManagentPlanBarrierDto>> {
    return this.findAllSepoAssetManagentPlanBarriers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoAssetManagentPlanBarrierDto>>): Array<SepoAssetManagentPlanBarrierDto> => r.body)
    );
  }

}
