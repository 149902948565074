<div>
  <goa-callout
    type="emergency"
    heading="{{ 'submitExpenditureCashflowHeading' | res: pageId }}"
    *ngIf="hasPendingCashflows"
  >
    {{ expendituresWithCashflowLabel }} <br />
    {{ 'expenditureCashflowsDescription' | res: pageId }}</goa-callout
  >
</div>
<div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
  <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
    <a
      *ngIf="formValidationErrors['additionalProjectExpenditureSelected']"
      (click)="jumpToField('sepoInformationId')"
      class="validation-message-link"
      >{{ 'additionalProjectExpenditureError' | res: pageId : [programYear] }}<br />
    </a>
    <a
      *ngIf="formValidationErrors['additionalProjectExpenditureDetails']"
      (click)="jumpToField('sepoInformationId')"
      class="validation-message-link"
      >{{ 'certificationAdditionalProjectLabel' | res: pageId }}<br />
    </a>
    <a
      *ngIf="formValidationErrors['unreportedProjectExpenditureDetails']"
      (click)="jumpToField('sepoInformationId')"
      class="validation-message-link"
      >{{ 'unreportedProjectExpenditureTextAreaError' | res: pageId }}<br
    /></a>
    <a
      *ngIf="
        formValidationErrors['sepoCertifiedSelected'] ||
        formValidationErrors['caoCertifiedNotChecked'] ||
        formValidationErrors['representativeCertifiedNotChecked']
      "
      (click)="jumpToField('certifyExpendituresId')"
      class="validation-message-link"
      >{{ 'certificationLabel' | res: pageId }}<br
    /></a>
  </goa-callout>
</div>

<form [formGroup]="certifySubmitForm">
  <div class="form-container">
    <h3 id="gen-info-heading-1">{{ 'certificationLabel3' | res: pageId }}</h3>
    <goa-form-item
      id="sepoInformationId"
      class="sepo-information"
      [error]="formValidationErrors && formValidationErrors['additionalProjectExpenditureSelected']"
    >
      <goa-form-item class="sepo-options-container" label="{{ 'certificationLabel4' | res: pageId : [programYear] }}">
        <goa-radio-group
          name="additionalProjectExpenditureSelected"
          formControlName="additionalProjectExpenditureSelected"
          goaValue
          [error]=""
          class="sepo-options"
          (focusout)="onAdditionalProjectExpenditureFocusOut('additionalProjectExpenditureSelected')"
          (click)="haveAdditionalProjectsSelected(true)"
        >
          <goa-radio-item
            id="additionalProjectExpenditureSelected"
            name="additionalProjectExpenditureSelected"
            class=""
            [error]="
              certifySubmitForm &&
              certifySubmitForm.controls.additionalProjectExpenditureSelected.hasError(
                'additionalProjectExpenditureSelected'
              )
            "
            label="Yes"
            [value]="true"
          >
          </goa-radio-item>
        </goa-radio-group>
      </goa-form-item>
      <goa-form-item
        class="sepo-options-container"
        [error]="formValidationErrors && formValidationErrors['additionalProjectExpenditureDetails']"
        requirement="optional"
        label="{{ 'certificationAdditionalProjectLabel' | res: pageId }}"
        *ngIf="hasadditionalProjectExpenditureBeenSelected"
      >
        <goa-textarea
          class="txtdescription"
          width="42rem"
          name="additionalProjectExpenditureDetails"
          id="additionalProjectExpenditureDetailsId"
          formControlName="additionalProjectExpenditureDetails"
          goaValue
          [error]="formValidationErrors && formValidationErrors['additionalProjectExpenditureDetails']"
          [maxcount]="150"
          countby="character"
          showcount="false"
          (focusout)="validateAdditionalProjectExpenditureDetails()"
        >
        </goa-textarea>
      </goa-form-item>
      <goa-form-item class="sepo-options-container">
        <goa-radio-group
          name="additionalProjectExpenditureNotSelected"
          formControlName="additionalProjectExpenditureNotSelected"
          goaValue
          [error]=""
          class="sepo-options"
          (focusout)="onAdditionalProjectExpenditureFocusOut('additionalProjectExpenditureNotSelected')"
          (click)="haveAdditionalProjectsSelected(false)"
        >
          <goa-radio-item
            id="additionalProjectExpenditureNotSelected"
            name="additionalProjectExpenditureNotSelected"
            class=""
            [error]="
              certifySubmitForm &&
              certifySubmitForm.controls.additionalProjectExpenditureNotSelected.hasError(
                'additionalProjectExpenditureSelected'
              )
            "
            label="No"
            [value]="true"
          >
          </goa-radio-item>
        </goa-radio-group>
      </goa-form-item>
      <goa-form-item
        class="sepo-options-container"
        requirement="required"
        label="{{ 'certificationUnreportedProjectLabel' | res: pageId }}"
        *ngIf="hasadditionalProjectExpenditureNotBeenSelected"
        [error]="formValidationErrors && formValidationErrors['unreportedProjectExpenditureDetails']"
      >
        <goa-textarea
          class="txtdescription"
          width="42rem"
          name="unreportedProjectExpenditureDetails"
          id="unreportedProjectExpenditureDetails"
          [error]="formValidationErrors && formValidationErrors['unreportedProjectExpenditureDetails']"
          formControlName="unreportedProjectExpenditureDetails"
          goaValue
          [maxcount]="150"
          countby="character"
          showcount="false"
          (focusout)="validateUnreportedProjectExpenditureDetails()"
        >
        </goa-textarea>
      </goa-form-item>
    </goa-form-item>

    <goa-divider mt="s" mb="s"></goa-divider>
    <h3 id="gen-info-heading-1">{{ 'certificationLabel2' | res: pageId }}</h3>
    <goa-form-item
      id="certifyExpendituresId"
      class="certify-expenditures"
      [error]="formValidationErrors && formValidationErrors['sepoCertifiedSelected']"
    >
      <goa-form-item
        id="func-cat-radio-other-source-of-funding"
        class="sepo-options-container"
        label="{{ 'certificationLabel' | res: pageId }}"
      >
        <goa-radio-group
          name="sepoCertified"
          id="areThereOtherSourcesOfFunding"
          formControlName="sepoCertified"
          goaValue
          [error]=""
          (focusout)="onFocusOut('sepoCertified')"
          class="sepo-options"
          (click)="hasSEPOCertified(true)"
        >
          <goa-radio-item
            id="sepoCertified"
            name="sepoCertified"
            class="sepoCertifiedCAOSelected"
            [error]="certifySubmitForm && certifySubmitForm.controls.sepoCertified.hasError('sepoCertifiedSelected')"
            label="Yes"
            [value]="true"
          >
            <span slot="description"
              >As the <b>Chief Administrative Officer and/or Administrator</b> of {{ organizationTitle }}.</span
            >
          </goa-radio-item>
        </goa-radio-group>
      </goa-form-item>
      <goa-form-item
        id="question-yes-checkbox"
        class="nestedStepOne"
        name="questionYesItem"
        *ngIf="isSEPOCertified"
        [error]="formValidationErrors && formValidationErrors['caoCertifiedNotChecked']"
      >
        <div style="height: 4px"></div>
        <goa-checkbox
          name="caoCertified"
          formControlName="caoCertified"
          [innerHTML]="certifyOptionCAOLabel"
          (focusout)="validatecaoCertifiedonFocusOut()"
          goaValue
          [error]="certifySubmitForm && certifySubmitForm.controls.caoCertified.hasError('caoCertifiedNotChecked')"
        ></goa-checkbox>
      </goa-form-item>
      <goa-form-item class="sepo-options-container">
        <goa-radio-group
          name="questionNo"
          id="questionNo"
          formControlName="sepoNotCertified"
          goaValue
          [error]=""
          (focusout)="onFocusOut('sepoNotCertified')"
          (click)="hasSEPOCertified(false)"
        >
          <goa-radio-item
            id="sepoNotCertified"
            name="sepoNotCertified"
            label="No"
            class="sepoCertifiedRepresentativeSelected"
            [value]="true"
            [error]="certifySubmitForm && certifySubmitForm.controls.sepoNotCertified.hasError('sepoCertifiedSelected')"
            ><span slot="description">As the <b>representative</b> of {{ organizationTitle }}.</span></goa-radio-item
          >
        </goa-radio-group>
      </goa-form-item>
      <goa-form-item
        id="sepo-certified-representative"
        class="nestedStepOne"
        name="questionYesItem"
        [error]="formValidationErrors && formValidationErrors['representativeCertifiedNotChecked']"
        *ngIf="isSEPORepresentativeCertified"
      >
        <div style="height: 4px"></div>
        <goa-checkbox
          name="representativeCertified"
          formControlName="representativeCertified"
          [innerHTML]="certifyOptionRepresentativeLabel"
          (focusout)="validateRepresentativeCertifiedonFocusOut()"
          goaValue
          [error]="
            certifySubmitForm &&
            certifySubmitForm.controls.representativeCertified.hasError('representativeCertifiedNotChecked')
          "
        ></goa-checkbox>
      </goa-form-item>
    </goa-form-item>

    <div class="field-validation-error-msg" *ngIf="">
      <goa-icon size="small" type="warning" theme="filled"></goa-icon>
      <span>{{ 'otherSourceOfFundingErrorMsg' | res: pageId }}</span>
    </div>
  </div>
</form>
