<div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
  <goa-callout
    #callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    *ngIf="showErrorFieldsCallout"
  >
    <ng-container *ngFor="let uiDef of pageUiDefs">
      <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link">
        {{ uiDef.labelText }}
        <br />
      </a>
    </ng-container>

    <!-- show extra custom error, and prevent duplicate field-label showing -->
    <a
      *ngIf="
        (UiDefEstimatedTotalCost.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage) &&
        !UiDefEstimatedTotalCost.errorMsg
      "
      (click)="jumpToField(UiDefEstimatedTotalCost.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefEstimatedTotalCost.labelText }}<br
    /></a>

    <a
      *ngIf="
        (UiDefLgffFundingAmountRequested.errorMsg ||
          shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
          shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage) &&
        !UiDefLgffFundingAmountRequested.errorMsg
      "
      (click)="jumpToField(UiDefLgffFundingAmountRequested.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefLgffFundingAmountRequested.labelText }}<br
    /></a>

    <a
      *ngIf="
        (UiDefFundingFromOtherGrantPrograms.errorMsg ||
          shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
          shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
          shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage) &&
        !UiDefFundingFromOtherGrantPrograms.errorMsg
      "
      (click)="jumpToField(UiDefFundingFromOtherGrantPrograms.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefFundingFromOtherGrantPrograms.labelText }}<br
    /></a>
  </goa-callout>
</div>
<form [formGroup]="projectEditForm">
  <div class="edit-project-container">
    <goa-form-item [label]="'Brief project description'" class="project-description">
      <p>{{ projectDescription }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div *ngIf="UiDefWithdrawProject; let UiDef" class="wd-500px">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
        <goa-checkbox
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          formControlName="{{ UiDef.nameCtr }}"
          text="Yes"
          [checked]="UiDef.formCtr.value"
          [value]="UiDef.formCtr.value"
          goaChecked
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
        ></goa-checkbox>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <div *ngIf="isProjectTypeNew && UiDefQuantityNew; let UiDef" class="wd-500px">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
        <goa-input
          autocomplete="off"
          type="text"
          positiveDecimal
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
          [attr.disabled]="projectEditForm.value['withdrawProject']"
        >
          <div slot="trailingContent" class="capital-asset-measurement-unit">{{ capitalAssetMeasurementUnit }}</div>
        </goa-input>
        <goa-button
          *ngIf="
            storedProjectToUpdate?.quantityNew_Updated !== storedProjectToUpdate?.quantityNew_Previous &&
            projectEditForm.value['quantityNew'] !== storedProjectToUpdate?.quantityNew_Previous
          "
          type="tertiary"
          size="compact"
          (_click)="revert(0)"
        >
          Revert to previous value
        </goa-button>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div
        *ngIf="
          storedProjectToUpdate?.quantityNew_Updated !== storedProjectToUpdate?.quantityNew_Previous &&
          projectEditForm.value['quantityNew'] !== storedProjectToUpdate?.quantityNew_Previous
        "
      >
        <span>Previously: </span><span>{{ storedProjectToUpdate?.quantityNew_Previous }}</span>
      </div>
      <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <div *ngIf="isProjectTypeUpgrade && UiDefQuantityUpgrade; let UiDef" class="wd-500px">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
        <goa-input
          type="text"
          positiveDecimal
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
          [attr.disabled]="projectEditForm.value['withdrawProject']"
        >
          <div slot="trailingContent" class="capital-asset-measurement-unit">{{ capitalAssetMeasurementUnit }}</div>
        </goa-input>
        <goa-button
          *ngIf="
            storedProjectToUpdate?.quantityUpgrade_Updated !== storedProjectToUpdate?.quantityUpgrade_Previous &&
            projectEditForm.value['quantityUpgrade'] !== storedProjectToUpdate?.quantityUpgrade_Previous
          "
          type="tertiary"
          size="compact"
          (_click)="revert(1)"
        >
          Revert to previous value
        </goa-button>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div
        *ngIf="
          storedProjectToUpdate?.quantityUpgrade_Updated !== storedProjectToUpdate?.quantityUpgrade_Previous &&
          projectEditForm.value['quantityUpgrade'] !== storedProjectToUpdate?.quantityUpgrade_Previous
        "
      >
        <span>Previously: </span><span>{{ storedProjectToUpdate?.quantityUpgrade_Previous }}</span>
      </div>
      <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <div *ngIf="UiDefAnticipatedStartDate; let UiDef" class="wd-500px">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
        <goa-input
          type="date"
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
          [attr.disabled]="projectEditForm.value['withdrawProject']"
        >
        </goa-input>
        <goa-button
          *ngIf="
            !areDatesEqual(anticipatedStartDate_Previous, this.storedProjectToUpdate?.anticipatedStartDate_Updated!) &&
            !areDatesEqual(anticipatedStartDate_Previous, projectEditForm.value['anticipatedStartDate'])
          "
          type="tertiary"
          size="compact"
          (_click)="revert(2)"
        >
          Revert to previous value
        </goa-button>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div
        *ngIf="
          !areDatesEqual(anticipatedStartDate_Previous, this.storedProjectToUpdate?.anticipatedStartDate_Updated!) &&
          !areDatesEqual(anticipatedStartDate_Previous, projectEditForm.value['anticipatedStartDate'])
        "
      >
        <span>Previously: </span><span>{{ anticipatedStartDate_Previous }}</span>
      </div>
      <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <div *ngIf="UiDefAnticipatedEndDate; let UiDef" class="wd-500px">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
        <goa-input
          type="date"
          name="{{ UiDef.name }}"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          maxlength="{{ UiDef.maxLength }}"
          (focusin)="onFocusIn(UiDef)"
          (focusout)="onFocusOut(UiDef, true)"
          [attr.disabled]="projectEditForm.value['withdrawProject']"
        >
        </goa-input>
        <goa-button
          *ngIf="
            !areDatesEqual(anticipatedEndDate_Previous, this.storedProjectToUpdate?.anticipatedEndDate_Updated!) &&
            !areDatesEqual(anticipatedEndDate_Previous, projectEditForm.value['anticipatedEndDate'])
          "
          type="tertiary"
          size="compact"
          (_click)="revert(3)"
        >
          Revert to previous value
        </goa-button>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div
        *ngIf="
          !areDatesEqual(anticipatedEndDate_Previous, this.storedProjectToUpdate?.anticipatedEndDate_Updated!) &&
          !areDatesEqual(anticipatedEndDate_Previous, projectEditForm.value['anticipatedEndDate'])
        "
      >
        <span>Previously: </span><span>{{ anticipatedEndDate_Previous }}</span>
      </div>
      <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <div class="fi-container-pb" *ngIf="UiDefEstimatedTotalCost; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="false"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            [attr.disabled]="projectEditForm.value['withdrawProject']"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <goa-button
            *ngIf="
              storedProjectToUpdate?.estimatedTotalCost_Updated !==
                storedProjectToUpdate?.estimatedTotalCost_Previous &&
              projectEditForm.value['estimatedTotalCost'] !== storedProjectToUpdate?.estimatedTotalCost_Previous
            "
            type="tertiary"
            size="compact"
            (_click)="revert(4)"
          >
            Revert to previous value
          </goa-button>
          <div
            *ngIf="UiDef.errorMsg || shouldShowcombinedFundingVsEstimatedAmountValidationMessage"
            class="error-msg amount-error-msg"
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <!-- <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsEstimatedAmountValidationMessage }}
              </div>
            </div> -->
          </div>
        </goa-form-item>
        <div
          *ngIf="
            storedProjectToUpdate?.estimatedTotalCost_Updated !== storedProjectToUpdate?.estimatedTotalCost_Previous &&
            projectEditForm.value['estimatedTotalCost'] !== storedProjectToUpdate?.estimatedTotalCost_Previous
          "
        >
          <span>Previously: </span><span>{{ storedProjectToUpdate?.estimatedTotalCost_Previous }}</span>
        </div>
        <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
      </div>
    </div>
    <goa-spacer vSpacing="xs"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefLgffFundingAmountRequested; let UiDef">
      <!-- <div class="label" [attr.data-automation-id]="UiDef.id + '-label'">{{ UiDef.labelText }}</div> -->
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="false"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
              shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            [attr.disabled]="projectEditForm.value['withdrawProject']"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <goa-button
            *ngIf="
              storedProjectToUpdate?.amountRequestedFromLGFF_Updated !==
                storedProjectToUpdate?.amountRequestedFromLGFF_Previous &&
              projectEditForm.value['lgffFundingAmountRequested'] !==
                storedProjectToUpdate?.amountRequestedFromLGFF_Previous
            "
            type="tertiary"
            size="compact"
            (_click)="revert(5)"
          >
            Revert to previous value
          </goa-button>
          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
              shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage
            "
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <!-- <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsEstimatedAmountValidationMessage }}
              </div>
            </div> -->
            <div *ngIf="shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountRequestedVsEstimatedAmountValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
      <div
        *ngIf="
          storedProjectToUpdate?.amountRequestedFromLGFF_Updated !==
            storedProjectToUpdate?.amountRequestedFromLGFF_Previous &&
          projectEditForm.value['lgffFundingAmountRequested'] !==
            storedProjectToUpdate?.amountRequestedFromLGFF_Previous
        "
      >
        <span>Previously: </span><span>{{ storedProjectToUpdate?.amountRequestedFromLGFF_Previous }}</span>
      </div>
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <goa-spacer vSpacing="xs"></goa-spacer>

    <div class="fi-container-pb" *ngIf="areThereOtherSourcesOfFunding && UiDefFundingFromOtherGrantPrograms; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
              shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
              shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            [attr.disabled]="projectEditForm.value['withdrawProject']"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <goa-button
            *ngIf="
              storedProjectToUpdate?.amountFromOtherGrantPrograms_Updated !==
                storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous &&
              projectEditForm.value['fundingFromOtherGrantPrograms'] !==
                storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous
            "
            type="tertiary"
            size="compact"
            (_click)="revert(6)"
          >
            Revert to previous value
          </goa-button>
          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
              shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
              shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
            "
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <!-- <div *ngIf="shouldShowcombinedFundingVsEstimatedAmountValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsEstimatedAmountValidationMessage }}
              </div>
            </div> -->
            <div *ngIf="shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountRequestedVsEstimatedAmountValidationMessage }}
              </div>
            </div>
            <div
              *ngIf="shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage"
              class="long-error-msg"
            >
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ fundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
      <div
        *ngIf="
          storedProjectToUpdate?.amountFromOtherGrantPrograms_Updated !==
            storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous &&
          projectEditForm.value['fundingFromOtherGrantPrograms'] !==
            storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous
        "
      >
        <span>Previously: </span><span>{{ storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous }}</span>
      </div>
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <goa-spacer vSpacing="xs"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefFundingFromMunicipalSources; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [attr.disabled]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="false"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
        </goa-form-item>
      </div>
      <!-- <div
        *ngIf="
          storedProjectToUpdate?.totalAmountFromMunicipalSources_Updated !==
          storedProjectToUpdate?.totalAmountFromMunicipalSources_Previous
        "
      >
        <span>Previously: </span><span>{{ storedProjectToUpdate?.totalAmountFromMunicipalSources_Previous }}</span>
      </div> -->
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>

    <div *ngIf="UiDefReasonForUpdate; let UiDef" class="fi-container-pb">
      <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" requirement="required">
        <goa-textarea
          name="{{ UiDef.name }}"
          rows="4"
          width="37.5rem"
          id="{{ UiDef.id }}"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          [maxcount]="UiDef.maxLength"
          countby="character"
          [showcount]="true"
          (focusin)="onFocusIn(UiDef, true)"
          (focusout)="onFocusOut(UiDef, true)"
        >
        </goa-textarea>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
      <div class="help-msg-prj" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>
  </div>

  <!-- <div class="ht-120px"></div> -->
</form>
