import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
@Component({
  selector: 'app-grant-application-banner',
  templateUrl: './grant-application-banner.component.html',
  styleUrls: ['./grant-application-banner.component.scss'],
})
export class GrantApplicationBannerComponent implements OnInit {
  pageId = 'GRANT_APPLICATION';
  pageUrl: string;
  constructor(
    public res: ResourcePipe,
    private router: Router,
    private authStateService: AuthStateService,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._setPageId(val.url, 'constructor');
      }
    });
  }
  ngOnInit(): void {
    const url = this.router.url;
    this._setPageId(url, 'onInit');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _setPageId(url: string, srcFrom: string) {
    this.pageUrl = url.split('/')[1];

    let clickedMenu = this.pageUrl;
    if (this.pageUrl === 'view-applications') {
      this.pageId = 'VIEW_APPLICATIONS';
    } else if (this.pageUrl === 'grant-applications') {
      this.pageId = 'GRANT_APPLICATION';
      clickedMenu = 'view-applications';
    } else if (this.pageUrl === 'edit-sepo' || this.pageUrl === 'view-sepo') {
      this.pageId = 'SEPO';
      clickedMenu = 'sepo';
    } else if (this.pageUrl === 'resources') {
      this.pageId = 'RESOURCES';
    } else if (this.pageUrl === 'allocations-payments') {
      this.pageId = 'ALLOCATIONS_PAYMENTS';
    } else if (this.pageUrl === 'home') {
      this.pageId = 'HOME';
    } else {
      this.pageId = 'GRANT_APPLICATION';
      clickedMenu = 'view-applications';
    }
    this.authStateService.setHomeMenuClicked(clickedMenu);
  }
}
