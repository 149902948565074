import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  ApplicationDraftDto,
  ApplicationExtDto,
  CapitalAssetTypeDto,
  FunctionalCategoryTypeDto,
  PrimaryOutcomeDto,
  ProjectStatusType,
} from '@app-com/api/models';
import { Subscription } from 'rxjs';
import { ApplicationExtService, LookupValueService, OrganizationService } from '@app-com/api/services';
import { ApplicationSummary } from '@app-pot/features/grant-application/models/application-summary-vm';
import { ApplicationStatus } from '@app-pot/features/grant-application/models/enums';
import { CommUtilsService } from '@app-com/services/comm-utils.service';

@Component({
  selector: 'app-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit, OnDestroy {
  pageId = 'APPLICATION_SUMMARY';
  organizationIdSelected: number;
  organizationTitle: string | undefined;
  organizationLgCode: string | undefined;
  sub = new Subscription();
  currentApplicationSummaryId: number;
  functionalCategoriesList: FunctionalCategoryTypeDto[];
  primaryOutcomes: PrimaryOutcomeDto[];
  allCapitalAssetTypes: CapitalAssetTypeDto[];
  applicationStatus: string | null;
  showProgress = true;
  applicationSummary: ApplicationSummary = {} as ApplicationSummary;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  CommUtilsService = CommUtilsService;
  constructor(
    private activatedRoute: ActivatedRoute,
    private applicationService: ApplicationExtService,
    private lookupValueService: LookupValueService,
    private organizations: OrganizationService,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.showProgress = true;
    this.sub.add(
      this.activatedRoute.paramMap.subscribe((prmap: ParamMap) => {
        this.currentApplicationSummaryId = Number(prmap?.get('id') ?? 0);
        if (Number.isNaN(this.currentApplicationSummaryId)) {
          this.currentApplicationSummaryId = 0;
        }
        this.applicationStatus = prmap?.get('status');
        this.organizationIdSelected = Number(prmap?.get('organizationId') ?? 0);
        if (Number.isNaN(this.organizationIdSelected)) {
          this.organizationIdSelected = 0;
        }
      }),
    );

    if (this.currentApplicationSummaryId > 0 && this.organizationIdSelected > 0) {
      this.getLookUpValues();
      this.fetchApplicationRecord();
    } else {
      this.router.navigate(['page-not-found']);
    }
  }

  fetchApplicationRecord() {
    if (this.applicationStatus === ApplicationStatus.Draft || this.applicationStatus === ApplicationStatus.Returned) {
      this.applicationService
        .findOneDraft({ organizationId: this.organizationIdSelected, id: this.currentApplicationSummaryId ?? 0 })
        .subscribe({
          next: (data) => {
            this.timeoutIds.push(
              setTimeout(() => {
                this.prepareApplicationDraftData(data);
              }, 1000),
            );
          },
          error: this.initErrorCallback,
        });
    } else if (
      this.applicationStatus === ApplicationStatus.Submitted ||
      this.applicationStatus === ApplicationStatus.Accepted ||
      this.applicationStatus === ApplicationStatus.Withdrawn
    ) {
      this.applicationService
        .findOneByOrganization({
          organizationId: this.organizationIdSelected,
          id: this.currentApplicationSummaryId ?? 0,
        })
        .subscribe({
          next: (data) => {
            this.timeoutIds.push(
              setTimeout(() => {
                this.prepareApplicationData(data);
              }, 1000),
            );
          },
          error: this.initErrorCallback,
        });
    } else {
      this.router.navigate(['page-not-found']);
    }
  }

  getLookUpValues() {
    this.sub.add(
      this.organizations.findOne({ id: this.organizationIdSelected }).subscribe({
        next: (org) => {
          this.organizationTitle = org.legalName;
          this.organizationLgCode = org.lgCode;
          console.log(this.organizationIdSelected, this.organizationTitle, org);
        },
        error: this.initErrorCallback,
      }),
    );

    this.sub.add(
      this.lookupValueService.findAllApplicationRecordLookupSet().subscribe((lookupList) => {
        this.primaryOutcomes = lookupList.primaryOutcomes;
        this.functionalCategoriesList = lookupList.functionalCategoryTypes;
        this.allCapitalAssetTypes = lookupList.capitalAssetTypes;
      }),
    );
  }

  prepareApplicationDraftData(data: ApplicationDraftDto) {
    this.applicationSummary = {
      applicationID:
        this.applicationStatus === ApplicationStatus.Draft
          ? 'DRAFT'
          : `APP-${data.parentApplicationId?.toString().padStart(5, '0')}`,
      applicationName: data.applicationRecord.name,
      createdDate: data.createdAt,
      submittedDate: '',
      acceptedDate: '',
      withdrawnAt: '',
      primaryOutcomeTitle:
        this.primaryOutcomes.find((x) => x.id === data.applicationRecord.primaryOutcomeId)?.title ?? '',
      functionalCategories: data.applicationRecord.functionalCategories.map((c) => {
        return {
          id: c.functionalCategoryTypeId,
          title: this.functionalCategoriesList.find((_) => _.id === c.functionalCategoryTypeId)?.title,
          isPrimary: c.isPrimary,
        };
      }),
      primaryCategory:
        this.functionalCategoriesList.find(
          (_) =>
            _.id ===
            data.applicationRecord.functionalCategories.find((x) => x.isPrimary === true)?.functionalCategoryTypeId,
        )?.title ?? '',
      applicationCapitalAssets: data.applicationRecord.projects.map((ca) => {
        return {
          id: ca.capitalAssetTypeId,
          title: this.allCapitalAssetTypes.find((x) => x.id === ca.capitalAssetTypeId)?.title,
        };
      }),
      totalProjectCost: data.applicationRecord.projects.reduce(
        (sum: number, current) => sum + +current.estimatedTotalCost! ?? 0,
        0,
      ),
      totalFundingAmountRequested: data.applicationRecord.projects.reduce(
        (sum: number, current) => sum + +current.amountRequestedFromLGFF! ?? 0,
        0,
      ),
    };
    this.showProgress = false;
  }

  prepareApplicationData(data: ApplicationExtDto) {
    this.applicationSummary = {
      applicationID: data.idTxt,
      applicationName: data.name,
      createdDate: data.createdAt,
      submittedDate: data.submittedAt,
      acceptedDate: data.acceptedAt || '',
      withdrawnAt: data.withdrawnAt || '',
      primaryOutcomeTitle: this.primaryOutcomes.find((x) => x.id === data.primaryOutcomeId)?.title,
      functionalCategories: data.functionalCategories.map((c) => {
        return {
          id: c.functionalCategoryTypeId,
          title: this.functionalCategoriesList.find((_) => _.id === c.functionalCategoryTypeId)?.title,
          isPrimary: c.isPrimary,
        };
      }),
      primaryCategory: this.functionalCategoriesList.find(
        (_) => _.id === data.functionalCategories.find((x) => x.isPrimary === true)?.functionalCategoryTypeId,
      )?.title,
      applicationCapitalAssets: data.projects?.map((ca) => {
        return {
          id: ca.capitalAssetTypeId,
          title: this.allCapitalAssetTypes.find((x) => x.id === ca.capitalAssetTypeId)?.title,
        };
      }),
      totalProjectCost: data.projects
        ?.filter((prj) => prj.status != ProjectStatusType.Withdrawn)
        ?.reduce((sum: number, current) => sum + +current.estimatedTotalCost! ?? 0, 0),
      totalFundingAmountRequested: data.projects
        ?.filter((prj) => prj.status != ProjectStatusType.Withdrawn)
        ?.reduce((sum: number, current) => sum + +current.amountRequestedFromLGFF! ?? 0, 0),
    };
    this.showProgress = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    // Remove the added styles from the body tag (by goa-circular-progress) before navigating away in case of error like 403
    this.renderer.setStyle(document.body, 'overflow', '');
    this.renderer.setStyle(document.body, 'padding-right', '');

    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  initErrorCallback() {
    this.showProgress = false;
  }
}
