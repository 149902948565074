import { Component, Input, OnInit } from '@angular/core';
import { TableHeader } from '../lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';

@Component({
  selector: 'app-sepo-outcomes-table',
  templateUrl: './sepo-outcomes-table.component.html',
  styleUrl: './sepo-outcomes-table.component.scss',
})
export class SepoOutcomesTableComponent implements OnInit {
  pageId = 'SEPO';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() sepoOutcomes: any[] = [];
  @Input() headers: TableHeader[] = [];
  @Input() additionalHeaders: TableHeader[] = [];
  @Input() parentColumnIndent = 3;
  @Input() tableData: unknown[];
  @Input() errorProjectOutcomeIds: Array<number> = [];
  @Input() showAdditionalHeader: boolean = true;
  @Input() editBtnDisabled: boolean = false;

  ngOnInit(): void {}

  @Input() editProject: (id: number) => void;
  exeEditProject = (id: number) => {
    if (!this.editBtnDisabled) {
      return this.editProject(id);
    }
    return null;
  };
}
