import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-draft-sepo-data-dialog',
  templateUrl: './delete-draft-sepo-data-dialog.component.html',
  styleUrl: './delete-draft-sepo-data-dialog.component.scss',
})
export class DeleteDraftSepoDataDialogComponent {
  @Input() isOpen: boolean;
  @Output() closeDialog = new EventEmitter<{ deleteDraft: boolean }>();

  closeModal(closeModal: boolean) {
    this.closeDialog.emit({ deleteDraft: closeModal });
  }
}
