<div id="sepo-table">
  <div id="sepo-table-title-row" class="row">
    <h1 id="sepo-table-title" class="col-lg-5 col-md-12 col-sm-12 align-middle">
      {{ 'tableTitle' | res: pageId }}
    </h1>
    <div class="col-lg-7 col-sm-12">
      <div class="row">
        <div class="col-lg-8 offset-lg-4 justify-content-end search-bar-container" style="display: flex">
          <goa-input
            goaValue
            id="view-applications-search-field"
            placeholder="{{ 'searchLabel' | res }}"
            [formControl]="searchFormCtrl"
            [value]="searchFormCtrl.value"
            (keyup.enter)="applySearchFilter()"
          >
            <div
              slot="trailingContent"
              (click)="applySearchFilter()"
              style="background-color: #0070c4; color: white; padding-bottom: 11px; border-radius: 2.5px"
            >
              <goa-icon type="search"></goa-icon>
            </div>
          </goa-input>
        </div>
      </div>
    </div>
  </div>
  <app-lgff-goa-table
    [tableId]="pageId"
    [resPageId]="pageId"
    [colDef]="colDef"
    [tableData]="tableData"
    [totalCount]="tableData.length"
    [displayPaginator]="false"
    (sortClicked)="handleSort($event)"
  ></app-lgff-goa-table>
</div>

<ng-template #sepoStatusColTemplate let-element let-column="column" let-colDef="colDef">
  <goa-badge [type]="getBadgeType(element['status'])" [content]="element['statusTitle']"></goa-badge>
</ng-template>

<ng-template #sepoCurrencyColTemplate let-element let-column="column" let-colDef="colDef">
  {{ element[column] | currency: '$' : 'symbol' : '1.0-0' }}
</ng-template>

<ng-template #actionsColTemplate let-element let-column="column" let-colDef="colDef">
  <div class="actions-container">
    <goa-tooltip content="View SEPO PDF" position="bottom">
      <app-download-sepo-pdf class="action-icon" [sepoId]="element.id"></app-download-sepo-pdf>
    </goa-tooltip>
    <goa-tooltip content="Edit" position="bottom" *ngIf="showSEPOEdit(element.status)">
      <goa-icon size="small" class="action-icon" type="pencil" (click)="onEditSEPO(element.id)"></goa-icon>
    </goa-tooltip>
  </div>
</ng-template>
