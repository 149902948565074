import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app-com/api/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { Observable, Subject, Subscription, interval } from 'rxjs';
import { environment } from '@app-int-env/environment';
import { IdleTimeoutState } from '../enums/idle-timeout-state.enum';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService implements OnDestroy {
  idleState = IdleTimeoutState.Active;
  timedOut = false;
  private idleTimeoutVisibility = new Subject<boolean>();
  private idleTimeoutVisibility$: Observable<boolean> = this.idleTimeoutVisibility.asObservable();

  private timeout = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeout$: Observable<any> = this.timeout.asObservable();

  private timeoutCountdown = new Subject<number>();
  private timeoutCountdown$: Observable<number> = this.timeoutCountdown.asObservable();

  private notifyIdleEnd = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private notifyIdleEnd$: Observable<any> = this.notifyIdleEnd.asObservable();

  private refreshToken = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refreshToken$: Observable<any> = this.refreshToken.asObservable();

  sub = new Subscription();
  timeoutWarningObservable$: Observable<number>;
  intervalSub: Subscription;
  intervalId: number[] = [];
  restartWatch: number;
  idleElapsedTime: number = (environment.idleElapsedTime as number) || 1;
  timeoutElapsedTime: number = 1;
  jwtTokenExpiryInterval: number = (environment.jwtTokenExpiry as number) || 5;

  constructor(
    private authService: AuthService,
    private idle: Idle,
  ) {
    idle.setIdle(this.idleElapsedTime * 60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.restartWatch = window.setInterval(() => {
      //console.log('is running', this.idle.isRunning());
      if (!this.idle.isRunning()) {
        this.idle.watch();
      }
    }, 1000);
    window.setInterval(
      () => {
        if (this.idleState == IdleTimeoutState.Active) {
          this.authService.tokenExpiry().subscribe((tokenExpiry) => {
            const curTimeSeconds = Math.trunc(Date.now() / 1000);
            if (tokenExpiry <= 0 || tokenExpiry <= curTimeSeconds) {
              //console.error('returned tokenExpiry has expired');
              idle.stop();
            } else {
              this.setRefreshToken();
            }
          });
        }
      },
      (this.jwtTokenExpiryInterval - 1) * 60 * 1000,
    );
    this.sub.add(
      idle.onIdleStart.subscribe(() => {
        this.idleState = IdleTimeoutState.Idle;
        //console.log('Your Idle !!!', Math.trunc(Date.now() / 1000));
        if (this.intervalId.length) {
          this.intervalId.forEach((interval) => {
            window.clearTimeout(interval);
          });
        }
        this.intervalId = [];
        const setTimeoutId = window.setTimeout(
          () => {
            //console.log('Idle start settimeout invoked for id', setTimeoutId);
            if (this.idleState == IdleTimeoutState.Idle) {
              this.setTimeoutVisibility(true);
              this.intervalSub = interval(1000).subscribe((val) => {
                if (val >= 59) {
                  this.idleState = IdleTimeoutState.TimedOut;
                  this.timedOut = true;

                  this.setTimeoutVisibility(false);
                }
                const countdown = 60 - val;
                this.timeoutCountdown.next(countdown);
              });
            }
          },
          this.timeoutElapsedTime * 60 * 1000,
        );
        //console.log('Idle start settimeout will be invoked for id', setTimeoutId);
        this.intervalId.push(setTimeoutId);
      }),
    );
    this.sub.add(
      idle.onInterrupt.subscribe(() => {
        this.idleState = IdleTimeoutState.Active;
        //console.log('Idel timeout service idle state', this.idleState);
        //console.log('onOnterrupt', this.intervalId);
        if (this.intervalId.length) {
          //console.log('[onOnterrupt] Timeout interval ids before clearing', this.intervalId);
          this.intervalId.forEach((interval) => {
            window.clearTimeout(interval);
          });
          this.intervalId = [];
          //console.log('[onOnterrupt] Timeout interval ids after clearing', this.intervalId);
        }
      }),
    );

    this.sub.add(
      idle.onIdleEnd.subscribe(() => {
        //console.log('Your Idle time ended!!!');
        //console.log('Idle time ended is Running', this.idle.isRunning());
        if (this.idleState == IdleTimeoutState.Active) {
          this.authService.tokenExpiry().subscribe((tokenExpiry) => {
            const curTimeSeconds = Math.trunc(Date.now() / 1000);
            if (tokenExpiry <= 0 || tokenExpiry <= curTimeSeconds) {
              //console.error('returned tokenExpiry has expired');
              idle.stop();
            } else {
              this.setRefreshToken();
            }
          });
        }
      }),
    );
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  reset() {
    this.idle.stop();
    //console.log('[Idletimeout service] Idle service restarting....', this.idle.isRunning());
    this.idle.watch();
    this.idle.setIdle(this.idleElapsedTime * 60);

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idleState = IdleTimeoutState.Active;
    this.timedOut = false;
    //console.log('reset', this.intervalId);
    if (this.intervalId.length) {
      //console.log('[reset] Timeout interval ids before clearing', this.intervalId);
      this.intervalId.forEach((interval) => {
        window.clearTimeout(interval);
      });
      this.intervalId = [];
      //console.log('[reset] Timeout interval ids after clearing', this.intervalId);
    }
  }

  stop() {
    this.idle.stop();
    this.idleState = IdleTimeoutState.Stopped;
    this.timedOut = true;
  }

  watch(skipExpiry: boolean) {
    this.idle.watch(skipExpiry);
    this.idleState = IdleTimeoutState.Active;
  }

  public getTimeoutVisibility(): Observable<boolean> {
    return this.idleTimeoutVisibility$;
  }

  public setTimeoutVisibility(isVisible: boolean) {
    this.idleTimeoutVisibility.next(isVisible);
  }

  public setTimeoutCountdown(countdown: number) {
    this.timeoutCountdown.next(countdown);
  }

  public getTimeoutCountdown() {
    return this.timeoutCountdown$;
  }

  public setIdleTimeEnd() {
    this.notifyIdleEnd.next(true);
  }

  public getIdleTimeEnd() {
    return this.notifyIdleEnd$;
  }

  public setRefreshToken() {
    this.refreshToken.next(true);
  }

  public getRefreshToken() {
    return this.refreshToken$;
  }
}
