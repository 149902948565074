import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
  FetchSEPODetails,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable, delay, switchMap, tap } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { UiFieldCtrDef } from '@app-com/constants/patterns';
import { TableHeader } from '@app-com/components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';
import { SepoExtDto, SepoProjectOutcomeDto } from '@app-com/api/models';
import {
  AssetConditionRankingLV,
  CapitalAssetTypeLV,
  LookupStringValue,
  LookupValue,
  LookupValueState,
} from '@app-com/state/lookup-value.state';
import {
  LoadAllCapitalAssetTypes,
  LoadAssetConditionRankings,
  LoadFunctionalCategoryTypes,
  LoadPrimaryOutcomes,
} from '@app-pot/store/actions/lookup-value.action';
import { SepoExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ResourcePipe } from '@app-com/pipes';
import { Clipboard } from '@angular/cdk/clipboard';
import { EditSepoProjectOutcomesModalComponent } from '@app-com/components/edit-sepo-project-outcomes-modal/edit-sepo-project-outcomes-modal.component';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { formatNumberWithCommas } from '@app-com/directives';
@Component({
  selector: 'app-edit-sepo-immediate-outcomes',
  templateUrl: './edit-sepo-immediate-outcomes.component.html',
  styleUrl: './edit-sepo-immediate-outcomes.component.scss',
})
export class EditSepoImmediateOutcomesComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  pageUiDefs: UiFieldCtrDef[] = [];

  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getSepoDetails) currentSepo$: Observable<SepoExtDto>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetsTypes$: Observable<CapitalAssetTypeLV[]>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupStringValue[]>;
  @Select(LookupValueState.getAssetConditionRankings) assetConditionRankings$: Observable<AssetConditionRankingLV[]>;
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;

  @ViewChild(EditSepoProjectOutcomesModalComponent)
  editSepoProjectModalComponent: EditSepoProjectOutcomesModalComponent;

  currentStep: EditSepoSequence | undefined;
  headers: TableHeader[] = [];
  additionalHeaders: TableHeader[] = [];
  applications: unknown[] = [];
  currentSepo: SepoExtDto = {} as SepoExtDto;
  allCapitalAssetTypes: CapitalAssetTypeLV[] = [];
  functionalCategoryTypes: LookupStringValue[] = [];
  assetConditionRankings: AssetConditionRankingLV[] = [];
  primaryOutcomes: LookupValue[] = [];
  primaryOutcomesReportingIndex: number[] = [8, 6, 5, 3, 7];
  primaryOutcomesReporting: SepoProjectOutcomeDto[] = [];
  parentColumnIndent = 2;
  editProjectPencilClicked: boolean;
  openSepoProjectUpdateModal = false;
  isProjectBeingEditedValid: boolean;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  projectOutcomeToUpdate: SepoProjectOutcomeDto;
  organizationId: number;
  sepoProjectOutcomes: SepoProjectOutcomeDto[];
  invalidProjectIds: Array<{ id: number; idTxt: string }> = [];
  errorProjectOutcomeIds: Array<number> = [];
  openSepoOutcomesReporting: boolean;
  sepoProjectOutcomeTitle: (string | undefined)[];
  outcomeReportingClipboardText: string;
  NoOfInvalidProjectsToShow = 5;
  minInvalidProjectsToShow = 5;
  isNextClicked = false;
  capitalAssetMeasurementUnit = '';
  constructor(
    private store: Store,
    private router: Router,
    private sepoService: SepoExtService,
    private cd: ChangeDetectorRef,
    private res: ResourcePipe,
    private clipboard: Clipboard,
    private snackBarService: SnackBarService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.isNextClicked = false;
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAssetConditionRankings());
    this.store.dispatch(new LoadPrimaryOutcomes());
    this.store.dispatch(new LoadAllCapitalAssetTypes());
    this.store.dispatch(new LoadFunctionalCategoryTypes());

    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
    this.store.dispatch(
      new SetEditSepoStepperStatus({
        [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
        [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
        [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
        [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
      }),
    );
    this.sub.add(
      this.assetConditionRankings$?.subscribe((data) => {
        this.assetConditionRankings = data ?? [];
        this.sub.add(
          this.primaryOutcomes$?.subscribe((data) => {
            this.primaryOutcomes = data ?? [];
            this.sub.add(
              this.functionalCategoryTypes$?.subscribe((data) => {
                this.functionalCategoryTypes = data ?? [];
                this.sub.add(
                  this.allCapitalAssetsTypes$?.subscribe((data) => {
                    this.allCapitalAssetTypes = data ?? [];
                    this.getSepoProjectOutcomes();
                  }),
                );
              }),
            );
          }),
        );
      }),
    );

    this.initTableConfig();

    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.ImmediateOutcomes) {
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          this.isNextClicked = true;
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.Certification));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.primaryOutcomesReporting = this.sepoProjectOutcomes.filter(
              (projectOutcome) =>
                this.primaryOutcomesReportingIndex.indexOf(projectOutcome.projectPrimaryOutcomeId) >= 0,
            );

            if (this.primaryOutcomesReporting.length > 0) {
              this.sepoProjectOutcomeTitle = this.primaryOutcomesReporting.map(
                (sepoProjectOutcome) =>
                  this.primaryOutcomes.find(
                    (primaryOutcome) => primaryOutcome.id == sepoProjectOutcome.projectPrimaryOutcomeId,
                  )?.title,
              );
              this.outcomeReportingClipboardText =
                this.res.transform('SEPOOutcomeReportingLabel1', this.pageId) +
                this.primaryOutcomesReporting
                  .map(
                    (sepoProjectOutcome, index) =>
                      `Project: ${sepoProjectOutcome.projectName} Outcome: ${this.sepoProjectOutcomeTitle[index]}`,
                  )
                  .join(' ') +
                this.res.transform('SEPOOutcomeReportingLabel2', this.pageId) +
                this.res.transform('SEPOOutcomeReportingLabel3', this.pageId) +
                this.res.transform('SEPOOutcomeReportingLabel4', this.pageId);
              this.openSepoOutcomesReporting = true;
            } else {
              this.router.navigate(['edit-sepo/certify-and-submit']);
            }
          } else {
            this.openSepoOutcomesReporting = false;
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));

            this.router.navigate(['edit-sepo/statement-of-expenditures']);
          }
        }
        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          this.router.navigate(['/view-sepo']);
        }
      }),
    );
  }
  getSepoProjectOutcomes() {
    this.currentSepo$?.subscribe({
      next: (value) => {
        const sepoProjectOutcomesData = value.sepoProjectOutcomes;
        this.sepoProjectOutcomes = sepoProjectOutcomesData;
        this.currentSepo = value;
        if (sepoProjectOutcomesData?.length > 0) {
          this.applications = this.groupByApplication(sepoProjectOutcomesData);
        }
      },
    });
  }

  scrollIntoView(fieldName: string | number) {
    const fieldElement = document.getElementById(fieldName.toString());
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  groupByApplication(sepoProjectOutcomes: SepoProjectOutcomeDto[]) {
    const grouped = sepoProjectOutcomes.reduce(
      (acc, item) => {
        const key = `${item.applicationIdTxt}-${item.applicationName}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      },
      {} as { [key: string]: SepoProjectOutcomeDto[] },
    );
    const result = Object.keys(grouped)
      .map((key) => {
        const group = grouped[key];
        const application = {
          applicationId: group[0].applicationId,
          applicationIdTxt: group[0].applicationIdTxt,
          applicationName: group[0].applicationName,
        };

        const updatedGroup = group.map((item) => {
          const capitalAssetMeasurementUnit =
            this.allCapitalAssetTypes.find((x) => x.id === item?.primaryCapitalAssetTypeId)?.measurementUnit ?? '';
          const assetConditionRanking = this.assetConditionRankings.find((x) => x.id === item.assetConditionRankingId);
          return {
            ...item,
            primaryCapitalAssetType: this.allCapitalAssetTypes.find((x) => x.id === item.primaryCapitalAssetTypeId)
              ?.title,
            assetConditionRanking: assetConditionRanking
              ? `${assetConditionRanking.id}. ${assetConditionRanking.title}`
              : '',
            projectPrimaryOutcome: this.primaryOutcomes.find((x) => x.id === item.projectPrimaryOutcomeId)?.title,
            formattedActualQuantityNew:
              (item.actualQuantityNew ?? 0) != 0
                ? `${formatNumberWithCommas(item.actualQuantityNew)} ${capitalAssetMeasurementUnit}`
                : 'N/A',
            formattedActualQuantityUpgrade:
              (item.actualQuantityUpgrade ?? 0) != 0
                ? `${formatNumberWithCommas(item.actualQuantityUpgrade)} ${capitalAssetMeasurementUnit}`
                : 'N/A',
          };
        });
        return {
          application,
          projects: updatedGroup,
        };
      })
      .map((group) => ({
        ...group.application,
        projects: group.projects,
      }));
    return result;
  }
  initTableConfig() {
    this.headers = [
      {
        fieldName: 'applicationIdTxt',
        displayName: 'Application ID',
        headerClassName: 'w-144',
        cellClassName: 'w-144',
      },
      {
        fieldName: 'applicationName',
        displayName: 'Application name',
        headerClassName: 'w-160',
        cellClassName: 'w-160',
      },
      {
        fieldName: 'projectIdTxt',
        displayName: 'Project ID',
        headerClassName: 'w-141',
        cellClassName: 'w-141',
      },
      {
        fieldName: 'projectName',
        displayName: 'Project name',
        headerClassName: 'w-165 frozen',
        cellClassName: 'w-165 frozen bg-white',
      },
      { fieldName: 'id', displayName: 'Edit', headerClassName: 'w-64', cellClassName: 'w-64' },
      {
        fieldName: 'primaryCapitalAssetType',
        displayName: 'Primary capital Asset',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'formattedActualQuantityNew',
        displayName: 'Actual quantity new',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'formattedActualQuantityUpgrade',
        displayName: 'Actual quantity upgrade',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'assetConditionRanking',
        displayName: 'Current condition ranking of upgraded asset',
        cellClassName: 'w-165',
        headerClassName: 'w-165',
      },
      {
        fieldName: 'projectPrimaryOutcome',
        displayName: 'Project primary outcome',
        cellClassName: 'w-165',
        headerClassName: 'w-165',
      },
    ];

    this.additionalHeaders = [];
  }
  checkIsProjectStatusValid() {
    this.invalidProjectIds = [];
    this.errorProjectOutcomeIds = [];
    this.sepoProjectOutcomes.forEach(({ actualQuantityNew, id, projectIdTxt }) => {
      if (actualQuantityNew == null) {
        this.invalidProjectIds.push({ id: id, idTxt: projectIdTxt });
        this.errorProjectOutcomeIds.push(id);
      }
    });
    this.cd.detectChanges();
  }
  onClickViewAddtionalItems(showAllProjects: boolean) {
    if (showAllProjects == true) {
      this.NoOfInvalidProjectsToShow = this.invalidProjectIds.length;
    } else if (showAllProjects == false) {
      this.NoOfInvalidProjectsToShow = this.minInvalidProjectsToShow;
    }
    setTimeout(() => {
      this.jumpToField('errors-callout');
    }, 100);
  }
  get showViewAdditionalItemsLink() {
    if (
      this.invalidProjectIds.length > this.minInvalidProjectsToShow &&
      this.NoOfInvalidProjectsToShow != this.invalidProjectIds.length
    ) {
      return true;
    }
    return false;
  }
  autosave() {
    console.log('Auto Save triggered');
  }
  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    this.checkIsProjectStatusValid();
    if (this.invalidProjectIds.length === 0) {
      this.autosave();
      return true;
    } else {
      setTimeout(() => {
        this.jumpToField('errors-callout');
      }, 200);
      return false;
    }
    //return this.invalidProjectIds.length > 0 ? false : true;
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return baseHasError;
  }

  onEditProject = (id: number) => {
    this.store.dispatch(new SetEditSepoButtonAction(['edit']));
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);

    this.editProjectPencilClicked = true;
    this.sub.add(
      this.sepoService.findOneProjectOutcome({ organizationId: this.organizationId, projectOutcomeId: id }).subscribe({
        next: (value) => {
          this.projectOutcomeToUpdate = value;
          this.capitalAssetMeasurementUnit =
            this.allCapitalAssetTypes.find((x) => x.id === this.projectOutcomeToUpdate?.primaryCapitalAssetTypeId)
              ?.measurementUnit ?? '';

          this.openSepoProjectUpdateModal = true;
        },
      }),
    );
  };

  saveSepoProjectEditModal() {
    console.log('Save button clicked');
    const isValid = this.editSepoProjectModalComponent.validateValues();
    if (isValid) {
      const data = this.editSepoProjectModalComponent._getProjectUpdate();
      this.sepoService
        .updateProjectOutcome({
          organizationId: this.organizationId,
          projectOutcomeId: this.projectOutcomeToUpdate.id.toString(),
          body: data,
        })
        .pipe(
          delay(500),
          // Switch to the next action (Fetch SEPODetails)
          switchMap(() => {
            const dispatch = this.store.dispatch(new FetchSEPODetails());
            this.isProjectValid(isValid);
            this.cancelSepoProjectEditModal();
            this.snackBarService.showSuccessMessage(
              this.res.transform('projectOutcomeSuccessfullyUpdateMessage', this.pageId, [
                this.projectOutcomeToUpdate?.projectIdTxt ?? '',
              ]),
            );
            return dispatch;
          }),
          // Add another delay before validating
          delay(500),
          tap(() => {
            if (this.isNextClicked) {
              this.validateOnNext();
            }
          }),
        )
        .subscribe({
          error: (err) => {
            console.error('Error during save or validation:', err);
          },
          complete: () => {
            console.log('Save and validation complete.');
          },
        });
    }
  }
  closePrimaryOutcomeReportingModal() {
    this.openSepoOutcomesReporting = false;
    this.router.navigate(['edit-sepo/certify-and-submit']);
  }
  isProjectValid(e: boolean) {
    this.isProjectBeingEditedValid = e;
    if (this.isProjectBeingEditedValid) {
      this.store.dispatch(new SetEditSepoButtonAction(['empty']));
      this.openSepoProjectUpdateModal = false;
    } else {
      this.openSepoProjectUpdateModal = true;
    }
  }
  cancelSepoProjectEditModal() {
    this.openSepoProjectUpdateModal = false;
  }
  copyClipboardPrimaryOutcomeReportingModal() {
    this.clipboard.copy(this.outcomeReportingClipboardText);
  }
}
