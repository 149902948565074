import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { ApplicationDraftRecordDto, ApplicationProjectType } from '@app-com/api/models';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { formatDecimalWithComma, formatNumberWithCommas } from '@app-com/directives';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { LoadAssetConditionRankings, LoadCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { ApplicationGrantState } from '@app-pot/store/state/application-draft.state';
import { AssetConditionRankingLV, CapitalAssetTypeLV, LookupValueState } from '@app-com/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { format } from 'date-fns';
import { Observable, Subscription, combineLatest, debounceTime, filter, startWith, takeWhile } from 'rxjs';
import { ProjectRecordVm } from '../../models';
import { FormSequence } from '../../models/enums';
import { MapService } from '@app-pot/shared/services/map.service';
import { GooglemapComponent } from '@app-pot/features/maps/googlemap/googlemap.component';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';

@Component({
  selector: 'project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss', '../../common.scss'],
})
export class ProjectFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  public applicationProjectType = ApplicationProjectType;
  public PageIds = PageIds;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'PROJECT';
  idPrefix = '';
  locationStartSpotId = 'location-start-spot-id'; // it is defined in googlemap.component.html, for error-jump-to
  locationErrorMessageId = 'location-error-message-id';
  calloutWrapId = 'project-form-callout-top';

  @Output() projectAdd = new EventEmitter<ProjectRecordVm>();
  @Output() clearProject = new EventEmitter();
  @Input() mode: 'ADD' | 'EDIT'; //ADD or EDIT
  @Input() project?: ProjectRecordVm;
  @Input() isMapVisible: boolean;

  @ViewChild(FormGroupDirective)
  private projectFormGroupDirective!: FormGroupDirective;
  @ViewChild(GooglemapComponent) mapComponentRef!: GooglemapComponent;

  @Select(ApplicationGrantState.fetchApplicationDto) application$: Observable<ApplicationDraftRecordDto>;
  @Select(LookupValueState.getAssetConditionRankings) assetConditionRankings$: Observable<AssetConditionRankingLV[]>;
  @Select(ApplicationGrantState.getFormStep) step$: Observable<FormSequence>;
  @Select(LookupValueState.getCapitalAssetTypes) capitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;

  additionalCapitalAssetTypes: CapitalAssetTypeLV[] = [];

  projectsForm: FormGroup;
  locationForm: FormGroup;
  projectTypeDetailsForm: FormGroup;
  locationDetails: { address: string; coord: { lat: number; lng: number } } | null;
  locationDetailsConfirmed: boolean;

  CurrentPageDef = PageIds.app.project;
  UiDefProjectName: UiFieldCtrDef;
  UiDefProjectDescription: UiFieldCtrDef;
  UiDefPrimaryCapitalAsset: UiFieldCtrDef;
  UiDefAdditionalCapitalAssets: UiFieldCtrDef;
  UiDefAnticipatedStartDate: UiFieldCtrDef;
  UiDefAnticipatedEndDate: UiFieldCtrDef;
  UiDefEstimatedTotalCost: UiFieldCtrDef;
  UiDefLgffFundingAmountRequested: UiFieldCtrDef;
  UiDefFundingFromOtherGrantPrograms: UiFieldCtrDef;
  UiDefFundingFromMunicipalSources: UiFieldCtrDef;

  UiDefProjectType: UiFieldCtrDef;
  UiDefQuantityNew: UiFieldCtrDef;
  UiDefQuantityUpgrade: UiFieldCtrDef;
  UiDefCurrentAssetRanking: UiFieldCtrDef;

  UiDefLocDescription: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];

  errors: { [key: string]: string } = {};
  combinedFundingVsEstimatedAmountValidationMessage =
    '"LGFF funding amount requested," "Funding from other grant programs," and "Funding from municipal sources" must be positive numbers, and their sum must equal the "Estimated total cost."';
  lgffFundingAmountRequestedVsEstimatedAmountValidationMessage =
    'Amount must be less than or equal to the Estimated total cost.';
  fundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage =
    'Amount must be less than or equal to the Estimated total cost.';
  emptyAddrsssLocationMessage =
    'Select a project location using Search or by clicking on the map to add a marker. When you find the location you want, click the "Select location" button.';

  submitClicked = false;
  isAlive = true;
  projectType: ApplicationProjectType | undefined = undefined;
  isDropdownStyleAdjusted = false;
  isAssertRankingAdjusted = false;
  shouldShowInvalidAddressLocationMessage = false;
  sub = new Subscription();
  capitalAssetMeasurementUnit = '';
  // locationDescription: string;
  hasOtherSourcesOfFunding: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
    private mapService: MapService,
    private ref: ChangeDetectorRef,
    private snackBarService: SnackBarService,
  ) {}

  ngOnChanges(): void {
    // const addressDescription = this.project?.addressDescription ?? '';
    // this.locationDescription = this.mode === 'EDIT' ? addressDescription : '';
  }

  ngOnInit(): void {
    if (this.mode === 'EDIT') {
      this.idPrefix = 'edit-';
      this.locationStartSpotId = this.idPrefix + this.locationStartSpotId;
      this.locationErrorMessageId = this.idPrefix + this.locationErrorMessageId;
      this.calloutWrapId = this.idPrefix + this.calloutWrapId;
    }

    this.store.dispatch(new LoadAssetConditionRankings());

    this.application$.pipe(filter((application) => !!application)).subscribe((_) => {
      let primary: number | undefined = undefined;
      if (_.functionalCategories && _.functionalCategories.length) {
        primary = _.functionalCategories.find((_) => _.isPrimary ?? false)?.functionalCategoryTypeId;
      }
      if (_.areThereOtherSourcesOfFunding) {
        this.hasOtherSourcesOfFunding = true;
      } else {
        this.hasOtherSourcesOfFunding = false;
      }
      this.store.dispatch(new LoadCapitalAssetTypes(primary));
      this.capitalAssetTypes$
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(() => this._refreshAdditionalCapitalAssetsDropdown());
    });

    this.projectsForm = this.formBuilder.group({});
    this.locationForm = this.formBuilder.group({});
    this.projectTypeDetailsForm = this.formBuilder.group({});

    this.createFormControls();
    this._prepareForm();

    this.pageUiDefs.forEach((uiDef) => {
      uiDef.formCtr.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .pipe(debounceTime(300))
        .subscribe(() => {
          // (changes)
          // console.log('value changes in: ' + uiDef.nameCtr + ' type: ' + typeof(changes) +
          //   ', UiDef-value= ' + this.UiDefApplicationName.formCtr.value +
          //   ', Focused-in-NonBlank-or-error field= ' + uiDef.focusedInNonBlankOrErrorField);
          // console.log(changes);
          if (
            (uiDef.focusedInNonBlankOrErrorField || uiDef.focusedOutFieldByTrueBlurEvent) &&
            this.isQuantityUiVisible(uiDef)
          ) {
            CommUtilsService.getUiFieldErrorList(uiDef, false, 'valueChanges');
          }
        });
    });
  }

  private _refreshAdditionalCapitalAssetsDropdown() {
    const primaryCapitalAsset: number = this.projectsForm?.get('primaryCapitalAsset')?.value;

    const capitalAssetTypes = this.store.selectSnapshot(LookupValueState.getCapitalAssetTypes);
    this.additionalCapitalAssetTypes = capitalAssetTypes
      .filter((_) => _.id !== primaryCapitalAsset)
      .map((_) => {
        return {
          ..._,
          title: _.title
            .replace(new RegExp(`\\(${_.measurementUnit}\\)(?![\\s\\S]*\\(${_.measurementUnit}\\))`), '')
            .trim(),
        };
      });
    if (this.UiDefAdditionalCapitalAssets?.formCtr.value?.length) {
      const existingSelectedAdditionalCapitalAssets = this.UiDefAdditionalCapitalAssets.formCtr.value as number[];

      const newSelectedAdditionalCapitalAssets: number[] = existingSelectedAdditionalCapitalAssets.filter(
        (_) => _ != primaryCapitalAsset,
      );

      if (newSelectedAdditionalCapitalAssets.length < existingSelectedAdditionalCapitalAssets.length) {
        const primaryCapitalAssetTitle = capitalAssetTypes.find((_) => _.id == primaryCapitalAsset)?.title;
        this.snackBarService.showSuccessMessage(
          `You've selected '${primaryCapitalAssetTitle}' as your primary capital asset, so it's been removed from your additional capital asset options.`,
        );
      }
      this.UiDefAdditionalCapitalAssets.formCtr.setValue(newSelectedAdditionalCapitalAssets);
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isDropdownStyleAdjusted) {
      this._adjustGoaDropdownStyle(this.UiDefPrimaryCapitalAsset?.idWrap ?? '', 32);
      this._adjustGoaDropdownStyle(this.UiDefProjectType?.idWrap ?? '');
      this._adjustGoaDropdownStyle(this.UiDefCurrentAssetRanking?.idWrap ?? '', 40.9375);
      this.isDropdownStyleAdjusted = true;
      this.isAssertRankingAdjusted = true;
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  // create form controls for both blank-application and editing-application, init value are ''
  createFormControls() {
    this.locationForm = this.formBuilder.group({
      address: [{ value: '', disabled: true }],
      latitude: [{ value: '', disabled: true }],
      longitude: [{ value: '', disabled: true }],
      // description: ['', Validators.maxLength(1000)],
    });

    this.projectTypeDetailsForm = this.formBuilder.group({});
    this.UiDefProjectType = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.projectType, this.idPrefix);
    this.projectTypeDetailsForm.addControl(this.UiDefProjectType.nameCtr, this.UiDefProjectType.formCtr);

    this.UiDefQuantityNew = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityNew, this.idPrefix);
    this.projectTypeDetailsForm.addControl(this.UiDefQuantityNew.nameCtr, this.UiDefQuantityNew.formCtr);

    this.UiDefQuantityUpgrade = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityUpgrade, this.idPrefix);
    this.projectTypeDetailsForm.addControl(this.UiDefQuantityUpgrade.nameCtr, this.UiDefQuantityUpgrade.formCtr);

    this.UiDefCurrentAssetRanking = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.currentAssetRanking,
      this.idPrefix,
    );
    this.projectTypeDetailsForm.addControl(
      this.UiDefCurrentAssetRanking.nameCtr,
      this.UiDefCurrentAssetRanking.formCtr,
    );

    this.projectsForm = this.formBuilder.group(
      {
        location: this.locationForm,
        projectTypeDetails: this.projectTypeDetailsForm,
        id: 0,
      },
      {
        validators: [
          this._lgffFundingAmountRequestedVsEstimatedAmountValidator,
          this._fundingFromOtherGrantProgramsVsEstimatedAmountValidator,
          this._combinedFundingVsEstimatedAmountValidator,
        ],
      },
    );

    this.UiDefProjectName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.projectName, this.idPrefix);
    this.projectsForm.addControl(this.UiDefProjectName.nameCtr, this.UiDefProjectName.formCtr);

    this.UiDefProjectDescription = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.projectDescription,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefProjectDescription.nameCtr, this.UiDefProjectDescription.formCtr);

    this.UiDefPrimaryCapitalAsset = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.primaryCapitalAsset,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefPrimaryCapitalAsset.nameCtr, this.UiDefPrimaryCapitalAsset.formCtr);

    this.UiDefAdditionalCapitalAssets = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.additionalCapitalAssets,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefAdditionalCapitalAssets.nameCtr, this.UiDefAdditionalCapitalAssets.formCtr);

    this.UiDefAnticipatedStartDate = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.anticipatedStartDate,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefAnticipatedStartDate.nameCtr, this.UiDefAnticipatedStartDate.formCtr);

    this.UiDefAnticipatedEndDate = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.anticipatedEndDate,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefAnticipatedEndDate.nameCtr, this.UiDefAnticipatedEndDate.formCtr);

    this.UiDefEstimatedTotalCost = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.estimatedTotalCost,
      this.idPrefix,
    );
    this.projectsForm.addControl(this.UiDefEstimatedTotalCost.nameCtr, this.UiDefEstimatedTotalCost.formCtr);

    this.UiDefLgffFundingAmountRequested = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.lgffFundingAmountRequested,
      this.idPrefix,
    );
    this.projectsForm.addControl(
      this.UiDefLgffFundingAmountRequested.nameCtr,
      this.UiDefLgffFundingAmountRequested.formCtr,
    );

    this.UiDefFundingFromOtherGrantPrograms = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromOtherGrantPrograms,
      this.idPrefix,
    );
    this.projectsForm.addControl(
      this.UiDefFundingFromOtherGrantPrograms.nameCtr,
      this.UiDefFundingFromOtherGrantPrograms.formCtr,
    );

    this.UiDefFundingFromMunicipalSources = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromMunicipalSources,
      this.idPrefix,
      { value: '', disabled: true },
    );
    this.projectsForm.addControl(
      this.UiDefFundingFromMunicipalSources.nameCtr,
      this.UiDefFundingFromMunicipalSources.formCtr,
    );

    this.pageUiDefs = [
      this.UiDefProjectName,
      this.UiDefProjectDescription,
      this.UiDefPrimaryCapitalAsset,
      this.UiDefProjectType,
      this.UiDefQuantityNew,
      this.UiDefQuantityUpgrade,
      this.UiDefCurrentAssetRanking,
      this.UiDefAnticipatedStartDate,
      this.UiDefAnticipatedEndDate,
      this.UiDefEstimatedTotalCost,
      this.UiDefLgffFundingAmountRequested,
      this.UiDefFundingFromOtherGrantPrograms,
      this.UiDefAdditionalCapitalAssets,
    ];
  }

  private _prepareForm() {
    if (this.mode === 'ADD') {
      this._watchForAmountChange(0, 0, 0);
    } else {
      //EDIT
      this._prepareEditForm();
    }
    this._watchForProjectTypeChange();
    this._watchForPrimaryCapitalAssetChange();
  }

  private _prepareEditForm() {
    if (this.project === undefined) {
      console.error('Error: current project to edit not set');
      return;
    }
    const formData = {
      id: this.project.id,
      projectName: this.project.projectName,
      projectDescription: this.project.description,
      primaryCapitalAsset: this.project.primaryCapitalAssetId,
      additionalCapitalAssets: this.project.additionalCapitalAssetIds,
      location: {
        address: this.project.address,
        latitude: this.project.latitude,
        longitude: this.project.longitude,
        //description: this.project.addressDescription,
      },
      projectTypeDetails: {
        projectType: this.project.projectType,
        quantityNew: formatDecimalWithComma(this.project.quantityNew),
        quantityUpgrade: formatDecimalWithComma(this.project.quantityUpgrade),
        currentAssetRanking: this.project.currentAssetRankingId,
      },
      anticipatedStartDate: this._getDateForDatePicker(this.project.anticipatedStartDate),
      anticipatedEndDate: this._getDateForDatePicker(this.project.anticipatedEndDate),
      estimatedTotalCost: formatNumberWithCommas(this.project.estimatedTotalCost),
      lgffFundingAmountRequested: formatNumberWithCommas(this.project.lgffFundingAmountRequested),
      fundingFromOtherGrantPrograms: formatNumberWithCommas(this.project.fundingFromOtherGrantPrograms),
      fundingFromMunicipalSources: formatNumberWithCommas(this.project.fundingFromMunicipalSources),
    };
    this.projectType = this.project?.projectType ?? undefined;
    this.checkProjectType(this.projectType);

    this.projectsForm.patchValue(formData);

    this._watchForAmountChange(
      this.project.estimatedTotalCost,
      this.project.lgffFundingAmountRequested,
      this.project.fundingFromOtherGrantPrograms,
    );
  }

  private _watchForAmountChange(
    estimatedTotalCost: number | undefined,
    lgffFundingAmountRequested: number | undefined,
    fundingFromOtherGrantPrograms: number | undefined,
  ) {
    const estimatedTotalCostCtrl = this.projectsForm.get('estimatedTotalCost');
    const lgffFundingAmountRequestedCtrl = this.projectsForm.get('lgffFundingAmountRequested');
    const fundingFromOtherGrantProgramsCtrl = this.projectsForm.get('fundingFromOtherGrantPrograms');
    const fundingFromMunicipalSourcesCtrl = this.projectsForm.get('fundingFromMunicipalSources');
    if (
      !estimatedTotalCostCtrl ||
      !lgffFundingAmountRequestedCtrl ||
      !fundingFromOtherGrantProgramsCtrl ||
      !fundingFromMunicipalSourcesCtrl
    )
      return;
    combineLatest([
      estimatedTotalCostCtrl.valueChanges.pipe(startWith(estimatedTotalCost?.toString())),
      lgffFundingAmountRequestedCtrl.valueChanges.pipe(startWith(lgffFundingAmountRequested?.toString())),
      fundingFromOtherGrantProgramsCtrl.valueChanges.pipe(startWith(fundingFromOtherGrantPrograms?.toString())),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([estimatedTotalCostStr, lgffFundingAmountRequestedStr, fundingFromOtherGrantProgramsStr]) => {
        const estimatedTotalCost = CommUtilsService.parseInputInt(estimatedTotalCostStr);
        const lgffFundingAmountRequested = CommUtilsService.parseInputInt(lgffFundingAmountRequestedStr);
        const fundingFromOtherGrantPrograms = CommUtilsService.parseInputInt(fundingFromOtherGrantProgramsStr);
        const fundingFromMunicipalSources = this._calculateFundingFromMunicipalSources(
          estimatedTotalCost,
          lgffFundingAmountRequested,
          fundingFromOtherGrantPrograms,
        );
        fundingFromMunicipalSourcesCtrl.setValue(formatNumberWithCommas(fundingFromMunicipalSources));
      });
  }

  private _watchForProjectTypeChange() {
    this._reactToProjectTypeChange(); //initial run
    const projectTypeCtr = this.projectsForm.get('projectTypeDetails')?.get('projectType');
    if (projectTypeCtr) {
      projectTypeCtr.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
        this._reactToProjectTypeChange();
      });
    }
  }

  private _reactToProjectTypeChange() {
    const projectTypeValue = this.projectsForm.get('projectTypeDetails')?.get('projectType')?.value ?? undefined;
    this.projectType = projectTypeValue ? <ApplicationProjectType>projectTypeValue : undefined;

    if (this.mode === 'ADD') {
      if (!this.projectType) {
        this._resetControl(this.UiDefQuantityNew);
        this._resetControl(this.UiDefQuantityUpgrade);
        this._resetControl(this.UiDefCurrentAssetRanking);
      } else if (this.projectType == ApplicationProjectType.New) {
        this._resetControl(this.UiDefQuantityUpgrade);
        this._resetControl(this.UiDefCurrentAssetRanking);
      } else if (this.projectType == ApplicationProjectType.Upgrade) {
        this._resetControl(this.UiDefQuantityNew);
      }
    }
  }

  private _watchForPrimaryCapitalAssetChange() {
    this._reactToPrimaryCapitalAssetChange(); //initial run
    const primaryCapitalAssetCtr = this.projectsForm.get('primaryCapitalAsset');
    if (primaryCapitalAssetCtr) {
      primaryCapitalAssetCtr.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
        this._reactToPrimaryCapitalAssetChange();
      });
    }
  }

  private _reactToPrimaryCapitalAssetChange(): void {
    const primaryCapitalAssetId: number = this.projectsForm.get('primaryCapitalAsset')?.value;
    this.capitalAssetMeasurementUnit =
      this.store.selectSnapshot(LookupValueState.getCapitalAssetTypes).find((_) => _.id === primaryCapitalAssetId)
        ?.measurementUnit ?? '';
    this._refreshAdditionalCapitalAssetsDropdown();
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    console.log('focus in: ' + UiDef.nameCtr + ' setAsTouched= ' + setAsTouched);
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    // console.log('focus out: ' + UiDef.nameCtr + ' value= ' + UiDef.formCtr.value + ' blur= ' + isBlurAway);
    if (isBlurAway) {
      UiDef.focusedInNonBlankOrErrorField = true;
    }

    if (isBlurAway) {
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');

        if (UiDef.nameCtr === 'projectType') {
          this.projectType = UiDef.formCtr.value ? <ApplicationProjectType>UiDef.formCtr.value : undefined;
          this.resetQuantity3UiErrors();
          this.checkProjectType(this.projectType);
          if (!this.needHideAssetRanking()) {
            this.UiDefCurrentAssetRanking.focusedInNonBlankOrErrorField = false;
            this.UiDefCurrentAssetRanking.focusedOutFieldByTrueBlurEvent = false;
            if (this.UiDefCurrentAssetRanking.errorMsg) {
              delete this.UiDefCurrentAssetRanking.errorMsg;
            }
          }
        }

        if (UiDef.nameCtr === this.CurrentPageDef.anticipatedEndDate.nameCtr) {
          if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedStartDate)) {
            CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedStartDate, true, 'crossFocusOut');
          }
        } else if (UiDef.nameCtr === this.CurrentPageDef.anticipatedStartDate.nameCtr) {
          if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedEndDate)) {
            CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedEndDate, true, 'crossFocusOut');
          }
        }
      }, 400);
    }
  }

  checkProjectType(currentType?: ApplicationProjectType) {
    const needShowType =
      !!currentType && (currentType === ApplicationProjectType.Both || currentType === ApplicationProjectType.Upgrade);
    console.log('Cur Project Type= ' + currentType + '; Need show= ' + needShowType);
  }

  needHideAssetRanking() {
    return (
      !this.projectType ||
      (this.projectType !== ApplicationProjectType.Both && this.projectType !== ApplicationProjectType.Upgrade)
    );
  }

  isQuantityNewVisible() {
    return (
      this.projectType &&
      (this.projectType === ApplicationProjectType.New || this.projectType === ApplicationProjectType.Both)
    );
  }
  isQuantityUpgradeVisible() {
    return (
      this.projectType &&
      (this.projectType === ApplicationProjectType.Upgrade || this.projectType === ApplicationProjectType.Both)
    );
  }
  isAssetRankingVisible() {
    return (
      this.projectType &&
      (this.projectType === ApplicationProjectType.Upgrade || this.projectType === ApplicationProjectType.Both)
    );
  }

  isQuantityUiVisible(UiDef: UiFieldCtrDef) {
    if (UiDef.nameCtr === this.UiDefQuantityNew.nameCtr) {
      return this.isQuantityNewVisible();
    } else if (UiDef.nameCtr === this.UiDefQuantityUpgrade.nameCtr) {
      return this.isQuantityUpgradeVisible();
    } else if (UiDef.nameCtr === this.UiDefCurrentAssetRanking.nameCtr) {
      return this.isAssetRankingVisible();
    } else {
      return true;
    }
  }

  clearHideInvisibleUiErrors(UiDef: UiFieldCtrDef, isQuantityNewOrUpgrade: boolean) {
    if (UiDef.errorMsg) {
      delete UiDef.errorMsg;
    }
    if (!this.isQuantityUiVisible(UiDef)) {
      if (isQuantityNewOrUpgrade) {
        UiDef.formCtr.setValue('');
      }
      UiDef.formCtr.setErrors(null);
      // UiDef.formCtr.reset();
    } else {
      UiDef.formCtr.updateValueAndValidity(); // set valid state, but not generate errorMsg[] for UI
    }
  }

  resetQuantity3UiErrors() {
    this.clearHideInvisibleUiErrors(this.UiDefQuantityNew, true);
    this.clearHideInvisibleUiErrors(this.UiDefQuantityUpgrade, true);
    this.clearHideInvisibleUiErrors(this.UiDefCurrentAssetRanking, false);
  }

  getProjectFormValue(): ProjectRecordVm {
    const formValue = this.projectsForm.value;
    const fundingFromOtherGrantProgramsValue = this.projectsForm.get('fundingFromOtherGrantPrograms')?.value;
    const location = this._getLocation();
    const estimatedTotalCost = CommUtilsService.parseInputInt(formValue.estimatedTotalCost);
    const lgffFundingAmountRequested = CommUtilsService.parseInputInt(formValue.lgffFundingAmountRequested);
    const fundingFromOtherGrantPrograms = CommUtilsService.parseInputInt(fundingFromOtherGrantProgramsValue);
    const fundingFromMunicipalSources = this._calculateFundingFromMunicipalSources(
      estimatedTotalCost || 0,
      lgffFundingAmountRequested || 0,
      fundingFromOtherGrantPrograms || 0,
    );
    const additionalCapitalAssetIds = formValue.additionalCapitalAssets === '' ? [] : formValue.additionalCapitalAssets;
    const project: ProjectRecordVm = {
      id: formValue.id ?? 0,
      detailsShown: true,
      projectName: formValue.projectName || '',
      description: formValue.projectDescription || '',
      primaryCapitalAssetId: formValue.primaryCapitalAsset || 0,
      additionalCapitalAssetIds,
      estimatedTotalCost,
      lgffFundingAmountRequested,
      fundingFromOtherGrantPrograms: this.hasOtherSourcesOfFunding ? fundingFromOtherGrantPrograms : 0,
      fundingFromMunicipalSources,
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude,
      // addressDescription: location.description,
      anticipatedStartDate: CommUtilsService.parseInputDateString(formValue.anticipatedStartDate),
      anticipatedEndDate: CommUtilsService.parseInputDateString(formValue.anticipatedEndDate),
      projectType:
        formValue.projectTypeDetails.projectType?.trim() != '' ? formValue.projectTypeDetails.projectType : undefined,
      quantityNew:
        formValue.projectTypeDetails.quantityNew?.trim() != ''
          ? CommUtilsService.parseInputFloat(formValue.projectTypeDetails.quantityNew)
          : undefined,
      quantityUpgrade:
        formValue.projectTypeDetails.quantityUpgrade?.trim() != ''
          ? CommUtilsService.parseInputFloat(formValue.projectTypeDetails.quantityUpgrade)
          : undefined,
      currentAssetRankingId:
        formValue.projectTypeDetails.currentAssetRanking != ''
          ? formValue.projectTypeDetails.currentAssetRanking
          : undefined,
    };
    return project;
  }

  public hasValidAddressLocation(): boolean {
    const currentLocation = this._getLocation();
    const valid =
      !!currentLocation &&
      currentLocation.address?.length > 0 &&
      currentLocation.latitude !== 0 &&
      currentLocation.longitude !== 0;
    console.warn('hasValidAddressLocation= ' + valid);
    this.shouldShowInvalidAddressLocationMessage = !valid;
    return valid;
  }

  public hasProjectFormAnyError(submitted: boolean): boolean {
    this.submitClicked = this.submitClicked || submitted;

    this.resetQuantity3UiErrors();

    if (this.hasOtherSourcesOfFunding) {
      this.projectsForm.get('fundingFromOtherGrantPrograms')?.enable();
    } else {
      this.projectsForm.get('fundingFromOtherGrantPrograms')?.disable();
    }

    this.pageUiDefs.forEach((uiDef) => {
      if (this.isQuantityUiVisible(uiDef)) {
        CommUtilsService.getUiFieldErrorList(uiDef, true, 'pageCheckError');
      }
    });

    return !this.hasValidAddressLocation() || !this.projectsForm.valid;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public hasProjectFormMissRequiredValue(showError: boolean, submitted: boolean): boolean {
    let missRequired = false;
    this.pageUiDefs.forEach((uiDef) => {
      if (uiDef.isRequired && (!uiDef.formCtr.value || uiDef.formCtr.value.toString().length < 1)) {
        missRequired = true;
        if (showError) {
          CommUtilsService.getUiFieldErrorList(uiDef, true, 'checkRequiredError');
        }
      }
    });

    return missRequired;
  }

  public hasProjectFormAnyNonEmptyValue(showError: boolean): boolean {
    let hasNonEmptyValue = false;
    this.pageUiDefs.forEach((uiDef) => {
      if (this.isQuantityUiVisible(uiDef)) {
        if (!!uiDef.formCtr.value && uiDef.formCtr.value.toString().length > 0) {
          hasNonEmptyValue = true;
        }
        if (showError) {
          this.onFocusOut(uiDef, true);
        }
      }
    });

    return hasNonEmptyValue;
  }

  addToList() {
    if (this.hasProjectFormAnyError(true)) {
      setTimeout(() => {
        this.jumpToField(this.calloutWrapId);
      }, 200);
      return;
    }

    const project = this.getProjectFormValue();

    this.projectAdd.emit(project);
    this.resetForm();
    // document.getElementById('clearProjectForm')?.click();
  }

  private _lgffFundingAmountRequestedVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost = CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value);
    const lgffFundingAmountRequested = CommUtilsService.parseInputInt(control.get('lgffFundingAmountRequested')?.value);
    if (lgffFundingAmountRequested > estimatedTotalCost) {
      return { lgffFundingAmountRequestedGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  private _fundingFromOtherGrantProgramsVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost = CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value);
    const fundingFromOtherGrantPrograms = CommUtilsService.parseInputInt(
      control.get('fundingFromOtherGrantPrograms')?.value,
    );
    if (fundingFromOtherGrantPrograms > estimatedTotalCost) {
      return { fundingFromOtherGrantProgramsGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  private _combinedFundingVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost = CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value);
    const lgffFundingAmountRequested = CommUtilsService.parseInputInt(control.get('lgffFundingAmountRequested')?.value);
    const fundingFromOtherGrantPrograms = CommUtilsService.parseInputInt(
      control.get('fundingFromOtherGrantPrograms')?.value,
    );
    if (lgffFundingAmountRequested + fundingFromOtherGrantPrograms > estimatedTotalCost) {
      return { combinedFundingGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  get shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage(): boolean {
    return (
      (this.projectsForm.get('lgffFundingAmountRequested')?.touched ?? false) &&
      this.projectsForm.hasError('lgffFundingAmountRequestedGreaterThanEstimatedAmount')
    );
  }

  get shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage(): boolean {
    return (
      (this.projectsForm.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.projectsForm.hasError('fundingFromOtherGrantProgramsGreaterThanEstimatedAmount')
    );
  }

  get shouldShowcombinedFundingVsEstimatedAmountValidationMessage(): boolean {
    return this.submitClicked && this.projectsForm.hasError('combinedFundingGreaterThanEstimatedAmount');
  }

  private _getDateForDatePicker(value: string | undefined): string | undefined {
    if (!value) return undefined;
    const date = new Date(value);
    if (date.toString() === 'Invalid Date') return undefined;
    return format(date, 'yyyy-MM-dd');
  }

  private _calculateFundingFromMunicipalSources(
    estimatedTotalCost: number,
    lgffFundingAmountRequested: number,
    fundingFromOtherGrantPrograms: number,
  ): number {
    const fundingFromMunicipalSources =
      estimatedTotalCost - (lgffFundingAmountRequested + fundingFromOtherGrantPrograms);
    return fundingFromMunicipalSources;
  }

  private _resetControl(controlDef: UiFieldCtrDef): void {
    if (controlDef && controlDef.formCtr) {
      if (!controlDef.formCtr.pristine) {
        controlDef.formCtr.reset();
      }
      controlDef.focusedInNonBlankOrErrorField = false;
      controlDef.focusedOutFieldByTrueBlurEvent = false;
      if (controlDef.errorMsg) {
        delete controlDef.errorMsg;
      }
    }
  }

  public resetForm() {
    if (this.mode === 'ADD') {
      this.mapService.setMapVisibility(false);
      // need send event to googlemap to cleal search + selected-address
      this.locationDetailsConfirmed = false;
      this.locationDetails = null;
      //this.mapComponentRef.clearLocationfromUI();
    } else if (this.mode === 'EDIT') {
      this.mapService.setEditModeMapVisibility(false);
      this.locationDetailsConfirmed = false;
    }
    this.shouldShowInvalidAddressLocationMessage = false;

    setTimeout(() => {
      this.projectFormGroupDirective.resetForm();
      this.isDropdownStyleAdjusted = false;
      this.isAssertRankingAdjusted = false;
      if (this.mode === 'ADD') {
        this.mapService.setMapVisibility(true);
      } else if (this.mode === 'EDIT') {
        this.mapService.setEditModeMapVisibility(true);
      }
    }, 2000);
  }

  private _clearValidationErrors() {
    this.pageUiDefs.forEach((uiDef) => {
      if (uiDef.errorMsg) {
        delete uiDef.errorMsg; // clear visible errors
      }

      uiDef.focusedInNonBlankOrErrorField = false; // stop real time validatation
      uiDef.focusedOutFieldByTrueBlurEvent = false;
      uiDef.formCtr.setErrors(null);
      uiDef.formCtr.markAsUntouched();
      uiDef.formCtr.markAsPristine();
    });
  }

  private _getLocation(): { address: string; latitude?: number; longitude?: number } {
    return {
      address: this.locationDetails ? this.locationDetails.address : this.project?.address ?? '',
      latitude: this.locationDetails ? this.locationDetails?.coord.lat : this.project?.latitude,
      longitude: this.locationDetails ? this.locationDetails?.coord.lng : this.project?.longitude,
      // description: this.locationDescription,
    };
  }

  private _adjustGoaDropdownStyle(fiId: string, width = 26) {
    if (!fiId || fiId.length <= 0) return;
    if (fiId.indexOf(this.UiDefCurrentAssetRanking.id ?? '') >= 0) {
      if (this.isAssertRankingAdjusted) {
        // prevent duplicate adjust
        return;
      } else {
        this.isAssertRankingAdjusted = true;
      }
    } else if (this.isAssertRankingAdjusted) {
      // prevent duplicate adjust
      return;
    }
    console.log('_adjustGoaDropdownStyle called with: ' + fiId);

    let fiPrimaryOutcome: HTMLElement | null;
    let goa_popover: HTMLElement | null | undefined;
    if (this.mode === 'EDIT') {
      fiPrimaryOutcome = document.querySelector(`.popup-form-container #${fiId}`);
      goa_popover = document
        .querySelector(`.popup-form-container #${fiId} goa-dropdown`)
        ?.shadowRoot?.querySelector('goa-popover');
    } else {
      fiPrimaryOutcome = document.getElementById(fiId);
      goa_popover = document.querySelector(`#${fiId} goa-dropdown`)?.shadowRoot?.querySelector('goa-popover');
    }

    if (!fiPrimaryOutcome || !goa_popover) {
      console.log('_adjustGoaDropdownStyle did not find elements for: ' + fiId);
    } else {
      fiPrimaryOutcome.style.width = width + 'rem';
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      goa_popover['maxwidth'] = width + 'rem';
      const popover_target_container = goa_popover.shadowRoot?.querySelector('div');
      if (popover_target_container) {
        popover_target_container.style.width = '100%';

        const popover_target = popover_target_container.querySelector('div.popover-target');
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        if (popover_target) popover_target['style']['width'] = '100%';
      }
    }
  }

  get showErrorFieldsCallout(): boolean {
    // could not use this.projectsForm.invalid, since it include many Required error from ngInit!
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;

    return (
      baseHasError ||
      this.shouldShowInvalidAddressLocationMessage ||
      this.shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
      this.shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
      this.shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
    );
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // window.scrollTo(0, fieldElement.offsetTop ?? 0);
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  clearProjectForm() {
    this.clearProject.emit();
  }
  // populateDescription(description: string) {
  //   this.locationDescription = description;
  // }

  populateAddress({
    address,
    coord,
    selectionConfirmed,
  }: {
    address?: string;
    coord?: { lat: number; lng: number };
    selectionConfirmed: boolean;
  }) {
    console.log('Location Form values= ', this.locationForm.value);
    console.log('[Project Form]Location values', address, coord);
    if (selectionConfirmed) {
      this.locationDetailsConfirmed = true;
      this.locationDetails = {
        address: address!,
        coord: { lat: coord!.lat, lng: coord!.lng },
      };
      this.shouldShowInvalidAddressLocationMessage = false;
    }

    this.ref.detectChanges();
  }
}
