<goa-modal
  id="modal"
  [open]="isOpen"
  maxwidth="550px"
  heading="Delete all draft SEPO data"
  [appPreventModalFlicker]="isOpen"
>
  <p>
    Are you sure you’d like to delete all draft data in this Statement of Expenditures and Project Outcomes? This cannot
    be undone.
  </p>
  <div slot="actions">
    <goa-button-group alignment="end">
      <goa-button (_click)="closeModal(false)" type="secondary">Cancel</goa-button>
      <goa-button (_click)="closeModal(true)">Yes, delete</goa-button>
    </goa-button-group>
  </div>
</goa-modal>
