import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { ResourcePipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { Select, Store } from '@ngxs/store';
import { format, isEqual } from 'date-fns';
import _ from 'lodash';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import { Observable, Subscription, combineLatest, skipUntil, startWith, takeWhile, timer, withLatestFrom } from 'rxjs';
import { ApplicationExtDto, ApplicationProjectType, CashFlowProjectUpdateDto } from '@app-com/api/models';
import { SetCashFlowProjectUpdate } from '@app-pot/store/actions/cash-flow-update.action';
import { CapitalAssetTypeLV, LookupValueState } from '@app-com/state/lookup-value.state';
import { formatDecimalWithComma, formatNumberWithCommas } from '@app-com/directives';
import { CashFlowProjectKey } from '../cash-flow-types';

@Component({
  selector: 'app-cash-flow-project-updates-edit-form',
  templateUrl: './cash-flow-project-updates-edit-form.component.html',
  styleUrl: './cash-flow-project-updates-edit-form.component.scss',
})
export class CashFlowProjectUpdatesEditFormComponent implements OnInit, OnDestroy {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.cashFlow.projectUpdates;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'CASH_FLOW_UPDATES';
  projectKeys: CashFlowProjectKey[] = [
    ['quantityNew_Previous', 'quantityNew'],
    ['quantityUpgrade_Previous', 'quantityUpgrade'],
    ['anticipatedStartDate_Previous', 'anticipatedStartDate'],
    ['anticipatedEndDate_Previous', 'anticipatedEndDate'],
    ['estimatedTotalCost_Previous', 'estimatedTotalCost'],
    ['amountRequestedFromLGFF_Previous', 'lgffFundingAmountRequested'],
    ['amountFromOtherGrantPrograms_Previous', 'fundingFromOtherGrantPrograms'],
    ['totalAmountFromMunicipalSources_Previous', 'fundingFromMunicipalSources'],
  ];

  projectEditForm: FormGroup;
  UiDefWithdrawProject: UiFieldCtrDef;
  UiDefProjectDescription: UiFieldCtrDef;
  UiDefQuantityNew: UiFieldCtrDef;
  UiDefQuantityUpgrade: UiFieldCtrDef;
  UiDefAnticipatedStartDate: UiFieldCtrDef;
  UiDefAnticipatedEndDate: UiFieldCtrDef;
  UiDefEstimatedTotalCost: UiFieldCtrDef;
  UiDefLgffFundingAmountRequested: UiFieldCtrDef;
  UiDefFundingFromOtherGrantPrograms: UiFieldCtrDef;
  UiDefFundingFromMunicipalSources: UiFieldCtrDef;
  UiDefReasonForUpdate: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  submitClicked = false;
  sub = new Subscription();
  combinedFundingVsEstimatedAmountValidationMessage =
    '"LGFF funding amount requested," "Funding from other grant programs," and "Funding from municipal sources" must be positive numbers, and their sum must equal the "Estimated total cost."';
  lgffFundingAmountRequestedVsEstimatedAmountValidationMessage =
    'Amount must be less than or equal to the Estimated total cost.';
  fundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage =
    'Amount must be less than or equal to the Estimated total cost.';

  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  @Input() areThereOtherSourcesOfFunding: boolean;

  @Input() storedProjectToUpdate?: CashFlowProjectUpdateDto;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() isProjectValid: EventEmitter<any> = new EventEmitter();
  @Output() saveProject: EventEmitter<CashFlowProjectUpdateDto> = new EventEmitter<CashFlowProjectUpdateDto>();
  @Select(CashFlowUpdateState.getApplicationDetails) appDetails$: Observable<ApplicationExtDto>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetsTypes$: Observable<CapitalAssetTypeLV[]>;
  @Select(CashFlowUpdateState.getCashFlowUpdateCta) cashFlowCta$: Observable<
    'cancel' | 'confirm' | 'save' | 'previous'
  >;
  calloutRef: ElementRef;
  @ViewChild('callout', { read: ElementRef, static: false }) set content(content: ElementRef) {
    if (content) {
      this.calloutRef = content;
    }
  }
  isAlive = true;
  capitalAssetMeasurementUnit: string | undefined;
  projectDescription: string | undefined;
  isAutosaveNeeded = false;
  anticipatedEndDate_Previous: string;
  anticipatedStartDate_Previous: string;
  isProjectTypeUpgrade: boolean;
  isProjectTypeNew: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // console.log('[Cashflow Project Update Edit] Project to edit', this.storedProjectToUpdate);
    this.isProjectTypeUpgrade =
      this.storedProjectToUpdate?.projectType == ApplicationProjectType.Upgrade ||
      this.storedProjectToUpdate?.projectType == ApplicationProjectType.Both;
    this.isProjectTypeNew =
      this.storedProjectToUpdate?.projectType == ApplicationProjectType.New ||
      this.storedProjectToUpdate?.projectType == ApplicationProjectType.Both;
    this.sub.add(
      this.appDetails$.pipe(withLatestFrom(this.allCapitalAssetsTypes$)).subscribe(([app, lv]) => {
        this.projectDescription = app.projects.find((pr) => pr.id == this.storedProjectToUpdate?.projectId.toString())
          ?.description;
        const capitalAssetTypeId = app.projects.find((pr) => pr.id == this.storedProjectToUpdate?.projectId.toString())
          ?.capitalAssetTypeId;
        this.capitalAssetMeasurementUnit = lv.find((val) => val.id == capitalAssetTypeId)?.measurementUnit;
      }),
    );

    this.sub.add(
      this.cashFlowCta$.subscribe((cta) => {
        if (cta && cta.indexOf('save') >= 0) {
          this.projectEditForm.markAllAsTouched();

          this.pageUiDefs.forEach((uiDef) => {
            CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
          });
          // console.log('Validate');
          // console.log('[Cashflow project updates edit] Is Valid', this.projectEditForm?.valid);
          this.isProjectValid.emit(this.projectEditForm?.valid);
          if (this.projectEditForm?.valid) {
            console.log('Updated project', this.projectEditForm?.value);
            if (this.projectEditForm.value['withdrawProject']) {
              this.projectKeys.forEach((_, idx) => {
                this.revert(idx);
              });
            }
            this.store.dispatch(new SetCashFlowProjectUpdate(this._getProjectUpdate()));
          }
        } else {
          setTimeout(() => {
            this.jumpToCallout(this.calloutRef);
          }, 100);
        }
      }),
    );

    this.createProjectEditForm();
    this.initializeProjectToUpdate();
    this._registerChangeObserverForAutosaveFlag();
  }

  //This is to prevent autosave from being triggered when a user simply tabs through input fields.
  private _registerChangeObserverForAutosaveFlag() {
    this.projectEditForm.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        skipUntil(timer(500)), //When the form is open in edit mode and values are being populated, the change event may trigger multiple times. This is to skip those initial changes.
      )
      .subscribe(() => (this.isAutosaveNeeded = true));
  }

  autosave() {
    if (!this.isAutosaveNeeded) return;
    this.isAutosaveNeeded = false;
    if (!this.projectEditForm.valid) return;
    const project = this._getProjectUpdate(); // form project data

    if (_.isEqual(project, this.storedProjectToUpdate)) {
      return;
    }

    console.log('saved project', project);
    this.store.dispatch(new SetCashFlowProjectUpdate(project));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeCommaFormat(val: any) {
    if (typeof val == 'string') {
      const str = val.replace(/,/g, '');
      return parseFloat(str);
    }
    return val;
  }

  // convert page UI-data into model, before sending to back-end
  private _getProjectUpdate(): CashFlowProjectUpdateDto {
    const project: CashFlowProjectUpdateDto = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      projectId: this.storedProjectToUpdate?.projectId!,
      isProjectWithdrawn: this.projectEditForm?.value[this.UiDefWithdrawProject.nameCtr] ?? false,
      anticipatedStartDate_Updated: new Date(
        CommUtilsService.parseInputDateString(
          this.projectEditForm?.value[this.UiDefAnticipatedStartDate.nameCtr] ??
            this.storedProjectToUpdate?.anticipatedStartDate_Previous,
        ) as string,
      ).toISOString(),
      anticipatedEndDate_Updated: new Date(
        CommUtilsService.parseInputDateString(
          this.projectEditForm?.value[this.UiDefAnticipatedEndDate.nameCtr] ??
            this.storedProjectToUpdate?.anticipatedEndDate_Previous,
        ) as string,
      ).toISOString(),

      estimatedTotalCost_Updated:
        this.projectEditForm?.value[this.UiDefEstimatedTotalCost.nameCtr] ??
        this.storedProjectToUpdate?.estimatedTotalCost_Previous,
      amountRequestedFromLGFF_Updated:
        this.projectEditForm?.value[this.UiDefLgffFundingAmountRequested.nameCtr] ??
        this.storedProjectToUpdate?.amountRequestedFromLGFF_Previous,
      totalAmountFromMunicipalSources_Updated:
        this.projectEditForm?.value[this.UiDefFundingFromMunicipalSources.nameCtr] ??
        this.storedProjectToUpdate?.totalAmountFromMunicipalSources_Previous,
      amountFromOtherGrantPrograms_Updated:
        this.projectEditForm?.value[this.UiDefFundingFromOtherGrantPrograms.nameCtr] ??
        this.storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous,
      reasonForUpdate: this.projectEditForm?.value[this.UiDefReasonForUpdate.nameCtr],
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      projectType: this.storedProjectToUpdate?.projectType!,

      createdAt: '',
      createdBy: '',
      createdByName: '',
      updatedAt: '',
      updatedBy: '',
      updatedByName: '',
    };

    if (this.isProjectTypeNew) {
      project.quantityNew_Updated =
        this.projectEditForm?.value[this.UiDefQuantityNew.nameCtr] ?? this.storedProjectToUpdate?.quantityNew_Previous;
    }
    if (this.isProjectTypeUpgrade) {
      project.quantityUpgrade_Updated =
        this.projectEditForm?.value[this.UiDefQuantityUpgrade.nameCtr] ??
        this.storedProjectToUpdate?.quantityUpgrade_Previous;
    }

    const projectValWithoutFormatting = {
      ...project,

      quantityNew_Updated: this.removeCommaFormat(project.quantityNew_Updated),
      quantityUpgrade_Updated: this.removeCommaFormat(project.quantityUpgrade_Updated),
      estimatedTotalCost_Updated: this.removeCommaFormat(project.estimatedTotalCost_Updated),
      amountRequestedFromLGFF_Updated: this.removeCommaFormat(project.amountRequestedFromLGFF_Updated),
      amountFromOtherGrantPrograms_Updated: this.removeCommaFormat(project.amountFromOtherGrantPrograms_Updated),
      totalAmountFromMunicipalSources_Updated: this.removeCommaFormat(project.totalAmountFromMunicipalSources_Updated),
    };
    return projectValWithoutFormatting;
  }

  revert(idx: number) {
    if (idx === 2 || idx === 3) {
      // start/end date indices
      this.projectEditForm.patchValue({
        [this.projectKeys[idx][1]]: this._getDateForDatePicker(
          this.storedProjectToUpdate![this.projectKeys[idx][0]] as string,
        ),
      });
    } else {
      this.projectEditForm.patchValue({
        [this.projectKeys[idx][1]]: this.storedProjectToUpdate![this.projectKeys[idx][0]],
      });
    }

    console.log('Reverted values', this.projectEditForm.value);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  createProjectEditForm() {
    this.projectEditForm = this.formBuilder.group({});

    this.UiDefWithdrawProject = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.withdrawProject);
    this.projectEditForm.addControl(this.UiDefWithdrawProject.nameCtr, this.UiDefWithdrawProject.formCtr);

    if (this.isProjectTypeNew) {
      this.UiDefQuantityNew = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityNew);
      this.projectEditForm.addControl(this.UiDefQuantityNew.nameCtr, this.UiDefQuantityNew.formCtr);
    }

    if (this.isProjectTypeUpgrade) {
      this.UiDefQuantityUpgrade = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityUpgrade);
      this.projectEditForm.addControl(this.UiDefQuantityUpgrade.nameCtr, this.UiDefQuantityUpgrade.formCtr);
    }

    this.UiDefAnticipatedStartDate = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.anticipatedStartDate);
    this.projectEditForm.addControl(this.UiDefAnticipatedStartDate.nameCtr, this.UiDefAnticipatedStartDate.formCtr);

    this.UiDefAnticipatedEndDate = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.anticipatedEndDate);
    this.projectEditForm.addControl(this.UiDefAnticipatedEndDate.nameCtr, this.UiDefAnticipatedEndDate.formCtr);

    this.UiDefEstimatedTotalCost = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.estimatedTotalCost);
    this.projectEditForm.addControl(this.UiDefEstimatedTotalCost.nameCtr, this.UiDefEstimatedTotalCost.formCtr);

    this.UiDefLgffFundingAmountRequested = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.lgffFundingAmountRequested,
    );
    this.projectEditForm.addControl(
      this.UiDefLgffFundingAmountRequested.nameCtr,
      this.UiDefLgffFundingAmountRequested.formCtr,
    );

    this.UiDefFundingFromOtherGrantPrograms = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromOtherGrantPrograms,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromOtherGrantPrograms.nameCtr,
      this.UiDefFundingFromOtherGrantPrograms.formCtr,
    );

    this.UiDefFundingFromMunicipalSources = CommUtilsService.makeUiFieldCtrDef(
      this.CurrentPageDef.fundingFromMunicipalSources,
    );
    this.projectEditForm.addControl(
      this.UiDefFundingFromMunicipalSources.nameCtr,
      this.UiDefFundingFromMunicipalSources.formCtr,
    );

    this.UiDefReasonForUpdate = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.reasonForUpdate);
    this.projectEditForm.addControl(this.UiDefReasonForUpdate.nameCtr, this.UiDefReasonForUpdate.formCtr);

    this.projectEditForm.addValidators([
      this._lgffFundingAmountRequestedVsEstimatedAmountValidator,
      this._fundingFromOtherGrantProgramsVsEstimatedAmountValidator,
      this._combinedFundingVsEstimatedAmountValidator,
    ]);

    this.pageUiDefs = [
      this.UiDefWithdrawProject,
      this.UiDefAnticipatedStartDate,
      this.UiDefAnticipatedEndDate,
      this.UiDefEstimatedTotalCost,
      this.UiDefLgffFundingAmountRequested,
      this.UiDefFundingFromOtherGrantPrograms,
      this.UiDefFundingFromMunicipalSources,
      this.UiDefReasonForUpdate,
    ];
    if (this.isProjectTypeNew) {
      this.pageUiDefs.push(this.UiDefQuantityNew);
    }
    if (this.isProjectTypeUpgrade) {
      this.pageUiDefs.push(this.UiDefQuantityUpgrade);
    }
  }

  initializeProjectToUpdate() {
    this.anticipatedEndDate_Previous = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      this.storedProjectToUpdate?.anticipatedEndDate_Previous!,
    ) as string;
    this.anticipatedStartDate_Previous = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      this.storedProjectToUpdate?.anticipatedStartDate_Previous!,
    ) as string;
    this.projectEditForm?.setValue({
      [this.UiDefWithdrawProject.nameCtr]: this.storedProjectToUpdate?.isProjectWithdrawn ?? false,
      ...(this.isProjectTypeNew && {
        [this.UiDefQuantityNew.nameCtr]:
          formatDecimalWithComma(this.storedProjectToUpdate?.quantityNew_Updated) ??
          formatDecimalWithComma(this.storedProjectToUpdate?.quantityNew_Previous),
      }),
      ...(this.isProjectTypeUpgrade && {
        [this.UiDefQuantityUpgrade.nameCtr]:
          formatDecimalWithComma(this.storedProjectToUpdate?.quantityUpgrade_Updated) ??
          formatDecimalWithComma(this.storedProjectToUpdate?.quantityUpgrade_Previous),
      }),
      [this.UiDefAnticipatedStartDate.nameCtr]: this._getDateForDatePicker(
        this.storedProjectToUpdate?.anticipatedStartDate_Updated ??
          this.storedProjectToUpdate?.anticipatedStartDate_Previous,
      ),
      [this.UiDefAnticipatedEndDate.nameCtr]: this._getDateForDatePicker(
        this.storedProjectToUpdate?.anticipatedEndDate_Updated ??
          this.storedProjectToUpdate?.anticipatedEndDate_Previous,
      ),
      [this.UiDefEstimatedTotalCost.nameCtr]:
        formatNumberWithCommas(this.storedProjectToUpdate?.estimatedTotalCost_Updated?.toString()) ??
        formatNumberWithCommas(this.storedProjectToUpdate?.estimatedTotalCost_Previous?.toString()),
      [this.UiDefLgffFundingAmountRequested.nameCtr]:
        formatNumberWithCommas(this.storedProjectToUpdate?.amountRequestedFromLGFF_Updated?.toString()) ??
        formatNumberWithCommas(this.storedProjectToUpdate?.amountRequestedFromLGFF_Previous?.toString()),
      [this.UiDefFundingFromOtherGrantPrograms.nameCtr]:
        formatNumberWithCommas(this.storedProjectToUpdate?.amountFromOtherGrantPrograms_Updated?.toString()) ??
        formatNumberWithCommas(this.storedProjectToUpdate?.amountFromOtherGrantPrograms_Previous?.toString()),
      [this.UiDefFundingFromMunicipalSources.nameCtr]:
        formatNumberWithCommas(this.storedProjectToUpdate?.totalAmountFromMunicipalSources_Updated?.toString()) ??
        formatNumberWithCommas(this.storedProjectToUpdate?.totalAmountFromMunicipalSources_Previous?.toString()),
      [this.UiDefReasonForUpdate.nameCtr]: this.storedProjectToUpdate?.reasonForUpdate ?? '',
    });
    this._watchForAmountChange(
      this.projectEditForm?.get('estimatedTotalCost')?.value,
      this.projectEditForm?.get('lgffFundingAmountRequested')?.value,
      this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.value,
    );
    console.log('Project edit form values', this.projectEditForm.value);
  }

  areDatesEqual(dt1: string, dt2: string) {
    const firstDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt1!,
    );
    const secondDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt2!,
    );
    const eq = isEqual(firstDt as string, secondDt as string);
    return eq;
  }

  get shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('lgffFundingAmountRequested')?.touched ?? false) &&
      this.projectEditForm?.hasError('lgffFundingAmountRequestedGreaterThanEstimatedAmount')
    );
  }

  get shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage(): boolean {
    return (
      (this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.touched ?? false) &&
      this.projectEditForm?.hasError('fundingFromOtherGrantProgramsGreaterThanEstimatedAmount')
    );
  }

  get shouldShowcombinedFundingVsEstimatedAmountValidationMessage(): boolean {
    return this.projectEditForm?.hasError('combinedFundingGreaterThanEstimatedAmount');
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return (
      baseHasError ||
      this.shouldShowcombinedFundingVsEstimatedAmountValidationMessage ||
      this.shouldShowlgffFundingAmountRequestedVsEstimatedAmountValidationMessage ||
      this.shouldShowfundingFromOtherGrantProgramsVsEstimatedAmountValidationMessage
    );
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  jumpToCallout(fieldTemplate: ElementRef) {
    if (fieldTemplate) {
      const fieldElement = fieldTemplate.nativeElement;
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        console.error('Cannot find linked field: ' + fieldTemplate.nativeElement);
      }
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedInNonBlankOrErrorField = true;
    }

    if (isBlurAway) {
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');

        if (UiDef.nameCtr === this.CurrentPageDef.anticipatedEndDate.nameCtr) {
          if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedStartDate)) {
            CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedStartDate, true, 'crossFocusOut');
          }
        } else if (UiDef.nameCtr === this.CurrentPageDef.anticipatedStartDate.nameCtr) {
          if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedEndDate)) {
            CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedEndDate, true, 'crossFocusOut');
          }
        }
      }, 400);
    }
  }

  public hasProjectFormAnyError(submitted: boolean): boolean {
    this.submitClicked = this.submitClicked || submitted;

    if (this.areThereOtherSourcesOfFunding) {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.enable();
    } else {
      this.projectEditForm.get('fundingFromOtherGrantPrograms')?.disable();
    }

    return !this.projectEditForm.valid;
  }

  private _getDateForDatePicker(value: string | undefined): string | undefined {
    if (!value) return undefined;
    const date = new Date(value);
    if (date.toString() === 'Invalid Date') return undefined;
    return format(date, 'yyyy-MM-dd');
  }

  private _lgffFundingAmountRequestedVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost =
      typeof control.get('estimatedTotalCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value)
        : control.get('estimatedTotalCost')?.value;
    const lgffFundingAmountRequested =
      typeof control.get('lgffFundingAmountRequested')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('lgffFundingAmountRequested')?.value)
        : control.get('lgffFundingAmountRequested')?.value;
    if (lgffFundingAmountRequested > estimatedTotalCost) {
      return { lgffFundingAmountRequestedGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  private _fundingFromOtherGrantProgramsVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost =
      typeof control.get('estimatedTotalCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value)
        : control.get('estimatedTotalCost')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (fundingFromOtherGrantPrograms > estimatedTotalCost) {
      return { fundingFromOtherGrantProgramsGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  private _combinedFundingVsEstimatedAmountValidator(control: AbstractControl): ValidationErrors | null {
    const estimatedTotalCost =
      typeof control.get('estimatedTotalCost')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('estimatedTotalCost')?.value)
        : control.get('estimatedTotalCost')?.value;
    const lgffFundingAmountRequested =
      typeof control.get('lgffFundingAmountRequested')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('lgffFundingAmountRequested')?.value)
        : control.get('lgffFundingAmountRequested')?.value;
    const fundingFromOtherGrantPrograms =
      typeof control.get('fundingFromOtherGrantPrograms')?.value == 'string'
        ? CommUtilsService.parseInputInt(control.get('fundingFromOtherGrantPrograms')?.value)
        : control.get('fundingFromOtherGrantPrograms')?.value;
    if (lgffFundingAmountRequested + fundingFromOtherGrantPrograms > estimatedTotalCost) {
      return { combinedFundingGreaterThanEstimatedAmount: true };
    }

    return null;
  }

  private _watchForAmountChange(
    estimatedTotalCost: number | undefined,
    lgffFundingAmountRequested: number | undefined,
    fundingFromOtherGrantPrograms: number | undefined,
  ) {
    const estimatedTotalCostCtrl = this.projectEditForm.get('estimatedTotalCost');
    const lgffFundingAmountRequestedCtrl = this.projectEditForm.get('lgffFundingAmountRequested');
    const fundingFromOtherGrantProgramsCtrl = this.projectEditForm.get('fundingFromOtherGrantPrograms');
    const fundingFromMunicipalSourcesCtrl = this.projectEditForm.get('fundingFromMunicipalSources');
    if (
      !estimatedTotalCostCtrl ||
      !lgffFundingAmountRequestedCtrl ||
      !fundingFromOtherGrantProgramsCtrl ||
      !fundingFromMunicipalSourcesCtrl
    )
      return;
    combineLatest([
      estimatedTotalCostCtrl.valueChanges.pipe(startWith(estimatedTotalCost?.toString())),
      lgffFundingAmountRequestedCtrl.valueChanges.pipe(startWith(lgffFundingAmountRequested?.toString())),
      fundingFromOtherGrantProgramsCtrl.valueChanges.pipe(startWith(fundingFromOtherGrantPrograms?.toString())),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([estimatedTotalCostStr, lgffFundingAmountRequestedStr, fundingFromOtherGrantProgramsStr]) => {
        const estimatedTotalCost =
          typeof estimatedTotalCostStr == 'string'
            ? CommUtilsService.parseInputInt(estimatedTotalCostStr)
            : estimatedTotalCostStr;
        const lgffFundingAmountRequested =
          typeof lgffFundingAmountRequestedStr == 'string'
            ? CommUtilsService.parseInputInt(lgffFundingAmountRequestedStr)
            : lgffFundingAmountRequestedStr;
        const fundingFromOtherGrantPrograms =
          typeof fundingFromOtherGrantProgramsStr == 'string'
            ? CommUtilsService.parseInputInt(fundingFromOtherGrantProgramsStr)
            : fundingFromOtherGrantProgramsStr;
        const fundingFromMunicipalSources = this._calculateFundingFromMunicipalSources(
          estimatedTotalCost,
          lgffFundingAmountRequested,
          fundingFromOtherGrantPrograms,
        );
        // fundingFromMunicipalSourcesCtrl.setValue(formatNumberWithCommas(fundingFromMunicipalSources));
        fundingFromMunicipalSourcesCtrl.setValue(fundingFromMunicipalSources);
      });
  }

  private _calculateFundingFromMunicipalSources(
    estimatedTotalCost: number,
    lgffFundingAmountRequested: number,
    fundingFromOtherGrantPrograms: number,
  ): string | null {
    const fundingFromMunicipalSources = formatNumberWithCommas(
      estimatedTotalCost - (lgffFundingAmountRequested + fundingFromOtherGrantPrograms),
    );
    return fundingFromMunicipalSources;
  }
}
