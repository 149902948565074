import { SepoExtDto } from '@app-com/api/models';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { EditSepoSequence } from '@app-pot/features/sepo/edit-sepo/enum/edit-sepo-sequence.enum';

export class SetEditSepoStepperStatus {
  static readonly type = '[Edit SEPO] Set Edit SEPO Step';
  constructor(
    public editSepoStepperState: Partial<{
      [Item in EditSepoSequence]: FormStatusCodes;
    }>,
  ) {}
}

export class SetEditSepoButtonAction {
  static readonly type = '[Edit SEPO] Set Edit SEPO Button Action';
  constructor(public buttonAction: ['cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'] | undefined) {
    console.log('file: edit-sepo.action.ts:16 ~ SetEditSepoButtonAction ~ constructor ~ buttonAction:', buttonAction);
  }
}

export class SetEditSepoCurrentStep {
  static readonly type = '[Edit SEPO] Set Edit SEPO current Step';
  constructor(public editSepoCurrentStep: EditSepoSequence) {}
}

export class SetSEPOContact {
  static readonly type = '[Edit SEPO] Set contact';
  constructor(
    public payload?: Pick<
      SepoExtDto,
      'contactFirstName' | 'contactLastName' | 'contactEmailAddress' | 'contactPhoneNumber'
    >,
  ) {}
}

export class SetSEPO {
  static readonly type = '[Edit SEPO] Set SEPO';
  constructor(public payload?: SepoExtDto) {}
}

export class ResetSEPOState {
  static readonly type = '[Edit SEPO] Reset';
}
export class SetSEPOApplication {
  static readonly type = '[Edit SEPO] Set Edit SEPO Application';
  constructor(public payload: SepoExtDto) {}
}
export class AutosaveSEPOAssetManagementPlan {
  static readonly type = '[Edit SEPO] Autosave Edit SEPO Asset Management Plan';
  constructor(public payload?: SepoExtDto) {}
}

export class SetSEPOAssetManagementPlan {
  static readonly type = '[Edit SEPO] Set Edit SEPO Asset Management Plan';
  constructor(public payload?: Partial<SepoExtDto>) {}
}
export class SetSEPOExpenditureProjects {
  static readonly type = '[Edit SEPO] Set Edit SEPO Expenditure Projects';
  constructor(public payload?: Partial<SepoExtDto>) {}
}
export class SetSEPOStatementExpendituresAndMediaPlan {
  static readonly type = '[Edit SEPO] Set Edit SEPO Expenditures and Media Plan';
  constructor(public payload?: Partial<SepoExtDto>) {}
}

export class SetSEPOProjectOutcomes {
  static readonly type = '[Edit SEPO] Set Edit SEPO Project outcomes';
  constructor(public payload?: Partial<SepoExtDto>) {}
}

export class FetchSEPODetails {
  static readonly type = '[Edit SEPO] Fetch sepo details';
}
