<div>
  <div class="download-pdf-link">
    <app-download-draft-applications-list-pdf
      linkText="Draft applications report"
    ></app-download-draft-applications-list-pdf>
  </div>
  <div class="application-list-container">
    <goa-table id="table-container" width="100%" (_sort)="handleSort($event)">
      <thead>
        <tr>
          <th class="detail-icon-column"></th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="name">{{ 'name' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-15rem">
            <goa-table-sort-header name="primaryFunctionalCategoryTitle">
              {{ 'primaryFunctionalCategoryTitle' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="primaryOutcomeTitle">
              {{ 'primaryOutcomeTitle' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="createdAt" direction="desc">
              {{ 'createdAt' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">{{ 'actions' | res: pageId }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!filteredApplications || filteredApplications.length === 0">
          <td></td>
          <td colspan="5">
            <div class="font-italic pl-1rem">No results found in Drafts</div>
          </td>
        </tr>
        <ng-container *ngFor="let application of filteredApplications">
          <tr>
            <td>
              <goa-icon
                class="action-icon"
                [type]="application.detailsShown ? 'chevron-down' : 'chevron-forward'"
                (click)="toggleApplicationDetails(application.id)"
              ></goa-icon>
            </td>

            <td>{{ application.name }}</td>
            <td>{{ application.primaryFunctionalCategoryTitle }}</td>
            <td>{{ application.primaryOutcomeTitle }}</td>
            <td>{{ application.createdAt | date: 'MMM d, y' }}</td>
            <td>
              <goa-block alignment="center" gap="l">
                <goa-tooltip content="Edit" position="bottom">
                  <goa-icon
                    class="action-icon"
                    type="pencil"
                    size="medium"
                    (click)="onEditClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
                <goa-tooltip content="Summary" position="bottom">
                  <goa-icon
                    class="action-icon"
                    type="information-circle"
                    size="medium"
                    (click)="onAppInfoClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
                <div class="action-download-tooltip-container">
                  <goa-tooltip content="PDF" position="bottom">
                    <app-download-draft-application-pdf
                      class="action-icon"
                      [applicationId]="application.id"
                    ></app-download-draft-application-pdf
                  ></goa-tooltip>
                </div>
                <goa-tooltip content="Delete" position="bottom">
                  <goa-icon
                    class="action-icon"
                    type="trash"
                    size="medium"
                    (click)="onDeleteClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
              </goa-block>
            </td>
          </tr>
          <tr *ngIf="application.detailsShown" class="projects-container">
            <td></td>
            <td colspan="5">
              <ng-container *ngIf="!application.projects || application.projects.length === 0; else projectList">
                <div class="font-italic pl-1rem">No Projects found for expanded row.</div>
              </ng-container>
              <ng-template #projectList>
                <table>
                  <thead>
                    <tr>
                      <th>Project name</th>
                      <th>Primary capital asset</th>
                      <th>Additional capital asset</th>
                      <th class="numeric-column">LGFF funding requested</th>
                      <th>Anticipated start</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let project of application.projects">
                      <td>{{ project.name }}</td>
                      <td>{{ getCapitalAssetTypeTitle(project.capitalAssetTypeId) }}</td>
                      <td>
                        {{ getAdditionalCapitalAssetTypeTitle(project.additionalCapitalAssetTypeIds ?? []) }}
                      </td>
                      <td class="numeric-column">
                        {{ project.amountRequestedFromLGFF ?? 0 | safeDollar }}
                      </td>
                      <td>
                        {{ CommUtilsService.getDateStrMonDdYear(project.anticipatedStartDate ?? '') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </goa-table>
    <div style="height: 1rem"></div>
    <goa-block alignment="center" style="font-size: 18px">
      <goa-block mb="m" alignment="center">
        <span>Show</span>
        <goa-dropdown (_change)="handlePerPageChange($event)" [value]="perPageCount" width="8.5ch" relative="true">
          <goa-dropdown-item *ngFor="let size of pageSizeOptions" value="{{ size }}"></goa-dropdown-item>
        </goa-dropdown>
        <span style="width: 100px">
          of
          {{ draftApplicationsCount }} items</span
        >
      </goa-block>
      <goa-spacer hspacing="fill"></goa-spacer>
      <app-lgff-goa-table-pagination
        [totalItemCount]="draftApplicationsCount"
        [perPageCount]="perPageCount"
        [pageNumber]="currentPage"
        (pageChanged)="handlePageChange($event)"
      >
      </app-lgff-goa-table-pagination>
    </goa-block>
  </div>
</div>

<app-confirm-cancel-delete-draft-app
  [pageId]="'MODEL_CANCEL_DELETE_DRAFT_IN_LIST'"
  [titleParam]="appNameToDelete"
  [showBothOnRight]="true"
  [isOpen]="wantCancelDeleteDraftAppInList"
  (afterNoHandler)="noCancelDeleteDraftAppInListHandler()"
  (afterYesHandler)="yesDeleteDraftAppInListHandler()"
>
</app-confirm-cancel-delete-draft-app>
