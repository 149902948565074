/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateWorknoteDto } from '../models/create-worknote-dto';
import { CsvAllSepoDataExportDto } from '../models/csv-all-sepo-data-export-dto';
import { SepoAssetManagementPlanUpdateDto } from '../models/sepo-asset-management-plan-update-dto';
import { SepoDto } from '../models/sepo-dto';
import { SepoListDto } from '../models/sepo-list-dto';
import { SepoListFilter } from '../models/sepo-list-filter';
import { SepoProjectExpenditureDto } from '../models/sepo-project-expenditure-dto';
import { SepoProjectExpenditureUpdateDto } from '../models/sepo-project-expenditure-update-dto';
import { SepoProjectOutcomeDto } from '../models/sepo-project-outcome-dto';
import { SepoProjectOutcomeUpdateDto } from '../models/sepo-project-outcome-update-dto';
import { SepoReviewValidationRecordDto } from '../models/sepo-review-validation-record-dto';
import { WorknoteDto } from '../models/worknote-dto';

@Injectable({ providedIn: 'root' })
export class SepoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `count()` */
  static readonly CountPath = '/api/sepo/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.CountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_1()` */
  static readonly FindAll_1Path = '/api/sepo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.FindAll_1Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<Array<SepoListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoListDto>>): Array<SepoListDto> => r.body)
    );
  }

  /** Path part for operation `csvFindAllSepoData()` */
  static readonly CsvFindAllSepoDataPath = '/api/sepo/csv/find-all-sepo-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllSepoData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllSepoData$Response(
    params: {
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllSepoDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.CsvFindAllSepoDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllSepoDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllSepoData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllSepoData(
    params: {
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllSepoDataExportDto>> {
    return this.csvFindAllSepoData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllSepoDataExportDto>>): Array<CsvAllSepoDataExportDto> => r.body)
    );
  }

  /** Path part for operation `findOne_1()` */
  static readonly FindOne_1Path = '/api/sepo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.FindOne_1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `createSepos()` */
  static readonly CreateSeposPath = '/api/sepo/create-sepos/{programBudgetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSepos()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepos$Response(
    params: {
      programBudgetId: number;
      organizationId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<number>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.CreateSeposPath, 'post');
    if (params) {
      rb.path('programBudgetId', params.programBudgetId, {});
      rb.query('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<number>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSepos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepos(
    params: {
      programBudgetId: number;
      organizationId?: number;
    },
    context?: HttpContext
  ): Observable<Array<number>> {
    return this.createSepos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `updateProjectExpenditure_1()` */
  static readonly UpdateProjectExpenditure_1Path = '/api/sepo/sepo-project-expenditure/{projectExpenditureId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectExpenditure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectExpenditure$Response(
    params: {
      projectExpenditureId: number;
      body: SepoProjectExpenditureUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectExpenditureDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateProjectExpenditure_1Path, 'patch');
    if (params) {
      rb.path('projectExpenditureId', params.projectExpenditureId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectExpenditureDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectExpenditure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectExpenditure(
    params: {
      projectExpenditureId: number;
      body: SepoProjectExpenditureUpdateDto
    },
    context?: HttpContext
  ): Observable<SepoProjectExpenditureDto> {
    return this.updateProjectExpenditure$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectExpenditureDto>): SepoProjectExpenditureDto => r.body)
    );
  }

  /** Path part for operation `updateProjectOutcome_1()` */
  static readonly UpdateProjectOutcome_1Path = '/api/sepo/sepo-project-outcome/{projectOutcomeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectOutcome()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectOutcome$Response(
    params: {
      projectOutcomeId: number;
      body: SepoProjectOutcomeUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectOutcomeDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateProjectOutcome_1Path, 'patch');
    if (params) {
      rb.path('projectOutcomeId', params.projectOutcomeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectOutcomeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectOutcome$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectOutcome(
    params: {
      projectOutcomeId: number;
      body: SepoProjectOutcomeUpdateDto
    },
    context?: HttpContext
  ): Observable<SepoProjectOutcomeDto> {
    return this.updateProjectOutcome$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectOutcomeDto>): SepoProjectOutcomeDto => r.body)
    );
  }

  /** Path part for operation `updateAssetManagementPlan()` */
  static readonly UpdateAssetManagementPlanPath = '/api/sepo/sepo-asset-management-plan/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssetManagementPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAssetManagementPlan$Response(
    params: {
      id: number;
      body: SepoAssetManagementPlanUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateAssetManagementPlanPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssetManagementPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAssetManagementPlan(
    params: {
      id: number;
      body: SepoAssetManagementPlanUpdateDto
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.updateAssetManagementPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `updateCreditItems()` */
  static readonly UpdateCreditItemsPath = '/api/sepo/sepo-credit-items/{id}/{creditAmount}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCreditItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCreditItems$Response(
    params: {
      id: number;
      creditAmount: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateCreditItemsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('creditAmount', params.creditAmount, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCreditItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCreditItems(
    params: {
      id: number;
      creditAmount: number;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.updateCreditItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `startReview()` */
  static readonly StartReviewPath = '/api/sepo/start-review/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReview$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.StartReviewPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReview(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.startReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `recommend()` */
  static readonly RecommendPath = '/api/sepo/recommend/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recommend()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommend$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.RecommendPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recommend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommend(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.recommend$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `returnToOrganization()` */
  static readonly ReturnToOrganizationPath = '/api/sepo/return-to-organization/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnToOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToOrganization$Response(
    params: {
      id: number;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.ReturnToOrganizationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnToOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToOrganization(
    params: {
      id: number;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.returnToOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `returnToGrantAdvisor()` */
  static readonly ReturnToGrantAdvisorPath = '/api/sepo/return-to-grant-advisor/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnToGrantAdvisor()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToGrantAdvisor$Response(
    params: {
      id: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.ReturnToGrantAdvisorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnToGrantAdvisor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToGrantAdvisor(
    params: {
      id: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.returnToGrantAdvisor$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `updateAssignedTo()` */
  static readonly UpdateAssignedToPath = '/api/sepo/assigned-to/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo$Response(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateAssignedToPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.updateAssignedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `updateReviewValidationRecord()` */
  static readonly UpdateReviewValidationRecordPath = '/api/sepo/review-validation-record/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReviewValidationRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReviewValidationRecord$Response(
    params: {
      id: number;
      body: SepoReviewValidationRecordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.UpdateReviewValidationRecordPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReviewValidationRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReviewValidationRecord(
    params: {
      id: number;
      body: SepoReviewValidationRecordDto
    },
    context?: HttpContext
  ): Observable<SepoDto> {
    return this.updateReviewValidationRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoDto>): SepoDto => r.body)
    );
  }

  /** Path part for operation `createSepoWorknote()` */
  static readonly CreateSepoWorknotePath = '/api/sepo/worknote/{sepoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSepoWorknote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSepoWorknote$Response(
    params: {
      sepoId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.CreateSepoWorknotePath, 'post');
    if (params) {
      rb.path('sepoId', params.sepoId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSepoWorknote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSepoWorknote(
    params: {
      sepoId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.createSepoWorknote$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableSepoActions()` */
  static readonly GetUserAvailableSepoActionsPath = '/api/sepo/available-actions/{sepoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableSepoActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableSepoActions$Response(
    params: {
      sepoId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoService.GetUserAvailableSepoActionsPath, 'get');
    if (params) {
      rb.path('sepoId', params.sepoId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableSepoActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableSepoActions(
    params: {
      sepoId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableSepoActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

}
