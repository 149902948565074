import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageIds, UiFieldCtrDef } from '@app-com/constants/patterns';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { ExternalRoleDto } from '@app-com/api/models';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ResourcePipe } from '@app-com/pipes';

@Component({
  selector: 'app-edit-sepo-certify-submit',
  templateUrl: './edit-sepo-certify-submit.component.html',
  styleUrl: './edit-sepo-certify-submit.component.scss',
})
export class EditSepoCertifySubmitComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo;
  checkboxLabelText = '';
  certifySubmitForm: FormGroup;
  formValidationErrors: { [key: string]: string } = {};
  UiDefAdditionalDetails: UiFieldCtrDef;
  UiDefCertifySEPO: UiFieldCtrDef;
  UiDefNoCertifySEPO: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  organizations: ExternalRoleDto[] | undefined;
  singleOrganizationTitle: string;
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(CurrentContextState.getCurrentOrganizationId) currentOrgId$: Observable<number>;
  currentStep: EditSepoSequence | undefined;
  submitting = false;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  organizationTitle: string | undefined;
  certifyOptionCAOLabel: string;
  certifyOptionRepresentativeLabel: string;

  constructor(
    private authStateService: AuthStateService,
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    private res: ResourcePipe,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  ngOnInit(): void {
    this.sub.add(
      combineLatest([this.authStateService.getAuthenticatedUser(), this.currentOrgId$]).subscribe(([user, id]) => {
        if (user) {
          this.organizations = user.roles.map((role) => {
            return {
              id: role.id,
              name: role.name,
              title: role.title,
            };
          });
          this.organizationTitle = this.organizations.find((org) => org.id == id)?.title;
          this.certifyOptionCAOLabel = this.res.transform('certifySepoOption1', this.pageId, [
            user.name,
            this.organizationTitle ?? '',
          ]);
          this.certifyOptionRepresentativeLabel = this.res.transform('certifySepoOption2', this.pageId, [
            this.organizationTitle ?? '',
          ]);
        }
      }),
    );
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.Certification) {
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('submit') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
                [EditSepoSequence.Certification]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            //TODO: Call submit API here or open a dialog for confirmation then submit in that modal
            /*Demo code, to Be removed*/
            this.submitting = true;
            // this.timeoutIds.push(
            //   setTimeout(() => {
            //     this.submitting = false;
            //     this.router.navigate(['']);
            //   }, 3000),
            // );
            /*Demo code, to Be removed*/
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            // console.log('return to previous : edit-sepo/immediate-outcomes');
            this.router.navigate(['edit-sepo/immediate-outcomes']);
          }
        }
        // if (buttonAction && buttonAction.indexOf('next') >= 0) {
        //   console.log('Edit SEPO Certify Form value', this.certifySubmitForm.value);
        // }
      }),
    );
    this.createCertifySubmitForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustShadowDomCss();
    }, 300);
    this.certifySubmitForm?.controls['caoCertified']?.valueChanges.subscribe((caoCertified) => {
      if (
        caoCertified === 'checked' &&
        this.certifySubmitForm.errors &&
        this.certifySubmitForm.hasError('caoCertifiedNotChecked')
      ) {
        delete this.certifySubmitForm.errors['caoCertifiedNotChecked'];
      }
      this.cd.detectChanges();
    });
    this.certifySubmitForm?.controls['representativeCertified']?.valueChanges.subscribe((representativeCertified) => {
      if (
        representativeCertified === 'checked' &&
        this.certifySubmitForm.errors &&
        this.certifySubmitForm.hasError('representativeCertifiedNotChecked')
      ) {
        delete this.certifySubmitForm.errors['representativeCertifiedNotChecked'];
      }
      this.cd.detectChanges();
    });
    this.certifySubmitForm?.controls.additionalProjectExpenditureDetails.valueChanges.subscribe((change) => {
      if (change) {
        if (this.formValidationErrors['additionalProjectExpenditureDetails']) {
          delete this.formValidationErrors['additionalProjectExpenditureDetails'];
        }
      } else {
        if (!this.formValidationErrors['additionalProjectExpenditureDetails']) {
          this.formValidationErrors['additionalProjectExpenditureDetails'] = this.res.transform(
            'additionalProjectExpenditureTextAreaError',
            this.pageId,
          );
        }
      }
    });
  }

  public validateAdditionalProjectExpenditureDetails() {
    if (
      this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value &&
      this.certifySubmitForm.controls.additionalProjectExpenditureDetails.value === ''
    ) {
      this.certifySubmitForm.controls.additionalProjectExpenditureDetails.setErrors({
        additionalProjectExpenditureDetails: this.res.transform(
          'additionalProjectExpenditureTextAreaError',
          this.pageId,
        ),
      });
      this.certifySubmitForm.setErrors({
        additionalProjectExpenditureDetails: this.res.transform(
          'additionalProjectExpenditureTextAreaError',
          this.pageId,
        ),
      });
      if (!this.formValidationErrors['additionalProjectExpenditureDetails']) {
        this.formValidationErrors['additionalProjectExpenditureDetails'] = this.res.transform(
          'additionalProjectExpenditureTextAreaError',
          this.pageId,
        );
      }
    }
  }

  public hasSEPOCertified(answer: boolean) {
    if (answer === true) {
      this.certifySubmitForm.controls['sepoNotCertified'].setValue(false);
      this.setRadioCheckedState('sepoNotCertified', false);
      this.certifySubmitForm?.controls['representativeCertified']?.setValue('');
      this.certifySubmitForm.controls['sepoCertified'].setValue(true);
      this.setRadioCheckedState('sepoCertified', true);
      if (this.formValidationErrors['representativeCertifiedNotChecked']) {
        delete this.formValidationErrors['representativeCertifiedNotChecked'];
      }
    } else {
      this.certifySubmitForm.controls['sepoCertified'].setValue(false);
      this.setRadioCheckedState('sepoCertified', false);
      this.certifySubmitForm?.controls['caoCertified']?.setValue('');
      this.certifySubmitForm.controls['sepoNotCertified'].setValue(true);
      this.setRadioCheckedState('sepoNotCertified', true);
      if (this.formValidationErrors['caoCertifiedNotChecked']) {
        delete this.formValidationErrors['caoCertifiedNotChecked'];
      }
    }
    if (this.formValidationErrors['sepoCertifiedSelected']) {
      delete this.formValidationErrors['sepoCertifiedSelected'];
    }
    if (this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['sepoCertifiedSelected'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }
    //console.log('Form validation errors', this.formValidationErrors);
  }

  public haveAdditionalProjectsSelected(answer: boolean) {
    if (answer === true) {
      this.certifySubmitForm.controls['additionalProjectExpenditureNotSelected'].setValue(false);
      this.setRadioCheckedState('additionalProjectExpenditureNotSelected', false);
      //this.certifySubmitForm?.controls['representativeCertified']?.setValue('');
      this.certifySubmitForm.controls['additionalProjectExpenditureSelected'].setValue(true);
      this.setRadioCheckedState('additionalProjectExpenditureSelected', true);
    } else {
      this.certifySubmitForm.controls['additionalProjectExpenditureSelected'].setValue(false);
      this.setRadioCheckedState('additionalProjectExpenditureSelected', false);
      //this.certifySubmitForm?.controls['caoCertified']?.setValue('');
      this.certifySubmitForm.controls['additionalProjectExpenditureNotSelected'].setValue(true);
      this.setRadioCheckedState('additionalProjectExpenditureNotSelected', true);
      if (this.formValidationErrors['additionalProjectExpenditureDetails']) {
        delete this.formValidationErrors['additionalProjectExpenditureDetails'];
      }
    }
    if (this.formValidationErrors['additionalProjectExpenditureSelected']) {
      delete this.formValidationErrors['additionalProjectExpenditureSelected'];
    }
    if (this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['additionalProjectExpenditureSelected'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }

    //console.log('Form validation errors', this.formValidationErrors);
  }
  createCertifySubmitForm() {
    this.certifySubmitForm = this.formBuilder.group(
      {
        additionalProjectExpenditureSelected: [''],
        additionalProjectExpenditureNotSelected: [''],
        additionalProjectExpenditureDetails: [''],
        sepoCertified: [''],
        sepoNotCertified: [''],
        caoCertified: [''],
        representativeCertified: [''],
      },
      // {
      //   validators: this.checkCertifyFormValidity,
      // },
    );
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    if (this.certifySubmitForm) {
      this.certifySubmitForm?.markAllAsTouched();
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value == '' &&
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.value == ''
      ) {
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        this.certifySubmitForm.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        if (!this.formValidationErrors['additionalProjectExpenditureSelected']) {
          this.formValidationErrors['additionalProjectExpenditureSelected'] = this.res.transform(
            'additionalProjectExpenditureLabelError',
            this.pageId,
          );
        }
      }
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value &&
        this.certifySubmitForm.controls.additionalProjectExpenditureDetails.value === ''
      ) {
        this.certifySubmitForm.controls.additionalProjectExpenditureDetails.setErrors({
          additionalProjectExpenditureDetails: this.res.transform(
            'additionalProjectExpenditureTextAreaError',
            this.pageId,
          ),
        });
        this.certifySubmitForm.setErrors({
          additionalProjectExpenditureDetails: this.res.transform(
            'additionalProjectExpenditureTextAreaError',
            this.pageId,
          ),
        });
        if (!this.formValidationErrors['additionalProjectExpenditureDetails']) {
          this.formValidationErrors['additionalProjectExpenditureDetails'] = this.res.transform(
            'additionalProjectExpenditureTextAreaError',
            this.pageId,
          );
        }
      }
      if (
        this.certifySubmitForm.controls.sepoCertified.value == '' &&
        this.certifySubmitForm.controls.sepoNotCertified.value == ''
      ) {
        this.certifySubmitForm.controls.sepoCertified.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        this.certifySubmitForm.controls.sepoNotCertified.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        this.certifySubmitForm.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        if (!this.formValidationErrors['sepoCertifiedSelected']) {
          this.formValidationErrors['sepoCertifiedSelected'] = this.res.transform(
            'certificationLabelError',
            this.pageId,
          );
        }
      }
      if (
        this.certifySubmitForm.controls.sepoCertified.value &&
        this.certifySubmitForm.controls.caoCertified.value == ''
      ) {
        this.certifySubmitForm.controls.caoCertified.setErrors({ caoCertifiedNotChecked: true });
        this.certifySubmitForm.setErrors({ caoCertifiedNotChecked: true });
        if (!this.formValidationErrors['caoCertifiedNotChecked']) {
          this.formValidationErrors['caoCertifiedNotChecked'] = 'caoCertifiedNotChecked';
        }
      }
      if (
        this.certifySubmitForm.controls.sepoNotCertified.value &&
        this.certifySubmitForm.controls.representativeCertified.value == ''
      ) {
        this.certifySubmitForm.controls.representativeCertified.setErrors({ representativeCertifiedNotChecked: true });
        this.certifySubmitForm.setErrors({ representativeCertifiedNotChecked: true });
        if (!this.formValidationErrors['representativeCertifiedNotChecked']) {
          this.formValidationErrors['representativeCertifiedNotChecked'] = 'representativeCertifiedNotChecked';
        }
      }

      this.cd.detectChanges();
      this.jumpToField('edit-sepo-stepper');
      //console.log('Is form valid,', this.certifySubmitForm.valid, this.certifySubmitForm.errors);
      return this.certifySubmitForm?.valid;
    }

    return true;
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(controlName: string) {
    const control = this.certifySubmitForm?.controls[controlName];
    control.markAsTouched();
    if (controlName == 'sepoCertified' || controlName == 'sepoNotCertified') {
      const sepoCertified = this.certifySubmitForm?.controls.sepoCertified;
      const sepoNotCertified = this.certifySubmitForm?.controls['sepoNotCertified'];
      if (
        sepoCertified.touched &&
        sepoNotCertified.touched &&
        sepoCertified.value == '' &&
        sepoNotCertified.value == ''
      ) {
        sepoCertified.setErrors({ sepoCertifiedSelected: 'Select how you want to certify your SEPO.' });
        sepoNotCertified.setErrors({ sepoCertifiedSelected: 'Select how you want to certify your SEPO.' });
        if (!this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
          this.certifySubmitForm.setErrors({
            ...this.certifySubmitForm.errors,
            sepoCertifiedSelected: 'Select how you want to certify your SEPO.',
          });
        }
        // this.formValidationErrors['sepoCertifiedSelected'] = 'Select how you want to certify your SEPO.';
        this.formValidationErrors['sepoCertifiedSelected'] = this.res.transform('certificationLabelError', this.pageId);
      } else {
        delete this.formValidationErrors['sepoCertifiedSelected'];
        if (this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
          const formErrors = this.certifySubmitForm.errors;
          if (formErrors) {
            delete formErrors['sepoCertifiedSelected'];
            this.certifySubmitForm.setErrors(formErrors);
          }
        }
      }
    }
    this.cd.detectChanges();
  }
  onAdditionalProjectExpenditureFocusOut(controlName: string) {
    const control = this.certifySubmitForm?.controls[controlName];
    control.markAsTouched();
    if (
      controlName == 'additionalProjectExpenditureSelected' ||
      controlName == 'additionalProjectExpenditureNotSelected'
    ) {
      const additionalProjectExpenditureSelected =
        this.certifySubmitForm?.controls.additionalProjectExpenditureSelected;
      const additionalProjectExpenditureNotSelected =
        this.certifySubmitForm?.controls.additionalProjectExpenditureNotSelected;
      if (
        additionalProjectExpenditureSelected.touched &&
        additionalProjectExpenditureNotSelected.touched &&
        additionalProjectExpenditureSelected.value == '' &&
        additionalProjectExpenditureNotSelected.value == ''
      ) {
        additionalProjectExpenditureSelected.setErrors({
          additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
        });
        additionalProjectExpenditureNotSelected.setErrors({
          additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
        });
        if (!this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
          this.certifySubmitForm.setErrors({
            ...this.certifySubmitForm.errors,
            additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
          });
        }

        this.formValidationErrors['additionalProjectExpenditureSelected'] = this.res.transform(
          'additionalProjectExpenditureError',
          this.pageId,
        );
      } else {
        if (this.formValidationErrors['additionalProjectExpenditureSelected']) {
          delete this.formValidationErrors['additionalProjectExpenditureSelected'];
        }
        if (this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
          const formErrors = this.certifySubmitForm.errors;
          if (formErrors) {
            delete formErrors['additionalProjectExpenditureSelected'];
            this.certifySubmitForm.setErrors(formErrors);
          }
        }
      }
    }
    this.cd.detectChanges();
  }
  validatecaoCertifiedonFocusOut() {
    const caoCertified = this.certifySubmitForm?.controls['caoCertified'];
    const sepoCertified = this.certifySubmitForm?.controls['sepoCertified'];
    if (sepoCertified && sepoCertified.value && caoCertified && caoCertified.value == '') {
      caoCertified.setErrors({ caoCertifiedNotChecked: true });
      if (!this.certifySubmitForm.hasError('caoCertifiedNotChecked')) {
        this.certifySubmitForm.setErrors({ ...this.certifySubmitForm.errors, caoCertifiedNotChecked: true });
      }

      this.formValidationErrors['caoCertifiedNotChecked'] = 'caoCertifiedNotChecked';
    }
    if (sepoCertified && sepoCertified.value && caoCertified && caoCertified.value == 'checked') {
      caoCertified.setErrors(null);
      delete this.formValidationErrors['caoCertifiedNotChecked'];
      if (this.certifySubmitForm.hasError('caoCertifiedNotChecked')) {
        const formErrors = this.certifySubmitForm.errors;
        if (formErrors) {
          delete formErrors['caoCertifiedNotChecked'];
          this.certifySubmitForm.setErrors(formErrors);
        }
      }
    }
    //console.log('Form validation errors', this.formValidationErrors);
    this.cd.detectChanges();
  }

  validateRepresentativeCertifiedonFocusOut() {
    const representativeCertified = this.certifySubmitForm?.controls['representativeCertified'];
    const sepoNotCertified = this.certifySubmitForm?.controls['sepoNotCertified'];
    if (sepoNotCertified && sepoNotCertified.value && representativeCertified && representativeCertified.value == '') {
      representativeCertified.setErrors({ representativeCertifiedNotChecked: true });
      if (!this.certifySubmitForm.hasError('representativeCertifiedNotChecked')) {
        this.certifySubmitForm.setErrors({ ...this.certifySubmitForm.errors, representativeCertifiedNotChecked: true });
      }

      this.formValidationErrors['representativeCertifiedNotChecked'] = 'representativeCertifiedNotChecked';
    }
    if (
      sepoNotCertified &&
      sepoNotCertified.value &&
      representativeCertified &&
      representativeCertified.value == 'checked'
    ) {
      representativeCertified.setErrors(null);
      delete this.formValidationErrors['representativeCertifiedNotChecked'];
      if (this.certifySubmitForm.hasError('representativeCertifiedNotChecked')) {
        const formErrors = this.certifySubmitForm.errors;
        if (formErrors) {
          delete formErrors['representativeCertifiedNotChecked'];
          this.certifySubmitForm.setErrors(formErrors);
        }
      }
    }
    //console.log('Form validation errors', this.formValidationErrors);
    this.cd.detectChanges();
  }

  adjustShadowDomCss() {
    const options = document.querySelectorAll('goa-form-item.certify-expenditures goa-radio-item');
    options.forEach((option) => {
      if (option) {
        const label = option?.shadowRoot?.querySelector('div.goa-radio-container span.goa-radio-label');

        if (label) {
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          label['style']['display'] = 'none';
        }
      }
    });
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elem = document.getElementById(nameStr);
    if (elem && elem.hasAttribute('checked')) {
      const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
      if (checkedValue !== undefined) {
        elem.setAttribute('checked', checked ? 'true' : 'false');
      }
    }
  }

  get isSEPOCertified(): boolean | undefined {
    return this.certifySubmitForm?.controls['sepoCertified'].value;
  }

  get hasadditionalProjectExpenditureBeenSelected(): boolean | undefined {
    return this.certifySubmitForm?.controls.additionalProjectExpenditureSelected.value;
  }

  get isSEPORepresentativeCertified(): boolean | undefined {
    return this.certifySubmitForm?.controls['sepoNotCertified'].value;
  }

  get showErrorFieldsCallout(): boolean {
    let hasError = false;
    // Object.keys(this.certifySubmitForm.controls).forEach((control) => {
    //   if (this.certifySubmitForm.get(control)?.errors) {
    //     hasError = true;
    //   }
    // });
    Object.keys(this.formValidationErrors).forEach((error) => {
      if (error) {
        hasError = true;
      }
    });

    return hasError;
  }
}
