import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
  SetSEPOStatementExpendituresAndMediaPlan,
  FetchSEPODetails,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import {
  CashFlowUpdateStatusType,
  ProjectStatusType,
  SepoExtDto,
  SepoProjectExpenditureDto,
} from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes';
import { ApplicationExtService, SepoExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { switchMap, delay, tap } from 'rxjs/operators';
import { LoadProjectStatus } from '@app-pot/store/actions/lookup-value.action';
import { LookupStringValue, LookupValueState } from '@app-com/state/lookup-value.state';
import { EditSepoProjectExpendituresModalComponent } from '@app-com/components/edit-sepo-project-expenditures-modal/edit-sepo-project-expenditures-modal.component';

@Component({
  selector: 'app-edit-sepo-statement-expenditures',
  templateUrl: './edit-sepo-statement-expenditures.component.html',
  styleUrl: './edit-sepo-statement-expenditures.component.scss',
})
export class EditSepoStatementExpendituresComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getSepoDetails) currentSepo$: Observable<SepoExtDto>;
  currentStep: EditSepoSequence | undefined;
  currentSepo: SepoExtDto = {} as SepoExtDto;
  CommUtilsService = CommUtilsService;
  sepoExpendituresData: SepoProjectExpenditureDto[] = [];

  @Select(LookupValueState.getProjectStatus) projectStatuses$: Observable<LookupStringValue[]>;

  @ViewChild(EditSepoProjectExpendituresModalComponent)
  editSepoProjectModalComponent: EditSepoProjectExpendituresModalComponent;

  totalLgffFundingAvailable: number = 0;
  totalLgffFundingApplied: number = 0;
  totalLgffFutureFundingAvailable: number = 0;
  parentColumnIndent = 3;

  mediaCommunicationForm: FormGroup;
  errors: { [key: string]: string } = {};
  editProjectPencilClicked: boolean;
  openSepoProjectUpdateModal = false;
  isProjectBeingEditedValid: boolean;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  projectToUpdate: SepoProjectExpenditureDto;
  organizationId: number;

  invalidProjectIds: Array<{ id: number; idTxt: string }> = [];
  errorExpenditureIds: Array<number> = [];
  expendituresWithCashflowLabel = '';
  hasPendingCashflows = false;
  isNextClicked = false;
  NoOfInvalidProjectsToShow = 5;
  minInvalidProjectsToShow = 5;
  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private sepoService: SepoExtService,
    private cashflowService: ApplicationExtService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.isNextClicked = false;
  }

  ngOnInit(): void {
    this._prepareForm();
    this.getSepoExpenditures();
    this.store.dispatch(new LoadProjectStatus());
    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
    this.store.dispatch(
      new SetEditSepoStepperStatus({
        [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
        [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
        [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
      }),
    );

    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.StatementExpenditures) {
          console.log(
            'file: edit-sepo-statement-expenditures.component.ts:45 ~ EditSepoStatementExpendituresComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          this.isNextClicked = true;
          if (this.validateOnNext()) {
            CommUtilsService.scrollToTop();
            this.validateProjectStatusandNavigate();
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/asset-management-and-funds');
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }

        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          this.autosave();
          this.router.navigate(['/view-sepo']);
        }
      }),
    );

    this.timeoutIds.push(
      setTimeout(() => {
        this.checkIfApplicationHasPendingCashflows();
      }, 500),
    );
  }

  private _prepareForm() {
    this.mediaCommunicationForm = this.formBuilder.group({
      hasMediaCommunication: [''],
      hasMediaCommunicationNo: [''],
      projectDetails: [''],
    });
  }

  getSepoExpenditures() {
    this.currentSepo$?.subscribe({
      next: (value) => {
        this.sepoExpendituresData = value.sepoProjectExpenditures;
        this.currentSepo = value;
        this.totalLgffFundingAvailable = value.totalFundingAvailable;
        this.totalLgffFundingApplied = value.totalFundingApplied;
        this.totalLgffFutureFundingAvailable = value.totalFutureFundingAvailable;
        this.mediaCommunicationForm.patchValue({
          hasMediaCommunication: value.mediaCommunicationsPlan ? true : false,
          hasMediaCommunicationNo: value?.mediaCommunicationsPlan == false ? true : false,
        });

        if (value.mediaCommunicationsPlan == false) {
          this.setRadioCheckedState('hasMediaCommunicationNo', true);
        } else if (value.mediaCommunicationsPlan == true) {
          this.setRadioCheckedState('hasMediaCommunication', true);
        }

        if (value?.mediaCommunicationsPlanDetails?.length > 0) {
          this.mediaCommunicationForm.patchValue({
            projectDetails: value.mediaCommunicationsPlanDetails,
          });
        }
      },
    });
  }

  get getMediaCommunicationPlanYes() {
    return this.mediaCommunicationForm.get('hasMediaCommunication')?.value;
  }
  get getMediaCommunicationPlanNo() {
    return this.mediaCommunicationForm.get('hasMediaCommunicationNo')?.value;
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = Object.keys(this.errors).length > 0 ? true : false;
    return baseHasError;
  }

  get checkMediaCommunicationErrorCallout() {
    let baseHasError = false;

    this.checkMediaCommunicationError();
    baseHasError = Object.keys(this.errors).includes('hasMediaCommunication') ? true : false;

    return baseHasError;
  }

  get checkProjectDetailsErrorCallout() {
    let baseHasError = false;

    this.checkMediaCommunicationError();
    baseHasError = Object.keys(this.errors).includes('projectDetails') ? true : false;

    return baseHasError;
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
        if (checkedValue !== undefined) {
          elem.setAttribute('checked', checked ? 'true' : 'false');
        }
      }
    });
  }

  hasMediaCommunicationPlanClicked(answer: boolean) {
    if (answer === true) {
      this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].setValue(false);
      this.setRadioCheckedState('hasMediaCommunicationNo', false);
      this.mediaCommunicationForm.controls['hasMediaCommunication'].setValue(true);
      this.setRadioCheckedState('hasMediaCommunication', true);
    } else {
      this.mediaCommunicationForm.controls['hasMediaCommunication'].setValue(false);
      this.setRadioCheckedState('hasMediaCommunication', false);
      this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].setValue(true);
      this.setRadioCheckedState('hasMediaCommunicationNo', true);
    }

    Object.keys(this.mediaCommunicationForm.controls).forEach((control) => {
      delete this.errors[control];
      this.autosave();
    });
  }

  getErrorMessage(name: string): string {
    switch (name) {
      case 'hasMediaCommunication':
      case 'hasMediaCommunicationNo':
        if (
          (this.mediaCommunicationForm.get('hasMediaCommunication')?.value ?? false) == false &&
          (this.mediaCommunicationForm.get('hasMediaCommunicationNo')?.value ?? false) == false
        ) {
          return this.res.transform('mediaCommunicationFormError', this.pageId);
        }
        break;

      case 'projectDetails':
        if (
          this.mediaCommunicationForm.get('projectDetails')?.value == null ||
          ((this.mediaCommunicationForm.get('projectDetails')?.value ?? '') == '' &&
            (this.mediaCommunicationForm.get('projectDetails')?.value ?? '').trim()?.length == 0)
        ) {
          return this.res.transform('projectDetailsError', this.pageId);
        }
        break;
      default:
        return '';
    }
    return '';
  }

  onFocusOut(controlName: string) {
    if (this.getErrorMessage(controlName)) {
      this.errors[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errors[controlName];
      this.autosave();
    }
  }

  onFocusIn(controlName: string) {
    delete this.errors[controlName];
  }

  checkMediaCommunicationError() {
    Object.keys(this.mediaCommunicationForm.controls).forEach((control) => {
      if (this.getErrorMessage(control)) {
        this.errors[control] = this.getErrorMessage(control);
      } else {
        delete this.errors[control];
      }
    });

    if (this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].value) {
      this.errors = {};
    }
  }

  getExpenditureData() {
    const hasMediaCommunication = this.mediaCommunicationForm.controls['hasMediaCommunication'].value;
    const hasMediaCommunicationNo = this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].value;

    return {
      mediaCommunicationsPlan: hasMediaCommunication ? true : hasMediaCommunicationNo ? false : null,
      mediaCommunicationsPlanDetails: hasMediaCommunication
        ? this.mediaCommunicationForm.controls['projectDetails'].value
        : '',
      sepoProjectExpenditures: this.currentSepo.sepoProjectExpenditures,
    };
  }

  validateOnPrevious(): boolean {
    return true;
  }

  autosave() {
    this.store.dispatch(
      new SetSEPOStatementExpendituresAndMediaPlan(
        this.getExpenditureData() as Pick<SepoExtDto, 'mediaCommunicationsPlan' | 'mediaCommunicationsPlanDetails'>,
      ),
    );
  }

  validateOnNext(isSaveAndClose = false): boolean {
    this.invalidProjectIds = [];
    this.errorExpenditureIds = [];
    this.checkMediaCommunicationError();
    const additionalInfo = this.mediaCommunicationForm.controls['projectDetails'].value;

    if (!isSaveAndClose) {
      this.checkIsProjectStatusValid();
    }

    if (additionalInfo.length > 150) {
      return false;
    }

    if (Object.keys(this.errors).length == 0 && this.invalidProjectIds.length === 0) {
      this.autosave();
      return true;
    } else {
      setTimeout(() => {
        this.jumpToField('errors-callout');
      }, 200);
      return false;
    }
  }

  onEditProject(expenditureId: number) {
    this.store.dispatch(new SetEditSepoButtonAction(['edit']));
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    console.log('[SEPO Project Updates] On edit Project', expenditureId);
    this.editProjectPencilClicked = true;
    this.sub.add(
      this.sepoService
        .findOneProjectExpenditure({ organizationId: this.organizationId, projectExpenditureId: expenditureId })
        .subscribe({
          next: (value) => {
            this.projectToUpdate = value;
            console.log(this.projectToUpdate);
            this.openSepoProjectUpdateModal = true;
          },
        }),
    );
  }

  saveSepoProjectEditModal() {
    console.log('Save button clicked');

    const isValid = this.editSepoProjectModalComponent.validateValues();

    if (isValid) {
      const data = this.editSepoProjectModalComponent._getProjectUpdate();
      this.sepoService
        .updateProjectExpenditure({
          organizationId: this.organizationId,
          projectExpenditureId: this.projectToUpdate.id.toString(),
          body: data,
        })
        .pipe(
          delay(500),
          // Switch to the next action (Fetch SEPODetails)
          switchMap(() => {
            const dispatch = this.store.dispatch(new FetchSEPODetails());
            this.isProjectValid(isValid);
            this.cancelSepoProjectEditModal();
            return dispatch;
          }),
          // Add another delay before validating
          delay(500),
          tap(() => {
            if (this.isNextClicked) {
              this.validateOnNext();
            }
          }),
        )
        .subscribe({
          error: (err) => {
            console.error('Error during save or validation:', err);
          },
          complete: () => {
            console.log('Save and validation complete.');
          },
        });
    }
  }
  isProjectValid(e: boolean) {
    console.log('[PROJECT UPDATES]Is project valid', e);
    this.isProjectBeingEditedValid = e;
    if (this.isProjectBeingEditedValid) {
      this.store.dispatch(new SetEditSepoButtonAction(['empty']));
      this.openSepoProjectUpdateModal = false;
    } else {
      this.openSepoProjectUpdateModal = true;
    }
  }
  cancelSepoProjectEditModal() {
    this.openSepoProjectUpdateModal = false;
  }
  checkIsProjectStatusValid() {
    this.sepoExpendituresData.forEach((project) => {
      if (project.status === null) {
        this.invalidProjectIds.push({ id: project.id, idTxt: project.projectIdTxt });
        this.errorExpenditureIds.push(project.id);
      }
    });
  }
  onClickViewAddtionalItems(showAllProjects: boolean) {
    if (showAllProjects == true) {
      this.NoOfInvalidProjectsToShow = this.invalidProjectIds.length;
    } else if (showAllProjects == false) {
      this.NoOfInvalidProjectsToShow = this.minInvalidProjectsToShow;
    }
    setTimeout(() => {
      this.jumpToField('errors-callout');
    }, 100);
  }
  get showViewAdditionalItemsLink() {
    if (
      this.invalidProjectIds.length > this.minInvalidProjectsToShow &&
      this.NoOfInvalidProjectsToShow != this.invalidProjectIds.length
    ) {
      return true;
    }
    return false;
  }
  checkIfApplicationHasPendingCashflows() {
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    const sepoDetails = this.store.selectSnapshot(EditSepoState.getSepoDetails);

    if (organizationId && sepoDetails?.programYear) {
      this.cashflowService
        .findAllCashFlowUpdates({
          organizationId,
          skip: 0,
          body: {
            programYear: { eq: sepoDetails?.programYear },
            statuses: [
              CashFlowUpdateStatusType.InReview,
              CashFlowUpdateStatusType.Submitted,
              CashFlowUpdateStatusType.Draft,
              CashFlowUpdateStatusType.Returned,
            ],
          },
        })
        .subscribe({
          next: (cashflows) => {
            if (cashflows?.length > 0) {
              this.hasPendingCashflows = true;
              const projectUpdates = cashflows.flatMap((cfu) => cfu.applicationUpdatesRecord.projectUpdates || []);
              const projectIds = projectUpdates.map(({ projectIdTxt }) => projectIdTxt).join(', ');
              this.expendituresWithCashflowLabel = this.res.transform(
                projectIds?.length > 1 ? 'multipleProjectsCallout' : 'singleProjectCallout',
                this.pageId,
                [projectIds],
              );
            }
          },
        });
    }
  }

  scrollIntoView(fieldName: string | number) {
    const fieldElement = document.getElementById(fieldName.toString());
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }
  validateProjectStatusandNavigate() {
    const isProjectCompleted = this.sepoExpendituresData.some(({ status }) => status === ProjectStatusType.Completed);

    if (isProjectCompleted) {
      this.store.dispatch(
        new SetEditSepoStepperStatus({
          [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
          [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
          [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
        }),
      );
      this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
      this.router.navigate(['edit-sepo/immediate-outcomes']);
    } else {
      this.store.dispatch(
        new SetEditSepoStepperStatus({
          [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
          [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
          [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
          [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
        }),
      );
      this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.Certification));
      this.router.navigate(['edit-sepo/certify-and-submit']);
    }

    //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
    this.store.dispatch(new SetEditSepoButtonAction(['empty']));
  }
}
