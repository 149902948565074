import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutosaveStatus, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
  AutoSaveSetSEPOCertifySubmit,
  SetSEPOCertifySubmit,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageIds, UiFieldCtrDef } from '@app-com/constants/patterns';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { CashFlowUpdateStatusType, ExternalRoleDto, SepoCertifiedByRole, SepoExtDto } from '@app-com/api/models';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ResourcePipe } from '@app-com/pipes';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { ApplicationExtService } from '@app-com/api/services';

@Component({
  selector: 'app-edit-sepo-certify-submit',
  templateUrl: './edit-sepo-certify-submit.component.html',
  styleUrl: './edit-sepo-certify-submit.component.scss',
})
export class EditSepoCertifySubmitComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo;
  checkboxLabelText = '';
  certifySubmitForm: FormGroup;
  formValidationErrors: { [key: string]: string } = {};
  UiDefAdditionalDetails: UiFieldCtrDef;
  UiDefCertifySEPO: UiFieldCtrDef;
  UiDefNoCertifySEPO: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  organizations: ExternalRoleDto[] | undefined;
  singleOrganizationTitle: string;
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getSepoDetails) currentSepo$: Observable<SepoExtDto>;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(CurrentContextState.getCurrentOrganizationId) currentOrgId$: Observable<number>;
  @Select(EditSepoState.getAutosaveStatus) autoSaveStatus$: Observable<AutosaveStatus>;
  currentStep: EditSepoSequence | undefined;
  submitting = false;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  organizationId: number;
  organizationTitle: string | undefined;
  certifyOptionCAOLabel: string;
  certifyOptionRepresentativeLabel: string;
  programYear: string;
  hasPendingCashflows = false;
  expendituresWithCashflowLabel = '';
  doesApplicationHavePendingCashflow: boolean;

  constructor(
    private authStateService: AuthStateService,
    private cashflowService: ApplicationExtService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private res: ResourcePipe,
    private cd: ChangeDetectorRef,
    private snackbarService: SnackBarService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.Certification));
    this.store.dispatch(
      new SetEditSepoStepperStatus({
        [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
        [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
        [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
        [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
        [EditSepoSequence.Certification]: FormStatusCodes.NotStarted,
      }),
    );

    this.sub.add(
      combineLatest([this.authStateService.getAuthenticatedUser(), this.currentOrgId$]).subscribe(([user, id]) => {
        this.organizationId = id;
        if (user) {
          this.organizations = user.roles.map((role) => {
            return {
              id: role.id,
              name: role.name,
              title: role.title,
            };
          });
          this.organizationTitle = this.organizations.find((org) => org.id == id)?.title;
          this.certifyOptionCAOLabel = this.res.transform('certifySepoOption1', this.pageId, [
            user.name,
            this.organizationTitle ?? '',
          ]);
          this.certifyOptionRepresentativeLabel = this.res.transform('certifySepoOption2', this.pageId, [
            this.organizationTitle ?? '',
          ]);
        }
      }),
    );
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.Certification) {
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          this.autosave();
          this.router.navigate(['view-sepo']);
        }
        if (buttonAction && buttonAction.indexOf('submit') >= 0) {
          if (this.validateOnNext()) {
            this.checkIfApplicationHasPendingCashflows();
            if (this.doesApplicationHavePendingCashflow) {
              return;
            }

            this.store.dispatch(
              new SetSEPOCertifySubmit(
                this.certifySubmitForm.value.sepoCertified
                  ? SepoCertifiedByRole.Cao
                  : SepoCertifiedByRole.CaoRepresentative,
              ),
            );
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
                [EditSepoSequence.Certification]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.submitting = true;
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            const projectOutcomes = this.store.selectSnapshot(EditSepoState.getSepoDetails).sepoProjectOutcomes;
            if (projectOutcomes?.length > 0) {
              this.store.dispatch(
                new SetEditSepoStepperStatus({
                  [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                  [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                  [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                  [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
                }),
              );
              this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
              this.router.navigate(['edit-sepo/immediate-outcomes']);
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['immediate-outcomes'], { relativeTo: this.route.parent });
              });
            } else {
              this.store.dispatch(
                new SetEditSepoStepperStatus({
                  [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                  [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                  [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
                  [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
                }),
              );
              this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
              this.router.navigate(['edit-sepo/statement-of-expenditures']);
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['statement-of-expenditures'], { relativeTo: this.route.parent });
              });
            }

            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
          }
        }
        // if (buttonAction && buttonAction.indexOf('next') >= 0) {
        //   console.log('Edit SEPO Certify Form value', this.certifySubmitForm.value);
        // }
      }),
    );
    this.createCertifySubmitForm();
    this.checkIfApplicationHasPendingCashflows();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustShadowDomCss();
    }, 300);
    this.sub.add(
      this.currentSepo$?.subscribe({
        next: (sepo) => {
          //console.log('Fetched SEPO', sepo);
          this.programYear = sepo.programYear;
          if (sepo.hasProjectExpendituresNotApproved) {
            this.certifySubmitForm?.patchValue({
              additionalProjectExpenditureSelected: true,
              additionalProjectExpenditureNotSelected: false,
              additionalProjectExpenditureDetails: sepo.additionalDetails,
            });
            this.setRadioCheckedState('additionalProjectExpenditureNotSelected', false);
            this.setRadioCheckedState('additionalProjectExpenditureSelected', true);
          } else if (sepo.hasProjectExpendituresNotApproved !== null && !sepo.hasProjectExpendituresNotApproved) {
            this.certifySubmitForm?.patchValue({
              additionalProjectExpenditureSelected: false,
              additionalProjectExpenditureNotSelected: true,
              unreportedProjectExpenditureDetails: sepo.additionalDetails,
            });
            this.setRadioCheckedState('additionalProjectExpenditureNotSelected', true);
            this.setRadioCheckedState('additionalProjectExpenditureSelected', false);
          }
        },
      }),
    );
    this.certifySubmitForm?.controls['caoCertified']?.valueChanges.subscribe((caoCertified) => {
      if (
        caoCertified === 'checked' &&
        this.certifySubmitForm.errors &&
        this.certifySubmitForm.hasError('caoCertifiedNotChecked')
      ) {
        delete this.certifySubmitForm.errors['caoCertifiedNotChecked'];
      }
      this.cd.detectChanges();
    });
    this.certifySubmitForm?.controls['representativeCertified']?.valueChanges.subscribe((representativeCertified) => {
      if (
        representativeCertified === 'checked' &&
        this.certifySubmitForm.errors &&
        this.certifySubmitForm.hasError('representativeCertifiedNotChecked')
      ) {
        delete this.certifySubmitForm.errors['representativeCertifiedNotChecked'];
      }
      this.cd.detectChanges();
    });
    this.certifySubmitForm?.controls.additionalProjectExpenditureDetails.valueChanges.subscribe((change) => {
      if (change) {
        if (this.formValidationErrors['additionalProjectExpenditureDetails']) {
          delete this.formValidationErrors['additionalProjectExpenditureDetails'];
        }
      }
    });
    this.certifySubmitForm?.controls.unreportedProjectExpenditureDetails.valueChanges.subscribe((change) => {
      if (change) {
        if (this.formValidationErrors['unreportedProjectExpenditureDetails']) {
          delete this.formValidationErrors['unreportedProjectExpenditureDetails'];
        }
      } else {
        if (!this.formValidationErrors['unreportedProjectExpenditureDetails']) {
          this.formValidationErrors['unreportedProjectExpenditureDetails'] = this.res.transform(
            'unreportedProjectExpenditureLabelError',
            this.pageId,
          );
        }
      }
    });
  }

  public validateAdditionalProjectExpenditureDetails() {
    if (
      this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value &&
      this.certifySubmitForm.controls.additionalProjectExpenditureDetails.value?.length > 150
    ) {
      const errorMsg = this.res.transform('informationTooLongError', this.pageId);
      this.certifySubmitForm.controls.additionalProjectExpenditureDetails.setErrors({
        additionalProjectExpenditureDetails: errorMsg,
      });
      this.certifySubmitForm.setErrors({
        additionalProjectExpenditureDetails: errorMsg,
      });
      if (!this.formValidationErrors['additionalProjectExpenditureDetails']) {
        this.formValidationErrors['additionalProjectExpenditureDetails'] = errorMsg;
      }
      return;
    } else {
      this.autosave();
    }
  }

  private checkIfApplicationHasPendingCashflows() {
    this.cashflowService
      .findAllCashFlowUpdates({
        organizationId: this.organizationId,
        skip: 0,
        body: {
          programYear: { eq: this.programYear },
          statuses: [
            CashFlowUpdateStatusType.InReview,
            CashFlowUpdateStatusType.Submitted,
            CashFlowUpdateStatusType.Draft,
            CashFlowUpdateStatusType.Returned,
          ],
        },
      })
      .subscribe({
        next: (cashflows) => {
          if (cashflows?.length > 0) {
            this.doesApplicationHavePendingCashflow = true;
            this.hasPendingCashflows = true;
            const projectUpdates = cashflows.flatMap((cfu) => cfu.applicationUpdatesRecord.projectUpdates || []);
            const projectIds = projectUpdates.map(({ projectIdTxt }) => projectIdTxt).join(', ');
            this.expendituresWithCashflowLabel = this.res.transform(
              projectIds?.length > 1 ? 'multipleProjectsCertifySubmitCallout' : 'singleProjectCertifySubmitCallout',
              this.pageId,
              [projectIds],
            );
          } else {
            this.doesApplicationHavePendingCashflow = false;
          }
        },
      });
  }

  public validateUnreportedProjectExpenditureDetails() {
    if (
      this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.value &&
      this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.value === ''
    ) {
      this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.setErrors({
        unreportedProjectExpenditureDetails: this.res.transform('unreportedProjectExpenditureLabelError', this.pageId),
      });
      this.certifySubmitForm.setErrors({
        unreportedProjectExpenditureDetails: this.res.transform('unreportedProjectExpenditureLabelError', this.pageId),
      });
      if (!this.formValidationErrors['unreportedProjectExpenditureDetails']) {
        this.formValidationErrors['unreportedProjectExpenditureDetails'] = this.res.transform(
          'unreportedProjectExpenditureLabelError',
          this.pageId,
        );
      }

      return;
    } else {
      this.autosave();
    }
  }

  autosave() {
    //console.log('Autosave invoked', this.certifySubmitForm.value);
    if (this.certifySubmitForm.value.additionalProjectExpenditureSelected) {
      this.store.dispatch(
        new AutoSaveSetSEPOCertifySubmit({
          hasProjectExpendituresNotApproved: true,
          additionalDetails: this.certifySubmitForm.value.additionalProjectExpenditureDetails,
        }),
      );
    } else {
      this.store.dispatch(
        new AutoSaveSetSEPOCertifySubmit({
          hasProjectExpendituresNotApproved: false,
          additionalDetails: this.certifySubmitForm.value.unreportedProjectExpenditureDetails,
        }),
      );
    }
  }

  public hasSEPOCertified(answer: boolean) {
    if (answer === true) {
      this.certifySubmitForm.controls['sepoNotCertified'].setValue(false);
      this.setRadioCheckedState('sepoNotCertified', false);
      this.certifySubmitForm?.controls['representativeCertified']?.setValue('');
      this.certifySubmitForm.controls['sepoCertified'].setValue(true);
      this.setRadioCheckedState('sepoCertified', true);
      if (this.formValidationErrors['representativeCertifiedNotChecked']) {
        delete this.formValidationErrors['representativeCertifiedNotChecked'];
      }
    } else {
      this.certifySubmitForm.controls['sepoCertified'].setValue(false);
      this.setRadioCheckedState('sepoCertified', false);
      this.certifySubmitForm?.controls['caoCertified']?.setValue('');
      this.certifySubmitForm.controls['sepoNotCertified'].setValue(true);
      this.setRadioCheckedState('sepoNotCertified', true);
      if (this.formValidationErrors['caoCertifiedNotChecked']) {
        delete this.formValidationErrors['caoCertifiedNotChecked'];
      }
    }
    if (this.formValidationErrors['sepoCertifiedSelected']) {
      delete this.formValidationErrors['sepoCertifiedSelected'];
    }
    if (this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['sepoCertifiedSelected'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }
    //console.log('[hasSEPOCertified] Form validation errors', this.formValidationErrors);
  }

  public haveAdditionalProjectsSelected(answer: boolean) {
    if (answer === true) {
      this.certifySubmitForm.controls['additionalProjectExpenditureNotSelected'].setValue(false);
      this.setRadioCheckedState('additionalProjectExpenditureNotSelected', false);

      this.certifySubmitForm.controls['additionalProjectExpenditureSelected'].setValue(true);
      this.setRadioCheckedState('additionalProjectExpenditureSelected', true);
    } else {
      this.certifySubmitForm.controls['additionalProjectExpenditureSelected'].setValue(false);
      this.setRadioCheckedState('additionalProjectExpenditureSelected', false);

      this.certifySubmitForm.controls['additionalProjectExpenditureNotSelected'].setValue(true);
      this.setRadioCheckedState('additionalProjectExpenditureNotSelected', true);
    }
    this.autosave();
    if (this.formValidationErrors['additionalProjectExpenditureSelected']) {
      delete this.formValidationErrors['additionalProjectExpenditureSelected'];
    }

    if (this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['additionalProjectExpenditureSelected'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }
    if (this.formValidationErrors['additionalProjectExpenditureDetails']) {
      delete this.formValidationErrors['additionalProjectExpenditureDetails'];
    }
    if (this.certifySubmitForm.hasError('additionalProjectExpenditureDetails')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['additionalProjectExpenditureDetails'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }
    if (this.formValidationErrors['unreportedProjectExpenditureDetails']) {
      delete this.formValidationErrors['unreportedProjectExpenditureDetails'];
    }
    if (this.certifySubmitForm.hasError('unreportedProjectExpenditureDetails')) {
      const formErrors = this.certifySubmitForm.errors;
      if (formErrors) {
        delete formErrors['unreportedProjectExpenditureDetails'];
        this.certifySubmitForm.setErrors(formErrors);
      }
    }

    //console.log('[haveAdditionalProjectsSelected]Form validation errors', this.formValidationErrors);
  }
  createCertifySubmitForm() {
    this.certifySubmitForm = this.formBuilder.group(
      {
        additionalProjectExpenditureSelected: [''],
        additionalProjectExpenditureNotSelected: [''],
        additionalProjectExpenditureDetails: [''],
        unreportedProjectExpenditureDetails: [''],
        sepoCertified: [''],
        sepoNotCertified: [''],
        caoCertified: [''],
        representativeCertified: [''],
      },
      // {
      //   validators: this.checkCertifyFormValidity,
      // },
    );
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    if (this.certifySubmitForm) {
      this.resetFormValidationBeforeNextValidation();
      this.certifySubmitForm?.markAllAsTouched();
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value == '' &&
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.value == ''
      ) {
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        this.certifySubmitForm.setErrors({
          additionalProjectExpenditureSelected: this.res.transform('additionalProjectExpenditureError', this.pageId),
        });
        if (!this.formValidationErrors['additionalProjectExpenditureSelected']) {
          this.formValidationErrors['additionalProjectExpenditureSelected'] = this.res.transform(
            'additionalProjectExpenditureLabelError',
            this.pageId,
            [this.programYear],
          );
        }
      }
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureSelected.value &&
        this.certifySubmitForm.controls.additionalProjectExpenditureDetails.value?.length > 150
      ) {
        const errorMsg = this.res.transform('informationTooLongError', this.pageId);
        this.certifySubmitForm.controls.additionalProjectExpenditureDetails.setErrors({
          additionalProjectExpenditureDetails: errorMsg,
        });
        this.certifySubmitForm.setErrors({
          additionalProjectExpenditureDetails: errorMsg,
        });
        if (!this.formValidationErrors['additionalProjectExpenditureDetails']) {
          this.formValidationErrors['additionalProjectExpenditureDetails'] = errorMsg;
        }
      }
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.value &&
        this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.value?.length > 150
      ) {
        const errorMsg = this.res.transform('informationTooLongError', this.pageId);
        this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.setErrors({
          unreportedProjectExpenditureDetails: errorMsg,
        });
        this.certifySubmitForm.setErrors({
          unreportedProjectExpenditureDetails: errorMsg,
        });
        if (!this.formValidationErrors['unreportedProjectExpenditureDetails']) {
          this.formValidationErrors['unreportedProjectExpenditureDetails'] = errorMsg;
        }
      }
      if (
        this.certifySubmitForm.controls.additionalProjectExpenditureNotSelected.value &&
        this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.value === ''
      ) {
        this.certifySubmitForm.controls.unreportedProjectExpenditureDetails.setErrors({
          unreportedProjectExpenditureDetails: this.res.transform(
            'unreportedProjectExpenditureLabelError',
            this.pageId,
          ),
        });
        this.certifySubmitForm.setErrors({
          unreportedProjectExpenditureDetails: this.res.transform(
            'unreportedProjectExpenditureLabelError',
            this.pageId,
          ),
        });
        if (!this.formValidationErrors['unreportedProjectExpenditureDetails']) {
          this.formValidationErrors['unreportedProjectExpenditureDetails'] = this.res.transform(
            'unreportedProjectExpenditureLabelError',
            this.pageId,
          );
        }
      }
      if (
        this.certifySubmitForm.controls.sepoCertified.value == '' &&
        this.certifySubmitForm.controls.sepoNotCertified.value == ''
      ) {
        this.certifySubmitForm.controls.sepoCertified.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        this.certifySubmitForm.controls.sepoNotCertified.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        this.certifySubmitForm.setErrors({
          sepoCertifiedSelected: this.res.transform('certificationLabelError', this.pageId),
        });
        if (!this.formValidationErrors['sepoCertifiedSelected']) {
          this.formValidationErrors['sepoCertifiedSelected'] = this.res.transform(
            'certificationLabelError',
            this.pageId,
          );
        }
      }

      if (
        this.certifySubmitForm.controls.sepoCertified.value &&
        this.certifySubmitForm.controls.caoCertified.value == ''
      ) {
        this.certifySubmitForm.controls.caoCertified.setErrors({ caoCertifiedNotChecked: true });
        this.certifySubmitForm.setErrors({ caoCertifiedNotChecked: true });
        if (!this.formValidationErrors['caoCertifiedNotChecked']) {
          this.formValidationErrors['caoCertifiedNotChecked'] = this.res.transform(
            'certifyCAOCheckboxError',
            this.pageId,
          );
        }
      }
      if (
        this.certifySubmitForm.controls.sepoNotCertified.value &&
        this.certifySubmitForm.controls.representativeCertified.value == ''
      ) {
        this.certifySubmitForm.controls.representativeCertified.setErrors({ representativeCertifiedNotChecked: true });
        this.certifySubmitForm.setErrors({ representativeCertifiedNotChecked: true });
        if (!this.formValidationErrors['representativeCertifiedNotChecked']) {
          this.formValidationErrors['representativeCertifiedNotChecked'] = this.res.transform(
            'certifyCAOCheckboxError',
            this.pageId,
          );
        }
      }

      this.cd.detectChanges();
      this.jumpToField('edit-sepo-stepper');
      // console.log(
      //   'Is form valid,',
      //   this.certifySubmitForm.valid,
      //   this.certifySubmitForm.errors,
      //   this.certifySubmitForm.value,
      //   this.formValidationErrors,
      // );

      return this.certifySubmitForm?.valid;
    }

    return true;
  }

  resetFormValidationBeforeNextValidation() {
    Object.keys(this.certifySubmitForm.controls).forEach((key) => {
      this.certifySubmitForm.controls[key].setErrors(null);
    });
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(controlName: string) {
    const control = this.certifySubmitForm?.controls[controlName];
    control.markAsTouched();
    if (controlName == 'sepoCertified' || controlName == 'sepoNotCertified') {
      const sepoCertified = this.certifySubmitForm?.controls.sepoCertified;
      const sepoNotCertified = this.certifySubmitForm?.controls['sepoNotCertified'];
      if (
        sepoCertified.touched &&
        sepoNotCertified.touched &&
        sepoCertified.value == '' &&
        sepoNotCertified.value == ''
      ) {
        sepoCertified.setErrors({ sepoCertifiedSelected: 'Select how you want to certify your SEPO.' });
        sepoNotCertified.setErrors({ sepoCertifiedSelected: 'Select how you want to certify your SEPO.' });
        if (!this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
          this.certifySubmitForm.setErrors({
            ...this.certifySubmitForm.errors,
            sepoCertifiedSelected: 'Select how you want to certify your SEPO.',
          });
        }
        // this.formValidationErrors['sepoCertifiedSelected'] = 'Select how you want to certify your SEPO.';
        this.formValidationErrors['sepoCertifiedSelected'] = this.res.transform('certificationLabelError', this.pageId);
      } else {
        delete this.formValidationErrors['sepoCertifiedSelected'];
        if (this.certifySubmitForm.hasError('sepoCertifiedSelected')) {
          const formErrors = this.certifySubmitForm.errors;
          if (formErrors) {
            delete formErrors['sepoCertifiedSelected'];
            this.certifySubmitForm.setErrors(formErrors);
          }
        }
      }
    }
    this.cd.detectChanges();
  }
  onAdditionalProjectExpenditureFocusOut(controlName: string) {
    const control = this.certifySubmitForm?.controls[controlName];
    control.markAsTouched();
    if (
      controlName == 'additionalProjectExpenditureSelected' ||
      controlName == 'additionalProjectExpenditureNotSelected'
    ) {
      const additionalProjectExpenditureSelected =
        this.certifySubmitForm?.controls.additionalProjectExpenditureSelected;
      const additionalProjectExpenditureNotSelected =
        this.certifySubmitForm?.controls.additionalProjectExpenditureNotSelected;
      if (
        additionalProjectExpenditureSelected.touched &&
        additionalProjectExpenditureNotSelected.touched &&
        additionalProjectExpenditureSelected.value == '' &&
        additionalProjectExpenditureNotSelected.value == ''
      ) {
        additionalProjectExpenditureSelected.setErrors({
          additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
        });
        additionalProjectExpenditureNotSelected.setErrors({
          additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
        });
        if (!this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
          this.certifySubmitForm.setErrors({
            ...this.certifySubmitForm.errors,
            additionalProjectExpenditureSelected: 'Select any additional project expenditure.',
          });
        }

        this.formValidationErrors['additionalProjectExpenditureSelected'] = this.res.transform(
          'additionalProjectExpenditureLabelError',
          this.pageId,
          [this.programYear],
        );
      } else {
        if (this.formValidationErrors['additionalProjectExpenditureSelected']) {
          delete this.formValidationErrors['additionalProjectExpenditureSelected'];
        }
        if (this.certifySubmitForm.hasError('additionalProjectExpenditureSelected')) {
          const formErrors = this.certifySubmitForm.errors;
          if (formErrors) {
            delete formErrors['additionalProjectExpenditureSelected'];
            this.certifySubmitForm.setErrors(formErrors);
          }
        }
      }
    }
    this.cd.detectChanges();
  }
  validatecaoCertifiedonFocusOut() {
    const caoCertified = this.certifySubmitForm?.controls['caoCertified'];
    const sepoCertified = this.certifySubmitForm?.controls['sepoCertified'];
    if (sepoCertified && sepoCertified.value && caoCertified && caoCertified.value == '') {
      caoCertified.setErrors({ caoCertifiedNotChecked: true });
      if (!this.certifySubmitForm.hasError('caoCertifiedNotChecked')) {
        this.certifySubmitForm.setErrors({ ...this.certifySubmitForm.errors, caoCertifiedNotChecked: true });
      }

      this.formValidationErrors['caoCertifiedNotChecked'] = this.res.transform('certifyCAOCheckboxError', this.pageId);
    }
    if (sepoCertified && sepoCertified.value && caoCertified && caoCertified.value == 'checked') {
      caoCertified.setErrors(null);
      delete this.formValidationErrors['caoCertifiedNotChecked'];
      if (this.certifySubmitForm.hasError('caoCertifiedNotChecked')) {
        const formErrors = this.certifySubmitForm.errors;
        if (formErrors) {
          delete formErrors['caoCertifiedNotChecked'];
          this.certifySubmitForm.setErrors(formErrors);
        }
      }
    }
    //console.log('[validatecaoCertifiedonFocusOut] Form validation errors', this.formValidationErrors);
    this.cd.detectChanges();
  }

  validateRepresentativeCertifiedonFocusOut() {
    const representativeCertified = this.certifySubmitForm?.controls['representativeCertified'];
    const sepoNotCertified = this.certifySubmitForm?.controls['sepoNotCertified'];
    if (sepoNotCertified && sepoNotCertified.value && representativeCertified && representativeCertified.value == '') {
      representativeCertified.setErrors({ representativeCertifiedNotChecked: true });
      if (!this.certifySubmitForm.hasError('representativeCertifiedNotChecked')) {
        this.certifySubmitForm.setErrors({ ...this.certifySubmitForm.errors, representativeCertifiedNotChecked: true });
      }

      this.formValidationErrors['representativeCertifiedNotChecked'] = this.res.transform(
        'certifyCAOCheckboxError',
        this.pageId,
      );
    }
    if (
      sepoNotCertified &&
      sepoNotCertified.value &&
      representativeCertified &&
      representativeCertified.value == 'checked'
    ) {
      representativeCertified.setErrors(null);
      delete this.formValidationErrors['representativeCertifiedNotChecked'];
      if (this.certifySubmitForm.hasError('representativeCertifiedNotChecked')) {
        const formErrors = this.certifySubmitForm.errors;
        if (formErrors) {
          delete formErrors['representativeCertifiedNotChecked'];
          this.certifySubmitForm.setErrors(formErrors);
        }
      }
    }
    //console.log('[validateRepresentativeCertifiedonFocusOut] Form validation errors', this.formValidationErrors);
    this.cd.detectChanges();
  }

  adjustShadowDomCss() {
    const options = document.querySelectorAll('goa-form-item.certify-expenditures goa-radio-item');
    options.forEach((option) => {
      if (option) {
        const label = option?.shadowRoot?.querySelector('div.goa-radio-container span.goa-radio-label');

        if (label) {
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          label['style']['display'] = 'none';
        }
      }
    });
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elem = document.getElementById(nameStr);
    if (elem && elem.hasAttribute('checked')) {
      const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
      if (checkedValue !== undefined) {
        elem.setAttribute('checked', checked ? 'true' : 'false');
      }
    }
  }

  get isSEPOCertified(): boolean | undefined {
    return this.certifySubmitForm?.controls['sepoCertified'].value;
  }

  get hasadditionalProjectExpenditureBeenSelected(): boolean | undefined {
    return this.certifySubmitForm?.controls.additionalProjectExpenditureSelected.value;
  }

  get hasadditionalProjectExpenditureNotBeenSelected(): boolean | undefined {
    return this.certifySubmitForm?.controls.additionalProjectExpenditureNotSelected.value;
  }

  get isSEPORepresentativeCertified(): boolean | undefined {
    return this.certifySubmitForm?.controls['sepoNotCertified'].value;
  }

  get showErrorFieldsCallout(): boolean {
    let hasError = false;
    // Object.keys(this.certifySubmitForm.controls).forEach((control) => {
    //   if (this.certifySubmitForm.get(control)?.errors) {
    //     hasError = true;
    //   }
    // });
    Object.keys(this.formValidationErrors).forEach((error) => {
      if (error) {
        hasError = true;
      }
    });

    return hasError;
  }
}
