import {
  ApplicationExtDto,
  CashFlowApplicationUpdatesRecord,
  CashFlowProjectUpdateDto,
  CashFlowUpdateExtDto,
} from '@app-com/api/models';
import { CashFlowFormSequence } from '@app-pot/features/cash-flow-updates/enum/cash-flow-form-sequence';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';

export class SetCashFlowUpdateStepperStatus {
  static readonly type = '[CashFlow Update] Set Cash Flow Form Stepper Staus';
  constructor(
    public cashFlowUpdateStepperStatus: Partial<{
      [Item in CashFlowFormSequence]: FormStatusCodes;
    }>,
  ) {}
}
export class SetCashFlowUpdateStep {
  static readonly type = '[CashFlow Update] Set Cash Flow Step';
  constructor(public cashFlowStep: CashFlowFormSequence) {}
}

export class CancelAndDeleteCashFlowUpdate {
  static readonly type = '[CashFlow Update] Cancel And Delete CashFlow Update';
}

export class SetCashFlowCta {
  static readonly type = '[CashFlow Update] Set Cash Flow CTA';
  constructor(public cta: ['cancel' | 'confirm' | 'save' | 'previous' | 'submit' | 'reset'] | undefined) {}
}

export class SetCashFlowValidationResult {
  static readonly type = '[CashFlow Update] Set Cash Flow Validation Result';
  constructor(public isValid: boolean | undefined) {}
}

export class SetCashFlowSubmit {
  static readonly type = '[CashFlow Update] Set Cash Flow Submit';
  constructor(public runSubmit: boolean | undefined) {}
}

export class ResetCashFlowValidationResult {
  static readonly type = '[CashFlow Update] Reset Cash Flow Validation Result';
}

export class SetCashFlowUpdateContact {
  static readonly type = '[CashFlow Update] Set Cash Flow Update contact';
  constructor(
    public payload?: Pick<
      CashFlowUpdateExtDto,
      'contactFirstName' | 'contactLastName' | 'contactEmailAddress' | 'contactPhoneNumber'
    >,
  ) {}
}

export class AutosaveCashFlowUpdateContact {
  static readonly type = '[CashFlow Update] Autosave CashFlow Update Contact';
  //   constructor(public contact: Partial<CashFlowUpdateExtDto>) {}
  constructor(
    public payload?: Pick<
      CashFlowUpdateExtDto,
      'contactFirstName' | 'contactLastName' | 'contactEmailAddress' | 'contactPhoneNumber'
    >,
  ) {}
}

export class AutosaveCashFlowUpdateApplicationFunding {
  static readonly type = '[CashFlow Update] Autosave CashFlow Update Application Funding';
  constructor(public payload?: Pick<CashFlowUpdateExtDto, 'applicationUpdatesRecord'>) {}
}

export class SetApplicationDetails {
  static readonly type = '[CashFlow Update] Set Application Details';
  constructor(public applicationDetails: ApplicationExtDto) {}
}
export class SetCashFlowApplicationFunding {
  static readonly type = '[CashFlow Update] Set Cash Flow Application Funding';
  constructor(public payload?: CashFlowApplicationUpdatesRecord) {}
}

export class SetCashFlowProjectUpdate {
  static readonly type = '[CashFlow Update] Set Cash Flow Project Updates';
  constructor(public payload?: CashFlowProjectUpdateDto) {}
}

export class ToggleAreThereOtherSourcesOfFunding {
  static readonly type = '[CashFlow Update] Toggle Are There Other Sources of Funding';
  constructor(public areThereOtherSourcesOfFunding?: boolean) {}
}

export class CashFlowProjectUpdatesErrorCallout {
  static readonly type = '[CashFlow Update] Error Callout';
  constructor(public callout?: boolean) {}
}
export class CashFlowReviewErrorCallout {
  static readonly type = '[CashFlow Update] Review Error Callout';
  constructor(public callout?: boolean) {}
}

export class ResetApiCallStatus {
  static readonly type = '[CashFlow Update] Reset Cash Flow Update Api call status';
  constructor(public calltype?: 'cancelAndDelete') {}
}

export class ResetCashFlowUpdate {
  static readonly type = '[CashFlow Update] Reset Cash Flow Update';
}
export class FetchCashFlowUpdateApplication {
  static readonly type = '[CashFlow Update] Fetch Cash Flow Update Application';
}
export class DeleteCashFlowUpdate {
  static readonly type = '[CashFlowUpdate]  Delete CashFlowUpdate';
  constructor(public cashFlowId: number) {}
}
