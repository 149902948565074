import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  ApplicationProjectType,
  CapitalAssetTypeDto,
  CashFlowProjectUpdateDto,
  CashFlowUpdateExtDto,
  ProjectDto,
} from '@app-com/api/models';
import { ApplicationExtService } from '@app-com/api/services';
import {
  LgffGoADisplayedColumnDef,
  LgffGoASummaryColumnDef,
} from '@app-com/components/lgff-goa-table/lgff-goa-table.component';
import { SafeDollarPipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import {
  CashFlowProjectUpdatesErrorCallout,
  SetCashFlowCta,
  SetCashFlowUpdateStep,
} from '@app-pot/store/actions/cash-flow-update.action';
import { LoadAllCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CapitalAssetTypeLV, LookupValueState } from '@app-com/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CashFlowFormSequence } from '../enum';
import { SetCurrentCashFlowUpdateStep } from '@app-pot/store/actions/current-context.action';

@Component({
  selector: 'app-project-updates',
  templateUrl: './project-updates.component.html',
  styleUrl: './project-updates.component.scss',
  providers: [SafeDollarPipe],
})
export class ProjectUpdatesComponent implements OnInit, OnDestroy {
  pageId = 'CASH_FLOW_PROJECT_UPDATES';
  CommUtilsService = CommUtilsService;
  sub = new Subscription();
  colDef: LgffGoADisplayedColumnDef[];
  summaryCol: LgffGoASummaryColumnDef[];
  displayPaginator = false;
  showSummaryColumns = true;
  openCashFlowProjectUpdateModal = false;
  organizationId: number;
  cashflowId: number;

  projectsWithOtherFunding: ProjectUpdatesRecordVm[] = [];
  tableData: ProjectUpdatesRecordVm[] = [];
  allCapitalAssetTypes: CapitalAssetTypeLV[] = [];
  applicationRecord: CashFlowUpdateExtDto = {} as CashFlowUpdateExtDto;
  cashFlowProjectUpdates: CashFlowProjectUpdateDto[];

  @Select(CashFlowUpdateState.getCashFlowUpdateProjectUpdates)
  cashFlowProjectUpdates$: Observable<CashFlowProjectUpdateDto[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes)
  allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;
  @Select(CashFlowUpdateState.getCashFlowApplicationReasonForFundingUpdate) reasonForFundingUpdate$: Observable<string>;
  @Select(CurrentContextState.getCurrentOrganizationId) organizationId$: Observable<number>;
  @Select(CashFlowUpdateState.getCashFlowUpdate) cashFlowUpdate$: Observable<CashFlowUpdateExtDto>;
  @Select(CashFlowUpdateState.getToggleAreThereOtherSourcesOfFunding)
  toggleAreThereOtherSourcesOfFunding$: Observable<boolean | undefined>;
  @Select(CashFlowUpdateState.getErrorCallout)
  getErrorCallout$: Observable<boolean | undefined>;
  errorCallout: boolean | undefined;

  @ViewChild('collapsibleContentTemplate', { static: true })
  collapsibleContentTemplate: TemplateRef<ProjectDto> | null = null;

  @ViewChild('expandCol', { static: true })
  expandCol: TemplateRef<ProjectDto> | null = null;

  @ViewChild('primaryCapitalAsset', { static: true })
  primaryCapitalAsset: TemplateRef<ProjectDto> | null = null;

  @ViewChild('additionalCapitalAsset', { static: true })
  additionalCapitalAsset: TemplateRef<ProjectDto> | null = null;

  @ViewChild('previousAcceptedLGFFfundingTemplate', { static: true })
  previousAcceptedLGFFfundingTemplate: TemplateRef<ProjectDto> | null = null;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<ProjectDto> | null = null;
  projectToUpdate?: CashFlowProjectUpdateDto;
  areThereOtherSourcesOfFunding: boolean;
  isProjectBeingEditedValid: boolean;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  editProjectPencilClicked: boolean;
  cumulativeAmountFromOtherGrantPrograms: number | undefined;
  fetchCashFlowUpdate: Observable<CashFlowUpdateExtDto>;

  totalUpdatedAmount = 0;
  totalPreviousAmount = 0;

  constructor(
    private store: Store,
    private apiService: ApplicationExtService,
    private currencyPipe: SafeDollarPipe,
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAllCapitalAssetTypes());
    this.store.dispatch(new SetCashFlowUpdateStep(CashFlowFormSequence.ProjectUpdates));
    this.store.dispatch(new SetCurrentCashFlowUpdateStep(CashFlowFormSequence.ProjectUpdates));
    this.initColDef();

    this.sub.add(
      this.allCapitalAssetTypes$.subscribe((data) => {
        this.allCapitalAssetTypes = data ?? [];
      }),
    );

    this.sub.add(
      this.organizationId$.subscribe((id) => {
        this.organizationId = id;
      }),
    );

    this.sub.add(
      this.cashFlowUpdate$.subscribe((cf) => {
        this.projectsWithOtherFunding = [];
        this.cashflowId = cf.id;
        this.cashFlowProjectUpdates = cf?.applicationUpdatesRecord?.projectUpdates ?? [];
        this.tableData = this.cashFlowProjectUpdates?.map((p) => new ProjectUpdatesRecordVm(p));
        this.applicationRecord = cf;

        this.areThereOtherSourcesOfFunding =
          cf.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Updated ??
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          cf.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Previous!;

        if (this.areThereOtherSourcesOfFunding) {
          // cumulativeAmountFromOtherGrantPrograms need to be evaluated only when there otherSourcesOfFunding is TRUE
          this.cumulativeAmountFromOtherGrantPrograms = this.tableData.reduce(
            (acc, data) =>
              acc + Number(data?.amountFromOtherGrantPrograms_Updated ?? data?.amountFromOtherGrantPrograms_Previous),
            0,
          );
        }

        this.cashFlowProjectUpdates.map((p) => {
          if (p?.amountFromOtherGrantPrograms_Previous && p?.amountFromOtherGrantPrograms_Previous > 0) {
            this.projectsWithOtherFunding.push(new ProjectUpdatesRecordVm(p));
          }
        });

        //console.log('[PROJECT UPDATES]Table data', this.tableData);
        if (
          cf.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Updated !== undefined &&
          !cf.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Updated &&
          cf.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Previous
        ) {
          this.tableData = this.tableData?.map((data) => {
            if (data.amountFromOtherGrantPrograms_Previous) {
              const newData = {
                ...data,
                amountFromOtherGrantPrograms_Updated: 0,
                totalAmountFromMunicipalSources_Updated:
                  data.totalAmountFromMunicipalSources_Previous! + data.amountFromOtherGrantPrograms_Previous,
              };
              return newData;
            } else {
              return data;
            }
          });
          this.cashFlowProjectUpdates = this.cashFlowProjectUpdates?.map((data) => {
            if (data.amountFromOtherGrantPrograms_Previous) {
              const newData: CashFlowProjectUpdateDto = {
                ...data,
                amountFromOtherGrantPrograms_Updated: 0,
                totalAmountFromMunicipalSources_Updated:
                  data.totalAmountFromMunicipalSources_Previous! + data.amountFromOtherGrantPrograms_Previous,
              };
              //this.store.dispatch(new SetCashFlowProjectUpdate(newData));
              return newData;
            } else {
              return data;
            }
          });
          this.cumulativeAmountFromOtherGrantPrograms = undefined;
        }
      }),
    );

    this.fetchCashFlowUpdate = this.apiService.findOneCashFlowUpdate({
      id: this.cashflowId,
      organizationId: this.organizationId,
    });

    this.timeoutIds.push(
      setTimeout(() => {
        //Fetch cashflow from api if store does not have cashflow projects
        if (!this.cashFlowProjectUpdates) {
          this.fetchCashFlowUpdate.subscribe({
            next: (value) => {
              this.projectsWithOtherFunding = [];
              this.applicationRecord = value;
              if (value.applicationUpdatesRecord.projectUpdates) {
                this.areThereOtherSourcesOfFunding =
                  this.applicationRecord.applicationUpdatesRecord.areThereOtherSourcesOfFunding_Updated ??
                  this.applicationRecord.applicationUpdatesRecord.areThereOtherSourcesOfFunding_Previous!;
                this.tableData = value.applicationUpdatesRecord.projectUpdates.map(
                  (p) => new ProjectUpdatesRecordVm(p),
                );
                if (this.areThereOtherSourcesOfFunding) {
                  // cumulativeAmountFromOtherGrantPrograms need to be evaluated only when there otherSourcesOfFunding is TRUE
                  this.cumulativeAmountFromOtherGrantPrograms = this.tableData.reduce(
                    (acc, data) =>
                      acc +
                      Number(data?.amountFromOtherGrantPrograms_Updated ?? data?.amountFromOtherGrantPrograms_Previous),
                    0,
                  );
                }

                value.applicationUpdatesRecord.projectUpdates.map((p) => {
                  if (p?.amountFromOtherGrantPrograms_Previous && p?.amountFromOtherGrantPrograms_Previous > 0) {
                    this.projectsWithOtherFunding.push(new ProjectUpdatesRecordVm(p));
                  }
                });
              }
            },
          });
        }
      }, 500),
    );

    this.sub.add(
      this.cashFlowProjectUpdates$.subscribe((projects) => {
        if (projects !== undefined) {
          console.log('[Project Updates]', this.cashFlowProjectUpdates);
          this.cashFlowProjectUpdates = projects;
          this.totalPreviousAmount = 0;
          this.totalUpdatedAmount = 0;
          if (this.areThereOtherSourcesOfFunding) {
            // cumulativeAmountFromOtherGrantPrograms need to be evaluated only when there otherSourcesOfFunding is TRUE
            this.cumulativeAmountFromOtherGrantPrograms = this.cashFlowProjectUpdates?.reduce(
              (acc, data) =>
                acc + Number(data?.amountFromOtherGrantPrograms_Updated ?? data?.amountFromOtherGrantPrograms_Previous),
              0,
            );
          }

          projects.forEach((element) => {
            if (element.amountRequestedFromLGFF_Previous) {
              this.totalPreviousAmount += element.amountRequestedFromLGFF_Previous;
            }

            if (element.amountRequestedFromLGFF_Updated) {
              this.totalUpdatedAmount += element.amountRequestedFromLGFF_Updated;
            } else {
              this.totalUpdatedAmount += element.amountRequestedFromLGFF_Previous as number;
            }

            if (element.isProjectWithdrawn) {
              this.totalUpdatedAmount -= element.amountRequestedFromLGFF_Previous as number;
            }
          });
          this.initColDef();

          if (this.cumulativeAmountFromOtherGrantPrograms == 0) {
            this.store.dispatch(new CashFlowProjectUpdatesErrorCallout(true));
          } else {
            this.store.dispatch(new CashFlowProjectUpdatesErrorCallout(false));
          }
        }
      }),
    );

    this.sub.add(
      this.toggleAreThereOtherSourcesOfFunding$.subscribe((val) => {
        if (val) {
          this.cumulativeAmountFromOtherGrantPrograms = this.tableData?.reduce(
            (acc, data) =>
              acc + Number(data?.amountFromOtherGrantPrograms_Updated ?? data?.amountFromOtherGrantPrograms_Previous),
            0,
          );
          if (this.cumulativeAmountFromOtherGrantPrograms == 0) {
            this.store.dispatch(new CashFlowProjectUpdatesErrorCallout(true));
          } else {
            this.store.dispatch(new CashFlowProjectUpdatesErrorCallout(false));
          }
        }
      }),
    );

    this.timeoutIds.push(
      setTimeout(() => {
        this.initColDef();
      }, 1000),
    );
  }

  onEditProject(project: ProjectUpdatesRecordVm) {
    // console.log('[Cashflow Project Updates] On edit Project', project);
    this.editProjectPencilClicked = true;
    //this.projectToUpdate = this.cashFlowProjectUpdates?.find((pr) => pr.projectId == project.projectId);
    this.projectToUpdate = project;
    this.openCashFlowProjectUpdateModal = true;
  }

  initColDef(): void {
    this.colDef = [
      {
        fieldName: 'projectId',
        displayName: 'expandCol',
        sortable: false,
        columnTemplate: this.expandCol,
        cellClass: 'w-56',
        headerClass: 'w-56',
      },
      {
        displayName: 'projectName',
        fieldName: 'projectName',
        sortable: false,
        headerClass: 'mw-189 align-top cashflow-project-updates-table-padding',
      },
      {
        displayName: 'projectNumber',
        fieldName: 'projectIdTxt',
        sortable: false,
        headerClass: 'w-156 align-top cashflow-project-updates-table-padding',
      },
      {
        displayName: 'primaryCapitalAsset',
        fieldName: 'capitalAssetTypeId',
        sortable: false,
        columnTemplate: this.primaryCapitalAsset,
        headerClass: 'w-154 overflow-scroll-col align-top cashflow-project-updates-table-padding',
        cellClass: 'w-154 overflow-scroll-col',
      },
      {
        displayName: 'additionalCapitalAsset',
        fieldName: 'additionalCapitalAsset',
        sortable: false,
        columnTemplate: this.additionalCapitalAsset,
        headerClass: 'w-154 overflow-scroll-col align-top cashflow-project-updates-table-padding',
        cellClass: 'w-154 overflow-scroll-col',
      },
      {
        displayName: 'previousAcceptedLGFFfunding',
        fieldName: 'amountRequestedFromLGFF_Previous',
        sortable: false,
        columnTemplate: this.previousAcceptedLGFFfundingTemplate,
        cellClass: 'goa-table-number-column w-164',
        headerClass: 'text-end w-164 align-top cashflow-project-updates-table-padding',
      },
      {
        displayName: 'previousStartDate',
        fieldName: 'formattedAnticipatedStartDatePrevious',
        sortable: false,
        headerClass: 'w-170 align-top cashflow-project-updates-table-padding',
        cellClass: 'w-170',
      },
      {
        fieldName: 'Actions',
        sortable: false,
        columnTemplate: this.actionsTemplate,
        headerClass: 'w-93 cashflow-project-updates-table-padding',
        cellClass: 'w-93',
      },
    ];

    this.summaryCol = [
      {
        summaryText: 'Previous accepted LGFF funding total',
        summaryTotal: this.currencyPipe.transform(this.totalPreviousAmount),
        summaryTextColDef: 5,
        summaryTotalColDef: 2,
        summaryTextClass: 'summary-text',
        summaryTotalClass: 'goa-table-number-column summary-total',
      },
      {
        summaryText: 'Updated LGFF funding total',
        summaryTotal:
          this.totalUpdatedAmount == this.totalPreviousAmount
            ? this.currencyPipe.transform(this.totalUpdatedAmount)
            : this.currencyPipe.transform(this.totalUpdatedAmount),
        summaryTextColDef: 5,
        summaryTotalColDef: 2,
        summaryTextClass: 'summary-text',
        summaryTotalClass: 'goa-table-number-column summary-total',
      },
      {
        summaryText: 'Cash flow update variance',
        summaryTotal:
          this.totalUpdatedAmount == this.totalPreviousAmount
            ? '-'
            : this.currencyPipe.transform(this.totalUpdatedAmount - this.totalPreviousAmount),
        summaryTextColDef: 5,
        summaryTotalColDef: 2,
        summaryTextClass: 'summary-text',
        summaryTotalClass: 'goa-table-number-column summary-total',
      },
    ];
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  getCapitalAssetTypeTitle(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';

    const title = this.allCapitalAssetTypes.find((item) => item.id === id)?.title ?? '' + id;
    return title;
  }

  getAdditionalCapitalAssetTypeTitle(capitalAssets: CapitalAssetTypeDto[]) {
    const titles = capitalAssets?.map((ca) => ca.title);
    return titles?.join(', ');
  }

  toggleProjectDetails(projectId: number) {
    this.tableData = this.tableData.map((project) => {
      // console.log('formmated value', project.formattedAnticipatedEndDatePrevious);
      if (project.projectId === projectId)
        return {
          ...project,
          detailsShown: !project.detailsShown,
          formattedAnticipatedStartDatePrevious: project.formattedAnticipatedStartDatePrevious,
          formattedAnticipatedStartDateUpdated: project.formattedAnticipatedStartDateUpdated,
          formattedAnticipatedEndDatePrevious: project.formattedAnticipatedEndDatePrevious,
          formattedAnticipatedEndDateUpdated: project.formattedAnticipatedEndDateUpdated,
        };
      return project;
    });
  }

  closeCashFlowProjectEditModal() {
    console.log('Save button clicked');
    this.store.dispatch(new SetCashFlowCta(['save']));
    this.store.dispatch(new SetCashFlowCta(['reset']));

    if (this.isProjectBeingEditedValid) {
      this.timeoutIds.push(
        setTimeout(() => {
          this.openCashFlowProjectUpdateModal = false;
        }, 1000),
      );
    } else {
      this.openCashFlowProjectUpdateModal = true;
    }
  }
  cancelCashFlowProjectEditModal() {
    this.openCashFlowProjectUpdateModal = false;
  }
  isProjectValid(e: boolean) {
    console.log('[PROJECT UPDATES]Is project valid', e);
    this.isProjectBeingEditedValid = e;
  }

  jumpToField(fieldName: string | number) {
    const fieldElement = document.getElementById(fieldName.toString());
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }
}

export class ProjectUpdatesRecordVm implements CashFlowProjectUpdateDto {
  additionalCapitalAsset?: CapitalAssetTypeDto | undefined;
  amountFromOtherGrantPrograms_Previous?: number | undefined;
  amountFromOtherGrantPrograms_Updated?: number | undefined;
  amountRequestedFromLGFF_Previous?: number | undefined;
  amountRequestedFromLGFF_Updated?: number | undefined;
  anticipatedEndDate_Previous?: string | undefined;
  anticipatedEndDate_Updated?: string | undefined;
  anticipatedStartDate_Previous?: string | undefined;
  anticipatedStartDate_Updated?: string | undefined;
  capitalAssetTypeId?: number | undefined;
  capitalAssetTypeTitle?: string | undefined;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  estimatedTotalCost_Previous?: number | undefined;
  estimatedTotalCost_Updated?: number | undefined;
  isProjectWithdrawn?: boolean | undefined;
  projectId: number;
  projectIdTxt?: string | undefined;
  projectName?: string | undefined;
  quantityNew_Previous?: number | undefined;
  quantityNew_Updated?: number | undefined;
  quantityUpgrade_Previous?: number | undefined;
  quantityUpgrade_Updated?: number | undefined;
  reasonForUpdate?: string | undefined;
  totalAmountFromMunicipalSources_Previous?: number | undefined;
  totalAmountFromMunicipalSources_Updated?: number | undefined;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  detailsShown: boolean;
  formattedAnticipatedStartDatePrevious?: string;
  formattedAnticipatedEndDatePrevious?: string;
  formattedAnticipatedStartDateUpdated?: string;
  formattedAnticipatedEndDateUpdated?: string;
  projectType: ApplicationProjectType;

  constructor(project: CashFlowProjectUpdateDto, shouldBeExpanded = false) {
    this.isProjectWithdrawn = project.isProjectWithdrawn;
    this.projectId = Number(project.projectId);
    this.projectIdTxt = project.projectIdTxt;
    this.detailsShown =
      shouldBeExpanded &&
      project.amountFromOtherGrantPrograms_Previous &&
      project.amountFromOtherGrantPrograms_Previous > 0
        ? true
        : false;
    this.projectName = project.projectName;
    this.capitalAssetTypeId = project.capitalAssetTypeId;
    this.capitalAssetTypeTitle = project.capitalAssetTypeTitle;
    this.additionalCapitalAsset = project.additionalCapitalAsset;
    this.estimatedTotalCost_Previous = project.estimatedTotalCost_Previous;
    this.amountRequestedFromLGFF_Previous = project.amountRequestedFromLGFF_Previous;
    this.amountFromOtherGrantPrograms_Previous = project.amountFromOtherGrantPrograms_Previous;
    this.totalAmountFromMunicipalSources_Previous = project.totalAmountFromMunicipalSources_Previous;
    this.quantityNew_Previous = project.quantityNew_Previous;
    this.quantityUpgrade_Previous = project.quantityUpgrade_Previous;

    this.estimatedTotalCost_Updated = project.estimatedTotalCost_Updated ?? project.estimatedTotalCost_Previous;
    this.amountRequestedFromLGFF_Updated =
      project.amountRequestedFromLGFF_Updated ?? project.amountRequestedFromLGFF_Previous;
    this.amountFromOtherGrantPrograms_Updated =
      project.amountFromOtherGrantPrograms_Updated ?? project.amountFromOtherGrantPrograms_Previous;
    this.totalAmountFromMunicipalSources_Updated =
      project.totalAmountFromMunicipalSources_Updated ?? project.totalAmountFromMunicipalSources_Previous;
    this.quantityNew_Updated = project.quantityNew_Updated ?? project.quantityNew_Previous;
    this.quantityUpgrade_Updated = project.quantityUpgrade_Updated ?? project.quantityUpgrade_Previous;

    this.anticipatedStartDate_Previous = project.anticipatedStartDate_Previous as string;
    this.anticipatedEndDate_Previous = project.anticipatedEndDate_Previous as string;
    this.anticipatedStartDate_Updated = (project.anticipatedStartDate_Updated ??
      project.anticipatedStartDate_Previous) as string;
    this.anticipatedEndDate_Updated = (project.anticipatedEndDate_Updated ??
      project.anticipatedEndDate_Previous) as string;
    this.reasonForUpdate = project.reasonForUpdate;
    this.projectType = project.projectType;

    this.formattedAnticipatedStartDatePrevious = CommUtilsService._getFormattedDate(
      this.anticipatedStartDate_Previous!,
    );
    this.formattedAnticipatedStartDateUpdated = CommUtilsService._getFormattedDate(this.anticipatedStartDate_Updated!);
    this.formattedAnticipatedEndDatePrevious = CommUtilsService._getFormattedDate(this.anticipatedEndDate_Previous!);
    this.formattedAnticipatedEndDateUpdated = CommUtilsService._getFormattedDate(this.anticipatedEndDate_Updated!);
  }
}
