import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { SafeDollarPipe } from '@app-com/pipes/safe-dollar/safe-dollar.pipe';
import { LgffGoaModalComponent } from './components/lgff-goa-modal/lgff-goa-modal.component';
import { LgffGoaTablePaginationComponent } from './components/lgff-goa-table-pagination/lgff-goa-table-pagination.component';
import { LgffGoaTableComponent } from './components/lgff-goa-table/lgff-goa-table.component';
import '@abgov/web-components';
import { AngularComponentsModule } from '@abgov/angular-components';
import { CashFlowUpdateViewComponent } from './components/cash-flow-update-view/cash-flow-update-view.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { LgffGoaNestedRowsTableComponent } from './components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.component';
import { SepoExpenditureTableComponent } from './components/sepo-expenditure-table/sepo-expenditure-table.component';
import { SepoOutcomesTableComponent } from './components/sepo-outcomes-table/sepo-outcomes-table.component';
import { EditSepoProjectExpendituresModalComponent } from './components/edit-sepo-project-expenditures-modal/edit-sepo-project-expenditures-modal.component';
import { EditSepoProjectOutcomesModalComponent } from './components/edit-sepo-project-outcomes-modal/edit-sepo-project-outcomes-modal.component';
import { CommaFormatNumberDirective } from './directives';

@NgModule({
  declarations: [
    ResourcePipe,
    LgffGoaTableComponent,
    LgffGoaTablePaginationComponent,
    LgffGoaModalComponent,
    CashFlowUpdateViewComponent,
    TimeoutComponent,
    LgffGoaNestedRowsTableComponent,
    SepoExpenditureTableComponent,
    SepoOutcomesTableComponent,
    EditSepoProjectExpendituresModalComponent,
    EditSepoProjectOutcomesModalComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, AngularComponentsModule, SafeDollarPipe, CommaFormatNumberDirective],
  exports: [
    ResourcePipe,
    LgffGoaTableComponent,
    LgffGoaTablePaginationComponent,
    LgffGoaModalComponent,
    CashFlowUpdateViewComponent,
    TimeoutComponent,
    LgffGoaNestedRowsTableComponent,
    SepoExpenditureTableComponent,
    SepoOutcomesTableComponent,
    EditSepoProjectExpendituresModalComponent,
    EditSepoProjectOutcomesModalComponent,
  ],
  providers: [ResourcePipe, SafeDollarPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectCommonModule {}
