/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { SepoExtDto } from '../models/sepo-ext-dto';
import { SepoExtUpdateDto } from '../models/sepo-ext-update-dto';
import { SepoFilter } from '../models/sepo-filter';
import { SepoListExtDto } from '../models/sepo-list-ext-dto';
import { SepoListFilter } from '../models/sepo-list-filter';
import { SepoProjectExpenditureDto } from '../models/sepo-project-expenditure-dto';
import { SepoProjectOutcomeDto } from '../models/sepo-project-outcome-dto';
import { SubmitSepoExtDto } from '../models/submit-sepo-ext-dto';

@Injectable({ providedIn: 'root' })
export class SepoExtService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `countByOrganization()` */
  static readonly CountByOrganizationPath = '/api/sepo-ext/count/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countByOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countByOrganization$Response(
    params: {
      organizationId: number;
      body: SepoFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.CountByOrganizationPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countByOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countByOrganization(
    params: {
      organizationId: number;
      body: SepoFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.countByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll()` */
  static readonly FindAllPath = '/api/sepo-ext/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoListExtDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.FindAllPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoListExtDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: SepoListFilter
    },
    context?: HttpContext
  ): Observable<Array<SepoListExtDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoListExtDto>>): Array<SepoListExtDto> => r.body)
    );
  }

  /** Path part for operation `findOne()` */
  static readonly FindOnePath = '/api/sepo-ext/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoExtDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.FindOnePath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoExtDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<SepoExtDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoExtDto>): SepoExtDto => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/api/sepo-ext/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(
    params: {
      organizationId: number;
      id: string;
      body: SepoExtUpdateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoExtDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.UpdatePath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoExtDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(
    params: {
      organizationId: number;
      id: string;
      body: SepoExtUpdateDto
    },
    context?: HttpContext
  ): Observable<SepoExtDto> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoExtDto>): SepoExtDto => r.body)
    );
  }

  /** Path part for operation `findOneProjectExpenditure()` */
  static readonly FindOneProjectExpenditurePath = '/api/sepo-ext/sepo-project-expenditure/{organizationId}/{projectExpenditureId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOneProjectExpenditure()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneProjectExpenditure$Response(
    params: {
      organizationId: number;
      projectExpenditureId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectExpenditureDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.FindOneProjectExpenditurePath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('projectExpenditureId', params.projectExpenditureId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectExpenditureDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOneProjectExpenditure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneProjectExpenditure(
    params: {
      organizationId: number;
      projectExpenditureId: number;
    },
    context?: HttpContext
  ): Observable<SepoProjectExpenditureDto> {
    return this.findOneProjectExpenditure$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectExpenditureDto>): SepoProjectExpenditureDto => r.body)
    );
  }

  /** Path part for operation `updateProjectExpenditure()` */
  static readonly UpdateProjectExpenditurePath = '/api/sepo-ext/sepo-project-expenditure/{organizationId}/{projectExpenditureId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectExpenditure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectExpenditure$Response(
    params: {
      organizationId: number;
      projectExpenditureId: string;
      body: SepoProjectExpenditureDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectExpenditureDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.UpdateProjectExpenditurePath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('projectExpenditureId', params.projectExpenditureId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectExpenditureDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectExpenditure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectExpenditure(
    params: {
      organizationId: number;
      projectExpenditureId: string;
      body: SepoProjectExpenditureDto
    },
    context?: HttpContext
  ): Observable<SepoProjectExpenditureDto> {
    return this.updateProjectExpenditure$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectExpenditureDto>): SepoProjectExpenditureDto => r.body)
    );
  }

  /** Path part for operation `findOneProjectOutcome()` */
  static readonly FindOneProjectOutcomePath = '/api/sepo-ext/sepo-project-outcome/{organizationId}/{projectOutcomeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOneProjectOutcome()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneProjectOutcome$Response(
    params: {
      organizationId: number;
      projectOutcomeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectOutcomeDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.FindOneProjectOutcomePath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('projectOutcomeId', params.projectOutcomeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectOutcomeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOneProjectOutcome$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneProjectOutcome(
    params: {
      organizationId: number;
      projectOutcomeId: number;
    },
    context?: HttpContext
  ): Observable<SepoProjectOutcomeDto> {
    return this.findOneProjectOutcome$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectOutcomeDto>): SepoProjectOutcomeDto => r.body)
    );
  }

  /** Path part for operation `updateProjectOutcome()` */
  static readonly UpdateProjectOutcomePath = '/api/sepo-ext/sepo-project-outcome/{organizationId}/{projectOutcomeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectOutcome()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectOutcome$Response(
    params: {
      organizationId: number;
      projectOutcomeId: string;
      body: SepoProjectOutcomeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoProjectOutcomeDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.UpdateProjectOutcomePath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('projectOutcomeId', params.projectOutcomeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoProjectOutcomeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectOutcome$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectOutcome(
    params: {
      organizationId: number;
      projectOutcomeId: string;
      body: SepoProjectOutcomeDto
    },
    context?: HttpContext
  ): Observable<SepoProjectOutcomeDto> {
    return this.updateProjectOutcome$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoProjectOutcomeDto>): SepoProjectOutcomeDto => r.body)
    );
  }

  /** Path part for operation `deleteDraftData()` */
  static readonly DeleteDraftDataPath = '/api/sepo-ext/delete-draft-data/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDraftData()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftData$Response(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.DeleteDraftDataPath, 'patch');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDraftData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftData(
    params: {
      organizationId: number;
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteDraftData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `submitCashFlow()` */
  static readonly SubmitCashFlowPath = '/api/sepo-ext/submit/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitCashFlow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitCashFlow$Response(
    params: {
      organizationId: number;
      id: number;
      body: SubmitSepoExtDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoExtDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoExtService.SubmitCashFlowPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoExtDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitCashFlow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitCashFlow(
    params: {
      organizationId: number;
      id: number;
      body: SubmitSepoExtDto
    },
    context?: HttpContext
  ): Observable<SepoExtDto> {
    return this.submitCashFlow$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoExtDto>): SepoExtDto => r.body)
    );
  }

}
