export const environment = {
  production: false,

  mapsApiKey: import.meta.env['NG_APP_MAPS_KEY'],
  totalProjectsLimit: import.meta.env['NG_APP_PROJECTS_LIMIT'],
  apiURL: import.meta.env['NG_APP_ROOT_URL'],
  ssoLoginKClkPath: import.meta.env['NG_APP_SSO_LOGIN_KC_PATH'],
  systemStartDate: import.meta.env['NG_APP_SYSTEM_START_DATE'],
  importFileSizeLimitText: '100KB',
  internalEnvironment: import.meta.env['NG_APP_INTERNAL_ENVIRONMENT'],
  internalVersion: import.meta.env['NG_APP_INTERNAL_VERSION'],
  jwtTokenExpiry: import.meta.env['NG_APP_JWT_TOKEN_EXPIRY_MINUTES'] || 4,
  idleElapsedTime: import.meta.env['NG_APP_IDLE_ELAPSED_TIME'] || 1,
};
