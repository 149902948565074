import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CashFlowReviewVM } from '../../../portal/src/app/features/cash-flow-updates/cash-flow-update-review/cash-flow-review-vm';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { CapitalAssetTypeDto, CashFlowUpdateExtDto, CashFlowUpdateStatusType } from '@app-com/api/models';
import { CashFlowFormSequence } from '../../../portal/src/app/features/cash-flow-updates/enum/index';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationExtService, LookupValueService, OrganizationService } from '@app-com/api/services';
import { CashFlowUpdateViewType } from './cash-flow-update-view-type';
@Component({
  selector: 'app-cash-flow-update-view',
  templateUrl: './cash-flow-update-view.component.html',
  styleUrls: ['./cash-flow-update-view.component.scss'],
})
export class CashFlowUpdateViewComponent implements OnInit {
  @Input() cfId: number;
  @Input() orgId: number;
  @Input() viewType: CashFlowUpdateViewType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() PrintContent: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() invalidReview: EventEmitter<boolean> = new EventEmitter<boolean>();
  PageId = 'CASH_FLOW_UPDATE_REVIEW';
  unchangedProjectsTotal: number | undefined;
  cashFlowUpdateRecord: CashFlowReviewVM = {} as CashFlowReviewVM;
  organizationTitle: string | undefined;
  organizationLgCode: string | undefined;
  sub = new Subscription();
  cashFlowUpdateViewType = CashFlowUpdateViewType;
  cashFlowFormSequence = CashFlowFormSequence;
  cashFlowUpdateStatusType = CashFlowUpdateStatusType;
  organizationId: number;
  cf: CashFlowUpdateExtDto;
  currentCfId: number;
  allCapitalAssetsTypes: capitalAssetTypes[];
  updatedFundingProjectsTotal: number | undefined;
  constructor(
    private res: ResourcePipe,
    private router: Router,
    private organizations: OrganizationService,
    private applicationService: ApplicationExtService,
    private lookupValueService: LookupValueService,
  ) {}

  ngOnChanges(): void {
    this.organizationId = this.orgId;
    this.currentCfId = this.cfId;
    this.getOrganizationDetails();
    this.getCashFlowUpdateApplication(this.currentCfId, this.organizationId);
  }
  ngOnInit(): void {
    this.organizationId = this.orgId;
    this.getOrganizationDetails();
    this.getCapitalAssetTypesWithMeasurement();
    this.getCashFlowUpdateApplication(this.currentCfId, this.organizationId);
  }
  getOrganizationDetails() {
    this.sub.add(
      this.organizations.findOne({ id: this.organizationId }).subscribe({
        next: (org) => {
          this.organizationTitle = org.legalName;
          this.organizationLgCode = org.lgCode;
        },
        error: this.initErrorCallback,
      }),
    );
  }
  getCapitalAssetTypesWithMeasurement() {
    this.lookupValueService.findAllCapitalAssetTypes().subscribe((capitalAssetTypes: CapitalAssetTypeDto[]) => {
      this.allCapitalAssetsTypes = capitalAssetTypes.map<capitalAssetTypes>((_) => {
        return {
          id: _.id,
          title: _.title,
          functionalCategoryTypeId: _.functionalCategoryType.id,
          measurementUnit: _.measurementUnit?.title ?? '',
        };
      });
    });
  }

  initErrorCallback() {}
  getCashFlowUpdateApplication(id: number, organizationId: number) {
    setTimeout(() => {
      this.sub.add(
        this.applicationService.findOneCashFlowUpdate({ id: id, organizationId: organizationId }).subscribe({
          next: (value) => {
            this.cf = value;
            console.log('cf summary', this.cf);
            this.loadCashFlowUpdates(this.cf);
          },
          error: this.initErrorCallback,
        }),
      );
    }, 0);
  }
  loadCashFlowUpdates(cf: CashFlowUpdateExtDto) {
    if (cf.id == this.currentCfId) {
      this.unchangedProjectsTotal = cf.applicationUpdatesRecord?.projectUpdates
        ?.filter((x) => (x.reasonForUpdate ?? '').length === 0)
        .reduce((sum: number, current) => sum + +(current.amountRequestedFromLGFF_Previous ?? 0) ?? 0, 0);
      this.updatedFundingProjectsTotal = cf.previousTotalLgffFundingAccepted + cf.totalUpdatedVarianceAmount;
      this.cashFlowUpdateRecord = {
        id: cf.id,
        idTxt: cf.idTxt,
        status: cf.status,
        statusTitle: cf.statusTitle,
        applicationUpdatesRecord: cf.applicationUpdatesRecord,
        previousTotalLgffFundingAccepted: cf.previousTotalLgffFundingAccepted ?? 0,
        updatedTotalLgffFundingAccepted: this.updatedFundingProjectsTotal ?? 0,
        totalUpdatedVarianceAmount: cf.totalUpdatedVarianceAmount ?? 0,
        cashFlowProjectReview: cf.applicationUpdatesRecord.projectUpdates!.map((project) => {
          return {
            projectIdTxt: project.projectIdTxt?.toString(),
            projectName: project.projectName?.toString(),
            capitalAssetTypeId: project.capitalAssetTypeId,
            capitalAssetTypeTitle: project.capitalAssetTypeTitle?.toString(),
            amountFromOtherGrantPrograms_Previous: project.amountFromOtherGrantPrograms_Previous?.toLocaleString(),
            amountFromOtherGrantPrograms_Updated: project.amountFromOtherGrantPrograms_Updated?.toLocaleString(),
            amountRequestedFromLGFF_Previous: project.amountRequestedFromLGFF_Previous?.toLocaleString(),
            amountRequestedFromLGFF_Updated: project.amountRequestedFromLGFF_Updated?.toLocaleString(),
            anticipatedEndDate_Previous: CommUtilsService._getFormattedDate(project.anticipatedEndDate_Previous!),
            anticipatedEndDate_Updated: CommUtilsService._getFormattedDate(project.anticipatedEndDate_Updated),
            anticipatedStartDate_Previous: CommUtilsService._getFormattedDate(project.anticipatedStartDate_Previous!),
            anticipatedStartDate_Updated: CommUtilsService._getFormattedDate(project.anticipatedStartDate_Updated),
            estimatedTotalCost_Previous: project.estimatedTotalCost_Previous?.toLocaleString(),
            estimatedTotalCost_Updated: project.estimatedTotalCost_Updated?.toLocaleString(),
            isProjectWithdrawn: project.isProjectWithdrawn,
            projectId: project.projectId,
            quantityNew_Previous: project.quantityNew_Previous,
            quantityNew_Updated: project.quantityNew_Updated,
            quantityUpgrade_Previous: project.quantityUpgrade_Previous,
            quantityUpgrade_Updated: project.quantityUpgrade_Updated,
            reasonForUpdate: project.reasonForUpdate ?? '',
            totalAmountFromMunicipalSources_Previous:
              project.totalAmountFromMunicipalSources_Previous?.toLocaleString(),
            totalAmountFromMunicipalSources_Updated: project.totalAmountFromMunicipalSources_Updated?.toLocaleString(),
          };
        }),
      };
    }
  }
  getCapitalAssetTypeUnit(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';
    const title = this.allCapitalAssetsTypes.find((item) => item.id === id)?.measurementUnit ?? '' + id;
    return title;
  }
  get isApplicationfundingUpdated() {
    return this.cashFlowUpdateRecord.applicationUpdatesRecord?.reasonForFundingUpdate ?? false;
  }

  get applicationfundingSourceUpdates() {
    const otherFundSource: string[] = [];
    const resPageId = 'APPLICATION_DETAIL';
    if (
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromAMWWP_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromCCBF_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromSTIP_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromWaterForLife_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromMSI_Updated ||
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Updated
    ) {
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromAMWWP_Updated ??
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromAMWWP_Previous
      ) {
        otherFundSource.push(this.res.transform('fundingFromAMWWP', resPageId));
      }
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromCCBF_Updated ??
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromCCBF_Previous
      ) {
        otherFundSource.push(this.res.transform('fundingFromCCBF', resPageId));
      }
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromSTIP_Updated ??
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromSTIP_Previous
      ) {
        otherFundSource.push(this.res.transform('fundingFromSTIP', resPageId));
      }
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromWaterForLife_Updated ??
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromWaterForLife_Previous
      ) {
        otherFundSource.push(this.res.transform('fundingFromWaterForLife', resPageId));
      }
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromMSI_Updated ??
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromMSI_Previous
      ) {
        otherFundSource.push(this.res.transform('fundingFromMSI', resPageId));
      }
      if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Updated &&
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromOther_Updated
      ) {
        if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Updated.length > 0) {
          otherFundSource.push(
            'Other: ' + this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Updated,
          );
        }
      } else if (
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous &&
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromOther_Previous &&
        this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromOther_Updated === undefined
      ) {
        if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous.length > 0) {
          otherFundSource.push(
            'Other: ' + this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous,
          );
        }
      }
    }
    if (otherFundSource.length > 0) {
      return otherFundSource.join(', ');
    } else {
      return '-';
    }
  }

  get applicationfundingSourcePreviously() {
    const otherFundSource: string[] = [];
    const resPageId = 'APPLICATION_DETAIL';
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromAMWWP_Previous) {
      otherFundSource.push(this.res.transform('fundingFromAMWWP', resPageId));
    }
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromCCBF_Previous) {
      otherFundSource.push(this.res.transform('fundingFromCCBF', resPageId));
    }
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromSTIP_Previous) {
      otherFundSource.push(this.res.transform('fundingFromSTIP', resPageId));
    }
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromWaterForLife_Previous) {
      otherFundSource.push(this.res.transform('fundingFromWaterForLife', resPageId));
    }
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.isFundingFromMSI_Previous) {
      otherFundSource.push(this.res.transform('fundingFromMSI', resPageId));
    }
    if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous) {
      if (this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous.length > 0) {
        otherFundSource.push('Other: ' + this.cashFlowUpdateRecord.applicationUpdatesRecord?.fundingFromOther_Previous);
      }
    }

    if (otherFundSource.length > 0) {
      return otherFundSource.join(', ');
    } else {
      return '-';
    }
  }

  get areThereOtherSourcesOfFunding() {
    return (
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Updated ??
      this.cashFlowUpdateRecord.applicationUpdatesRecord?.areThereOtherSourcesOfFunding_Previous
    );
  }
  get isProjectUpdated() {
    if (this.cf) {
      const changedProjectsCount =
        this.cf.applicationUpdatesRecord?.projectUpdates?.filter((x) => (x.reasonForUpdate ?? '').length > 0).length ??
        0;

      return changedProjectsCount > 0;
    } else {
      return false;
    }
  }
  get cashFlowUpdatesRequiredCallout() {
    if (this.viewType == this.cashFlowUpdateViewType.Review) {
      if (!this.isApplicationfundingUpdated && !this.isProjectUpdated) {
        this.invalidReview.emit(true);
        return true;
      } else {
        this.invalidReview.emit(false);
      }
    }
    return false;
  }
  get areAllProjectsWithdrawn() {
    const withdrawnProjectsCount =
      this.cf?.applicationUpdatesRecord?.projectUpdates?.filter((x) => (x.isProjectWithdrawn ?? false) === true)
        .length ?? 0;
    const projectsCount = this.cf?.applicationUpdatesRecord?.projectUpdates?.length;
    return withdrawnProjectsCount === projectsCount;
  }
  getBadgeType(stage: string | undefined) {
    if (stage == CashFlowUpdateStatusType.Accepted) {
      return 'success';
    }

    if (stage == CashFlowUpdateStatusType.Returned) {
      return 'emergency';
    }

    return 'information';
  }
  stepRoute(stepSequence: number) {
    if (stepSequence === CashFlowFormSequence.ApplicationFunding) {
      this.router.navigate(['cash-flow-updates/application-funding']);
    }
    if (stepSequence === CashFlowFormSequence.ProjectUpdates) {
      this.router.navigate(['cash-flow-updates/project-updates']);
    }
  }
  onClose() {
    this.closeModal.emit();
  }
  onClickPrint() {
    this.PrintContent.emit();
  }
}
export interface capitalAssetTypes {
  id: number;
  title: string;
  functionalCategoryTypeId: number;
  measurementUnit: string;
}
