import { Component, Input } from '@angular/core';

import { SepoProjectOutcomeDto } from '@app-com/api/models';

import { LookupValue } from '@app-com/state/lookup-value.state';

@Component({
  selector: 'app-edit-outcomes-reporting',
  templateUrl: './edit-outcomes-reporting.component.html',
  styleUrl: './edit-outcomes-reporting.component.scss',
})
export class EditOutcomesReportingComponent {
  @Input() sepoProjectOutcomeForReporting: SepoProjectOutcomeDto[];
  @Input() primaryOutcomes: LookupValue[];

  sepoProjectOutcomeTitle: (string | undefined)[];
  heading = '';
  outcomeReportingClipboardText: string;
  pageId = 'SEPO';

  ngOnInit() {
    this.sepoProjectOutcomeTitle = this.sepoProjectOutcomeForReporting.map(
      (sepoProjectOutcome) =>
        this.primaryOutcomes.find((primaryOutcome) => primaryOutcome.id == sepoProjectOutcome.projectPrimaryOutcomeId)
          ?.title,
    );
  }
}
