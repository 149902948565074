/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddPaymentsToBatchDto } from '../models/add-payments-to-batch-dto';
import { CreatePaymentBatchDto } from '../models/create-payment-batch-dto';
import { CreateWorknoteDto } from '../models/create-worknote-dto';
import { CsvAllPaymentBatchDataExportDto } from '../models/csv-all-payment-batch-data-export-dto';
import { CsvPaymentBatchSummaryExportDto } from '../models/csv-payment-batch-summary-export-dto';
import { PaymentBatchDto } from '../models/payment-batch-dto';
import { PaymentBatchListDto } from '../models/payment-batch-list-dto';
import { PaymentBatchListFilter } from '../models/payment-batch-list-filter';
import { PaymentListDto } from '../models/payment-list-dto';
import { PaymentListFilter } from '../models/payment-list-filter';
import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';
import { WorknoteDto } from '../models/worknote-dto';

@Injectable({ providedIn: 'root' })
export class PaymentBatchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create_4()` */
  static readonly Create_4Path = '/api/payment-batch/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreatePaymentBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.Create_4Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreatePaymentBatchDto
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `count_7()` */
  static readonly Count_7Path = '/api/payment-batch/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.Count_7Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_11()` */
  static readonly FindAll_11Path = '/api/payment-batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentBatchListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.FindAll_11Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentBatchListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<Array<PaymentBatchListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentBatchListDto>>): Array<PaymentBatchListDto> => r.body)
    );
  }

  /** Path part for operation `findAllPayments()` */
  static readonly FindAllPaymentsPath = '/api/payment-batch/batch-payments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllPayments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllPayments$Response(
    params: {
      id: number;
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.FindAllPaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllPayments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllPayments(
    params: {
      id: number;
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<Array<PaymentListDto>> {
    return this.findAllPayments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentListDto>>): Array<PaymentListDto> => r.body)
    );
  }

  /** Path part for operation `findOne_9()` */
  static readonly FindOne_9Path = '/api/payment-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.FindOne_9Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `addPaymentToBatch()` */
  static readonly AddPaymentToBatchPath = '/api/payment-batch/add-to-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPaymentToBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPaymentToBatch$Response(
    params: {
      id: number;
      body: AddPaymentsToBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.AddPaymentToBatchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPaymentToBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPaymentToBatch(
    params: {
      id: number;
      body: AddPaymentsToBatchDto
    },
    context?: HttpContext
  ): Observable<number> {
    return this.addPaymentToBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `updateAssignedTo_2()` */
  static readonly UpdateAssignedTo_2Path = '/api/payment-batch/assigned-to/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo$Response(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.UpdateAssignedTo_2Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.updateAssignedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `updateOneGx()` */
  static readonly UpdateOneGxPath = '/api/payment-batch/update-one-gx-payment/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneGx()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOneGx$Response(
    params: {
      id: number;
      paidWithOneGX?: any;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.UpdateOneGxPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('paidWithOneGX', params.paidWithOneGX, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneGx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOneGx(
    params: {
      id: number;
      paidWithOneGX?: any;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.updateOneGx$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `removePaymentFromBatch()` */
  static readonly RemovePaymentFromBatchPath = '/api/payment-batch/remove-from-batch/{id}/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removePaymentFromBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePaymentFromBatch$Response(
    params: {
      id: number;
      paymentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.RemovePaymentFromBatchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('paymentId', params.paymentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removePaymentFromBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePaymentFromBatch(
    params: {
      id: number;
      paymentId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.removePaymentFromBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `submitBatchByAdmin()` */
  static readonly SubmitBatchByAdminPath = '/api/payment-batch/submit-batch-by-admin/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitBatchByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitBatchByAdmin$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.SubmitBatchByAdminPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitBatchByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitBatchByAdmin(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.submitBatchByAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `approveBatchReviewer()` */
  static readonly ApproveBatchReviewerPath = '/api/payment-batch/complete-review-by-reviewer/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveBatchReviewer()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBatchReviewer$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.ApproveBatchReviewerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveBatchReviewer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBatchReviewer(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.approveBatchReviewer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `returnBatchToAdmin()` */
  static readonly ReturnBatchToAdminPath = '/api/payment-batch/return-to-admin/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnBatchToAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  returnBatchToAdmin$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.ReturnBatchToAdminPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnBatchToAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  returnBatchToAdmin(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.returnBatchToAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `returnBatchToReviewer()` */
  static readonly ReturnBatchToReviewerPath = '/api/payment-batch/return-to-reviewer/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnBatchToReviewer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  returnBatchToReviewer$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.ReturnBatchToReviewerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnBatchToReviewer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  returnBatchToReviewer(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.returnBatchToReviewer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `recommendBatch()` */
  static readonly RecommendBatchPath = '/api/payment-batch/recommend-batch-eo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recommendBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendBatch$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.RecommendBatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recommendBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendBatch(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.recommendBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `approveBatch()` */
  static readonly ApproveBatchPath = '/api/payment-batch/approve-batch-ao/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBatch$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.ApproveBatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveBatch(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.approveBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cancelBatch_1()` */
  static readonly CancelBatch_1Path = '/api/payment-batch/cancel-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelBatch$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.CancelBatch_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelBatch(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.cancelBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `updatePaymentsLatestCostCentre()` */
  static readonly UpdatePaymentsLatestCostCentrePath = '/api/payment-batch/update-payments-latest-cost-centre/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaymentsLatestCostCentre()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePaymentsLatestCostCentre$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.UpdatePaymentsLatestCostCentrePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaymentsLatestCostCentre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePaymentsLatestCostCentre(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentBatchDto> {
    return this.updatePaymentsLatestCostCentre$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentBatchDto>): PaymentBatchDto => r.body)
    );
  }

  /** Path part for operation `createPaymentWorknote()` */
  static readonly CreatePaymentWorknotePath = '/api/payment-batch/worknote/{paymentBatchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaymentWorknote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentWorknote$Response(
    params: {
      paymentBatchId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.CreatePaymentWorknotePath, 'post');
    if (params) {
      rb.path('paymentBatchId', params.paymentBatchId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPaymentWorknote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentWorknote(
    params: {
      paymentBatchId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.createPaymentWorknote$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `csvFindAllPaymentBatchData()` */
  static readonly CsvFindAllPaymentBatchDataPath = '/api/payment-batch/csv/find-all-payment-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllPaymentBatchData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllPaymentBatchData$Response(
    params: {
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllPaymentBatchDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.CsvFindAllPaymentBatchDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllPaymentBatchDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllPaymentBatchData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllPaymentBatchData(
    params: {
      body: PaymentBatchListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllPaymentBatchDataExportDto>> {
    return this.csvFindAllPaymentBatchData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllPaymentBatchDataExportDto>>): Array<CsvAllPaymentBatchDataExportDto> => r.body)
    );
  }

  /** Path part for operation `csvGetPaymentsBatchSummary()` */
  static readonly CsvGetPaymentsBatchSummaryPath = '/api/payment-batch/csv/payment-batch-summary/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvGetPaymentsBatchSummary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvGetPaymentsBatchSummary$Response(
    params: {
      id: number;
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvPaymentBatchSummaryExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.CsvGetPaymentsBatchSummaryPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvPaymentBatchSummaryExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvGetPaymentsBatchSummary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvGetPaymentsBatchSummary(
    params: {
      id: number;
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvPaymentBatchSummaryExportDto>> {
    return this.csvGetPaymentsBatchSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvPaymentBatchSummaryExportDto>>): Array<CsvPaymentBatchSummaryExportDto> => r.body)
    );
  }

  /** Path part for operation `getUserAvailablePaymentBatchActions()` */
  static readonly GetUserAvailablePaymentBatchActionsPath = '/api/payment-batch/available-actions/{paymentBatchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailablePaymentBatchActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailablePaymentBatchActions$Response(
    params: {
      paymentBatchId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.GetUserAvailablePaymentBatchActionsPath, 'get');
    if (params) {
      rb.path('paymentBatchId', params.paymentBatchId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailablePaymentBatchActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailablePaymentBatchActions(
    params: {
      paymentBatchId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailablePaymentBatchActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `getPaymentBatchSummaryPdf()` */
  static readonly GetPaymentBatchSummaryPdfPath = '/api/payment-batch/pdf/payment-batch-summary-pdf/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentBatchSummaryPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentBatchSummaryPdf$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentBatchService.GetPaymentBatchSummaryPdfPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentBatchSummaryPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentBatchSummaryPdf(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.getPaymentBatchSummaryPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

}
