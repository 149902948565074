/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateSepoBatchDto } from '../models/create-sepo-batch-dto';
import { SepoBatchDto } from '../models/sepo-batch-dto';

@Injectable({ providedIn: 'root' })
export class SepoBatchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sepoBatchControllerFindAll()` */
  static readonly SepoBatchControllerFindAllPath = '/api/sepo-batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerCreate()` */
  static readonly SepoBatchControllerCreatePath = '/api/sepo-batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerCreate$Response(
    params: {
      body: CreateSepoBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerCreate(
    params: {
      body: CreateSepoBatchDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerFindOne()` */
  static readonly SepoBatchControllerFindOnePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerRemove()` */
  static readonly SepoBatchControllerRemovePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerRemove(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerUpdate()` */
  static readonly SepoBatchControllerUpdatePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerUpdate$Response(
    params: {
      id: string;
      body: SepoBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerUpdate(
    params: {
      id: string;
      body: SepoBatchDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
