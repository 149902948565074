import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SepoListExtDto, SepoListExtFilter, SepoListExtOrderBy, SepoStatusType, SortOrder } from '@app-com/api/models';
import { SepoExtService } from '@app-com/api/services';
import { LgffGoADisplayedColumnDef } from '@app-com/components/lgff-goa-table/lgff-goa-table.component';
import { SetCurrentSEPOId } from '@app-pot/store/actions/current-context.action';
import { ResetSEPOState } from '@app-pot/store/actions/edit-sepo.action';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-sepo',
  templateUrl: './view-sepo.component.html',
  styleUrl: './view-sepo.component.scss',
})
export class ViewSepoComponent {
  pageId = 'SEPO';

  organizationId: number = 0;
  searchFormCtrl: FormControl;
  colDef: LgffGoADisplayedColumnDef[] = [];

  //TODO: Wait for SepoListDto in foundation to be created
  tableData: SepoListExtDto[] = [];
  currentPage = 1;
  perPageCount = 25;
  searchTerm = '';
  sub = new Subscription();

  orderByCol: SepoListExtOrderBy = { programYear: SortOrder.Desc };

  @ViewChild('sepoStatusColTemplate', { static: true })
  sepoStatusColTemplate: TemplateRef<SepoListExtDto> | null = null;

  @ViewChild('sepoCurrencyColTemplate', { static: true })
  sepoCurrencyColTemplate: TemplateRef<SepoListExtDto> | null = null;

  @ViewChild('actionsColTemplate', { static: true })
  actionsColTemplate: TemplateRef<SepoListExtDto> | null = null;

  constructor(
    private sepoService: SepoExtService,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchFormCtrl = new FormControl('');
    this.sub.add(
      this.searchFormCtrl.valueChanges.subscribe((newValue) => {
        this.searchTerm = newValue;
        if (this.searchTerm?.length == 0) {
          this.loadData();
        }
      }),
    );
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.loadData();
    this.initColDef();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  configQuery() {
    const body: SepoListExtFilter = {};
    if (this.searchTerm) {
      body.SEARCH = this.searchTerm;
    }
    body.ORDERBY = [this.orderByCol];
    const query = {
      organizationId: this.organizationId,
      body,
    };
    return query;
  }

  loadData() {
    this.sub.add(
      this.sepoService.findAll(this.configQuery()).subscribe({
        next: (result) => {
          this.tableData = result;
        },
        error: (error: Error) => {
          console.error(
            'file: view-sepo.component.ts:72 ~ ViewSepoComponent ~ this.sepoBatchService.sepoBatchControllerFindAll ~ error:',
            error,
          );
        },
      }),
    );
  }

  getBadgeType(status: SepoStatusType) {
    if (status === SepoStatusType.Certified) return 'success';
    if (status == SepoStatusType.Returned) return 'emergency';
    return 'information';
  }

  initColDef(): void {
    this.colDef = [
      {
        fieldName: 'programYear',
        sortable: true,
        sortName: 'programYear',
        sortDir: 'desc',
      },
      {
        fieldName: 'status',
        sortable: true,
        sortName: 'status',
        columnTemplate: this.sepoStatusColTemplate,
      },
      {
        fieldName: 'totalFundingAvailable',
        sortable: true,
        sortName: 'totalFundingAvailable',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'totalFundingApplied',
        sortable: true,
        sortName: 'totalFundingApplied',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'totalFutureFundingAvailable',
        displayName: 'totalLgffFundingRemaining',
        sortable: true,
        sortName: 'totalLgffFundingRemaining',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'actions',
        sortable: false,
        columnTemplate: this.actionsColTemplate,
        cellClass: 'actions-col text-center',
      },
    ];
  }

  applySearchFilter() {
    this.loadData();
  }

  handleSort(event: Event) {
    const { sortBy, sortDir } = (event as CustomEvent).detail;
    this.orderByCol = {};
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    this.orderByCol[sortBy] = sortDir === 1 ? SortOrder.Asc : SortOrder.Desc;
    this.loadData();
  }

  showSEPOEdit(status: string): boolean {
    if (status === SepoStatusType.Draft || status === SepoStatusType.Returned || status === SepoStatusType.NotStarted) {
      return true;
    }
    return false;
  }

  onEditSEPO(id: number) {
    this.store.dispatch(new SetCurrentSEPOId(id));
    this.store.dispatch(new ResetSEPOState());
    this.router.navigate(['/edit-sepo']);
  }
}
