import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { SafeDollarPipe } from '@app-com/pipes/safe-dollar/safe-dollar.pipe';
import { LgffGoaModalComponent } from './components/lgff-goa-modal/lgff-goa-modal.component';
import { LgffGoaTablePaginationComponent } from './components/lgff-goa-table-pagination/lgff-goa-table-pagination.component';
import { LgffGoaTableComponent } from './components/lgff-goa-table/lgff-goa-table.component';
import '@abgov/web-components';
import { AngularComponentsModule } from '@abgov/angular-components';
import { CashFlowUpdateViewComponent } from './components/cash-flow-update-view/cash-flow-update-view.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { LgffGoaNestedRowsTableComponent } from './components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.component';
@NgModule({
  declarations: [
    ResourcePipe,
    LgffGoaTableComponent,
    LgffGoaTablePaginationComponent,
    LgffGoaModalComponent,
    CashFlowUpdateViewComponent,
    TimeoutComponent,
    LgffGoaNestedRowsTableComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, AngularComponentsModule, SafeDollarPipe],
  exports: [
    ResourcePipe,
    LgffGoaTableComponent,
    LgffGoaTablePaginationComponent,
    LgffGoaModalComponent,
    CashFlowUpdateViewComponent,
    TimeoutComponent,
    LgffGoaNestedRowsTableComponent,
  ],
  providers: [ResourcePipe, SafeDollarPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectCommonModule {}
