import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExternalRoleDto, ExternalUserDto } from '@app-com/api/models';
import { environment } from '@app-pot-env/environment';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { ClearApplicationDraftContext, SetCurrentOrganizationId } from '@app-pot/store/actions/current-context.action';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { AuthService } from '@app-com/api/services';
import { IdleTimeoutService } from '@app-com/services/idle-timeout.service';
import { IdleTimeoutState } from '@app-com/enums/idle-timeout-state.enum';
@Component({
  selector: 'app-pot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  ddlOrganization = new FormControl('ddlOrganization');

  user$: Observable<ExternalUserDto | null>;
  menuClicked$: Observable<string>;
  pageId = 'HEADER_MENU';

  organizations: ExternalRoleDto[] | undefined;
  singleOrganizationTitle: string;
  organizationIdSelected: number;
  organizationIdSelectedTitle: string;
  maConnectDashboardLink = environment.maConnectDashboard;

  clickedMenu = '';

  wantShowLoginTimeoutDlg = false; // control dialog on/off
  sub = new Subscription();
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  constructor(
    private authStateService: AuthStateService,
    private auth: AuthService,
    private store: Store,
    private router: Router,
    private location: Location,
    private idleTimeoutService: IdleTimeoutService,
  ) {
    this.sub.add(
      this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      }),
    );
  }

  reset() {
    this.idleTimeoutService.reset();
  }

  stop() {
    this.idleTimeoutService.stop();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.timeoutIds.push(
      setTimeout(() => {
        this.styleUserInfoDdl();
        this.setHeaderStyles();
      }, 0),
    );
  }
  @HostListener('window:load', ['$event'])
  onPageLoad() {
    this.styleUserInfoDdl();
    this.setHeaderStyles();
  }

  ngOnInit(): void {
    this.clickedMenu = ''; // avoid double top-bar

    this.user$ = this.authStateService.getAuthenticatedUser();
    this.sub.add(
      this.user$.subscribe((user) => {
        if (user?.roles) {
          this.idleTimeoutService.watch(false);
          this.organizations = user.roles.map((role) => {
            return {
              id: role.id,
              name: role.name,
              title: role.title,
            };
          });
          if (this.organizations.length == 1) {
            this.organizationIdSelected = this.organizations[0].id;
            this.singleOrganizationTitle = this.organizations[0].title;
            this.store.dispatch(new SetCurrentOrganizationId(this.organizationIdSelected));
          } else if (
            this.organizationIdSelected &&
            this.organizationIdSelected > 0 &&
            this.organizations.find((org) => org.id == this.organizationIdSelected)
          ) {
            this.ddlOrganization.setValue(String(this.organizationIdSelected));
            this.organizationIdSelectedTitle = this.organizations.find(
              (org) => org.id == this.organizationIdSelected,
            )!.title;
          } else {
            this.organizationIdSelected = this.organizations[0].id;
            this.organizationIdSelectedTitle = this.organizations[0].title;
            this.ddlOrganization.setValue(String(this.organizationIdSelected));
            this.store.dispatch(new SetCurrentOrganizationId(this.organizationIdSelected));
          }
          this.setOrganizationDropdownStyle();
        } else {
          this.stop();
        }
      }),
    );

    const handleEvent = () => {
      if (visibilityFlag) {
        visibilityFlag = false;
        this.auth.tokenExpiry().subscribe((tokenExpiry) => {
          const curTimeSeconds = Math.trunc(Date.now() / 1000);
          //console.error('elapsed time between tokenExpiry and currentTime', tokenExpiry - curTimeSeconds);
          if (tokenExpiry <= 0 || tokenExpiry <= curTimeSeconds) {
            this.stop();
            this.auth.logout().subscribe();
            window.location.href = environment.maConnectDashboard as string;
          }
        });
        setTimeout(() => {
          visibilityFlag = true;
        }, 100);
      }
    };

    let visibilityFlag = true;

    const documentVisbilityChange$ = fromEvent(document, 'visibilitychange');
    const windowFocus$ = fromEvent(window, 'focus');

    this.sub.add(
      documentVisbilityChange$.subscribe({
        next: () => {
          if (document.visibilityState === 'visible') {
            handleEvent();
          }
        },
      }),
    );
    this.sub.add(
      windowFocus$.subscribe({
        next: () => handleEvent(),
      }),
    );

    this.sub.add(
      this.idleTimeoutService.getTimeoutVisibility().subscribe((visible) => {
        this.wantShowLoginTimeoutDlg = visible;
        if (this.idleTimeoutService.intervalSub) {
          console.log('Idle timeout service timeout interval unsubscribed');
          this.idleTimeoutService.intervalSub.unsubscribe();
        }
        if (!visible && this.idleTimeoutService.idleState == IdleTimeoutState.TimedOut) {
          if (this.idleTimeoutService.restartWatch) {
            window.clearTimeout(this.idleTimeoutService.restartWatch);
          }
          if (this.idleTimeoutService.intervalId) {
            window.clearTimeout(this.idleTimeoutService.intervalId);
          }
          //this.idleTimeoutService.stop();
          this.auth.logout().subscribe();
          window.location.href = environment.maConnectDashboard as string;
        }
      }),
    );

    this.sub.add(
      this.idleTimeoutService.getRefreshToken().subscribe(() => {
        if (this.idleTimeoutService.idleState == IdleTimeoutState.Active) {
          // call back for expiry-possible-extended-user-profile
          this.auth.getExternalProfile().subscribe({
            next: (user2: ExternalUserDto) => {
              if (!user2) {
                this.auth.logout().subscribe();
                window.location.href = environment.maConnectDashboard as string;
              } else {
                //this.idleTimeoutService.reset();
              }
            },
            error: () => {
              this.auth.logout().subscribe();
              window.location.href = environment.maConnectDashboard as string;
            },
          });
        }
      }),
    );

    this.menuClicked$ = this.authStateService.getHomeMenuClicked();
    this.sub.add(
      this.menuClicked$.subscribe((menuName) => {
        if (!menuName || menuName.indexOf('view-applications') < 0) {
          // remove goa-top-blur-bar
          this.timeoutIds.push(
            setTimeout(() => {
              this.addRemoveHeadMenuTopBlueBar('#app-header-2-a-applications', false);
              this.clickedMenu = menuName ?? '';
              // console.warn('last clicked menu name= ', this.clickedMenu);
            }, 100),
          );
        } else {
          // add top-blur-bar
          this.timeoutIds.push(
            setTimeout(() => {
              this.addRemoveHeadMenuTopBlueBar('#app-header-2-a-applications', true);
              this.clickedMenu = menuName ?? '';
              // console.warn('last clicked menu name= ', this.clickedMenu);
            }, 100),
          );
        }
      }),
    );
  }

  ngAfterViewInit() {
    this.setHeaderStyles();
    this.styleUserInfoDdl();
    this.addRemoveHeadMenuTopBlueBar('#app-header-2-a-applications', false); // remove on init
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  public noShowIdleTimeoutDialogHandler() {
    this.auth.logout().subscribe();
    this.idleTimeoutService.idleState = IdleTimeoutState.TimedOut;
    this.idleTimeoutService.setTimeoutVisibility(false);
    window.location.href = environment.maConnectDashboard as string;
  }

  public yesShowIdleTimeoutDialogHandler() {
    // call back for expiry-possible-extened-user-profile
    this.auth.getExternalProfile().subscribe({
      next: (user2: ExternalUserDto) => {
        if (user2) {
          //console.log('getExternalProfile= ', user2.exp, user2);

          this.idleTimeoutService.setTimeoutVisibility(false);
        } else {
          this.auth.logout().subscribe();
          window.location.href = environment.maConnectDashboard as string;
        }
      },
      error: () => {
        this.auth.logout().subscribe();
        window.location.href = environment.maConnectDashboard as string;
      },
    });
  }

  onChangeUserOrganizationList($event: Event) {
    if (!($event instanceof CustomEvent)) return;
    let needToReload = false;
    if (this.organizationIdSelected != Number($event.detail.value)) {
      this.organizationIdSelected = Number($event.detail.value);
      needToReload = true;
    }
    this.store.dispatch(new SetCurrentOrganizationId(Number($event.detail.value ?? 0)));
    if (needToReload) {
      this.store.dispatch(new ClearApplicationDraftContext());
      this.router.navigate(['/home']);
      this.location.replaceState('/home');
      window.location.reload();
    }
  }

  setOrganizationDropdownStyle() {
    const orgDropdown = document.querySelector('#user-organizations-goa-dropdown');
    if (orgDropdown) {
      if (this.organizations && this.organizations.length > 1) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        orgDropdown['style']['display'] = 'inline-block';
        const ddlSelect = document
          .querySelector('#user-organizations-goa-dropdown')
          ?.shadowRoot?.querySelector('select');
        if (ddlSelect) {
          ddlSelect.style.textOverflow = 'ellipsis';
        }
      } else {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        orgDropdown['style']['display'] = 'none';
      }
    }
  }

  setHeaderStyles() {
    const idheader2 = document.querySelector('#app-header-2');
    if (idheader2) {
      const logo = idheader2?.shadowRoot?.querySelector('div.header-logo-title-area');
      if (logo) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        logo['style']['display'] = 'none';
      }

      const divContainer2 = idheader2?.shadowRoot?.querySelector('div.container');
      if (divContainer2) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        divContainer2['style']['width'] = '0px';
      }
    }
  }

  addRemoveHeadMenuTopBlueBar(parentId: string, isAdd: boolean) {
    // '#app-header-2-a-applications'
    const headApplication = document.querySelector(parentId);
    const headApplicationPopOver = headApplication?.shadowRoot?.querySelector('goa-popover');
    const headApplicationPopOverButton = headApplicationPopOver?.querySelector('button'); // .current
    let className = '';
    if (headApplicationPopOverButton) {
      className = headApplicationPopOverButton?.className ?? '';
      let newClassName = '';
      if (isAdd) {
        if (className.indexOf('current') < 0) {
          newClassName = className + ' current';
        }
      } else {
        if (className.indexOf('current') >= 0) {
          newClassName = className.replace('current', '');
        }
      }
      if (newClassName.length > 0 && newClassName !== className) {
        headApplicationPopOverButton.className = newClassName;
      }
    }
    // console.warn('called addRemoveHeadMenuTopBlueBar with ' + parentId + ' exist class name= ' + className);
  }

  styleUserInfoDdl() {
    const idheader1 = document.querySelector('#app-header-1');
    const idheaderdivUserInfo = idheader1?.querySelector('div.userInfo');
    if (idheaderdivUserInfo) {
      const idheader1UserMenu = idheaderdivUserInfo.querySelector('#app-header-1-user-menu');
      const btn = idheader1UserMenu?.shadowRoot?.querySelector('button.primary');
      if (btn) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        btn['style']['boxShadow'] = '0 0 0 0';
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        btn['style']['padding'] = '0 1rem';
      }
    }
  }

  public clickedMenuItem(menuName: string, allowNext: boolean) {
    this.clickedMenu = menuName;
    this.authStateService.setHomeMenuClicked(menuName);
    if (!allowNext) {
      const routeName = '/' + menuName;
      this.router.navigate([routeName]);
    }
    return allowNext;
  }
}
