/* tslint:disable */
/* eslint-disable */
export enum SepoStatusType {
  NotStarted = 'NotStarted',
  Draft = 'Draft',
  Submitted = 'Submitted',
  InReview = 'InReview',
  Certified = 'Certified',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
