<app-lgff-goa-nested-rows-table
  [headers]="headers"
  [showAdditionalHeader]="true"
  [additionalHeaders]="additionalHeaders"
  [tableData]="sepoExpenditures"
  childKey="projects"
  tableWidth="136.1875rem"
  [parentColumnIndent]="parentColumnIndent"
  [showTotalsColumn]="true"
  totalsColumnText="Total:"
  [totalsColumnIndent]="7"
  [totalsColDef]="totalColumn"
  [cellTemplates]="{
    expenditureId: editTemplate,
    estimatedTotalLGFFFunding: currencyTemplate,
    totalRemaining: currencyTemplate,
    actualReportingYearCost: currencyTemplate,
    amountFundedOtherGrantPrograms: currencyTemplate,
    fundingAppliedFromLGFF: currencyTemplate,
    fundingAppliedFromMunicipalSources: currencyTemplate,
    projectStatusTitle: badgeTemplate,
    applicationStatus: badgeTemplate
  }"
></app-lgff-goa-nested-rows-table>

<ng-template #editTemplate let-row>
  <goa-icon-button
    [disabled]="editBtnDisabled"
    id="{{ row }}"
    size="small"
    class="action-icon"
    variant="color"
    icon="pencil"
    (click)="onEditProject(row)"
    [ngClass]="{ 'errored-border': errorExpenditureIds.includes(row), 'disabled-action-icon': editBtnDisabled }"
  ></goa-icon-button>
</ng-template>

<ng-template #currencyTemplate let-data>
  <ng-container *ngIf="data !== null && data !== undefined; else dashTemplate">
    {{ data | currency: '$' : 'symbol' : '1.0-0' }}
  </ng-container>
  <ng-template #dashTemplate> - </ng-template>
</ng-template>

<ng-template #badgeTemplate let-data>
  <ng-container *ngIf="data !== null && data !== undefined; else defaultTemplate">
    <goa-badge type="{{ getType(data) }}" content="{{ data }}"></goa-badge>
  </ng-container>
  <ng-template #defaultTemplate> - </ng-template>
</ng-template>
