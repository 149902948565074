export const environment = {
  production: false,

  importFileSizeLimitText: '100KB',

  mapsApiKey: import.meta.env['NG_APP_MAPS_KEY'],
  ssoLoginKClkPath: import.meta.env['NG_APP_SSO_LOGIN_KC_PATH'],
  ssoLoginSamlPath: import.meta.env['NG_APP_SSO_LOGIN_SAML_PATH'],
  totalProjectsLimit: import.meta.env['NG_APP_PROJECTS_LIMIT'],
  rootUrl: import.meta.env['NG_APP_ROOT_URL'],
  maConnectDashboard: import.meta.env['NG_APP_MA_CONNECT_DASHBOARD'],
  systemStartDate: import.meta.env['NG_APP_SYSTEM_START_DATE'],
  portalEnvironment: import.meta.env['NG_APP_PORTAL_ENVIRONMENT'],
  portalVersion: import.meta.env['NG_APP_PORTAL_VERSION'],
  jwtTokenExpiry: import.meta.env['NG_APP_JWT_TOKEN_EXPIRY_MINUTES'] || 4,
  idleElapsedTime: import.meta.env['NG_APP_IDLE_ELAPSED_TIME'] || 1,
};
