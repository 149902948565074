// shared-form.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedEditSepoService {
  private formFilledSubject = new BehaviorSubject<boolean>(false);
  formFilled$ = this.formFilledSubject.asObservable();

  updateFormFilledStatus(isFilled: boolean) {
    this.formFilledSubject.next(isFilled);
  }
}
