import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewApplicationTab } from '../view-applications/view-application-tab.enum';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  pageId = 'HOME';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  viewApplicationBtnClick() {
    this.router.navigate(['view-applications', ViewApplicationTab.Drafts]);
  }

  viewAllocationBtnClick() {
    this.router.navigate(['allocations-payments']);
  }

  viewResourcesBtnClick() {
    this.router.navigate(['resources']);
  }

  viewSepoBtnClick() {
    this.router.navigate(['view-sepo']);
  }
}
