import { Injectable } from '@angular/core';
import { ApplicationFilter, ApplicationStatusType } from '@app-com/api/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveTabService {
  private activeTabSubject = new BehaviorSubject<string | undefined>(undefined);
  private activeTab$: Observable<string | undefined> = this.activeTabSubject.asObservable();

  private activeTabSearchSubject = new BehaviorSubject<string | undefined>(undefined);
  private activeTabSearch$: Observable<string | undefined> = this.activeTabSearchSubject.asObservable();

  private listCountSubject = new BehaviorSubject<number>(0);
  private listCount$: Observable<number> = this.listCountSubject.asObservable();

  private isLoading = new Subject<boolean>();
  private isLoading$: Observable<boolean> = this.isLoading.asObservable();

  constructor() {
    console.log('Active tab service instantiated');
  }

  public getIsLoading() {
    return this.isLoading$;
  }

  public setIsLoading(isLoading: boolean) {
    this.isLoading.next(isLoading);
  }

  public getActiveTab(): Observable<string | undefined> {
    return this.activeTab$;
  }

  public getActiveTabSearch(): Observable<string | undefined> {
    return this.activeTabSearch$;
  }

  public setActiveTab(tab: string | undefined) {
    if (tab) {
      this.activeTabSubject.next(tab);
    }
  }
  public setActiveTabSearch(search: string | undefined) {
    if (search !== undefined) {
      this.activeTabSearchSubject.next(search);
    }
  }

  public setListCount(count: number) {
    this.listCountSubject.next(count);
  }

  public getListCount(): Observable<number> {
    return this.listCount$;
  }

  public configActiveTabQuery(
    status: ApplicationStatusType[],
    organizationId: number,
    searchString: string,
    perPageCount?: number,
    currentPage?: number,
  ) {
    const body: ApplicationFilter = { organizationId: { eq: organizationId } };
    if (searchString) {
      body.SEARCH = searchString.toLocaleLowerCase();
    }

    body.statuses = status;
    let query;

    if (currentPage && perPageCount) {
      query = {
        take: perPageCount,
        skip: (currentPage - 1) * perPageCount,
        body: body,
        organizationId: organizationId,
      };
    } else {
      query = { body: body, organizationId: organizationId };
    }

    return query;
  }
}
