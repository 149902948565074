<div class="div-home-page">
  <div class="split-table">
    <div class="table-row">
      <div class="column">
        <div>
          <h2>{{ 'announcementsHeading' | res: pageId }}</h2>
        </div>
        <goa-container type="info">
          <div class="div-goa-container">
            <h3 class="announcement-title">{{ 'announcementATitle' | res: pageId }}</h3>
            <p class="announcement-text">{{ 'announcementALine1' | res: pageId }}</p>
            <p class="announcement-text">{{ 'announcementALine2' | res: pageId }}</p>
            <p class="announcement-text">{{ 'announcementALine3' | res: pageId }}</p>
            <p class="announcement-date">Posted: Oct 2, 2024</p>
          </div>
        </goa-container>
      </div>
      <div class="column">
        <goa-block direction="column" mb="3xl">
          <h2>{{ 'applicationsHeading' | res: pageId }}</h2>
          <p class="sub-heading">{{ 'applicationSubHeading' | res: pageId }}</p>
          <div class="btn-view-applications">
            <goa-button type="secondary" class="btn-view" (click)="viewApplicationBtnClick()">{{
              'viewApplicationButtonLabel' | res: pageId
            }}</goa-button>
          </div>
        </goa-block>
        <goa-block direction="column">
          <h2>{{ 'allocationsPaymentsHeading' | res: pageId }}</h2>
          <p class="sub-heading">{{ 'allocationsPaymentsSubHeading' | res: pageId }}</p>
          <div class="btn-view-applications">
            <goa-button type="secondary" class="btn-view" (click)="viewAllocationBtnClick()">{{
              'viewAllocationsPaymentsButtonLabel' | res: pageId
            }}</goa-button>
          </div>
        </goa-block>
      </div>
    </div>
    <div style="height: 64px"></div>
    <div class="table-row">
      <!-- see ticket for hiding column.   Tools not ready yet. LGFF-1259-->
      <!--<div class="column" style="display: none">
        <h2>{{ 'toolsAndReportsHeading' | res: pageId }}</h2>
        <p class="sub-heading">{{ 'toolsAndReportSubHeading' | res: pageId }}</p>
        <goa-spacer vSpacing="s"></goa-spacer>
        <goa-button type="secondary" class="btn-view">{{ 'viewToolsAndReportsButtonLabel' | res: pageId }}</goa-button>
      </div> -->
      <div class="column">
        <h2>{{ 'lgffResourcesHeading' | res: pageId }}</h2>
        <table style="width: 22.8125rem">
          <tr>
            <td class="guide-link-column">
              <a href="{{ 'lgffWebsiteLink' | res: pageId }}" target="_blank"
                >{{ 'lgffWebsite' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
              ></a>
            </td>
          </tr>
          <tr>
            <td class="guide-link-column">
              <a href="{{ 'lgffGuidelinesLink' | res: pageId }}" target="_blank"
                >{{ 'lgffGuidelines' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
              ></a>
            </td>
          </tr>
          <tr>
            <td class="guide-link-column">
              <a href="{{ 'msiOperatingLink' | res: pageId }}" target="_blank"
                >{{ 'msiOperating' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
              ></a>
            </td>
          </tr>
          <tr>
            <td class="more-info-column">
              {{ 'moreInformationText' | res: pageId }}
              <a href="{{ 'organizationSignUpGuideLink' | res: pageId }}" target="_blank">More information</a>.
            </td>
          </tr>
        </table>
        <goa-button type="secondary" class="btn-view" (click)="viewResourcesBtnClick()">{{
          'viewlgffResourcesButtonLabel' | res: pageId
        }}</goa-button>
      </div>
      <div class="column" *appFeatureFlag="'SEPO'">
        <goa-block direction="column">
          <h2>{{ 'sepoHeading' | res: pageId }}</h2>
          <p class="sub-heading">{{ 'sepoSubHeading' | res: pageId }}</p>
          <div class="btn-view-applications">
            <goa-button type="secondary" class="btn-view" (click)="viewSepoBtnClick()">{{
              'viewSepoButtonLabel' | res: pageId
            }}</goa-button>
          </div>
        </goa-block>
      </div>
    </div>
  </div>
  <div style="height: 80px"></div>
</div>
