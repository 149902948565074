<div class="view-applications-container">
  <div class="headerSubtitle">
    <h2 id="viewapp-heading-h2">{{ 'viewApplicationsHeading' | res: pageId }}</h2>
    <goa-button type="primary" class="btn-new-application" (click)="newApplicationBtnClick()">
      {{ 'newApplicationBtnText' | res: pageId }}</goa-button
    >
  </div>
  <goa-block class="loading-container" alignment="center">
    <goa-circular-progress variant="inline" size="large" [visible]="isLoading"></goa-circular-progress>
  </goa-block>
  <div id="application-threshold">
    {{ 'viewApplicationThreshold' | res: pageId }}:
    <span>{{ applicationThreshold | currency: '$' : 'symbol' : '1.0-0' }}</span>
  </div>
  <div class="tabs-and-search-container">
    <ng-container *ngIf="activeTab">
      <goa-block id="tab-container" gap="2xl">
        <a role="tab" type="tertiary" [ngClass]="{ active: activeTab === 'drafts' }" (click)="tabChanged('drafts')"
          ><span>Drafts</span>
          <goa-badge type="information" [content]="draftApplicationsCount"></goa-badge>
        </a>
        <a
          role="tab"
          type="tertiary"
          [ngClass]="{ active: activeTab === 'submitted' }"
          (click)="tabChanged('submitted')"
          ><span>Submitted/In review</span
          ><goa-badge type="information" [content]="submittedApplicationsCount"></goa-badge
        ></a>
        <a role="tab" type="tertiary" [ngClass]="{ active: activeTab === 'returned' }" (click)="tabChanged('returned')"
          ><span>Returned</span><goa-badge type="information" [content]="returnedApplicationsCount"></goa-badge
        ></a>
        <a role="tab" type="tertiary" [ngClass]="{ active: activeTab === 'accepted' }" (click)="tabChanged('accepted')"
          ><span>Accepted</span><goa-badge type="information" [content]="acceptedApplicationsCount"></goa-badge
        ></a>
        <a
          role="tab"
          type="tertiary"
          [ngClass]="{ active: activeTab === 'withdrawn' }"
          (click)="tabChanged('withdrawn')"
          ><span>Withdrawn</span><goa-badge type="information" [content]="withdrawnApplicationsCount"></goa-badge
        ></a>
        <a
          role="tab"
          type="tertiary"
          [ngClass]="{ active: activeTab === 'cash-flow-updates' }"
          (click)="tabChanged('cash-flow-updates')"
          ><span>Cash flow updates</span
          ><goa-badge type="information" [content]="cashFlowUpdateApplicationsCount"></goa-badge
        ></a>
      </goa-block>
    </ng-container>
    <div id="view-applications-search-field-container">
      <goa-input
        goaValue
        id="view-applications-search-field"
        placeholder="{{ 'searchLabel' | res }}"
        [formControl]="searchFormControl"
        [value]="searchFormControl.value"
        (keyup.enter)="applySearchFilter()"
        (keyup.backspace)="clearSearchFilters($event)"
      >
        <div
          slot="trailingContent"
          (click)="applySearchFilter()"
          style="background-color: #0070c4; color: white; padding-bottom: 11px; border-radius: 2.5px"
        >
          <goa-icon type="search"></goa-icon>
        </div>
      </goa-input>
    </div>
  </div>
  <goa-divider mb="xl"></goa-divider>
  <router-outlet></router-outlet>
</div>
