<div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
  <goa-callout
    #callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    *ngIf="showErrorFieldsCallout"
  >
    <ng-container *ngFor="let uiDef of pageUiDefs">
      <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link">
        {{ uiDef.labelText }}
        <br />
      </a>
    </ng-container>

    <!-- show extra custom error, and prevent duplicate field-label showing -->
    <a
      *ngIf="
        (UiDefActualReportingYearCost.errorMsg || shouldShowcombinedFundingVsReportingYearCostValidationMessage) &&
        !UiDefActualReportingYearCost.errorMsg
      "
      (click)="jumpToField(UiDefActualReportingYearCost.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefActualReportingYearCost.labelText }}<br
    /></a>

    <a
      *ngIf="
        (UiDefFundingFromOtherGrantPrograms.errorMsg ||
          shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
          shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage) &&
        !UiDefFundingFromOtherGrantPrograms.errorMsg
      "
      (click)="jumpToField(UiDefFundingFromOtherGrantPrograms.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefFundingFromOtherGrantPrograms.labelText }}<br
    /></a>
    <a
      *ngIf="
        (UiDefLgffFundingApplied.errorMsg ||
          shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
          shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
          shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage) &&
        !UiDefLgffFundingApplied.errorMsg
      "
      (click)="jumpToField(UiDefLgffFundingApplied.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefLgffFundingApplied.labelText }}<br
    /></a>
  </goa-callout>
</div>
<form [formGroup]="projectEditForm">
  <div class="edit-project-container">
    <goa-form-item [label]="'Application ID'" id="application-id">
      <p>{{ this.storedProjectToUpdate?.applicationId ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Application name'" id="application-name" class="application-name">
      <p>{{ this.storedProjectToUpdate?.applicationName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Application status'" id="application-status">
      <p>{{ 'status' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Project ID'" id="project-id">
      <p>{{ this.storedProjectToUpdate?.projectId ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Project name'" id="project-name" class="project-name">
      <p>{{ this.storedProjectToUpdate?.projectName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div *ngIf="UiDefProjectStatus; let UiDef" class="gene-app-info-wd">
      <goa-form-item
        class="app-section-item"
        [attr.id]="UiDef.id + '-wrap'"
        [label]="UiDef.labelText"
        [helptext]="UiDef.helptext"
      >
        <goa-dropdown
          width="22rem"
          maxheight="250px"
          relative="true"
          name="{{ UiDef.name }}"
          [attr.id]="UiDef.id"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          (focusin)="onFocusIn(UiDef, true)"
          (focusout)="onFocusOut(UiDef, true)"
          (blur)="autosave()"
        >
          <goa-dropdown-item
            *ngFor="let projectStatus of projectStatuses$ | async"
            id="{{ UiDefProjectStatus.id }} + {{ projectStatus.id + 1 }}"
            [value]="projectStatus.id"
            [label]="projectStatus.title"
          >
          </goa-dropdown-item>
        </goa-dropdown>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Total estimated LGFF funding'" id="total-estimated-funding-id">
      <p>{{ projectDescription }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'LGFF funding remaining'" id="funding-remaining-id" class="funding-remaining">
      <p>{{ projectDescription }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefActualReportingYearCost; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="false"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg || shouldShowcombinedFundingVsReportingYearCostValidationMessage"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>

          <div
            *ngIf="UiDef.errorMsg || shouldShowcombinedFundingVsReportingYearCostValidationMessage"
            class="error-msg amount-error-msg"
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <div *ngIf="shouldShowcombinedFundingVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsReportingYearCostValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>

        <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
      </div>
    </div>
    <goa-spacer vSpacing="xs"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefFundingFromOtherGrantPrograms; let UiDef">
      <!-- <div class="label" [attr.data-automation-id]="UiDef.id + '-label'">{{ UiDef.labelText }}</div> -->
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="false"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
              shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>

          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
              shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage
            "
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <div
              *ngIf="shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage"
              class="long-error-msg"
            >
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ fundingFromOtherGrantProgramsVsReportingYearCostValidationMessage }}
              </div>
            </div>
            <div *ngIf="shouldShowcombinedFundingVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsReportingYearCostValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
    </div>
    <goa-spacer vSpacing="xs"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefLgffFundingApplied; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>

          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowcombinedFundingVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage
            "
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum" class="error-msg amount-error-msg">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
            <div *ngIf="shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountAppliedVsReportingYearCostValidationMessage }}
              </div>
            </div>
            <div *ngIf="shouldShowcombinedFundingVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsReportingYearCostValidationMessage }}
              </div>
            </div>
            <div *ngIf="shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountAppliedVsFundingRemainingValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <goa-spacer vSpacing="xs"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefFundingFromMunicipalSources; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [attr.disabled]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="false"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
        </goa-form-item>
      </div>
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
  </div>
</form>
