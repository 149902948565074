import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { CashFlowApplicationUpdatesRecord } from '@app-com/api/models';
import { ResourcePipe } from '@app-com/pipes';
import {
  AutosaveCashFlowUpdateApplicationFunding,
  ResetCashFlowValidationResult,
  SetCashFlowApplicationFunding,
  SetCashFlowUpdateStep,
  SetCashFlowUpdateStepperStatus,
  SetCashFlowValidationResult,
  ToggleAreThereOtherSourcesOfFunding,
} from '@app-pot/store/actions/cash-flow-update.action';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import { Select, Store } from '@ngxs/store';
import {
  catchError,
  combineLatestWith,
  debounceTime,
  delay,
  Observable,
  skip,
  skipUntil,
  Subscription,
  takeWhile,
  throwError,
  timer,
} from 'rxjs';
import { Router } from '@angular/router';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import _ from 'lodash';
import { ApplicationExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CashFlowFormSequence } from '../enum/cash-flow-form-sequence';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { SetCurrentCashFlowUpdateStep } from '@app-pot/store/actions/current-context.action';

@Component({
  selector: 'app-application-funding',
  templateUrl: './application-funding.component.html',
  styleUrls: ['./application-funding.component.scss'],
})
export class ApplicationFundingComponent implements OnInit, AfterViewInit {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.cashFlow.applicationFunding;
  public ValidationPatterns = ValidationPatterns;

  pageId = 'APPLICATION_FUNDING';
  sub = new Subscription();
  functionalCategoriesSources: FormGroup;
  UiDefReasonForUpdate: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];

  nextClickValidation = false;
  shouldShowReasonTextBox = false;
  touchedName: string[] = [];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  applicationRecord: CashFlowApplicationUpdatesRecord = {};
  showDialog = false;
  isAutosaveNeeded = false;
  isAlive = true;
  organizationId: number;
  cashFlowId: number | undefined;
  isValid = false;

  @Select(CurrentContextState.getCurrentOrganizationId) organizationId$: Observable<number>;
  @Select(CashFlowUpdateState.getCashFlowUpdateCta) cashFlowCta$: Observable<
    'cancel' | 'confirm' | 'save' | 'previous'
  >;
  @Select(CashFlowUpdateState.getCashFlowUpdateValidationResult) cashFlowisValid$: Observable<boolean>;
  @Select(CurrentContextState.getCurrentCashFlowUpdateId) cashFlowId$: Observable<number | undefined>;
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    public res: ResourcePipe,
    private router: Router,
    private apiService: ApplicationExtService,
  ) {}
  ngAfterViewInit(): void {
    this.sub.add(
      this.functionalCategoriesSources
        .get('areThereOtherSourcesOfFunding')
        ?.valueChanges.pipe(debounceTime(100), skip(1))
        .subscribe((change) => {
          if (change != this.applicationRecord.areThereOtherSourcesOfFunding_Previous) {
            this.store.dispatch(new ToggleAreThereOtherSourcesOfFunding(change));
          } else {
            const changedOtherSourcesOfFunding = {
              isFundingFromAMWWP: this.functionalCategoriesSources.value['isFundingFromAMWWP'],
              isFundingFromCCBF: this.functionalCategoriesSources.value['isFundingFromCCBF'],
              isFundingFromSTIP: this.functionalCategoriesSources.value['isFundingFromSTIP'],
              isFundingFromMSI: this.functionalCategoriesSources.value['isFundingFromMSI'],
              isFundingFromWaterForLife: this.functionalCategoriesSources.value['isFundingFromWaterForLife'],
              isFundingFromOther: this.functionalCategoriesSources.value['isFundingFromOther'],
            };
            const previousOtherSourcesOfFunding = {
              isFundingFromAMWWP: this.applicationRecord.isFundingFromAMWWP_Previous,
              isFundingFromCCBF: this.applicationRecord.isFundingFromCCBF_Previous,
              isFundingFromSTIP: this.applicationRecord.isFundingFromSTIP_Previous,
              isFundingFromMSI: this.applicationRecord.isFundingFromMSI_Previous,
              isFundingFromWaterForLife: this.applicationRecord.isFundingFromWaterForLife_Previous,
              isFundingFromOther: this.applicationRecord.isFundingFromOther_Previous,
            };
            if (_.isEqual(changedOtherSourcesOfFunding, previousOtherSourcesOfFunding)) {
              this.store.dispatch(new ToggleAreThereOtherSourcesOfFunding(undefined)); // No change
            }
          }
        }),
    );

    const combined$ = this.functionalCategoriesSources.get('isFundingFromAMWWP')?.valueChanges.pipe(
      combineLatestWith(
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.functionalCategoriesSources?.get('isFundingFromCCBF')?.valueChanges!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.functionalCategoriesSources?.get('isFundingFromSTIP')?.valueChanges!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.functionalCategoriesSources?.get('isFundingFromMSI')?.valueChanges!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.functionalCategoriesSources?.get('isFundingFromWaterForLife')?.valueChanges!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.functionalCategoriesSources?.get('isFundingFromOther')?.valueChanges!,
      ),
    );

    this.sub.add(
      combined$
        ?.pipe(debounceTime(100), skip(1))
        .subscribe(
          ([
            isFundingFromAMWWP,
            isFundingFromCCBF,
            isFundingFromSTIP,
            isFundingFromMSI,
            isFundingFromWaterForLife,
            isFundingFromOther,
          ]) => {
            const changedOtherSourcesOfFunding = {
              isFundingFromAMWWP,
              isFundingFromCCBF,
              isFundingFromSTIP,
              isFundingFromMSI,
              isFundingFromWaterForLife,
              isFundingFromOther,
            };
            this._evaluateChangesToOtherSourcesOfFunding(changedOtherSourcesOfFunding);
          },
        ),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new SetCashFlowUpdateStep(CashFlowFormSequence.ApplicationFunding));
    this.store.dispatch(new SetCurrentCashFlowUpdateStep(CashFlowFormSequence.ApplicationFunding));
    this.cashFlowId$.pipe(delay(500)).subscribe((id) => {
      if (_.isEmpty(this.applicationRecord) && id && id > 0) {
        this.cashFlowId = id;
        this.sub.add(
          this.apiService
            .findOneCashFlowUpdate({ id: this.cashFlowId!, organizationId: this.organizationId })
            .pipe(
              catchError((err) => {
                console.error('Error in retrieving cash flow update', id);
                console.error(err);
                return throwError(err);
              }),
            )
            .subscribe({
              next: (value) => {
                this.applicationRecord = value.applicationUpdatesRecord;
                this._updateFormValues(this.applicationRecord);
                const changedOtherSourcesOfFunding = {
                  isFundingFromAMWWP: this.functionalCategoriesSources?.value['isFundingFromAMWWP'],
                  isFundingFromCCBF: this.functionalCategoriesSources?.value['isFundingFromCCBF'],
                  isFundingFromSTIP: this.functionalCategoriesSources?.value['isFundingFromSTIP'],
                  isFundingFromMSI: this.functionalCategoriesSources?.value['isFundingFromMSI'],
                  isFundingFromWaterForLife: this.functionalCategoriesSources?.value['isFundingFromWaterForLife'],
                  isFundingFromOther: this.functionalCategoriesSources?.value['isFundingFromOther'],
                };
                this._evaluateChangesToOtherSourcesOfFunding(changedOtherSourcesOfFunding);
              },
            }),
        );
      }
    });
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    const cashflowUpdatesAppRecord = this.store.selectSnapshot(
      CashFlowUpdateState.getCashFlowUpdate,
    ).applicationUpdatesRecord;
    this._prepareForm();

    if (cashflowUpdatesAppRecord) {
      this.applicationRecord = cashflowUpdatesAppRecord;
      this.timeoutIds.push(
        setTimeout(() => {
          this._updateFormValues(cashflowUpdatesAppRecord);
        }, 100),
      );
    }

    this.pageUiDefs.forEach((uiDef) => {
      uiDef.formCtr.valueChanges.pipe(debounceTime(300)).subscribe(() => {
        if (uiDef.focusedInNonBlankOrErrorField || uiDef.focusedOutFieldByTrueBlurEvent) {
          CommUtilsService.getUiFieldErrorList(uiDef, uiDef.focusedOutFieldByTrueBlurEvent ?? false, 'valueChanges');
        }
      });
    });

    this.sub.add(
      this.cashFlowisValid$.subscribe((isValid) => {
        if (isValid) {
          const appFunding = this.getApplicationRecord();
          this.store.dispatch(new SetCashFlowApplicationFunding(appFunding));
          this.store.dispatch(new ResetCashFlowValidationResult());
          this.store.dispatch(
            new SetCashFlowUpdateStepperStatus({
              [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
              [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.Complete,
              [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
            }),
          );
          this.store.dispatch(new SetCashFlowUpdateStep(3));
          this.store.dispatch(new SetCurrentCashFlowUpdateStep(3)); // Persist current cash flow update step in session storage to fix page refresh issues
          CommUtilsService.scrollToTop();
          this.router.navigate(['cash-flow-updates/project-updates']);
        }
      }),
    );

    this.sub.add(
      this.cashFlowCta$.subscribe((cta) => {
        if (cta && cta.indexOf('confirm') >= 0) {
          if (this.functionalCategoriesSources && this.validateApplication()) {
            this.store.dispatch(new SetCashFlowValidationResult(true));
          }
        }

        if (cta && cta.indexOf('save') >= 0) {
          const appFunding = this.getApplicationRecord();
          this.store.dispatch(new SetCashFlowApplicationFunding(appFunding));
          this.autosave();
        }
      }),
    );

    this.functionalCategoriesSources.setErrors(null);
    this.functionalCategoriesSources.markAsUntouched();
    this.functionalCategoriesSources.markAsPristine();
    this._registerChangeObserverForAutosaveFlag();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  get isFundingFromOther(): boolean {
    return this.functionalCategoriesSources?.get('isFundingFromOther')?.value === true;
  }

  get areThereOtherSourcesOfFunding(): boolean | undefined {
    return this.functionalCategoriesSources?.controls['areThereOtherSourcesOfFunding'].value;
  }

  get shouldShowOtherSourcesOfFundingYesButNoSourcesSelected(): boolean {
    return (
      (this.nextClickValidation ||
        this._isTouchedFcs('isFundingFromAMWWP') ||
        this._isTouchedFcs('isFundingFromCCBF') ||
        this._isTouchedFcs('isFundingFromSTIP') ||
        this._isTouchedFcs('isFundingFromMSI') ||
        this._isTouchedFcs('isFundingFromWaterForLife') ||
        this._isTouchedFcs('isFundingFromOther')) &&
      this._hasErrorFcs('otherSourcesOfFundingYesButNoSourcesSelected')
    );
  }

  get shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('fundingFromOther')) &&
      this._hasErrorFcs('otherSourcesOfFundingYesAndOtherSpecifyButEmpty')
    );
  }

  private _isTouchedFcs(controlName: string): boolean {
    const isTouched = this.functionalCategoriesSources?.get(controlName)?.touched ?? false;
    if (isTouched && !this.touchedName.includes(controlName)) {
      this.touchedName.push(controlName);
    }
    return isTouched;
  }

  private _evaluateChangesToOtherSourcesOfFunding(changedOtherSourcesOfFunding: {
    isFundingFromAMWWP: boolean;
    isFundingFromCCBF: boolean;
    isFundingFromSTIP: boolean;
    isFundingFromMSI: boolean;
    isFundingFromWaterForLife: boolean;
    isFundingFromOther: boolean;
  }) {
    const previousOtherSourcesOfFunding = {
      isFundingFromAMWWP: this.applicationRecord.isFundingFromAMWWP_Previous,
      isFundingFromCCBF: this.applicationRecord.isFundingFromCCBF_Previous,
      isFundingFromSTIP: this.applicationRecord.isFundingFromSTIP_Previous,
      isFundingFromMSI: this.applicationRecord.isFundingFromMSI_Previous,
      isFundingFromWaterForLife: this.applicationRecord.isFundingFromWaterForLife_Previous,
      isFundingFromOther: this.applicationRecord.isFundingFromOther_Previous,
    };
    console.log(
      'Changed',
      changedOtherSourcesOfFunding,
      previousOtherSourcesOfFunding,
      _.isEqual(changedOtherSourcesOfFunding, previousOtherSourcesOfFunding),
    );
    if (_.isEqual(changedOtherSourcesOfFunding, previousOtherSourcesOfFunding)) {
      this.store.dispatch(new ToggleAreThereOtherSourcesOfFunding(undefined)); // No change
    } else {
      this.store.dispatch(new ToggleAreThereOtherSourcesOfFunding(true));
    }
  }

  private _hasErrorFcs(errorCode: string): boolean {
    return this.functionalCategoriesSources?.hasError(errorCode);
  }

  get shouldShowOtherFundingSourceValidationMessage(): boolean {
    return (
      (this.nextClickValidation || this._isTouchedFcs('areThereOtherSourcesOfFunding')) &&
      this._hasErrorFcs('areThereOtherSourcesOfFundingQuestionNotAnswered')
    );
  }

  _updateFormValues(appPrevDetails: CashFlowApplicationUpdatesRecord) {
    let areThereOtherSourcesOfFunding;
    if (appPrevDetails.areThereOtherSourcesOfFunding_Updated === false) {
      areThereOtherSourcesOfFunding = appPrevDetails.areThereOtherSourcesOfFunding_Updated;
    } else if (appPrevDetails.areThereOtherSourcesOfFunding_Updated === true) {
      areThereOtherSourcesOfFunding = appPrevDetails.areThereOtherSourcesOfFunding_Updated;
    } else {
      areThereOtherSourcesOfFunding = appPrevDetails.areThereOtherSourcesOfFunding_Previous;
    }
    this.setRadioCheckedState('areThereOtherSourcesOfFunding', areThereOtherSourcesOfFunding as boolean);
    this.setRadioCheckedState('questionNo', !areThereOtherSourcesOfFunding);

    if (areThereOtherSourcesOfFunding) {
      this.functionalCategoriesSources?.patchValue({
        areThereOtherSourcesOfFunding: true,
        noOtherSourceOfFunding: false,
        isFundingFromOther: appPrevDetails.isFundingFromOther_Previous
          ? appPrevDetails.isFundingFromOther_Previous
          : appPrevDetails.isFundingFromOther_Updated,
        fundingFromOther: appPrevDetails.fundingFromOther_Previous
          ? appPrevDetails.fundingFromOther_Previous
          : appPrevDetails.fundingFromOther_Updated,
      });
    } else {
      this.functionalCategoriesSources?.patchValue({
        areThereOtherSourcesOfFunding: false,
        noOtherSourceOfFunding: true,
        fundingFromOther: null,
      });
    }

    this.functionalCategoriesSources?.patchValue({
      isFundingFromAMWWP:
        appPrevDetails.isFundingFromAMWWP_Updated ?? appPrevDetails.isFundingFromAMWWP_Previous ?? false,
      isFundingFromCCBF: appPrevDetails.isFundingFromCCBF_Updated ?? appPrevDetails.isFundingFromCCBF_Previous ?? false,
      isFundingFromSTIP: appPrevDetails.isFundingFromSTIP_Updated ?? appPrevDetails.isFundingFromSTIP_Previous ?? false,
      isFundingFromMSI: appPrevDetails.isFundingFromMSI_Updated ?? appPrevDetails.isFundingFromMSI_Previous ?? false,
      isFundingFromWaterForLife:
        appPrevDetails.isFundingFromWaterForLife_Updated ?? appPrevDetails.isFundingFromWaterForLife_Previous ?? false,
      isFundingFromOther:
        appPrevDetails.isFundingFromOther_Updated ?? appPrevDetails.isFundingFromOther_Previous ?? false,
      fundingFromOther: appPrevDetails.fundingFromOther_Updated ?? appPrevDetails.fundingFromOther_Previous,
    });

    if (appPrevDetails.reasonForFundingUpdate) {
      this.shouldShowReasonTextBox = true;
      this.functionalCategoriesSources.controls[this.UiDefReasonForUpdate.nameCtr].setValue(
        appPrevDetails.reasonForFundingUpdate,
      );
    }
  }

  private _prepareForm() {
    this.functionalCategoriesSources = this.formBuilder.group(
      {
        areThereOtherSourcesOfFunding: [undefined],
        isFundingFromAMWWP: [false],
        isFundingFromCCBF: [false],
        isFundingFromSTIP: [false],
        isFundingFromMSI: [false],
        isFundingFromWaterForLife: [false],
        isFundingFromOther: [false],
        fundingFromOther: [''],
        noOtherSourceOfFunding: [undefined],
      },
      {
        validators: [
          this._areThereOtherSourcesOfFundingValidator,
          this._otherSourcesOfFundingYesValidator,
          this._otherSourcesOfFundingYesOtherSpecifyValidator,
        ],
      },
    );

    this.UiDefReasonForUpdate = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.reasonForUpdate);
    this.functionalCategoriesSources?.addControl(this.UiDefReasonForUpdate.nameCtr, this.UiDefReasonForUpdate.formCtr);
    this.pageUiDefs = [this.UiDefReasonForUpdate];
  }

  private _areThereOtherSourcesOfFundingValidator(control: AbstractControl): ValidationErrors | null {
    const areThereOtherSourcesOfFunding = control.get('areThereOtherSourcesOfFunding');
    if (areThereOtherSourcesOfFunding?.value !== true && areThereOtherSourcesOfFunding?.value !== false) {
      return { areThereOtherSourcesOfFundingQuestionNotAnswered: true };
    }
    return null;
  }

  private _otherSourcesOfFundingYesValidator(control: AbstractControl): ValidationErrors | null {
    const areThereOtherSourcesOfFunding = control.get('areThereOtherSourcesOfFunding');
    if (areThereOtherSourcesOfFunding?.value !== true) return null;

    const fundingSources = [
      control.get('isFundingFromAMWWP'),
      control.get('isFundingFromCCBF'),
      control.get('isFundingFromSTIP'),
      control.get('isFundingFromMSI'),
      control.get('isFundingFromWaterForLife'),
      control.get('isFundingFromOther'),
    ];

    if (fundingSources.every((source) => source?.value !== true)) {
      return { otherSourcesOfFundingYesButNoSourcesSelected: true };
    }
    return null;
  }

  private static _specifyValidator(
    checkbox: AbstractControl | null,
    textbox: AbstractControl | null,
    errorCode: string,
  ): ValidationErrors | null {
    if (checkbox?.value !== true) return null;
    if (!textbox?.value?.trim()) return { [errorCode]: true };
    return null;
  }

  private _otherSourcesOfFundingYesOtherSpecifyValidator(control: AbstractControl): ValidationErrors | null {
    const isFundingFromOther = control.get('isFundingFromOther');
    const fundingFromOther = control.get('fundingFromOther');
    return ApplicationFundingComponent._specifyValidator(
      isFundingFromOther,
      fundingFromOther,
      'otherSourcesOfFundingYesAndOtherSpecifyButEmpty',
    );
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        elem.setAttribute('checked', checked ? 'true' : 'false');
      }
    });
  }

  otherSourcesOfFundingYesClicked() {
    this.functionalCategoriesSources?.patchValue({
      areThereOtherSourcesOfFunding: true,
      noOtherSourceOfFunding: false,
    });
    this.setRadioCheckedState('questionNo', false);
    this.setRadioCheckedState('areThereOtherSourcesOfFunding', true);
    this.checkValues();
  }

  otherSourcesOfFundingNoClicked() {
    this.functionalCategoriesSources?.patchValue({
      areThereOtherSourcesOfFunding: false,
      noOtherSourceOfFunding: true,
    });

    if (!this.applicationRecord.areThereOtherSourcesOfFunding_Previous) {
      [
        'isFundingFromAMWWP',
        'isFundingFromCCBF',
        'isFundingFromSTIP',
        'isFundingFromMSI',
        'isFundingFromWaterForLife',
        'isFundingFromOther',
      ].forEach((control) => {
        this.functionalCategoriesSources.controls[control].setValue(false);
        this.functionalCategoriesSources.controls[control].markAsUntouched();
      });
    }

    this.setRadioCheckedState('areThereOtherSourcesOfFunding', false);
    this.setRadioCheckedState('questionNo', true);
    this.checkValues();
  }

  onFcsTxtBoxBlur(controlName: string) {
    this.functionalCategoriesSources?.get(controlName)?.markAsTouched();
    this.checkValues();
  }

  private _markVisibleControlsAsTouched() {
    this.functionalCategoriesSources?.controls['areThereOtherSourcesOfFunding'].markAsTouched();

    if (this.areThereOtherSourcesOfFunding) {
      this.functionalCategoriesSources?.controls['isFundingFromAMWWP'].markAsTouched();
      this.functionalCategoriesSources?.controls['isFundingFromCCBF'].markAsTouched();
      this.functionalCategoriesSources?.controls['isFundingFromSTIP'].markAsTouched();
      this.functionalCategoriesSources?.controls['isFundingFromMSI'].markAsTouched();
      this.functionalCategoriesSources?.controls['isFundingFromWaterForLife'].markAsTouched();
      this.functionalCategoriesSources?.controls['isFundingFromOther'].markAsTouched();

      if (this.isFundingFromOther) {
        this.functionalCategoriesSources?.controls['fundingFromOther'].markAsTouched();
      }
    }
  }

  getFormValues() {
    return {
      areThereOtherSourcesOfFunding: this.functionalCategoriesSources?.controls['areThereOtherSourcesOfFunding'].value,
      isFundingFromAMWWP: this.functionalCategoriesSources?.controls['isFundingFromAMWWP'].value ? true : false,
      isFundingFromCCBF: this.functionalCategoriesSources?.controls['isFundingFromCCBF'].value ? true : false,
      isFundingFromSTIP: this.functionalCategoriesSources?.controls['isFundingFromSTIP'].value ? true : false,
      isFundingFromMSI: this.functionalCategoriesSources?.controls['isFundingFromMSI'].value ? true : false,
      isFundingFromWaterForLife: this.functionalCategoriesSources?.controls['isFundingFromWaterForLife'].value
        ? true
        : false,
      isFundingFromOther: this.functionalCategoriesSources?.controls['isFundingFromOther'].value ? true : false,
      fundingFromOther: this.functionalCategoriesSources?.controls['isFundingFromOther'].value
        ? this.functionalCategoriesSources?.controls['fundingFromOther'].value
        : undefined,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyPreviousAndUpdatedValues(data: any) {
    let hasChanged = false;

    for (const key in data) {
      if (key.endsWith('_Previous')) {
        const updatedKey = key.replace('_Previous', '_Updated');
        if (updatedKey in data) {
          if (data[key] !== data[updatedKey]) {
            hasChanged = true;
          } else {
            delete data[updatedKey];
          }
        }
      }
    }

    if (!hasChanged) {
      delete data.reasonForFundingUpdate;
    }

    return {
      hasChanged,
      data,
    };
  }

  checkValues() {
    const values = this.getFormValues();
    const applicationSnapshot = this.applicationRecord;
    const updatedApplication: CashFlowApplicationUpdatesRecord = {
      ...applicationSnapshot,
      areThereOtherSourcesOfFunding_Updated:
        values.areThereOtherSourcesOfFunding == applicationSnapshot.areThereOtherSourcesOfFunding_Previous
          ? null
          : values.areThereOtherSourcesOfFunding,
      isFundingFromMSI_Updated:
        values.isFundingFromMSI == applicationSnapshot.isFundingFromMSI_Previous ? undefined : values.isFundingFromMSI,
      isFundingFromCCBF_Updated:
        values.isFundingFromCCBF == applicationSnapshot.isFundingFromCCBF_Previous
          ? undefined
          : values.isFundingFromCCBF,
      isFundingFromAMWWP_Updated:
        values.isFundingFromAMWWP == applicationSnapshot.isFundingFromAMWWP_Previous
          ? undefined
          : values.isFundingFromAMWWP,
      isFundingFromSTIP_Updated:
        values.isFundingFromSTIP == applicationSnapshot.isFundingFromSTIP_Previous
          ? undefined
          : values.isFundingFromSTIP,
      isFundingFromWaterForLife_Updated:
        values.isFundingFromWaterForLife == applicationSnapshot.isFundingFromWaterForLife_Previous
          ? undefined
          : values.isFundingFromWaterForLife,
      isFundingFromOther_Updated:
        values.isFundingFromOther == applicationSnapshot.isFundingFromOther_Previous
          ? undefined
          : values.isFundingFromOther,
      fundingFromOther_Updated:
        values.fundingFromOther == applicationSnapshot.fundingFromOther_Previous ? undefined : values.fundingFromOther,
    };
    const filteredObj = CommUtilsService.removeNullOrUndefinedValues(updatedApplication);
    const { hasChanged } = this.verifyPreviousAndUpdatedValues(filteredObj);
    this.shouldShowReasonTextBox = hasChanged;

    this.autosave();
  }

  validateApplication() {
    if (this.shouldShowReasonTextBox) {
      this.functionalCategoriesSources.controls[this.UiDefReasonForUpdate.nameCtr].enable();
    } else {
      this.functionalCategoriesSources.controls[this.UiDefReasonForUpdate.nameCtr].disable();
    }
    this._markVisibleControlsAsTouched();

    if (this.pageUiDefs.length > 0) {
      this.pageUiDefs.forEach((uiDef) => {
        CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
      });
    }

    return this.functionalCategoriesSources?.valid;
  }

  handleOpen(event: Event) {
    event.preventDefault();
    const isNoSelected = this.functionalCategoriesSources.controls['noOtherSourceOfFunding'].value;

    if (!this.applicationRecord.areThereOtherSourcesOfFunding_Previous) {
      this.otherSourcesOfFundingNoClicked();
      return;
    }
    if (isNoSelected) {
      return;
    }
    this.showDialog = true;
  }

  handleClose(data: { isYesClicked: boolean }) {
    if (data.isYesClicked) {
      this.otherSourcesOfFundingNoClicked();
    }
    this.showDialog = false;
  }

  handleChecked() {
    this.checkValues();
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedOutFieldByTrueBlurEvent = true;
    }
    this.autosave();
    this.timeoutIds.push(
      setTimeout(() => {
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');
      }, 400),
    );
  }

  getApplicationRecord() {
    const values = this.getFormValues();
    const reasonForUpdateValue = this.functionalCategoriesSources?.controls[this.UiDefReasonForUpdate?.nameCtr]?.value;

    const applicationSnapshot = this.applicationRecord;
    const updatedApplication: CashFlowApplicationUpdatesRecord = {
      ...applicationSnapshot,
      areThereOtherSourcesOfFunding_Updated:
        values.areThereOtherSourcesOfFunding == applicationSnapshot.areThereOtherSourcesOfFunding_Previous
          ? null
          : values.areThereOtherSourcesOfFunding,
      isFundingFromMSI_Updated:
        values.isFundingFromMSI == applicationSnapshot.isFundingFromMSI_Previous ? undefined : values.isFundingFromMSI,
      isFundingFromCCBF_Updated:
        values.isFundingFromCCBF == applicationSnapshot.isFundingFromCCBF_Previous
          ? undefined
          : values.isFundingFromCCBF,
      isFundingFromAMWWP_Updated:
        values.isFundingFromAMWWP == applicationSnapshot.isFundingFromAMWWP_Previous
          ? undefined
          : values.isFundingFromAMWWP,
      isFundingFromSTIP_Updated:
        values.isFundingFromSTIP == applicationSnapshot.isFundingFromSTIP_Previous
          ? undefined
          : values.isFundingFromSTIP,
      isFundingFromWaterForLife_Updated:
        values.isFundingFromWaterForLife == applicationSnapshot.isFundingFromWaterForLife_Previous
          ? undefined
          : values.isFundingFromWaterForLife,
      isFundingFromOther_Updated:
        values.isFundingFromOther == applicationSnapshot.isFundingFromOther_Previous
          ? undefined
          : values.isFundingFromOther,
      fundingFromOther_Updated:
        values.fundingFromOther == applicationSnapshot.fundingFromOther_Previous ? null : values.fundingFromOther,
      reasonForFundingUpdate: reasonForUpdateValue ? reasonForUpdateValue : null,
    };

    const filteredObj = CommUtilsService.removeNullOrUndefinedValues(updatedApplication);
    const formattedApplication = this.verifyPreviousAndUpdatedValues(filteredObj);

    return formattedApplication.data;
  }

  private _registerChangeObserverForAutosaveFlag() {
    this.functionalCategoriesSources?.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        skipUntil(timer(500)), //When the form is open in edit mode and values are being populated, the change event may trigger multiple times. This is to skip those initial changes.
      )
      .subscribe(() => (this.isAutosaveNeeded = true));
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;

    return baseHasError;
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  autosave() {
    if (!this.isAutosaveNeeded) return;
    this.isAutosaveNeeded = false;
    const appRecord = this.getApplicationRecord(); // form contact data
    const storeAppRecord = this.store.selectSnapshot(CashFlowUpdateState.getCashFlowUpdate).applicationUpdatesRecord;
    if (_.isEqual(appRecord, storeAppRecord)) {
      return;
    }
    this.store.dispatch(new SetCashFlowApplicationFunding(appRecord));
    this.store.dispatch(new AutosaveCashFlowUpdateApplicationFunding({ applicationUpdatesRecord: appRecord }));
  }
}
