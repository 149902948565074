/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateWorknoteDto } from '../models/create-worknote-dto';
import { CsvAllPaymentDataExportDto } from '../models/csv-all-payment-data-export-dto';
import { PaymentDto } from '../models/payment-dto';
import { PaymentListDto } from '../models/payment-list-dto';
import { PaymentListFilter } from '../models/payment-list-filter';
import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';
import { ReconcilePaymentDto } from '../models/reconcile-payment-dto';
import { WorknoteDto } from '../models/worknote-dto';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `count_8()` */
  static readonly Count_8Path = '/api/payments/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.Count_8Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_12()` */
  static readonly FindAll_12Path = '/api/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.FindAll_12Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<Array<PaymentListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentListDto>>): Array<PaymentListDto> => r.body)
    );
  }

  /** Path part for operation `findOne_9()` */
  static readonly FindOne_9Path = '/api/payments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.FindOne_9Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<PaymentDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `createPayment()` */
  static readonly CreatePaymentPath = '/api/payments/create/{programBudgetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPayment$Response(
    params: {
      programBudgetId: number;
      organizationId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.CreatePaymentPath, 'post');
    if (params) {
      rb.path('programBudgetId', params.programBudgetId, {});
      rb.query('organizationId', params.organizationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPayment(
    params: {
      programBudgetId: number;
      organizationId?: number;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `markPaymentEligible()` */
  static readonly MarkPaymentEligiblePath = '/api/payments/mark-eligible/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markPaymentEligible()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentEligible$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.MarkPaymentEligiblePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markPaymentEligible$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentEligible(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<PaymentDto> {
    return this.markPaymentEligible$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `markPaymentIneligible()` */
  static readonly MarkPaymentIneligiblePath = '/api/payments/mark-ineligible/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markPaymentIneligible()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentIneligible$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.MarkPaymentIneligiblePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markPaymentIneligible$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentIneligible(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.markPaymentIneligible$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markPaymentAsOnHold()` */
  static readonly MarkPaymentAsOnHoldPath = '/api/payments/hold/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markPaymentAsOnHold()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentAsOnHold$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.MarkPaymentAsOnHoldPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markPaymentAsOnHold$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markPaymentAsOnHold(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.markPaymentAsOnHold$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `releasePaymentHold()` */
  static readonly ReleasePaymentHoldPath = '/api/payments/release-hold/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `releasePaymentHold()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  releasePaymentHold$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ReleasePaymentHoldPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `releasePaymentHold$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  releasePaymentHold(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.releasePaymentHold$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `cancelPayment()` */
  static readonly CancelPaymentPath = '/api/payments/cancel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelPayment$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.CancelPaymentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelPayment(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.cancelPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reconcilePayment()` */
  static readonly ReconcilePaymentPath = '/api/payments/reconcile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reconcilePayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reconcilePayment$Response(
    params: {
      id: number;
      body: ReconcilePaymentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ReconcilePaymentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reconcilePayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reconcilePayment(
    params: {
      id: number;
      body: ReconcilePaymentDto
    },
    context?: HttpContext
  ): Observable<PaymentDto> {
    return this.reconcilePayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `createApplicationWorknote_1()` */
  static readonly CreateApplicationWorknote_1Path = '/api/payments/worknote/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApplicationWorknote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationWorknote$Response(
    params: {
      paymentId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.CreateApplicationWorknote_1Path, 'post');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApplicationWorknote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationWorknote(
    params: {
      paymentId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.createApplicationWorknote$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `updateRemitMessage()` */
  static readonly UpdateRemitMessagePath = '/api/payments/remit-message/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRemitMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRemitMessage$Response(
    params: {
      paymentId: number;
      remitMessage: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.UpdateRemitMessagePath, 'patch');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
      rb.query('remitMessage', params.remitMessage, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRemitMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRemitMessage(
    params: {
      paymentId: number;
      remitMessage: string;
    },
    context?: HttpContext
  ): Observable<PaymentDto> {
    return this.updateRemitMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `updatePaidOn()` */
  static readonly UpdatePaidOnPath = '/api/payments/update-paid-on/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaidOn()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePaidOn$Response(
    params: {
      paymentId: number;
      paidOn: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.UpdatePaidOnPath, 'patch');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
      rb.query('paidOn', params.paidOn, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaidOn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePaidOn(
    params: {
      paymentId: number;
      paidOn: string;
    },
    context?: HttpContext
  ): Observable<PaymentDto> {
    return this.updatePaidOn$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailablePaymentActions()` */
  static readonly GetUserAvailablePaymentActionsPath = '/api/payments/available-actions/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailablePaymentActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailablePaymentActions$Response(
    params: {
      paymentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.GetUserAvailablePaymentActionsPath, 'get');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailablePaymentActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailablePaymentActions(
    params: {
      paymentId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailablePaymentActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `csvFindAllPaymentData()` */
  static readonly CsvFindAllPaymentDataPath = '/api/payments/csv/find-all-payment-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllPaymentData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllPaymentData$Response(
    params: {
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllPaymentDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.CsvFindAllPaymentDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllPaymentDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllPaymentData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllPaymentData(
    params: {
      body: PaymentListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllPaymentDataExportDto>> {
    return this.csvFindAllPaymentData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllPaymentDataExportDto>>): Array<CsvAllPaymentDataExportDto> => r.body)
    );
  }

  /** Path part for operation `pdfInvoiceSummaryReport()` */
  static readonly PdfInvoiceSummaryReportPath = '/api/payments/pdf/invoice-summary/{programBudgetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfInvoiceSummaryReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfInvoiceSummaryReport$Response(
    params: {
      programBudgetId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.PdfInvoiceSummaryReportPath, 'get');
    if (params) {
      rb.path('programBudgetId', params.programBudgetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfInvoiceSummaryReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfInvoiceSummaryReport(
    params: {
      programBudgetId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfInvoiceSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `pdfPaymentStatusReport()` */
  static readonly PdfPaymentStatusReportPath = '/api/payments/pdf/payment-status-summary/{programBudgetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfPaymentStatusReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfPaymentStatusReport$Response(
    params: {
      programBudgetId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.PdfPaymentStatusReportPath, 'get');
    if (params) {
      rb.path('programBudgetId', params.programBudgetId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfPaymentStatusReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfPaymentStatusReport(
    params: {
      programBudgetId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfPaymentStatusReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

}
