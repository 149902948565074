<div>
  <div id="errors-callout" class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
    <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
      <a
        *ngIf="checkProjectDetailsErrorCallout && !checkMediaCommunicationErrorCallout"
        (click)="jumpToField('hasMediaCommunication')"
        class="validation-message-link"
        >Project Details<br
      /></a>

      <a
        *ngIf="checkMediaCommunicationErrorCallout"
        (click)="jumpToField('hasMediaCommunication')"
        class="validation-message-link"
        >{{ 'mediaCommunicationFormError' | res: pageId }}<br
      /></a>
    </goa-callout>
  </div>

  <h2 id="gen-info-heading-1">{{ 'statementExpenseSectionHeading1' | res: pageId }}</h2>

  <div id="total-lgff-funding-available">
    {{ 'totalLgffFundingAvailable' | res: pageId }}:
    <span>{{ totalLgffFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</span>
  </div>

  <app-lgff-goa-nested-rows-table
    [headers]="headers"
    [showAdditionalHeader]="true"
    [additionalHeaders]="additionalHeaders"
    [tableData]="sepoExpenditures"
    childKey="projects"
    tableWidth="136.1875rem"
    [parentColumnIndent]="parentColumnIndent"
    [showTotalsColumn]="true"
    totalsColumnText="Total:"
    [totalsColumnIndent]="7"
    [totalsColDef]="totalColumn"
    [cellTemplates]="{
      expenditureId: editTemplate,
      estimatedTotalLGFFFunding: currencyTemplate,
      totalRemaining: currencyTemplate,
      actualReportingYearCost: currencyTemplate,
      amountFundedOtherGrantPrograms: currencyTemplate,
      fundingAppliedFromLGFF: currencyTemplate,
      fundingAppliedFromMunicipalSources: currencyTemplate,
      projectStatusTitle: badgeTemplate,
      applicationStatus: badgeTemplate
    }"
  ></app-lgff-goa-nested-rows-table>

  <ng-template #editTemplate let-row>
    <goa-icon size="small" class="action-icon" type="pencil" (click)="onEditProject(row)"></goa-icon>
  </ng-template>

  <ng-template #currencyTemplate let-data>
    <ng-container *ngIf="data !== null && data !== undefined; else dashTemplate">
      {{ data | currency: '$' : 'symbol' : '1.0-0' }}
    </ng-container>
    <ng-template #dashTemplate> - </ng-template>
  </ng-template>

  <ng-template #badgeTemplate let-data>
    <ng-container *ngIf="data !== null && data !== undefined; else defaultTemplate">
      <goa-badge type="{{ getType(data) }}" content="{{ data }}"></goa-badge>
    </ng-container>
    <ng-template #defaultTemplate> - </ng-template>
  </ng-template>

  <div class="future-funding-container">
    <div class="funding-text">
      <span>Total LGFF funding available for future years: </span>
    </div>
    <div class="funding-value">
      <span>{{ currentSepo.totalFutureFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</span>
    </div>
  </div>

  <goa-divider mt="xl" mb="xl"></goa-divider>

  <h2 id="gen-info-heading-1">{{ 'statementExpenseSectionHeading2' | res: pageId }}</h2>

  <div class="media-communication-form-container">
    <form [formGroup]="mediaCommunicationForm">
      <goa-form-item id="hasMediaCommunication" label="{{ 'mediaComunicationQuestionLabel' | res: pageId }}">
        <goa-radio-group
          name="hasMediaCommunication"
          formControlName="hasMediaCommunication"
          id="hasMediaCommunication"
          goaValue
          (click)="hasMediaCommunicationPlanClicked(true)"
        >
          <goa-radio-item
            name="hasMediaCommunication"
            label="Yes"
            [value]="true"
            [error]="errors['hasMediaCommunication']"
          ></goa-radio-item>
        </goa-radio-group>
        <div class="nestedStep" *ngIf="getMediaCommunicationPlanYes">
          <goa-form-item
            label="Please specify"
            helpText="Provide additional details about the application, event, or communication activities."
          >
            <goa-textarea
              type="text"
              formControlName="projectDetails"
              goaValue
              width="600px"
              countby="character"
              maxcount="150"
              name="projectDetails"
              value=""
              [error]="errors['projectDetails']"
              (focusout)="onFocusOut('projectDetails')"
              (focusin)="onFocusIn('projectDetails')"
            ></goa-textarea>
            <div class="field-validation-error-msg" *ngIf="errors['projectDetails']">
              <goa-icon size="small" type="warning" theme="filled"></goa-icon>
              <span>{{ errors['projectDetails'] }}</span>
            </div>
          </goa-form-item>
        </div>
      </goa-form-item>
      <goa-form-item>
        <goa-radio-group
          name="hasMediaCommunicationNo"
          id="hasMediaCommunicationNo"
          formControlName="hasMediaCommunicationNo"
          goaValue
          (click)="hasMediaCommunicationPlanClicked(false)"
        >
          <goa-radio-item
            name="hasMediaCommunicationNo"
            label="No"
            [value]="true"
            [error]="errors['hasMediaCommunicationNo']"
          ></goa-radio-item>
        </goa-radio-group>
        <div class="nestedStep disclaimer-container" *ngIf="getMediaCommunicationPlanNo">
          <p class="disclaimer-text">
            <span class="disclaimer-heading">Disclaimer:</span>
            {{ 'noDisclaimer' | res: pageId }}
            <span>
              <a href="mailto:{{ 'noDisclaimerMail' | res: pageId }}"> {{ 'noDisclaimerMail' | res: pageId }}.</a>
            </span>
          </p>
        </div>
        <div class="field-validation-error-msg" *ngIf="errors['hasMediaCommunicationNo']">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ errors['hasMediaCommunicationNo'] }}</span>
        </div>
      </goa-form-item>
    </form>
  </div>
</div>
<ng-container *ngIf="openSepoProjectUpdateModal">
  <app-lgff-goa-modal
    [id]="'app-edit-project-expenditures-modal'"
    [pageId]="pageId"
    [headingId]="'Edit project expenditures'"
    [open]="openSepoProjectUpdateModal"
    [actionBtnNumber]="2"
    [maxwidth]="'1024px'"
    [okBtnLabel]="'Save'"
    (okBtnClickedEvent)="closeSepoProjectEditModal()"
    (cancelBtnClickedEvent)="cancelSepoProjectEditModal()"
  >
    <app-edit-project-expenditures-modal
      (isProjectValid)="isProjectValid($event)"
      [storedProjectIdToUpdate]="projectToUpdate"
    ></app-edit-project-expenditures-modal>
  </app-lgff-goa-modal>
</ng-container>
