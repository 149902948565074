import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
  SetSEPOStatementExpendituresAndMediaPlan,
  SetSEPOProjectOutcomes,
  FetchSEPODetails,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import {
  TableHeader,
  TotalsColDef,
} from '@app-com/components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';
import { ProjectStatusType, SepoExtDto, SepoProjectExpenditureDto, SepoProjectOutcomeDto } from '@app-com/api/models';
import { SepoExpenditures, SepoExpendituresProject } from './sepo-expenditure-types';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes';

@Component({
  selector: 'app-edit-sepo-statement-expenditures',
  templateUrl: './edit-sepo-statement-expenditures.component.html',
  styleUrl: './edit-sepo-statement-expenditures.component.scss',
})
export class EditSepoStatementExpendituresComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getSepoDetails) currentSepo$: Observable<SepoExtDto>;
  currentStep: EditSepoSequence | undefined;
  headers: TableHeader[] = [];
  additionalHeaders: TableHeader[] = [];
  totalColumn: TotalsColDef[] = [];
  sepoExpenditures: SepoExpenditures[] = [];
  currentSepo: SepoExtDto = {} as SepoExtDto;
  CommUtilsService = CommUtilsService;

  totalLgffFundingAvailable: number = 0;
  parentColumnIndent = 3;

  mediaCommunicationForm: FormGroup;
  errors: { [key: string]: string } = {};
  editProjectPencilClicked: boolean;
  openSepoProjectUpdateModal = false;
  isProjectBeingEditedValid: boolean;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  projectToUpdate: SepoExpenditures;

  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this._prepareForm();
    this.getSepoExpenditures();
    this.initTableConfig();
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.StatementExpenditures) {
          console.log(
            'file: edit-sepo-statement-expenditures.component.ts:45 ~ EditSepoStatementExpendituresComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
              }),
            );
            this.updateProjectOutcomes();
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/immediate-outcomes']);
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/asset-management-and-funds');
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }

        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          if (this.validateOnNext()) {
            this.router.navigate(['/view-sepo']);
          }
        }
      }),
    );
  }

  private _prepareForm() {
    this.mediaCommunicationForm = this.formBuilder.group({
      hasMediaCommunication: [''],
      hasMediaCommunicationNo: [''],
      projectDetails: [''],
    });
  }

  getSepoExpenditures() {
    this.currentSepo$?.subscribe({
      next: (value) => {
        const sepoExpenditureData = value.sepoProjectExpenditures;
        this.currentSepo = value;
        this.totalLgffFundingAvailable = value.totalFundingAvailable;
        if (sepoExpenditureData?.length > 0) {
          this.sepoExpenditures = this.formatExpenditureData(sepoExpenditureData);
          this.totalColumn = this.calculateTotals(this.sepoExpenditures);
        }

        this.mediaCommunicationForm.patchValue({
          hasMediaCommunication: value.mediaCommunicationsPlan ? true : false,
          hasMediaCommunicationNo: value?.mediaCommunicationsPlan == false ? true : false,
        });

        if (value?.mediaCommunicationsPlanDetails.length > 0) {
          this.mediaCommunicationForm.patchValue({
            projectDetails: value.mediaCommunicationsPlanDetails,
          });
        }
      },
    });
  }

  initTableConfig() {
    this.headers = [
      {
        fieldName: 'applicationIdTxt',
        displayName: 'Application ID',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144 header-bg-gray',
      },
      {
        fieldName: 'applicationName',
        displayName: 'Application name',
        headerClassName: 'header-bg-gray w-160',
        cellClassName: 'w-160 header-bg-gray',
      },
      {
        fieldName: 'applicationStatus',
        displayName: 'Application status',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144 header-bg-gray',
      },
      {
        fieldName: 'projectIdTxt',
        displayName: 'Project ID',
        headerClassName: 'header-bg-gray w-141 td-8',
        cellClassName: 'w-141 td-8 header-bg-gray',
      },
      {
        fieldName: 'projectName',
        displayName: 'Project name',
        headerClassName: 'header-bg-gray w-165',
        cellClassName: 'w-165 header-bg-gray',
      },
      {
        fieldName: 'expenditureId',
        displayName: 'Edit',
        headerClassName: 'header-bg-gray w-64',
        cellClassName: 'w-64 header-bg-gray',
      },
      {
        fieldName: 'projectStatusTitle',
        displayName: 'Project status',
        headerClassName: 'header-bg-gray w-181',
        cellClassName: 'w-181 header-bg-gray',
      },
      {
        fieldName: 'estimatedTotalLGFFFunding',
        displayName: 'Total estimated LGFF funding',
        headerClassName: 'w-190 number-column',
        cellClassName: 'w-190 goa-table-number-column',
      },
      {
        fieldName: 'totalRemaining',
        displayName: 'LGFF funding remaining',
        headerClassName: 'w-190 number-column',
        cellClassName: 'w-190 goa-table-number-column',
      },
      {
        fieldName: 'actualReportingYearCost',
        displayName: 'Actual reporting year cost',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column ',
      },
      {
        fieldName: 'amountFundedOtherGrantPrograms',
        displayName: 'Portion funded by other grant programs',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column',
      },
      {
        fieldName: 'fundingAppliedFromLGFF',
        displayName: 'LGFF funding applied',
        headerClassName: 'header-bg-gray w-190 number-column',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
      },
      {
        fieldName: 'fundingAppliedFromMunicipalSources',
        displayName: 'Portion funded from municipal sources',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column',
      },
    ];

    this.additionalHeaders = [
      {
        displayName: 'Application and project details',
        colspan: 7,
        headerClassName: 'header-bg-gray additionalHeader-contact',
        fieldName: '',
      },
      {
        displayName: 'Application financial information',
        colspan: 2,
        fieldName: '',
        headerClassName: 'additionalHeader-contact',
      },
      {
        displayName: 'Reporting year qualifying cost',
        colspan: 4,
        headerClassName: 'header-bg-gray additionalHeader-contact',
        fieldName: '',
      },
    ];
  }

  formatExpenditureData(sepoProjectExpenditures: SepoProjectExpenditureDto[]): SepoExpenditures[] {
    return sepoProjectExpenditures.reduce((acc: SepoExpenditures[], current: SepoProjectExpenditureDto) => {
      const existingApplication = acc.find((app) => app.applicationId === current.applicationId);

      const project: SepoExpendituresProject = {
        expenditureId: current.id,
        projectId: current.projectId,
        projectIdTxt: current.projectIdTxt,
        projectName: current.projectName,
        status: current.status,
        projectStatusTitle: current.projectStatusTitle,
        estimatedTotalLGFFFunding: current.estimatedTotalLGFFFunding,
        totalRemaining: current.totalRemaining,
        actualReportingYearCost: current.actualReportingYearCost,
        amountFundedOtherGrantPrograms: current.amountFundedOtherGrantPrograms,
        fundingAppliedFromLGFF: current.fundingAppliedFromLGFF,
        fundingAppliedFromMunicipalSources: current.fundingAppliedFromMunicipalSources,
      };

      if (existingApplication) {
        existingApplication.projects.push(project);
        existingApplication.applicationStatus = this.getApplicationStatus(existingApplication.projects); // Update status
      } else {
        const newApplication: SepoExpenditures = {
          id: current.id,
          applicationId: current.applicationId,
          applicationIdTxt: current.applicationIdTxt,
          applicationName: current.applicationName,
          createdAt: current.createdAt,
          createdBy: current.createdBy,
          createdByName: current.createdByName,
          updatedAt: current.updatedAt,
          updatedBy: current.updatedBy,
          updatedByName: current.updatedByName,
          applicationStatus: this.getApplicationStatus([project]), // Initial status
          projects: [project],
        };

        acc.push(newApplication);
      }

      return acc;
    }, []);
  }

  calculateTotals(formattedData: SepoExpenditures[]): TotalsColDef[] {
    const totals: { [key: string]: number } = {
      estimatedTotalLGFFFunding: 0,
      totalRemaining: 0,
      actualReportingYearCost: 0,
      amountFundedOtherGrantPrograms: 0,
      fundingAppliedFromLGFF: 0,
      fundingAppliedFromMunicipalSources: 0,
    };

    formattedData.forEach((app) => {
      app.projects.forEach((project) => {
        totals['estimatedTotalLGFFFunding'] += project.estimatedTotalLGFFFunding || 0;
        totals['totalRemaining'] += project.totalRemaining || 0;
        totals['actualReportingYearCost'] += project.actualReportingYearCost || 0;
        totals['amountFundedOtherGrantPrograms'] += project.amountFundedOtherGrantPrograms || 0;
        totals['fundingAppliedFromLGFF'] += project.fundingAppliedFromLGFF || 0;
        totals['fundingAppliedFromMunicipalSources'] += project.fundingAppliedFromMunicipalSources || 0;
      });
    });

    return Object.keys(totals).map(
      (key): TotalsColDef => ({
        totalClass: 'goa-table-number-column totals-font',
        totalsKey: key,
        totalsValue: totals[key],
      }),
    );
  }

  getType(type: string) {
    if (type == 'Not Started' || type == 'In Progress') {
      return 'information';
    } else if (type == 'Completed') {
      return 'success';
    } else {
      return 'emergency';
    }
  }

  getApplicationStatus(projects: SepoExpendituresProject[]): string | null {
    const statuses = projects.map((project) => project.status);

    if (statuses.every((status) => status === null)) {
      return null;
    } else if (statuses.every((status) => status === ProjectStatusType.Withdrawn)) {
      return 'Withdrawn';
    } else if (statuses.every((status) => status === ProjectStatusType.NotStarted)) {
      return 'Not Started';
    } else if (
      statuses.every((status) => status === ProjectStatusType.Completed || status === ProjectStatusType.Withdrawn)
    ) {
      return 'Completed';
    } else {
      return 'In Progress';
    }
  }

  get getMediaCommunicationPlanYes() {
    return this.mediaCommunicationForm.get('hasMediaCommunication')?.value;
  }
  get getMediaCommunicationPlanNo() {
    return this.mediaCommunicationForm.get('hasMediaCommunicationNo')?.value;
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = Object.keys(this.errors).length > 0 ? true : false;
    return baseHasError;
  }

  get checkMediaCommunicationErrorCallout() {
    let baseHasError = false;

    this.checkMediaCommunicationError();
    baseHasError = Object.keys(this.errors).includes('hasMediaCommunication') ? true : false;

    return baseHasError;
  }

  get checkProjectDetailsErrorCallout() {
    let baseHasError = false;

    this.checkMediaCommunicationError();
    baseHasError = Object.keys(this.errors).includes('projectDetails') ? true : false;

    return baseHasError;
  }

  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
        if (checkedValue !== undefined) {
          elem.setAttribute('checked', checked ? 'true' : 'false');
        }
      }
    });
  }

  hasMediaCommunicationPlanClicked(answer: boolean) {
    if (answer === true) {
      this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].setValue(false);
      this.setRadioCheckedState('hasMediaCommunicationNo', false);
      this.mediaCommunicationForm.controls['hasMediaCommunication'].setValue(true);
      this.setRadioCheckedState('hasMediaCommunication', true);
    } else {
      this.mediaCommunicationForm.controls['hasMediaCommunication'].setValue(false);
      this.setRadioCheckedState('hasMediaCommunication', false);
      this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].setValue(true);
      this.setRadioCheckedState('hasMediaCommunicationNo', true);
    }

    Object.keys(this.mediaCommunicationForm.controls).forEach((control) => {
      delete this.errors[control];
      this.autosave();
    });
  }

  getErrorMessage(name: string): string {
    switch (name) {
      case 'hasMediaCommunication':
      case 'hasMediaCommunicationNo':
        if (
          (this.mediaCommunicationForm.get('hasMediaCommunication')?.value ?? false) == false &&
          (this.mediaCommunicationForm.get('hasMediaCommunicationNo')?.value ?? false) == false
        ) {
          return this.res.transform('mediaCommunicationFormError', this.pageId);
        }
        break;

      case 'projectDetails':
        if (
          this.mediaCommunicationForm.get('projectDetails')?.value == null ||
          ((this.mediaCommunicationForm.get('projectDetails')?.value ?? '') == '' &&
            (this.mediaCommunicationForm.get('projectDetails')?.value ?? '').trim()?.length == 0)
        ) {
          return this.res.transform('projectDetailsError', this.pageId);
        }
        break;
      default:
        return '';
    }
    return '';
  }

  onFocusOut(controlName: string) {
    if (this.getErrorMessage(controlName)) {
      this.errors[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errors[controlName];
      this.autosave();
    }
  }

  onFocusIn(controlName: string) {
    delete this.errors[controlName];
  }

  checkMediaCommunicationError() {
    Object.keys(this.mediaCommunicationForm.controls).forEach((control) => {
      if (this.getErrorMessage(control)) {
        this.errors[control] = this.getErrorMessage(control);
      } else {
        delete this.errors[control];
      }
    });

    if (this.mediaCommunicationForm.controls['hasMediaCommunicationNo'].value) {
      this.errors = {};
    }
  }

  getExpenditureData(): Pick<
    SepoExtDto,
    'mediaCommunicationsPlan' | 'mediaCommunicationsPlanDetails' | 'sepoProjectExpenditures'
  > {
    const hasMediaCommunication = this.mediaCommunicationForm.controls['hasMediaCommunication'].value;
    return {
      mediaCommunicationsPlan: hasMediaCommunication ? true : false,
      mediaCommunicationsPlanDetails: hasMediaCommunication
        ? this.mediaCommunicationForm.controls['projectDetails'].value
        : '',
      sepoProjectExpenditures: this.currentSepo.sepoProjectExpenditures,
    };
  }

  validateOnPrevious(): boolean {
    return true;
  }

  autosave() {
    this.store.dispatch(new SetSEPOStatementExpendituresAndMediaPlan(this.getExpenditureData()));
  }

  validateOnNext(): boolean {
    this.checkMediaCommunicationError();
    if (Object.keys(this.errors).length == 0) {
      this.autosave();
      return true;
    } else {
      setTimeout(() => {
        this.jumpToField('errors-callout');
      }, 200);
      return false;
    }
  }

  onEditProject(expenditureId: number) {
    console.log('[SEPO Project Updates] On edit Project', expenditureId);
    this.editProjectPencilClicked = true;
    // this.projectToUpdate = projectId; //.projects?.find((pr) => pr.projectId == 1);
    //this.projectToUpdate = project;
    this.openSepoProjectUpdateModal = true;
  }

  updateProjectOutcomes() {
    const projectOutcomes: Partial<SepoProjectOutcomeDto>[] = [];
    const sepoExpendituresArray = this.store.selectSnapshot(EditSepoState.getSepoDetails).sepoProjectExpenditures;
    const sepoOutcomesArray = this.store.selectSnapshot(EditSepoState.getSepoDetails).sepoProjectOutcomes;

    if (sepoExpendituresArray && sepoExpendituresArray?.length > 0) {
      sepoExpendituresArray.map((exp) => {
        const alreadyExists = sepoOutcomesArray?.some((outcome) => outcome.projectId === exp.projectId);

        if (exp.status === ProjectStatusType.Completed && !alreadyExists) {
          projectOutcomes.push({
            projectId: exp.projectId,
            projectIdTxt: exp.projectIdTxt,
            projectName: exp.projectName,
            applicationId: exp.applicationId,
            applicationIdTxt: exp.applicationIdTxt,
            applicationName: exp.applicationName,
            sepoId: exp.sepoId,
          });
        }
      });
    }
    this.store.dispatch(
      new SetSEPOProjectOutcomes({ sepoProjectOutcomes: projectOutcomes as SepoProjectOutcomeDto[] }),
    );
    this.store.dispatch(new FetchSEPODetails());
  }

  closeSepoProjectEditModal() {
    console.log('Save button clicked');
    this.store.dispatch(new SetEditSepoButtonAction(['save']));

    if (this.isProjectBeingEditedValid) {
      this.timeoutIds.push(
        setTimeout(() => {
          this.openSepoProjectUpdateModal = false;
        }, 1000),
      );
    } else {
      this.openSepoProjectUpdateModal = true;
    }
  }
  isProjectValid(e: boolean) {
    console.log('[PROJECT UPDATES]Is project valid', e);
    this.isProjectBeingEditedValid = e;
  }
  cancelSepoProjectEditModal() {
    this.openSepoProjectUpdateModal = false;
  }
}
