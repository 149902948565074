import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, delay, filter, take, tap } from 'rxjs';
import { ApplicationsState } from '@app-pot/store/state/applications.state';
import { RemoveApplicationFromStateByDraftId } from '@app-pot/store/actions/applications.action';
import { DeleteApplicationDraft } from '@app-pot/store/actions/application-draft.action';
import { ViewApplicationDrafts } from '@app-pot/features/grant-application/models';
import { LoadFunctionalCategoryTypes, LoadPrimaryOutcomes } from '@app-pot/store/actions/lookup-value.action';
import { CapitalAssetTypeLV, LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { LoadAllCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { ApplicationDraftDto, ApplicationDraftFilter } from '@app-com/api/models';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { ClearApplicationDraftContext } from '@app-pot/store/actions/current-context.action';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ApplicationStatus } from '@app-pot/features/grant-application/models/enums/application-status';
import { ViewApplicationTab } from '../view-application-tab.enum';
import { ApplicationUrlLocationHistoryState } from '@app-pot/features/grant-application/models/application-url-location-history-state';
import { ActiveTabService } from '../active-tab.service';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ApplicationExtService } from '@app-com/api/services';

@Component({
  selector: 'app-draft-applications',
  templateUrl: './draft-applications.component.html',
  styleUrls: ['../common-tab-style.scss'],
})
export class DraftApplicationsComponent implements OnInit, OnDestroy {
  @Select(ApplicationsState.fetchApplications) fetchApplications$: Observable<ApplicationDraftDto[]>;
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;
  @Output() filterTableData: EventEmitter<string> = new EventEmitter<string>();

  pageId = 'DRAFT_APPLICATIONS';
  applications: ViewApplicationDrafts[];
  filteredApplications: ViewApplicationDrafts[];
  expandableRows: true;
  isAlive = true;
  allCapitalAssetTypesCache: CapitalAssetTypeLV[] = [];
  sub = new Subscription();

  appIdToDelete = 0;
  appNameToDelete = 'NoSel';
  wantCancelDeleteDraftAppInList = false;
  organizationIdSelected: number;
  draftApplicationsCount: number = 0;

  CommUtilsService = CommUtilsService;

  currentPage = 1;
  perPageCount = 25;
  pageSizeOptions = [10, 25, 50, 100];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  searchTerm: string = '';
  isLoading: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private snackBarService: SnackBarService,
    private activeTabService: ActiveTabService,
    private applicationService: ApplicationExtService,
  ) {
    this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
      this.organizationIdSelected = currentOrganizationId;
    });
    this.sub.add(
      this.store.select(ApplicationsState.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );

    this.getDraftsCount();
  }

  ngOnInit(): void {
    this.getDraftAppData();
    this.sub.add(
      this.activatedRoute.url.subscribe((segments) => {
        this.activeTabService.setActiveTab(segments[0].toString());
      }),
    );

    this.sub.add(
      this.allCapitalAssetTypes$.subscribe((data) => {
        this.allCapitalAssetTypesCache = data ?? [];
      }),
    );
    this.sub.add(
      this.activeTabService
        .getActiveTabSearch()
        .pipe(
          delay(500),
          filter((search) => search !== undefined),
        )
        .subscribe((search) => {
          this.searchTerm = search as string;
          this.searchTableData();
        }),
    );
  }

  public getDraftAppData() {
    this.isLoading = true;
    this.activeTabService.setIsLoading(true);
    this.store.dispatch(new LoadPrimaryOutcomes());
    this.store.dispatch(new LoadFunctionalCategoryTypes());
    this.store.dispatch(new LoadAllCapitalAssetTypes());
    this.sub.add(
      combineLatest([this.primaryOutcomes$, this.functionalCategoryTypes$])
        .pipe(
          tap(([primaryOutcomesLookupValues, functionalCategoryTypeValues]) => {
            this.applicationService.findAllDrafts(this.configQuery(this.organizationIdSelected)).subscribe({
              next: (applicationDrafts) => {
                this.isLoading = false;
                this.activeTabService.setIsLoading(false);
                this.applications = applicationDrafts.map((applicationDraft) =>
                  this.toViewApplicationDraft(
                    applicationDraft,
                    primaryOutcomesLookupValues,
                    functionalCategoryTypeValues,
                  ),
                );

                this.applications.sort(
                  (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
                    (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1) * -1,
                );
                this.filteredApplications = this.applications;
              },
              error: () => {
                this.isLoading = false;
                this.activeTabService.setIsLoading(false);
              },
            });
          }),
        )
        .subscribe(),
    );
  }

  public getDraftsCount() {
    this.applicationService.countAllDrafts(this.configQuery(this.organizationIdSelected)).subscribe((count) => {
      this.timeoutIds.push(
        setTimeout(() => {
          this.draftApplicationsCount = count;
          this.activeTabService.setListCount(count);
        }),
      );
    });
  }

  public configQuery(organizationId: number) {
    const body: ApplicationDraftFilter = { organizationId: { eq: organizationId } };
    if (this.searchTerm) {
      body.SEARCH = this.searchTerm?.toLocaleLowerCase();
    }

    const query = {
      take: this.perPageCount,
      skip: (this.currentPage - 1) * this.perPageCount,
      body: body,
      organizationId: organizationId,
    };
    return query;
  }

  public searchTableData(): void {
    this.isLoading = true;
    const primaryOutcomesLookupValues = this.store.selectSnapshot(LookupValueState.getPrimaryOutcomes);
    const functionalCategoryTypeValues = this.store.selectSnapshot(LookupValueState.getFunctionalCategoryTypes);
    this.applicationService.findAllDrafts(this.configQuery(this.organizationIdSelected)).subscribe({
      next: (value) => {
        this.isLoading = false;
        const filteredData = value?.map((app) =>
          this.toViewApplicationDraft(app, primaryOutcomesLookupValues, functionalCategoryTypeValues),
        );

        this.filteredApplications = filteredData;
        if (this.searchTerm) {
          this.draftApplicationsCount = filteredData?.length;
          this.currentPage = 1;
          this.perPageCount = 25;
          this.activeTabService.setListCount(filteredData?.length);
        } else {
          this.getDraftsCount();
        }
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  toViewApplicationDraft(
    applicationDraft: ApplicationDraftDto,
    primaryOutcomesLookupValues: LookupValue[],
    functionalCategoryTypeValues: LookupValue[],
  ): ViewApplicationDrafts {
    const lookupValuePrimaryOutcome = primaryOutcomesLookupValues.find(
      (value) => value.id === applicationDraft.applicationRecord.primaryOutcomeId,
    );
    const primaryFunctionalCategoryId =
      applicationDraft.applicationRecord.functionalCategories?.find((x) => x.isPrimary === true)
        ?.functionalCategoryTypeId || 0;
    const lookupValuePrimaryFunctionalCategory = functionalCategoryTypeValues.find(
      (value) => value.id === primaryFunctionalCategoryId,
    );

    return {
      id: applicationDraft.id || 0,
      name: applicationDraft.applicationRecord.name,
      primaryFunctionalCategoryTitle: lookupValuePrimaryFunctionalCategory
        ? lookupValuePrimaryFunctionalCategory.title
        : '',
      primaryOutcomeTitle: lookupValuePrimaryOutcome ? lookupValuePrimaryOutcome.title : '',
      createdAt: applicationDraft.createdAt ?? '',
      projects: applicationDraft.applicationRecord.projects ?? [],
    };
  }

  onEditClick(applicationId: number) {
    this.store.dispatch(new ClearApplicationDraftContext());
    const state: ApplicationUrlLocationHistoryState = { applicationDraftId: applicationId };
    this.router.navigate(['grant-application'], { state });
  }

  onAppInfoClick(applicationId: number) {
    const url =
      '/application-summary/' + ApplicationStatus.Draft + '/' + this.organizationIdSelected + '/' + applicationId;
    const width = Math.min(1024, window.screen.availWidth - 150);
    const height = Math.min(1439, window.screen.availHeight - 100);
    window.open(
      url,
      '_blank',
      `toolbar=yes, scrollbars=yes, resizable=yes,
       width=${width}, height=${height}`,
    );
  }

  onAppDownloadClick(applicationId: number) {
    alert('App Download clicked ' + applicationId);
  }

  onDeleteClick(applicationId: number) {
    if (!this.wantCancelDeleteDraftAppInList) {
      this.appIdToDelete = applicationId;
      this.appNameToDelete = this.applications.find((app) => app.id == applicationId)?.name ?? '';
      this.wantCancelDeleteDraftAppInList = true;
    }
  }

  noCancelDeleteDraftAppInListHandler() {
    this.wantCancelDeleteDraftAppInList = false;
  }

  yesDeleteDraftAppInListHandler() {
    if (!this.wantCancelDeleteDraftAppInList) return;
    this.wantCancelDeleteDraftAppInList = false;
    console.log('want delete draft in list id: ' + this.appIdToDelete + ', name: ' + this.appNameToDelete);

    if (!this.appIdToDelete || this.appIdToDelete <= 0) {
      console.log('DeleteApplicationDraft with an invalid draft id!');
      this.router.navigate(['/view-applications', ViewApplicationTab.Drafts]);
    } else {
      // let successConfirm = this._res.transform('deleteSuccessConfirm', 'DRAFT_APPLICATIONS');
      const successConfirm = "Your '" + this.appNameToDelete + "' application was successfully deleted.";

      this.store.dispatch(new RemoveApplicationFromStateByDraftId(this.appIdToDelete));

      this.sub.add(
        this.store
          .dispatch(new DeleteApplicationDraft(this.appIdToDelete))
          .pipe(take(1))
          .subscribe({
            next: (data) => {
              console.log('DeleteApplicationDraft in backend successed');
              console.log(data);
              this.snackBarService.showSuccessMessage(successConfirm);
              this.router.navigate(['/view-applications', ViewApplicationTab.Drafts]);
              this.searchTableData();
            },
            error: (error) => {
              console.log('DeleteApplicationDraft in backend failed');
              console.log(error);
              this.router.navigate(['/view-applications', ViewApplicationTab.Drafts]);
            },
          }),
      );
    }
  }

  // sort handler
  handleSort(event: Event) {
    if (!(event instanceof CustomEvent)) return;
    const { sortBy, sortDir } = event.detail;
    if (sortBy === 'createdAt') {
      this?.filteredApplications?.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (new Date(a[sortBy]) > new Date(b[sortBy]) ? 1 : -1) * sortDir,
      );
    } else {
      this?.filteredApplications?.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1) * sortDir,
      );
    }
  }

  public getCapitalAssetTypeTitle(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';

    const title = this.allCapitalAssetTypesCache.find((item) => item.id === id)?.title ?? '' + id;
    return title;
  }

  getAdditionalCapitalAssetTypeTitle(additionalCapitalAssetTypeIds: number[]) {
    if (!additionalCapitalAssetTypeIds || additionalCapitalAssetTypeIds.length == 0) {
      return '-';
    }
    return this.allCapitalAssetTypesCache
      .filter((cat) => additionalCapitalAssetTypeIds?.includes(cat.id))
      .map((filteredCat) => filteredCat.title.replace(/\([^)]*\)/g, '').trim())
      .join(', ')
      .trim();
  }

  toggleApplicationDetails(applicationId: number) {
    this.filteredApplications = this.filteredApplications.map((_) => {
      if (_.id === applicationId) return { ..._, detailsShown: !_.detailsShown };
      return _;
    });
  }

  handlePerPageChange(event: Event) {
    const e = event as CustomEvent;
    this.perPageCount = parseInt(e.detail.value, 10);
    this.searchTableData();
    this.getDraftsCount();
  }

  handlePageChange(event: Event) {
    const e = event as CustomEvent;
    this.currentPage = e.detail.page;
    this.searchTableData();
    this.getDraftsCount();
  }
}
