import { Component, Input, OnInit } from '@angular/core';
import { PdfService, SepoExtService } from '@app-com/api/services';
import { DownloadPdfLinkComponent } from '@app-pot/shared/components/download-pdf-link/download-pdf-link.component';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-download-sepo-pdf',
  template:
    '<app-download-pdf-link [textPosition]="textPosition" iconSize="medium" [linkText]="linkText" [pdfGenerationApiCall]="pdfGenerationApiCall"></app-download-pdf-link>',
})
export class DownloadSepoPdfComponent extends DownloadPdfLinkComponent implements OnInit {
  @Input() sepoId: number;
  constructor(
    private readonly sepoService: SepoExtService,
    pdfService: PdfService,
    store: Store,
  ) {
    super(pdfService, store);
  }

  ngOnInit(): void {
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.pdfGenerationApiCall = () => this.sepoService.pdfSepoDetails({ organizationId, sepoId: this.sepoId });
  }
}
