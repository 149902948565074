<div *ngIf="cf">
  <div *ngIf="viewType == cashFlowUpdateViewType.Review">
    <goa-callout
      type="emergency"
      heading="The following items require attention:"
      size="large"
      *ngIf="!isApplicationfundingUpdated && !isProjectUpdated"
    >
      <b
        >At least one change must be made to make a cash flow update. Navigate to
        <a class="navigation-link" (click)="stepRoute(cashFlowFormSequence.ApplicationFunding)">Application funding</a>
        or <a class="navigation-link" (click)="stepRoute(cashFlowFormSequence.ProjectUpdates)">Project updates</a> to
        make a change.</b
      >
    </goa-callout>
    <goa-callout
      type="important"
      heading="Review your withdrawn selection."
      size="large"
      *ngIf="areAllProjectsWithdrawn"
    >
      You are selecting to withdraw all projects. Confirming this will also withdraw your application once approved.
    </goa-callout>
  </div>
  <div *ngIf="viewType == cashFlowUpdateViewType.Summary">
    <div style="display: flex; align-items: center; gap: 1rem">
      <h2 id="app-heading-h2">Cash flow Update: {{ cashFlowUpdateRecord.idTxt }}</h2>
      <div>
        <p
          class="font-size-14 badge-style information"
          *ngIf="
            cashFlowUpdateRecord.status != cashFlowUpdateStatusType.Accepted &&
            cashFlowUpdateRecord.status != cashFlowUpdateStatusType.Returned
          "
        >
          {{ cashFlowUpdateRecord.statusTitle }}
        </p>
        <p
          class="font-size-14 badge-style success"
          *ngIf="cashFlowUpdateRecord.status == cashFlowUpdateStatusType.Accepted"
        >
          {{ cashFlowUpdateRecord.statusTitle }}
        </p>
        <p
          class="font-size-14 badge-style emergency"
          *ngIf="cashFlowUpdateRecord.status == cashFlowUpdateStatusType.Returned"
        >
          {{ cashFlowUpdateRecord.statusTitle }}
        </p>
      </div>
    </div>
    <p class="font-24-400-lh-32 text-uppercase">
      {{ organizationTitle + ' (' + organizationLgCode + ')' }}
    </p>
    <p class="font-24-400-lh-32">Application {{ cf.applicationIdTxt }} - {{ cf.applicationName }}</p>
    <div class="nonPrintable-btn padding-top-16">
      <goa-button type="secondary" leadingIcon="print" (_click)="onClickPrint()">Print</goa-button>
    </div>
    <hr class="divider-32" />
  </div>
  <div>
    <p id="section-heading-font">Application funding</p>
    <div *ngIf="isApplicationfundingUpdated">
      <div>
        <h4 id="section-sub-heading-font">Are there other sources of provincial or federal grant funding?</h4>
        <p>
          {{ areThereOtherSourcesOfFunding ? 'Yes' : 'No' }}
        </p>
        <p class="font-size-14">
          Previously:
          {{ cashFlowUpdateRecord.applicationUpdatesRecord.areThereOtherSourcesOfFunding_Previous ? 'Yes' : 'No' }}
        </p>
      </div>
      <div>
        <h4 id="section-sub-heading-font" class="padding-bottom-8">Source</h4>
        <p>{{ applicationfundingSourceUpdates }}</p>
        <p class="font-size-14">Previously: {{ applicationfundingSourcePreviously }}</p>
      </div>
      <div class="reason-update-width">
        <h4 id="section-sub-heading-font" class="padding-bottom-8">Reason for update</h4>
        <p>{{ cashFlowUpdateRecord.applicationUpdatesRecord.reasonForFundingUpdate }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="!isApplicationfundingUpdated" id="section-sub-heading-font">No changes</div>
  <hr class="divider-24" />
  <div>
    <p id="section-heading-font" class="padding-bottom-24">Project updates</p>
    <div id="project-updates" *ngIf="isProjectUpdated" class="padding-bottom-24">
      <ng-container *ngFor="let project of cashFlowUpdateRecord?.cashFlowProjectReview; let i = index">
        <div *ngIf="(project?.reasonForUpdate ?? '').length > 0">
          <hr class="divider-24" *ngIf="i != 0" />
          <div class="bg-gray">
            <p>
              <span id="section-project-grey-section-text" class="padding-bottom-16">Project name: </span
              ><span class="gap-24">{{ project.projectName }}</span>
              <span id="section-project-grey-section-text" class="padding-bottom-16">Project number: </span
              ><span class="gap-24">{{ project.projectIdTxt }}</span>

              <span id="section-project-grey-section-text" class="padding-bottom-16">Primary capital asset: </span
              ><span>{{ project.capitalAssetTypeTitle }}</span>
            </p>
            <p>
              <span id="section-project-grey-section-text" class="padding-bottom-16"
                >Previous accepted LGFF funding: </span
              ><span class="gap-24">$ {{ project.amountRequestedFromLGFF_Previous }}</span>

              <span id="section-project-grey-section-text" class="padding-bottom-16"
                >Previous accepted anticipated start: </span
              ><span>{{ project.anticipatedStartDate_Previous }}</span>
            </p>
          </div>
          <div *ngIf="project.isProjectWithdrawn">
            <h4 id="section-sub-heading-font" class="padding-bottom-8">
              {{ 'projectWithdrawnTitle' | res: PageId }}
            </h4>
            <p>{{ project.isProjectWithdrawn ? 'Yes' : 'No' }}</p>
          </div>
          <table width="100%">
            <tr>
              <td
                width="50%"
                *ngIf="
                  project?.quantityNew_Updated != undefined &&
                  project?.quantityNew_Updated != project.quantityNew_Previous
                "
              >
                <div>
                  <h4 id="section-sub-heading-font" class="padding-bottom-8">
                    {{ 'quantityNewTitle' | res: PageId }}
                  </h4>
                  <p>{{ project.quantityNew_Updated }} {{ getCapitalAssetTypeUnit(project.capitalAssetTypeId) }}</p>
                  <p class="font-size-14">
                    Previously: {{ project.quantityNew_Previous }}
                    {{ getCapitalAssetTypeUnit(project.capitalAssetTypeId) }}
                  </p>
                </div>
              </td>
              <td
                width="50%"
                *ngIf="
                  project.quantityUpgrade_Updated != undefined &&
                  project.quantityUpgrade_Updated != project.quantityUpgrade_Previous
                "
              >
                <div>
                  <h4 id="section-sub-heading-font" class="padding-bottom-8">
                    {{ 'quantityUpgradeTitle' | res: PageId }}
                  </h4>
                  <p>{{ project.quantityUpgrade_Updated }} {{ getCapitalAssetTypeUnit(project.capitalAssetTypeId) }}</p>
                  <p class="font-size-14">
                    Previously: {{ project.quantityUpgrade_Previous }}
                    {{ getCapitalAssetTypeUnit(project.capitalAssetTypeId) }}
                  </p>
                </div>
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td
                width="50%"
                *ngIf="
                  project.anticipatedStartDate_Updated &&
                  (project.anticipatedStartDate_Updated | date) != project.anticipatedStartDate_Previous
                "
              >
                <div>
                  <h4 id="section-sub-heading-font" class="padding-bottom-8">
                    {{ 'anticipatedStartDateTitle' | res: PageId }}
                  </h4>
                  <p>{{ project.anticipatedStartDate_Updated | date }}</p>
                  <p class="font-size-14">Previously: {{ project.anticipatedStartDate_Previous }}</p>
                </div>
              </td>
              <td
                width="50%"
                *ngIf="
                  project.anticipatedEndDate_Updated &&
                  (project.anticipatedEndDate_Updated | date) != project.anticipatedEndDate_Previous
                "
              >
                <div>
                  <h4 id="section-sub-heading-font" class="padding-bottom-8">
                    {{ 'anticipatedEndDateTitle' | res: PageId }}
                  </h4>
                  <p>{{ project.anticipatedEndDate_Updated | date }}</p>
                  <p class="font-size-14">Previously: {{ project.anticipatedEndDate_Previous }}</p>
                </div>
              </td>
            </tr>
          </table>
          <div
            *ngIf="
              project.estimatedTotalCost_Updated &&
              project.estimatedTotalCost_Updated != project.estimatedTotalCost_Previous
            "
          >
            <h4 id="section-sub-heading-font" class="padding-bottom-8">
              {{ 'estimatedTotalCostTitle' | res: PageId }}
            </h4>
            <p>$ {{ project.estimatedTotalCost_Updated }}</p>
            <p class="font-size-14">Previously: $ {{ project.estimatedTotalCost_Previous }}</p>
          </div>
          <div
            *ngIf="
              project.amountRequestedFromLGFF_Updated &&
              project.amountRequestedFromLGFF_Updated != project.amountRequestedFromLGFF_Previous
            "
          >
            <h4 id="section-sub-heading-font" class="padding-bottom-8">
              {{ 'amountRequestedFromLGFFTitle' | res: PageId }}
            </h4>
            <p>$ {{ project.amountRequestedFromLGFF_Updated }}</p>
            <p class="font-size-14">Previously: $ {{ project.amountRequestedFromLGFF_Previous }}</p>
          </div>
          <div
            *ngIf="
              project.amountFromOtherGrantPrograms_Updated &&
              project.amountFromOtherGrantPrograms_Updated != project.amountFromOtherGrantPrograms_Previous
            "
          >
            <h4 id="section-sub-heading-font" class="padding-bottom-8">
              {{ 'amountFromOtherGrantProgramsTitle' | res: PageId }}
            </h4>
            <p>$ {{ project.amountFromOtherGrantPrograms_Updated }}</p>
            <p class="font-size-14">Previously: $ {{ project.amountFromOtherGrantPrograms_Previous }}</p>
          </div>

          <div
            *ngIf="project.totalAmountFromMunicipalSources_Updated != project.totalAmountFromMunicipalSources_Previous"
          >
            <span style="display: flex"
              ><h4 id="section-sub-heading-font" class="padding-bottom-8">
                {{ 'totalAmountFromMunicipalSourcesTitle' | res: PageId }}
              </h4>
              <span class="font-size-14 padding-left-8 padding-bottom-8" style="padding-top: 32px"
                >Calculated automatically</span
              ></span
            >
            <p>$ {{ project.totalAmountFromMunicipalSources_Updated }}</p>
            <p class="font-size-14">Previously: $ {{ project.totalAmountFromMunicipalSources_Previous }}</p>
          </div>

          <div class="reason-update-width" *ngIf="project.reasonForUpdate">
            <h4 id="section-sub-heading-font" class="padding-bottom-8">Reason for update</h4>
            <p>{{ project.reasonForUpdate }}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!isProjectUpdated" id="section-project-grey-section-text" class="padding-bottom-24">No changes</div>
    <table class="bg-gray total-table">
      <ng-container *ngIf="unchangedProjectsTotal ?? 0 > 0">
        <tr style="background-color: white; border-top: 0.0625rem solid rgb(210, 210, 210)">
          <td style="max-width: 972px"><span id="section-unchanged-project-font">Unchanged projects</span></td>
          <td style="max-width: 164px" class="td-money-data-style">$ {{ unchangedProjectsTotal?.toLocaleString() }}</td>
        </tr>
      </ng-container>
      <tr>
        <td style="max-width: 972px" class="bg-gray">
          <span id="section-project-grey-section-text">Previous accepted LGFF funding total</span>
        </td>

        <td style="max-width: 164px" class="td-money-data-style">
          {{ cashFlowUpdateRecord.previousTotalLgffFundingAccepted | safeDollar }}
        </td>
      </tr>
      <tr>
        <td>
          <span id="section-project-grey-section-text">Updated LGFF funding total</span>
        </td>
        <td
          class="td-money-data-style"
          *ngIf="
            !(
              cashFlowUpdateRecord.updatedTotalLgffFundingAccepted ==
                cashFlowUpdateRecord.previousTotalLgffFundingAccepted &&
              cashFlowUpdateRecord.status == cashFlowUpdateStatusType.Draft
            )
          "
        >
          {{ cashFlowUpdateRecord.updatedTotalLgffFundingAccepted | safeDollar }}
        </td>
        <td
          class="td-money-data-style"
          *ngIf="
            cashFlowUpdateRecord.updatedTotalLgffFundingAccepted ==
              cashFlowUpdateRecord.previousTotalLgffFundingAccepted &&
            cashFlowUpdateRecord.status == cashFlowUpdateStatusType.Draft
          "
        >
          {{ cashFlowUpdateRecord.updatedTotalLgffFundingAccepted | safeDollar }}
        </td>
      </tr>
      <tr>
        <td>
          <span id="section-project-grey-section-text">Cash flow update variance</span>
        </td>
        <td class="td-money-data-style">{{ cashFlowUpdateRecord.totalUpdatedVarianceAmount | safeDollar }}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="viewType === cashFlowUpdateViewType.Summary" class="nonPrintable-btn">
    <div style="height: 40px"></div>
    <goa-button-group alignment="end">
      <goa-button type="primary" (_click)="onClose()">Close</goa-button>
    </goa-button-group>
  </div>
</div>
