import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, delay, filter, tap } from 'rxjs';
import { ApplicationsState } from '@app-pot/store/state/applications.state';
import { ViewApplicationDrafts } from '@app-pot/features/grant-application/models';
import { LoadFunctionalCategoryTypes, LoadPrimaryOutcomes } from '@app-pot/store/actions/lookup-value.action';
import { CapitalAssetTypeLV, LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { LoadAllCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { ApplicationFilter, ApplicationStatusType, ProjectDraftDto, ProjectDto } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ApplicationExtDto } from '@app-com/api/models';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { ApplicationStatus } from '@app-pot/features/grant-application/models/enums/application-status';
import { ActiveTabService } from '../active-tab.service';
import { ApplicationExtService } from '@app-com/api/services';
@Component({
  selector: 'app-submitted-applications',
  templateUrl: './submitted-applications.component.html',
  styleUrls: ['../common-tab-style.scss'],
})
export class SubmittedApplicationsComponent implements OnInit, OnDestroy {
  @Select(ApplicationsState.fetchSubmittedApplications) fetchApplications$: Observable<ApplicationExtDto[]>;
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;
  @Output() filterTableData: EventEmitter<string> = new EventEmitter<string>();

  pageId = 'SUBMITTED_APPLICATIONS';
  applications: ViewApplicationDrafts[];
  filteredApplications: ViewApplicationDrafts[];
  expandableRows: true;
  isAlive = true;
  allCapitalAssetTypesCache: CapitalAssetTypeLV[] = [];
  sub = new Subscription();

  appIdPicked = 0;
  appNamePicked = 'NoSel';
  organizationIdSelected: number;

  CommUtilsService = CommUtilsService;
  isLoading: boolean;

  currentPage = 1;
  perPageCount = 25;
  pageSizeOptions = [10, 25, 50, 100];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  submittedApplicationsCount: number = 0;
  searchTerm: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    public activeTabService: ActiveTabService,
    private applicationService: ApplicationExtService,
  ) {
    this.sub.add(
      this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      }),
    );
    this.sub.add(
      this.store.select(ApplicationsState.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );

    this.getSubmittedApplicationCount();
  }

  ngOnInit(): void {
    this.getAllSubmittedApplication();
    this.sub.add(
      this.allCapitalAssetTypes$.subscribe((data) => {
        this.allCapitalAssetTypesCache = data ?? [];
      }),
    );
    this.sub.add(
      this.activatedRoute.url.subscribe((segments) => {
        this.activeTabService.setActiveTab(segments[0].toString());
      }),
    );
    this.sub.add(
      this.activeTabService
        .getActiveTabSearch()
        .pipe(
          delay(500),
          filter((search) => search !== undefined),
        )
        .subscribe((search) => {
          if (search !== undefined) {
            this.searchTerm = search;
            this.fetchData();
          }
        }),
    );
  }

  public getAllSubmittedApplication() {
    this.isLoading = true;
    this.activeTabService.setIsLoading(true);
    this.store.dispatch(new LoadPrimaryOutcomes());
    this.store.dispatch(new LoadFunctionalCategoryTypes());
    this.store.dispatch(new LoadAllCapitalAssetTypes());

    this.sub.add(
      combineLatest([this.primaryOutcomes$, this.functionalCategoryTypes$])
        .pipe(
          tap(([primaryOutcomesLookupValues, functionalCategoryTypeValues]) => {
            if (primaryOutcomesLookupValues && functionalCategoryTypeValues) {
              this.applicationService.findAllByOrganization(this.configQuery(this.organizationIdSelected)).subscribe({
                next: (applications) => {
                  this.isLoading = false;
                  this.activeTabService.setIsLoading(this.isLoading);
                  this.applications = applications.map((application) =>
                    this.toViewApplications(application, primaryOutcomesLookupValues, functionalCategoryTypeValues),
                  );
                  this.applications.sort(
                    // use submitted Date
                    (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
                      (new Date(a.submittedDate!) > new Date(b.submittedDate!) ? 1 : -1) * -1,
                  );
                  this.filteredApplications = this.applications;
                },
                error: () => {
                  this.isLoading = false;
                  this.activeTabService.setIsLoading(this.isLoading);
                },
              });
            }
          }),
        )
        .subscribe(),
    );
  }

  public fetchData() {
    this.isLoading = true;
    const primaryOutcomes = this.store.selectSnapshot(LookupValueState.getPrimaryOutcomes);
    const functionalCategoryTypes = this.store.selectSnapshot(LookupValueState.getFunctionalCategoryTypes);

    this.applicationService.findAllByOrganization(this.configQuery(this.organizationIdSelected)).subscribe({
      next: (value) => {
        const searchData = value.map((v) => this.toViewApplications(v, primaryOutcomes, functionalCategoryTypes));
        this.filteredApplications = searchData;
        if (this.searchTerm) {
          this.submittedApplicationsCount = this.filteredApplications?.length;
          this.currentPage = 1;
          this.perPageCount = 25;
        } else {
          this.getSubmittedApplicationCount();
        }
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  public getSubmittedApplicationCount() {
    this.applicationService.countByOrganization(this.configQuery(this.organizationIdSelected)).subscribe((count) => {
      this.timeoutIds.push(
        setTimeout(() => {
          this.submittedApplicationsCount = count;
        }),
      );
    });
  }

  public configQuery(organizationId: number) {
    const body: ApplicationFilter = {
      organizationId: { eq: organizationId },
      statuses: [ApplicationStatusType.Submitted, ApplicationStatusType.InReview],
    };
    if (this.searchTerm) {
      body.SEARCH = this.searchTerm?.toLocaleLowerCase();
    }

    const query = {
      take: this.perPageCount,
      skip: (this.currentPage - 1) * this.perPageCount,
      body: body,
      organizationId: organizationId,
    };
    return query;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  toViewApplications(
    application: ApplicationExtDto,
    primaryOutcomesLookupValues: LookupValue[],
    functionalCategoryTypeValues: LookupValue[],
  ): ViewApplicationDrafts {
    const lookupValuePrimaryOutcome = primaryOutcomesLookupValues.find(
      (value) => value.id === application.primaryOutcomeId,
    );
    const primaryFunctionalCategoryId =
      application.functionalCategories?.find((x) => x.isPrimary === true)?.functionalCategoryTypeId || 0;
    const lookupValuePrimaryFunctionalCategory = functionalCategoryTypeValues.find(
      (value) => value.id === primaryFunctionalCategoryId,
    );
    const uiProjects: ProjectDto[] = application?.projects ?? [];
    const uiData = {
      id: application.id || 0,
      name: application.name,
      primaryFunctionalCategoryTitle: lookupValuePrimaryFunctionalCategory
        ? lookupValuePrimaryFunctionalCategory.title
        : '',
      primaryOutcomeTitle: lookupValuePrimaryOutcome ? lookupValuePrimaryOutcome.title : '',
      createdAt: application.createdAt ?? '',
      projects: uiProjects,
      appNumber: application.idTxt, // submit generated, APP-#####
      // submittedDate: application.applicationRecord.certifiedAt,  // it current has a wrong encode of \"\"date string\"\"
      submittedDate: application.submittedAt,
      status: application.status,
    };
    return uiData;
  }

  onAppInfoClick(applicationId: number) {
    const url =
      '/application-summary/' + ApplicationStatus.Submitted + '/' + this.organizationIdSelected + '/' + applicationId;
    const width = Math.min(1024, window.screen.availWidth - 150);
    const height = Math.min(1439, window.screen.availHeight - 100);
    window.open(
      url,
      '_blank',
      `toolbar=yes, scrollbars=yes, resizable=yes,
       width=${width}, height=${height}`,
    );
  }

  onAppDownloadClick(applicationId: number) {
    alert('App Download clicked ' + applicationId);
  }

  // onDeleteClick(applicationId: number) {
  //   if (!this.wantCancelDeleteDraftAppInList) {
  //     this.appIdToDelete = applicationId;
  //     this.appNameToDelete = this.applications.find((app) => app.id == applicationId)?.name ?? '';
  //     this.wantCancelDeleteDraftAppInList = true;
  //   }
  // }

  // sort handler
  handleSort(event: Event) {
    if (!(event instanceof CustomEvent)) return;
    const { sortBy, sortDir } = event.detail;
    if (sortBy === 'submittedDate') {
      // submitted Date
      this?.filteredApplications?.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (new Date(a[sortBy]) > new Date(b[sortBy]) ? 1 : -1) * sortDir,
      );
    } else {
      this?.filteredApplications?.sort(
        (a: ViewApplicationDrafts, b: ViewApplicationDrafts) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1) * sortDir,
      );
    }
  }

  public getCapitalAssetTypeTitle(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';

    const title = this.allCapitalAssetTypesCache.find((item) => item.id === id)?.title ?? '' + id;
    return title;
  }

  getAdditionalCapitalAssetTypeTitle(project: ProjectDraftDto) {
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    if (!project['additionalCapitalAssetTypes'] || project['additionalCapitalAssetTypes'].length == 0) {
      return '-';
    }
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    return (project['additionalCapitalAssetTypes'] as CapitalAssetTypeLV[])
      .map((cat: CapitalAssetTypeLV) => {
        return cat.title.replace(/\([^)]*\)/g, '').trim();
      })
      .join(', ');
  }

  toggleApplicationDetails(applicationId: number) {
    this.filteredApplications = this.filteredApplications.map((_) => {
      if (_.id === applicationId) return { ..._, detailsShown: !_.detailsShown };
      return _;
    });
  }

  handlePerPageChange(event: Event) {
    const e = event as CustomEvent;
    this.perPageCount = parseInt(e.detail.value);
    this.fetchData();
    this.getSubmittedApplicationCount();
  }

  handlePageChange(event: Event) {
    const e = event as CustomEvent;
    this.currentPage = e.detail.page;
    this.fetchData();
    this.getSubmittedApplicationCount();
  }
}
