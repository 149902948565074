import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationDraftRecordDto } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { AutosaveProjects, SetFormStepperStatus, SetProjects } from '@app-pot/store/actions/application-draft.action';
import { LoadAssetConditionRankings, LoadCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { ApplicationGrantState } from '@app-pot/store/state/application-draft.state';
import { CapitalAssetTypeLV, LookupValue, LookupValueState } from '@app-com/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, filter, tap } from 'rxjs';
import { BaseStepperComponent } from '../../../shared/components/base-stepper/base-stepper.component';
import { ProjectRecordVm } from '../models';
import { FormSequence, FormStatusCodes } from '../models/enums';
import { ProjectFormComponent } from './project-form/project-form.component';
import { MapService } from '@app-pot/shared/services/map.service';
import { environment } from '@app-pot-env/environment';
import { ViewportScroller } from '@angular/common';

const DynMaxCols = 8;
export interface DynExpRow {
  rowId: number;
  descStartCol: number;
  descSpanCol: number;
  emptyColSpanAfterDesc: number;
  descNextCol: number;
  cellTitle: string[];
  cellText: string[];
}

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss', '../common.scss'],
})
export class ProjectsComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'PROJECT';
  errorMsgAtLeastOneProject: string;

  @Select(ApplicationGrantState.getFormStep) step$: Observable<FormSequence>;
  @Select(ApplicationGrantState.fetchApplicationDto) application$: Observable<ApplicationDraftRecordDto>;
  @Select(LookupValueState.getAssetConditionRankings) assetConditionRankings$: Observable<LookupValue[]>;
  @Select(LookupValueState.getCapitalAssetTypes) capitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;

  isDeleteDialogOpen = false;
  currentProjectToDelete?: ProjectRecordVm;
  isEditDialogOpen = false;
  currentProjectToEdit?: ProjectRecordVm;
  blankNA = 'N/A';

  projectRecords: ProjectRecordVm[] = [];
  expendedRowDynData: Map<number, DynExpRow>;
  showErrorFieldsCallout = false;
  @ViewChild(ProjectFormComponent) projectsFromRef!: ProjectFormComponent;
  sub = new Subscription();
  isMapVisible: boolean;
  totalProjectsAllowed = environment?.totalProjectsLimit ?? 20;
  showGrantFundingError = false;
  fundingGrantCalloutId = 'funding-from-grant-program-callout';

  expendedRow2Loop: number[] = [];
  displayProjectForm: boolean = true;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  showOtherGrantChangedToNoCallout = false;
  showOtherGrantChangedToNoCalloutFirstSet = true;

  constructor(
    private snackBarService: SnackBarService,
    private _res: ResourcePipe,
    private store: Store,
    private mapService: MapService,
    private scroller: ViewportScroller,
  ) {
    super();
    this.errorMsgAtLeastOneProject = _res.transform('errorMsgAtLeastOneProject', this.pageId);

    this.expendedRow2Loop = [];
    for (let ii = 2; ii <= DynMaxCols; ii++) {
      this.expendedRow2Loop.push(ii);
    }
    this.showOtherGrantChangedToNoCalloutFirstSet = true;
  }

  ngOnInit(): void {
    this.expendedRowDynData = new Map<number, DynExpRow>(); // new empty map
    this.store.dispatch(new LoadAssetConditionRankings());
    this.application$.pipe(filter((application) => !!application)).subscribe((_) => {
      let primary: number | undefined = undefined;
      if (_.functionalCategories && _.functionalCategories.length) {
        primary = _.functionalCategories.find((_) => _.isPrimary ?? false)?.functionalCategoryTypeId;
      }
      this.store.dispatch(new LoadCapitalAssetTypes(primary));
    });
    this.sub.add(
      combineLatest([
        this.application$.pipe(filter((application) => !!application)),
        this.assetConditionRankings$,
        this.capitalAssetTypes$,
      ])
        .pipe(
          tap(([application, assetConditionRankings, capitalAssetTypes]) => {
            this.projectRecords = this.getProjectsPageFromApplication(
              application,
              assetConditionRankings,
              capitalAssetTypes,
            );
            const projectsWithOtherSourceFunding = application.projects.filter((project) => {
              return project.amountFromOtherGrantPrograms ?? 0 > 0;
            });
            if (this.showOtherGrantChangedToNoCalloutFirstSet) {
              this.showOtherGrantChangedToNoCallout =
                !application.areThereOtherSourcesOfFunding && projectsWithOtherSourceFunding.length > 0;
              this.showOtherGrantChangedToNoCalloutFirstSet = !this.showOtherGrantChangedToNoCallout;
            }
            if (this.showOtherGrantChangedToNoCallout) {
              this.projectRecords.forEach((project) => {
                if (project.fundingFromOtherGrantPrograms ?? 0 > 0) {
                  project.fundingFromMunicipalSources =
                    Number(project.fundingFromMunicipalSources ?? 0) +
                    Number(project.fundingFromOtherGrantPrograms ?? 0);
                  project.fundingFromOtherGrantPrograms = 0;
                  this.updateProject(project);
                }
              });
            }
          }),
        )
        .subscribe(),
    );
    this.sub.add(
      this.mapService.getMapVisibility().subscribe((isVisible) => {
        this.isMapVisible = isVisible;
      }),
    );
    this.sub.add(
      this.step$.subscribe((step) => {
        if (step == FormSequence.Proj) {
          // Need to set the map asynchronously to give the map api time to reload on a page refresh
          // might be better if we can cache the api, will research later
          this.timeoutIds.push(
            setTimeout(() => {
              this.mapService.setMapVisibility(true);
            }, 1000),
          );
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  autosave() {
    this.store.dispatch(new AutosaveProjects(this.projectRecords));
  }

  handleGrantFundingError(hasError: boolean) {
    if (hasError) {
      this.showGrantFundingError = true;
      this.timeoutIds.push(
        setTimeout(() => {
          const fieldElement = document.getElementById(this.fundingGrantCalloutId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          } else {
            console.error('Cannot find linked field: ' + this.fundingGrantCalloutId);
          }
        }, 200),
      );
    } else {
      this.showGrantFundingError = false;
    }
  }

  private getProjectsPageFromApplication(
    application: ApplicationDraftRecordDto,
    assetConditionRankings: LookupValue[],
    capitalAssetTypes: CapitalAssetTypeLV[],
  ): ProjectRecordVm[] {
    let projects: ProjectRecordVm[] = [];
    if (application && application.projects) {
      projects = application.projects?.map((project, index) => {
        return {
          id: index,
          detailsShown: false,
          projectName: project.name,
          description: project.description || '',
          primaryCapitalAssetId: project.capitalAssetTypeId || 0,
          primaryCapitalAssetTitle: capitalAssetTypes.find((_) => _.id === project.capitalAssetTypeId)?.title,
          additionalCapitalAssetIds: project.additionalCapitalAssetTypeIds,
          additionalCapitalAssetTitles: capitalAssetTypes
            .filter((cat) => project.additionalCapitalAssetTypeIds?.includes(cat.id))
            .map((filteredCat) =>
              filteredCat.title
                .replace(
                  new RegExp(`\\(${filteredCat.measurementUnit}\\)(?![\\s\\S]*\\(${filteredCat.measurementUnit}\\))`),
                  '',
                )
                .trim(),
            ),
          estimatedTotalCost: project.estimatedTotalCost || 0,
          lgffFundingAmountRequested: project.amountRequestedFromLGFF || 0,
          fundingFromOtherGrantPrograms: project.amountFromOtherGrantPrograms || 0,
          fundingFromMunicipalSources: project.totalAmountFromMunicipalSources || 0,
          address: project.address || '',
          latitude: project.latitude,
          longitude: project.longitude,
          anticipatedStartDate: this._getFormattedDate(project.anticipatedStartDate),
          anticipatedEndDate: this._getFormattedDate(project.anticipatedEndDate),
          projectType: project.projectType ?? undefined,
          quantityNew: project.quantityNew,
          quantityUpgrade: project.quantityUpgrade,
          measurementUnit: this._getQuantityMeasurementUnit(project.capitalAssetTypeId),
          currentAssetRankingId: project.currentAssetConditionRankingId,
          currentAssetRankingTitle: assetConditionRankings.find((_) => _.id === project.currentAssetConditionRankingId)
            ?.title,
        };
      });
    }

    return projects;
  }

  _getQuantityMeasurementUnit(primaryCapitalAssetId?: number): string {
    const measurementUnit =
      this.store.selectSnapshot(LookupValueState.getCapitalAssetTypes).find((_) => _.id === primaryCapitalAssetId)
        ?.measurementUnit ?? '';
    return measurementUnit;
  }

  getProjects(): ProjectRecordVm[] {
    return [...this.projectRecords];
  }

  toggleProjectDetails(recordId: number) {
    this.projectRecords = this.projectRecords.map((_) => {
      if (_.id === recordId) {
        const willShow = !_.detailsShown;
        if (willShow) {
          this.updateExpendedRowDynData(_); // using latest data record to prepare expended-dynamic data
        }
        return { ..._, detailsShown: willShow };
      } else {
        return _;
      }
    });
    //this.store.dispatch(new SetProjects(this.getProjects()));
  }

  // using latest data record to prepare expended-dynamic data
  updateExpendedRowDynData(record: ProjectRecordVm) {
    if (record.id < 0) return;
    const newDynExpRow: DynExpRow = {
      rowId: record.id,
      descStartCol: 0,
      descSpanCol: 1,
      emptyColSpanAfterDesc: 0,
      descNextCol: 0,
      cellTitle: [],
      cellText: [],
    };
    let curCol = 0;
    if (record.projectType === 'New' || record.projectType === 'Both') {
      newDynExpRow.cellTitle.push('Quantity: new');
      const cellNumb: number = record.quantityNew ? record.quantityNew : 0;
      let positiveNumbStr = CommUtilsService.makePositiveNumber(cellNumb) ?? '';
      if (record.measurementUnit && record.measurementUnit.length > 0) {
        positiveNumbStr += ' ' + record.measurementUnit;
      }
      newDynExpRow.cellText.push(positiveNumbStr);
      curCol++;
    }
    if (record.projectType === 'Upgrade' || record.projectType === 'Both') {
      newDynExpRow.cellTitle.push('Quantity: upgrade');
      const cellNumb: number = record.quantityUpgrade ? record.quantityUpgrade : 0;
      let positiveNumbStr = CommUtilsService.makePositiveNumber(cellNumb) ?? '';
      if (record.measurementUnit && record.measurementUnit.length > 0) {
        positiveNumbStr += ' ' + record.measurementUnit;
      }
      newDynExpRow.cellText.push(positiveNumbStr);
      curCol++;
    }
    if (record.projectType === 'Upgrade' || record.projectType === 'Both') {
      newDynExpRow.cellTitle.push('Current condition');
      let cell: string = record.currentAssetRankingId ? Number(record.currentAssetRankingId) + '' : '';
      if (record.currentAssetRankingTitle && record.currentAssetRankingTitle.length > 0) {
        cell += cell.length > 0 ? ' - ' : '';
        cell += record.currentAssetRankingTitle;
      }
      newDynExpRow.cellText.push(cell);
      curCol++;
    }
    if (record.additionalCapitalAssetTitles && record.additionalCapitalAssetTitles.length > 0) {
      newDynExpRow.cellTitle.push('Additional capital Asset(s)');
      newDynExpRow.cellText.push(record.additionalCapitalAssetTitles.join(', '));
      curCol++;
    }
    newDynExpRow.descStartCol = curCol;
    if (record.description.length > 0) {
      newDynExpRow.cellTitle.push('Brief project description');
      newDynExpRow.cellText.push(record.description ?? '');
      curCol++;
    }
    newDynExpRow.descSpanCol = 3;
    // to use max columns: = DynMaxCols - newDynExpRow.descStartCol;
    newDynExpRow.descNextCol = newDynExpRow.descStartCol + newDynExpRow.descSpanCol;
    newDynExpRow.emptyColSpanAfterDesc = DynMaxCols - newDynExpRow.descNextCol;

    // add empty cells
    while (curCol < DynMaxCols) {
      newDynExpRow.cellTitle.push('');
      newDynExpRow.cellText.push('');
      curCol++;
    }

    console.warn('prj expended row dynamic= ', newDynExpRow);
    this.expendedRowDynData.set(record.id, newDynExpRow);
  }

  // col 1: last column of row-1;
  // col >=2: column (col - 1) of row-2
  getDetailExpendedCellTitleOrText(record: ProjectRecordVm, col: number, isTitle: boolean): string {
    const rowDynData = this.expendedRowDynData.get(record.id);
    if (rowDynData && col >= 1 && col <= DynMaxCols) {
      return isTitle ? rowDynData.cellTitle[col - 1] : rowDynData.cellText[col - 1];
    } else {
      return '';
    }
  }
  // // show all non-empty cell, and column immdiately after description (with span)
  // isExpendedCellNeedShow(record: ProjectRecordVm, col: number, isTitle: boolean): string {
  //   const rowDynData = this.expendedRowDynData.get(record.id);
  //   if (rowDynData && col >= 1 && col <= DynMaxCols) {
  //     return isTitle ? rowDynData.cellTitle[col - 1] : rowDynData.cellText[col - 1];
  //   } else {
  //     return '';
  //   }
  // }

  // col 1: last column of row-1;
  // col >=2: column (col - 1) of row-2
  isDescriptionCell(record: ProjectRecordVm, col: number): boolean {
    const rowDynData = this.expendedRowDynData.get(record.id);
    if (rowDynData && col >= 1 && col <= DynMaxCols) {
      const colOffset0 = col - 1;
      return colOffset0 >= rowDynData.descStartCol;
    } else {
      return false;
    }
  }

  getDetailExpendedCellDescSpans(record: ProjectRecordVm, col: number): string {
    const rowDynData = this.expendedRowDynData.get(record.id);
    if (rowDynData && col >= 1 && col <= DynMaxCols) {
      const colOffset0 = col - 1;
      if (colOffset0 === rowDynData.descStartCol) {
        return rowDynData.descSpanCol + '';
      } else if (colOffset0 === rowDynData.descNextCol) {
        return rowDynData.emptyColSpanAfterDesc + '';
      } else if (colOffset0 < rowDynData.descStartCol) {
        return '1';
      } else if (colOffset0 > rowDynData.descNextCol) {
        return ''; // means not show
      }
    }
    return ''; // means not show
  }

  openEditProjectDialog(recordId: number) {
    this.currentProjectToEdit = this.projectRecords.find((_) => _.id === recordId);
    this.isEditDialogOpen = true;
    this.mapService.setMapVisibility(false);
    this.mapService.setEditModeMapVisibility(true);
    this._adjustModalZIndex();
  }

  _adjustModalZIndex() {
    //This is a workaround. The goa-modal has a higher z-index than mat-select. So, lowering its z-index properly shows the mat-select options.
    this.timeoutIds.push(
      setTimeout(() => {
        document
          .querySelector('project-edit goa-modal')
          ?.shadowRoot?.querySelector('style')
          ?.append(':host{z-index:999}');
      }, 0),
    );
  }

  openDeleteProjectDialog(recordId: number) {
    this.currentProjectToDelete = this.projectRecords.find((_) => _.id === recordId);
    this.isDeleteDialogOpen = true;
  }

  deleteProject(recordId: number) {
    const projectName = this.projectRecords.find((_) => _.id === recordId)?.projectName;
    this.projectRecords = this.projectRecords.filter((_) => _.id !== recordId);
    this.store.dispatch(new SetProjects(this.getProjects()));
    this.snackBarService.showSuccessMessage(`"${projectName}" project successfully deleted.`);
    this.showErrorFieldsCallout = this.projectRecords.length == 0;
    this.autosave();
  }

  closeDeleteProjectDialog() {
    this.isDeleteDialogOpen = false;
    this.currentProjectToDelete = undefined;
  }

  updateProject(updatedProject: ProjectRecordVm): void {
    const projectToUpdate = this.projectRecords.find((_) => _.id === updatedProject.id);
    if (!projectToUpdate) {
      console.error(`Project with id: ${updatedProject.id} doesn't exists.`);
      return;
    }
    this.projectRecords = this.projectRecords.map((project) =>
      project.id === projectToUpdate.id ? { ...updatedProject } : project,
    );
    this.store.dispatch(new SetProjects(this.getProjects()));
    this.snackBarService.showSuccessMessage(`"${updatedProject.projectName}" project successfully edited.`);
    this.autosave();
  }

  closeEditProjectDialog() {
    this.isEditDialogOpen = false;
    this.currentProjectToEdit = undefined;
  }

  addProject(project: ProjectRecordVm) {
    this.displayProjectForm = false;
    console.log('[Projects component][Add Project]', project);
    const newId = this.projectRecords.length + 1;
    this.projectRecords = [...this.projectRecords, { ...project, id: newId }];

    this.showErrorFieldsCallout = this.projectRecords.length == 0;
    this.showGrantFundingError = false;
    this.store.dispatch(new SetProjects(this.getProjects()));
    this.snackBarService.showSuccessMessageWithScrollToTopLink(
      `"${project.projectName}" project successfully added.`,
      'project-heading-h1',
      'to add another project.',
    );
    this.timeoutIds.push(
      setTimeout(() => {
        this.displayProjectForm = true;
        this.scroller.scrollToPosition([0, 0]);
      }, 0),
    );
    this.autosave();
  }

  clearProject() {
    this.displayProjectForm = false;
    this.timeoutIds.push(
      setTimeout(() => {
        this.displayProjectForm = true;
        this.scroller.scrollToPosition([0, 0]);
      }, 0),
    );
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  override validateOnPrevious(): boolean {
    if (this.projectRecords.length == 0) {
      this.store.dispatch(
        new SetFormStepperStatus({
          [FormSequence.Proj]: FormStatusCodes.NotStarted,
        }),
      );
    }
    return !this.hasIncompleteProject();
  }

  override validateOnNext() {
    this.showErrorFieldsCallout = this.projectRecords.length == 0;
    if (this.showErrorFieldsCallout) {
      this.timeoutIds.push(
        setTimeout(() => {
          this.jumpToField('project-heading-h3');
        }, 200),
      );
      return false;
    }
    return true;
  }

  hasIncompleteProject() {
    if (!this.projectsFromRef || !this.projectsFromRef.projectsForm || !this.projectsFromRef.projectsForm.value) {
      return false;
    }

    return this.projectsFromRef.hasProjectFormAnyNonEmptyValue(false);
  }

  //Should we put this function into the CommUtilsService?
  private _getFormattedDate(dateString: string | undefined): string | undefined {
    if (!dateString) {
      return undefined;
    }
    const date = new Date(dateString);
    if (date.toString() === 'Invalid Date') {
      return undefined;
    }
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }
}
