<div class="form">
  <h3 id="gen-info-heading-1">{{ 'assetFundHeading1' | res: pageId }}</h3>

  <div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
    <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
      <a
        *ngIf="checkAssetManagementBarrierErrorCallout || checkAssetManagementErrorCallout"
        (click)="jumpToField('hasAssetManagementPlan')"
        class="validation-message-link"
        >{{ 'assetPlanQuestionLabel' | res: pageId }}<br
      /></a>
      <a *ngIf="errorsA['amountCredit']" (click)="jumpToField('amountCreditDiv')" class="validation-message-link"
        >{{ 'assetPlanCreditItemLabel' | res: pageId }}<br
      /></a>
    </goa-callout>
  </div>

  <form [formGroup]="assetManagementForm">
    <goa-form-item id="hasAssetManagementPlan" label="{{ 'assetPlanQuestionLabel' | res: pageId }}">
      <goa-radio-group
        name="hasAssetManagementPlan"
        formControlName="hasAssetManagementPlan"
        id="hasAssetManagementPlan"
        goaValue
        (click)="hasAssetManagementPlanClicked(true)"
      >
        <goa-radio-item
          name="hasAssetManagementPlan"
          label="Yes"
          [value]="true"
          [error]="errors['hasAssetManagementPlan']"
        ></goa-radio-item>
      </goa-radio-group>
      <div class="nestedStepOne" *ngIf="getAssetManagementPlanYes">
        <goa-form-item
          id="assetManagementPlanLastUpdatedAtFormId"
          label="{{ 'assetPlanLastUpdatedlbl' | res: pageId }}"
        >
          <goa-date-picker
            id="assetManagementPlanLastUpdatedAtId"
            name="assetManagementPlanLastUpdatedAt"
            goaValue
            formControlName="assetManagementPlanLastUpdatedAt"
            [min]=""
            [max]="today"
            [error]="errors['assetManagementPlanLastUpdatedAt']"
            (focusout)="onFocusOut('assetManagementPlanLastUpdatedAt')"
          ></goa-date-picker>
          <div class="field-validation-error-msg" *ngIf="errors['assetManagementPlanLastUpdatedAt']">
            <goa-icon size="small" theme="filled" type="warning"></goa-icon>
            <span>{{ errors['assetManagementPlanLastUpdatedAt'] | res: pageId }}</span>
          </div>
        </goa-form-item>
      </div>
    </goa-form-item>
    <goa-form-item>
      <goa-radio-group
        name="hasAssetManagementPlanNo"
        id="hasAssetManagementPlanNo"
        formControlName="hasAssetManagementPlanNo"
        goaValue
        (click)="hasAssetManagementPlanClicked(false)"
      >
        <goa-radio-item
          name="hasAssetManagementPlanNo"
          label="No"
          [value]="true"
          [error]="errors['hasAssetManagementPlanNo']"
        ></goa-radio-item>
      </goa-radio-group>
      <goa-form-item
        class="nestedStepOne"
        mt="m"
        label="{{ 'assetPlanBarrierddllbl' | res: pageId }}"
        *ngIf="getAssetManagementPlanNo"
      >
        <mat-form-field class="multi-select-dropdown" id="assetManagementPlanBarriers">
          <mat-label>Please select up to three barriers</mat-label>
          <mat-select
            formControlName="assetManagementPlanBarriers"
            multiple
            (openedChange)="openedChangeBarrierList($event)"
          >
            <mat-select-trigger>
              <ng-container
                *ngIf="
                  this.assetManagementForm.get('assetManagementPlanBarriers')?.value?.length > 1;
                  else singleOption
                "
                >{{ this.assetManagementForm.get('assetManagementPlanBarriers')?.value?.length }} options
                selected</ng-container
              >
              <ng-template #singleOption>1 option selected</ng-template>
            </mat-select-trigger>
            <mat-option
              [attr.data-automation-id]="'assetManagementPlanBarriers' + '-option-' + assetManagentPlanBarrier.id"
              *ngFor="let assetManagentPlanBarrier of this.assetManagementPlanBarriers"
              [value]="assetManagentPlanBarrier.id"
              >{{ assetManagentPlanBarrier.title }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="field-validation-error-msg" *ngIf="checkAssetManagementBarrierErrorCallout">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ errors['assetManagementPlanBarriers'] }}</span>
        </div>
      </goa-form-item>
      <div class="nestedStepTwo" *ngIf="showAssetManagementPlanOtherSpecify">
        <goa-form-item label="Please specify">
          <goa-textarea
            type="text"
            formControlName="assetManagementPlanOther"
            goaValue
            width="600px"
            countby="character"
            maxcount="100"
            name="assetManagementPlanOther"
            value=""
            [error]="errors['assetManagementPlanOther']"
            (focusout)="onFocusOut('assetManagementPlanOther')"
            (focusin)="onFocusIn('assetManagementPlanOther')"
          ></goa-textarea>
          <div class="field-validation-error-msg" *ngIf="errors['assetManagementPlanOther']">
            <goa-icon size="small" type="warning" theme="filled"></goa-icon>
            <span>{{ errors['assetManagementPlanOther'] }}</span>
          </div>
        </goa-form-item>
      </div>
      <div class="field-validation-error-msg" *ngIf="errors['hasAssetManagementPlan']">
        <goa-icon size="small" type="warning" theme="filled"></goa-icon>
        <span>{{ errors['hasAssetManagementPlan'] }}</span>
      </div>
    </goa-form-item>
    <div class="help-msg-text" style="max-width: 600px; padding-top: 1rem">
      {{ 'assetFundPlanQuestion1HelpText' | res: pageId }}
    </div>
  </form>
  <goa-divider mt="xl" mb="xl"></goa-divider>
  <h2 id="gen-info-heading-1">{{ 'assetFundHeading2' | res: pageId }}</h2>
  <div>
    <h4 id="section-sub-heading-font" style="padding-top: 0">{{ 'assetFundCarryForwardLabel' | res: pageId }}</h4>
    <p>$ {{ this.creditAmountForm.get('amountCarryForward')?.value ?? 0 }}</p>
    <h4 id="section-sub-heading-font">LGFF funding allocation</h4>
    <p>$ {{ this.creditAmountForm.get('amountFundingAllocation')?.value ?? 0 }}</p>
    <form [formGroup]="creditAmountForm">
      <div id="amountCreditDiv">
        <h4 id="section-sub-heading-font">{{ 'assetPlanCreditItemLabel' | res: pageId }}</h4>
        <goa-form-item>
          <goa-input
            id="amountCredit"
            value=""
            name="amountCredit"
            leadingcontent="$"
            formControlName="amountCredit"
            [error]="errorsA['amountCredit']"
            (focusout)="onFocusOutA('amountCredit')"
            (focusin)="onFocusInA('amountCredit')"
            goaValue
            commaFormatNumber
            [allowZero]="true"
          >
            <div slot="leadingContent">$</div>
          </goa-input>
        </goa-form-item>
        <div class="field-validation-error-msg" *ngIf="errorsA['amountCredit']">
          <goa-icon size="small" theme="filled" type="warning"></goa-icon>
          <span>{{ errorsA['amountCredit'] }}</span>
        </div>
        <div class="help-msg-text" style="padding-top: 1rem">
          {{ 'assetPlanCreditAmountHelpText' | res: pageId }}
        </div>
      </div>
      <h4 id="section-sub-heading-font">Total LGFF funding available</h4>
      <goa-form-item helptext="This value is calculated based on the amounts above.">
        <goa-input
          formControlName="totalFundingAvailable"
          value=""
          name="Total LGFF funding available"
          [attr.disabled]="true"
          leadingcontent="$"
          goaValue
        >
          <div slot="leadingContent">$</div>
        </goa-input>
      </goa-form-item>
    </form>
  </div>
</div>
