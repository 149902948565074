/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CostCentreDto } from '../models/cost-centre-dto';
import { CostCentreFilter } from '../models/cost-centre-filter';
import { CreateCostCentreDto } from '../models/create-cost-centre-dto';
import { UpdateCostCentreDto } from '../models/update-cost-centre-dto';

@Injectable({ providedIn: 'root' })
export class CostCentreService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAll_6()` */
  static readonly FindAll_6Path = '/api/cost-centres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
      filter?: CostCentreFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CostCentreDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.FindAll_6Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CostCentreDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
      filter?: CostCentreFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<Array<CostCentreDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CostCentreDto>>): Array<CostCentreDto> => r.body)
    );
  }

  /** Path part for operation `create_2()` */
  static readonly Create_2Path = '/api/cost-centres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateCostCentreDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CreateCostCentreDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.Create_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreateCostCentreDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateCostCentreDto
    },
    context?: HttpContext
  ): Observable<Array<CreateCostCentreDto>> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreateCostCentreDto>>): Array<CreateCostCentreDto> => r.body)
    );
  }

  /** Path part for operation `getUserAvailableCostCentreActions()` */
  static readonly GetUserAvailableCostCentreActionsPath = '/api/cost-centres/available-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableCostCentreActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableCostCentreActions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.GetUserAvailableCostCentreActionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableCostCentreActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableCostCentreActions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableCostCentreActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `count_4()` */
  static readonly Count_4Path = '/api/cost-centres/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(
    params?: {
      filter?: CostCentreFilter;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.Count_4Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(
    params?: {
      filter?: CostCentreFilter;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findOne_6()` */
  static readonly FindOne_6Path = '/api/cost-centres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CostCentreDto>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.FindOne_6Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostCentreDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<CostCentreDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<CostCentreDto>): CostCentreDto => r.body)
    );
  }

  /** Path part for operation `remove_1()` */
  static readonly Remove_1Path = '/api/cost-centres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.Remove_1Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.remove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `update_2()` */
  static readonly Update_2Path = '/api/cost-centres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(
    params: {
      id: string;
      body: UpdateCostCentreDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CostCentreService.Update_2Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(
    params: {
      id: string;
      body: UpdateCostCentreDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
