import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { UiFieldCtrDef } from '@app-com/constants/patterns';
import { TableHeader } from '@app-com/components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';
import { SepoExtDto, SepoProjectOutcomeDto } from '@app-com/api/models';
import { CapitalAssetTypeLV, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { LoadAllCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';

@Component({
  selector: 'app-edit-sepo-immediate-outcomes',
  templateUrl: './edit-sepo-immediate-outcomes.component.html',
  styleUrl: './edit-sepo-immediate-outcomes.component.scss',
})
export class EditSepoImmediateOutcomesComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  pageUiDefs: UiFieldCtrDef[] = [];

  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getSepoDetails) currentSepo$: Observable<SepoExtDto>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetsTypes$: Observable<CapitalAssetTypeLV[]>;
  currentStep: EditSepoSequence | undefined;
  headers: TableHeader[] = [];
  additionalHeaders: TableHeader[] = [];
  applications: unknown[] = [];
  currentSepo: SepoExtDto = {} as SepoExtDto;
  allCapitalAssetTypes: CapitalAssetTypeLV[] = [];
  parentColumnIndent = 2;
  constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAllCapitalAssetTypes());
    this.sub.add(
      this.allCapitalAssetsTypes$?.subscribe((data) => {
        this.allCapitalAssetTypes = data ?? [];
        this.getSepoProjectOutcomes();
      }),
    );

    this.initTableConfig();

    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.ImmediateOutcomes) {
          console.log(
            'file: edit-sepo-immediate-outcomes.component.ts:45 ~ EditSepoImmediateOutcomesComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.Certification));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/certify-and-submit']);
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/statement-of-expenditures');
            this.router.navigate(['edit-sepo/statement-of-expenditures']);
          }
        }
      }),
    );
  }
  getSepoProjectOutcomes() {
    this.currentSepo$?.subscribe({
      next: (value) => {
        const sepoProjectOutcomesData = value.sepoProjectOutcomes;
        this.currentSepo = value;
        if (sepoProjectOutcomesData?.length > 0) {
          this.applications = this.groupByApplication(sepoProjectOutcomesData);
        }
      },
    });
    /*this.currentSepo$.pipe(withLatestFrom(this.allCapitalAssetsTypes$)).subscribe(([sepo, lv]) => {
      const sepoProjectOutcomesData = sepo.sepoProjectOutcomes;
      this.currentSepo = sepo;
      if (sepoProjectOutcomesData?.length > 0) {
        this.applications = this.groupByApplication(sepoProjectOutcomesData, lv);
      }
    });
    */
  }
  groupByApplication(sepoProjectOutcomes: SepoProjectOutcomeDto[]) {
    const grouped = sepoProjectOutcomes.reduce(
      (acc, item) => {
        const key = `${item.applicationIdTxt}-${item.applicationName}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      },
      {} as { [key: string]: SepoProjectOutcomeDto[] },
    );
    const result = Object.keys(grouped)
      .map((key) => {
        const group = grouped[key];
        const application = {
          applicationId: group[0].applicationId,
          applicationIdTxt: group[0].applicationIdTxt,
          applicationName: group[0].applicationName,
        };
        const updatedGroup = group.map((item) => ({
          ...item,
          primaryCapitalAssetType: this.allCapitalAssetTypes.find((x) => x.id === item.primaryCapitalAssetTypeId)
            ?.title,
        }));
        return {
          application,
          projects: updatedGroup,
          /*children: group.map(item => ({
          ...item,
          parent,
          children: group.filter(child => child.id !== item.id)
        }))*/
        };
      })
      .map((group) => ({
        ...group.application,
        projects: group.projects,
      }));
    return result;
  }
  initTableConfig() {
    this.headers = [
      {
        fieldName: 'applicationIdTxt',
        displayName: 'Application ID',
        headerClassName: 'w-144',
        cellClassName: 'w-144',
      },
      {
        fieldName: 'applicationName',
        displayName: 'Application name',
        headerClassName: 'w-160',
        cellClassName: 'w-160',
      },
      {
        fieldName: 'projectIdTxt',
        displayName: 'Project ID',
        headerClassName: 'w-141',
        cellClassName: 'w-141',
      },
      {
        fieldName: 'projectName',
        displayName: 'Project name',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      { fieldName: 'edit', displayName: 'Edit', headerClassName: 'w-64', cellClassName: 'w-64' },
      {
        fieldName: 'primaryCapitalAssetType',
        displayName: 'Primary capital Asset',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'actualQuantityNew',
        displayName: 'Actual quantity new',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'actualQuantityUpgrade',
        displayName: 'Actual quantity upgrade',
        headerClassName: 'w-165',
        cellClassName: 'w-165',
      },
      {
        fieldName: 'assetConditionRanking',
        displayName: 'Asset condition ranking',
        cellClassName: 'w-165',
        headerClassName: 'w-165',
      },
      {
        fieldName: 'projectPrimaryOutcome',
        displayName: 'Project primary outcome',
        cellClassName: 'w-165',
        headerClassName: 'w-165',
      },
    ];

    this.additionalHeaders = [];
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    return true;
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return baseHasError;
  }
}
