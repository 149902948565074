/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AllocationDto } from '../models/allocation-dto';
import { AllocationFilter } from '../models/allocation-filter';
import { AllocationImportDto } from '../models/allocation-import-dto';
import { AllocationOrganizationLookupDto } from '../models/allocation-organization-lookup-dto';
import { AllocationProgramBudgetLookupDto } from '../models/allocation-program-budget-lookup-dto';
import { CreateAllocationDto } from '../models/create-allocation-dto';
import { LgffResponseDto } from '../models/lgff-response-dto';
import { UpdateAllocationDto } from '../models/update-allocation-dto';

@Injectable({ providedIn: 'root' })
export class AllocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAll_1()` */
  static readonly FindAll_1Path = '/api/allocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AllocationDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.FindAll_1Path, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllocationDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AllocationDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AllocationDto>>): Array<AllocationDto> => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/api/allocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateAllocationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AllocationDto>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllocationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateAllocationDto
    },
    context?: HttpContext
  ): Observable<AllocationDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllocationDto>): AllocationDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableAllocationActions()` */
  static readonly GetUserAvailableAllocationActionsPath = '/api/allocations/available-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableAllocationActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableAllocationActions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.GetUserAvailableAllocationActionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableAllocationActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableAllocationActions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableAllocationActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `getOrganizations()` */
  static readonly GetOrganizationsPath = '/api/allocations/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AllocationOrganizationLookupDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.GetOrganizationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllocationOrganizationLookupDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AllocationOrganizationLookupDto>> {
    return this.getOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AllocationOrganizationLookupDto>>): Array<AllocationOrganizationLookupDto> => r.body)
    );
  }

  /** Path part for operation `getProgramBudgets()` */
  static readonly GetProgramBudgetsPath = '/api/allocations/program-budgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProgramBudgets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProgramBudgets$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AllocationProgramBudgetLookupDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.GetProgramBudgetsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllocationProgramBudgetLookupDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProgramBudgets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProgramBudgets(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AllocationProgramBudgetLookupDto>> {
    return this.getProgramBudgets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AllocationProgramBudgetLookupDto>>): Array<AllocationProgramBudgetLookupDto> => r.body)
    );
  }

  /** Path part for operation `findOne_1()` */
  static readonly FindOne_1Path = '/api/allocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AllocationDto>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.FindOne_1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllocationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AllocationDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllocationDto>): AllocationDto => r.body)
    );
  }

  /** Path part for operation `remove()` */
  static readonly RemovePath = '/api/allocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AllocationDto>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.RemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllocationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AllocationDto> {
    return this.remove$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllocationDto>): AllocationDto => r.body)
    );
  }

  /** Path part for operation `update_1()` */
  static readonly Update_1Path = '/api/allocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(
    params: {
      id: string;
      body: UpdateAllocationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AllocationDto>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.Update_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllocationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(
    params: {
      id: string;
      body: UpdateAllocationDto
    },
    context?: HttpContext
  ): Observable<AllocationDto> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllocationDto>): AllocationDto => r.body)
    );
  }

  /** Path part for operation `importAllocations()` */
  static readonly ImportAllocationsPath = '/api/allocations/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importAllocations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importAllocations$Response(
    params: {
      body: AllocationImportDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LgffResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.ImportAllocationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LgffResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importAllocations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importAllocations(
    params: {
      body: AllocationImportDto
    },
    context?: HttpContext
  ): Observable<LgffResponseDto> {
    return this.importAllocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<LgffResponseDto>): LgffResponseDto => r.body)
    );
  }

  /** Path part for operation `findAllByOrganization()` */
  static readonly FindAllByOrganizationPath = '/api/allocations/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllByOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllByOrganization$Response(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: AllocationFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AllocationDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AllocationService.FindAllByOrganizationPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllocationDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllByOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllByOrganization(
    params: {
      organizationId: number;
      take?: number;
      skip?: number;
      body: AllocationFilter
    },
    context?: HttpContext
  ): Observable<Array<AllocationDto>> {
    return this.findAllByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AllocationDto>>): Array<AllocationDto> => r.body)
    );
  }

}
