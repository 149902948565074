import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TableHeader, TotalsColDef } from '../lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';
import { SepoExpenditures, SepoExpendituresProject } from './sepo-expenditure-types';
import { ProjectStatusType, SepoProjectExpenditureDto } from '@app-com/api/models';

@Component({
  selector: 'app-sepo-expenditure-table',
  templateUrl: './sepo-expenditure-table.component.html',
  styleUrl: './sepo-expenditure-table.component.scss',
})
export class SepoExpenditureTableComponent implements OnInit {
  @Input() sepoExpendituresData: SepoProjectExpenditureDto[] = [];
  @Input() editBtnDisabled: boolean = false;
  @Input() errorExpenditureIds: Array<number> = [];

  @Output() expenditureIdToEdit = new EventEmitter<number>();

  headers: TableHeader[] = [];
  additionalHeaders: TableHeader[] = [];
  totalColumn: TotalsColDef[] = [];
  parentColumnIndent = 3;
  sepoExpenditures: SepoExpenditures[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sepoExpendituresData'] && changes['sepoExpendituresData'].currentValue) {
      this.sepoExpenditures = this.formatExpenditureData(this.sepoExpendituresData);
      this.totalColumn = this.calculateTotals(this.sepoExpenditures);
    }
  }

  ngOnInit(): void {
    this.initTableConfig();
  }

  calculateTotals(formattedData: SepoExpenditures[]): TotalsColDef[] {
    const totals: { [key: string]: number } = {
      estimatedTotalLGFFFunding: 0,
      totalRemaining: 0,
      actualReportingYearCost: 0,
      amountFundedOtherGrantPrograms: 0,
      fundingAppliedFromLGFF: 0,
      fundingAppliedFromMunicipalSources: 0,
    };

    formattedData.forEach((app) => {
      app.projects.forEach((project) => {
        totals['estimatedTotalLGFFFunding'] += project.estimatedTotalLGFFFunding || 0;
        totals['totalRemaining'] += project.totalRemaining || 0;
        totals['actualReportingYearCost'] += project.actualReportingYearCost || 0;
        totals['amountFundedOtherGrantPrograms'] += project.amountFundedOtherGrantPrograms || 0;
        totals['fundingAppliedFromLGFF'] += project.fundingAppliedFromLGFF || 0;
        totals['fundingAppliedFromMunicipalSources'] += project.fundingAppliedFromMunicipalSources || 0;
      });
    });

    return Object.keys(totals).map(
      (key): TotalsColDef => ({
        totalClass: 'goa-table-number-column totals-font',
        totalsKey: key,
        totalsValue: totals[key],
      }),
    );
  }

  getApplicationStatus(projects: SepoExpendituresProject[]): string | null {
    const statuses = projects.map((project) => project.status);

    if (statuses.every((status) => status === null)) {
      return 'Accepted';
    } else if (statuses.every((status) => status === ProjectStatusType.Withdrawn)) {
      return 'Withdrawn';
    } else if (statuses.every((status) => status === ProjectStatusType.NotStarted)) {
      return 'Not Started';
    } else if (
      statuses.every((status) => status === ProjectStatusType.Completed || status === ProjectStatusType.Withdrawn)
    ) {
      return 'Completed';
    } else {
      return 'In Progress';
    }
  }

  formatExpenditureData(sepoProjectExpenditures: SepoProjectExpenditureDto[]): SepoExpenditures[] {
    return sepoProjectExpenditures.reduce((acc: SepoExpenditures[], current: SepoProjectExpenditureDto) => {
      const existingApplication = acc.find((app) => app.applicationId === current.applicationId);

      const project: SepoExpendituresProject = {
        expenditureId: current.id,
        projectId: current.projectId,
        projectIdTxt: current.projectIdTxt,
        projectName: current.projectName,
        status: current.status,
        projectStatusTitle: current.projectStatusTitle,
        estimatedTotalLGFFFunding: current.estimatedTotalLGFFFunding,
        totalRemaining: current.totalRemaining,
        actualReportingYearCost: current.actualReportingYearCost,
        amountFundedOtherGrantPrograms: current.amountFundedOtherGrantPrograms,
        fundingAppliedFromLGFF: current.fundingAppliedFromLGFF,
        fundingAppliedFromMunicipalSources: current.fundingAppliedFromMunicipalSources,
      };

      if (existingApplication) {
        existingApplication.projects.push(project);
        existingApplication.applicationStatus = this.getApplicationStatus(existingApplication.projects); // Update status
      } else {
        const newApplication: SepoExpenditures = {
          id: current.id,
          applicationId: current.applicationId,
          applicationIdTxt: current.applicationIdTxt,
          applicationName: current.applicationName,
          createdAt: current.createdAt,
          createdBy: current.createdBy,
          createdByName: current.createdByName,
          updatedAt: current.updatedAt,
          updatedBy: current.updatedBy,
          updatedByName: current.updatedByName,
          applicationStatus: this.getApplicationStatus([project]), // Initial status
          projects: [project],
        };

        acc.push(newApplication);
      }

      return acc;
    }, []);
  }

  initTableConfig() {
    this.headers = [
      {
        fieldName: 'applicationIdTxt',
        displayName: 'Application ID',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144 header-bg-gray',
      },
      {
        fieldName: 'applicationName',
        displayName: 'Application name',
        headerClassName: 'header-bg-gray w-160',
        cellClassName: 'w-160 header-bg-gray',
      },
      {
        fieldName: 'applicationStatus',
        displayName: 'Application stage',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144 header-bg-gray',
      },
      {
        fieldName: 'projectIdTxt',
        displayName: 'Project ID',
        headerClassName: 'header-bg-gray w-141 td-8',
        cellClassName: 'w-141 td-8 header-bg-gray',
      },
      {
        fieldName: 'projectName',
        displayName: 'Project name',
        headerClassName: 'header-bg-gray w-165 frozen',
        cellClassName: 'w-165 header-bg-gray frozen',
      },
      {
        fieldName: 'expenditureId',
        displayName: 'Edit',
        headerClassName: 'header-bg-gray w-64',
        cellClassName: 'w-64 header-bg-gray center-align-cell',
      },
      {
        fieldName: 'projectStatusTitle',
        displayName: 'Project status',
        headerClassName: 'header-bg-gray w-181',
        cellClassName: 'w-181 header-bg-gray align-middle',
      },
      {
        fieldName: 'estimatedTotalLGFFFunding',
        displayName: 'Total approved LGFF funding',
        headerClassName: 'w-190 number-column',
        cellClassName: 'w-190 goa-table-number-column',
      },
      {
        fieldName: 'totalRemaining',
        displayName: 'LGFF funding available for this reporting year',
        headerClassName: 'w-190 number-column',
        cellClassName: 'w-190 goa-table-number-column',
      },
      {
        fieldName: 'actualReportingYearCost',
        displayName: 'Actual reporting year cost',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column ',
      },
      {
        fieldName: 'amountFundedOtherGrantPrograms',
        displayName: 'Portion funded by other grant programs',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column',
      },
      {
        fieldName: 'fundingAppliedFromLGFF',
        displayName: 'LGFF funding applied this reporting year',
        headerClassName: 'header-bg-gray w-190 number-column',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
      },
      {
        fieldName: 'fundingAppliedFromMunicipalSources',
        displayName: 'Portion funded from municipal sources',
        cellClassName: 'w-190 header-bg-gray goa-table-number-column',
        headerClassName: 'header-bg-gray w-190 number-column',
      },
    ];

    this.additionalHeaders = [
      {
        displayName: 'Application and project details',
        colspan: 7,
        headerClassName: 'header-bg-gray additionalHeader-contact application-details',
        fieldName: '',
      },
      {
        displayName: 'Application financial information',
        colspan: 2,
        fieldName: '',
        headerClassName: 'additionalHeader-contact financial-info',
        additionalHeaderColspan: 2,
      },
      {
        displayName: 'Reporting year qualifying cost',
        colspan: 4,
        headerClassName: 'header-bg-gray additionalHeader-contact reporting-year',
        fieldName: '',
      },
    ];
  }

  getType(type: string) {
    if (type == 'Not Started' || type == 'In Progress' || type == 'Accepted') {
      return 'information';
    } else if (type == 'Completed') {
      return 'success';
    } else {
      return 'emergency';
    }
  }

  onEditProject(expenditureId: number) {
    if (!this.editBtnDisabled) {
      this.expenditureIdToEdit.emit(expenditureId);
    }
    return;
  }
}
